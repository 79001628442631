import { BarChartOutlined, DeleteOutlined, ExclamationCircleOutlined, FormOutlined, PlusOutlined, ScissorOutlined, SearchOutlined,ReloadOutlined } from '@ant-design/icons'
import { Badge, Button, List, Modal, Pagination} from 'antd'
import React, { Component } from 'react'
import '../../assets/css/OrderList.css'
import OrderBtnDelete from '../../assets/images/img/order_btn_delete.jpg'
import OrderBtnDismantling from '../../assets/images/img/order_btn_dismantling.jpg'
import OrderBtnTransfer from '../../assets/images/img/order_btn_transfer.jpg'
import http from '../api/http'
import IconFont from '../api/IconFont'
import OrderDetail from './OrderDetail'
import OrderForm from './OrderForm'
import OrderTrack from './OrderTrack'
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable'
import Text from 'antd/lib/typography/Text'
const { confirm } = Modal
class OrderList extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.orderDetail = React.createRef()
    this.orderTrack = new React.createRef()
    this.state = {
      loading: false,
      data: [],
      page: 1,
      pageSize: 9,
      showList: true,
      showDetail: false,
      showComponent: false,
      status: '',
      current: 0,
      isTableView: false,
      searchValue: '',
      CncStatus:'',
    }
  }

  onLoading() {
    this.setState({
      loading: true,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }
  toAdd = () => {
    this.form.current.toAdd()
  }
  toEdit = (record) => {
    this.form.current.toEdit(record)
  }

  toDetail = (record, values, isNew) => {
    this.orderDetail.current.toOrderDetail(record.id, null, 'new')
    this.setState({
      // showList: false,
      // showComponent: false,
    })
  }

  showList = () => {
    this.setState({
      showList: true,
    })
  }
  
  delOrder = (record) => {
    let list = this.getOrderList
    confirm({
      title: '这是配置信息，确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,
      content: '这如果有其他外关联信息使用就无法删除',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/order/del?id=' + record.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list()
          })
          .catch(() => {
            Modal.error({
              content: '删除失败,请联系管理员！！！！！！',
            })
          })
      },
      onCancel() {},
    })
  }

  getOrderList = (values) => {
    this.onLoading()
    if (values == null) {
      let values = {}
      values.page = this.state.page
      values.pageSize = this.state.pageSize
    }

    http
      .post('/admin/order/pageQuery', values)
      .then((res) => {
        console.log(res.data.pageList)
        this.setState({
          data: res.data.pageList,
          CncStatus:res.data.CncStatus,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
      })
  }
  orderUpdata=()=>{
    http
    .post('/functionCorn')
    .then((res) => {
      console.log(res)
    })
    .catch(() => {
     
    })
    this.getOrderList();
  }


  onSearch = (value) => {}
  //加载完成
  componentDidMount() {
    this.getOrderList()
  }
  render() {
    return (
      <Panel
      footerBar={
        <Button
        icon={<ReloadOutlined />}
        type="text"
        onClick={() => {
          this.getOrderList()
        }}
      >
        刷新
      </Button>
      }
      footerRight={
        <Pagination
          // 分页
          showTotal={(total) => {
            return <>共 {total} 条</>
          }}
          current={this.state.data.number + 1}
          showSizeChanger={false}
          showQuickJumper={true}
          // pageSize={this.state.pageSize}
          defaultPageSize={this.state.pageSize}
          total={this.state.data.totalElements}
          onChange={(page) => {
            this.setState({
              page: page,
            })
            let values = {}
            values.page = page
            values.pageSize = this.state.pageSize
            values.searchValue = this.state.searchValue
            values.status = this.state.status
            this.getOrderList(values)
          }}
        />
      }
      >
        <div className="order_list" style={{flex:1, display: this.state.showList ? '' : 'none' }}>
          <div className="search">
            <dl>
              <dd
                style={{ backgroundColor: this.state.status === '预算' ? '#ff8000' : '#7f8187' }}
                onClick={() => {
                  this.setState({ status: '预算' })
                  let values = {}
                  values.page = 1
                  values.pageSize = this.state.pageSize
                  values.searchValue = this.state.searchValue
                  values.status = '预算'
                  this.getOrderList(values)
                }}
              >
                预算
              </dd>
              <dd
                style={{ backgroundColor: this.state.status === '在产' ? '#306ce5' : '#7f8187' }}
                onClick={() => {
                  this.setState({ status: '在产' })
                  let values = {}
                  values.page = 1
                  values.pageSize = this.state.pageSize
                  values.searchValue = this.state.searchValue
                  values.status = '在产'
                  this.getOrderList(values)
                }}
              >
                在产
              </dd>
              <dd
                onClick={() => {
                  this.setState({ status: '结束' })
                  let values = {}
                  values.page = 1
                  values.pageSize = this.state.pageSize
                  values.searchValue = this.state.searchValue
                  values.status = '结束'
                  this.getOrderList(values)
                }}
                style={{ backgroundColor: this.state.status === '结束' ? '#38ad5f' : '#7f8187' }}
              >
                已完成
              </dd>
              <dd
                onClick={() => {
                  this.setState({
                    status: '',
                  })
                  let values = {}
                  values.page = 1
                  values.pageSize = this.state.pageSize
                  values.searchValue = this.state.searchValue
                  values.status = ''
                  this.getOrderList(values)
                }}
                style={{ backgroundColor: this.state.status === '全部订单' || this.state.status === '' ? '#306ce5' : '#7f8187' }}
              >
                全部订单
              </dd>
            </dl>
            <div>
              <input
                value={this.state.searchValue}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.getOrderList()
                  }
                }}
                onChange={(e) => {
                  let value = e.target.value
                  this.setState({ searchValue: value })
                }}
                placeholder="请输入搜索的订单号码"
              />
              <dl>
                <dd>
                  <Button
                    icon={<SearchOutlined />}
                    onClick={() => {
                      let values = {}
                      values.page = 1
                      values.pageSize = this.state.pageSize
                      values.searchValue = this.state.searchValue
                      this.getOrderList(values)
                    }}
                  >
                    查询
                  </Button>
                </dd>
                <dd>
                  <Button icon={<PlusOutlined />} onClick={this.toAdd}>
                    新增订单
                  </Button>
                </dd>

              </dl>
              
            
            </div>
            <dl style={{  marginLeft : '-15px' }}>
                <dd>
                  <Button onClick={this.orderUpdata}>
                    更新订单
                  </Button>
                </dd>
              </dl>
          </div>
          {this.state.isTableView ? (
            <ResizeTable
              dataSource={this.state.data.content}
              pagination={{
                // 分页
                showTotal: (total) => {
                  return `共 ${total} 条`
                },
                current: this.state.data.number + 1,
                showSizeChanger: false,
                showQuickJumper: true,
                pageSize: this.state.pageSize,
                defaultPageSize: this.state.pageSize,
                total: this.state.data.totalElements,
                onChange: (page) => {
                  this.setState({
                    page: page,
                  })
                  let values = {}
                  values.page = page
                  values.pageSize = this.state.pageSize
                  values.searchValue = this.state.searchValue
                  values.status = this.state.status
                  this.getOrderList(values)
                },
              }}
              columns={[
                {
                  title: '客户',
                  align: 'center',
                  dataIndex: 'client',
                  key: 'client',
                  width: 120,
                  ellipsis: true,
                  render: (value, record) => {
                    return <>{value.name}</>
                  },
                },
                {
                  title: '订单名称',
                  align: 'center',
                  dataIndex: 'orderName',
                  key: 'orderName',
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: '订单编号',
                  align: 'center',
                  dataIndex: 'orderCode',
                  key: 'orderCode',
                  width: 180,
                  ellipsis: true,
                },
                {
                  title: '零件分类数',
                  align: 'center',
                  dataIndex: 'componentTypeNum',
                  key: 'componentTypeNum',
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '零件总数',
                  align: 'center',
                  dataIndex: 'componentsNum',
                  key: 'componentsNum',
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: '工艺总数',
                  align: 'center',
                  dataIndex: 'stepNum',
                  width: 120,
                  key: 'stepNum',
                  ellipsis: true,
                },
                {
                  title: '创建时间',
                  align: 'center',
                  dataIndex: 'createTime',
                  key: 'createTime',
                  width: 180,
                  ellipsis: true,
                },
                {
                  title: '状态',
                  dataIndex: 'status',
                  align: 'center',
                  width: 120,
                  key: 'status',
                  ellipsis: true,
                },
                {
                  title: '投产零件数',
                  dataIndex: 'operationCompCnt',
                  align: 'center',
                  width: 120,
                  key: 'operationCompCnt',
                  ellipsis: true,
                  render: (value, record) => {
                    return <>{value == null ? 0 : value}</>
                  },
                },
                {
                  title: '已完成',
                  dataIndex: 'doneCompCnt',
                  align: 'center',
                  width: 120,
                  key: 'doneCompCnt',
                  ellipsis: true,
                  render: (value, record) => {
                    return <>{value == null ? 0 : value}</>
                  },
                },
                {
                  title: '操作',
                  dataIndex: 'status',
                  key: 'status',
                  ellipsis: true,
                  render: (value, item) => {
                    return (
                      <>
                        <div className="op">
                          <dl>
                            {item.status === '预算' ? (
                              <>
                                <Button
                                  type="link"
                                  onClick={() => {
                                    this.toDetail(item, '', 'show')
                                  }}
                                  icon={<ScissorOutlined />}
                                >
                                  工艺管理
                                </Button>

                                <Button
                                  icon={<DeleteOutlined />}
                                  type="link"
                                  onClick={() => {
                                    this.delOrder(item)
                                  }}
                                >
                                  删除
                                </Button>
                              </>
                            ) : item.status === '新建' ? (
                              <>
                                <Button
                                  type="link"
                                  onClick={() => {
                                    this.toDetail(item, '', 'show')
                                  }}
                                  icon={<ScissorOutlined />}
                                >
                                  工艺管理
                                </Button>

                                <Button
                                  icon={<DeleteOutlined />}
                                  type="link"
                                  onClick={() => {
                                    this.delOrder(item)
                                  }}
                                >
                                  删除
                                </Button>
                              </>
                            ) : item.status === '结束' ? (
                              <>
                                <Button
                                  icon={<ScissorOutlined />}
                                  type="link"
                                  onClick={() => {
                                    this.toDetail(item)
                                  }}
                                >
                                  工艺管理
                                </Button>
                                <Button
                                  type="link"
                                  icon={<BarChartOutlined />}
                                  onClick={() => {
                                    this.toDetail(item)
                                  }}
                                >
                                  生产报表
                                </Button>
                              </>
                            ) : item.status === '在产' ? (
                              <>
                                <Button
                                  type="link"
                                  icon={<ScissorOutlined />}
                                  onClick={() => {
                                    this.toDetail(item)
                                  }}
                                >
                                  工艺管理
                                </Button>
                                <Button
                                  icon={<FormOutlined />}
                                  type="link"
                                  onClick={() => {
                                    this.orderTrack.current.toOrderDetail(item.id)
                                  }}
                                >
                                  生产跟踪
                                </Button>
                              </>
                            ) : (
                              ''
                            )}
                          </dl>
                        </div>
                      </>
                    )
                  },
                },
              ]}
            ></ResizeTable>
          ) : (
            <List
              dataSource={this.state.data.content}
              pagination={false}
              renderItem={(item) => (
                <List.Item>
                  <Badge.Ribbon
                    text={item.extraOrderCode != null ? item.extraOrderCode : item.orderCode}
                    color={
                      // item.status === '预算' ? '#ff8000' : item.status === '拆解' ? '#ff8000' : ''
                      item.status === '预算' ? '#ff8000' : item.status === '在产' ? '#306ce5' : item.status === '已完成' ? '#38ad5f' : '#38ad5f'
                    }
                  >
                    <div className="t">
                      <div className="l">
                        <div className="title">
                          {item.status === '新建' || item.status === '' || item.status == null ? (
                            <span class="s1">新建</span>
                          ) : item.status === '预算' ? (
                            <span class="s1">预算</span>
                          ) : item.status === '在产' ? (
                            <span class="s2">在产</span>
                          ) : item.status === '已完成' ? (
                            <span class="s3">已完成</span>
                          ) : (
                            <span class="s3">结束</span>
                          )}
                          <p>客户：{item.client.name}</p>
                        </div>
                        <div className="con">
                          <div className="txt">
                            <div style={{ marginBottom: '6px' }}>
                              <IconFont size="20px" type="icon-dingdan"></IconFont>
                              <dd>订单名称 : {item.orderName}</dd>
                            </div>
                            <dl>
                              <dd>
                                <IconFont size="20px" type="icon-gongyede-10"></IconFont>
                                <p>零件分类数 : {item.componentTypeNum}</p>
                              </dd>
                              <dd>
                                <IconFont size="20px" type="icon-gongyede-19"></IconFont>
                                <p>零件总数 : {item.componentsNum}</p>
                              </dd>
                              <dd>
                                <IconFont size="20px" type="icon-gongneng_zhizaoqiye"></IconFont>
                                <p>工艺总数 : {item.stepNum}</p>
                              </dd>
                              <dd>
                                <IconFont size="20px" type="icon-gongneng_zhizaoqiye"></IconFont>
                                <p>CNC拆解状态 :<Text strong style={{color:"red"}}> {this.state.CncStatus[item.id]?this.state.CncStatus[item.id]:''}</Text></p>
                              </dd>
                              <dd>
                                <IconFont size="20px" type="icon-gongyede-29"></IconFont>
                                <p>创建时间 : {item.createTime}</p>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                      <div className="op">
                        <dl>
                          {item.status === '预算' ? (
                            <div>
                              <dd
                                className="OrderBtnDismantling"
                                onClick={() => {
                                  this.toDetail(item)
                                }}
                              >
                                <img src={OrderBtnDismantling} />
                                <p>工艺管理</p>
                              </dd>

                              <dd
                                className="OrderBtnDelete"
                                onClick={() => {
                                  this.delOrder(item)
                                }}
                              >
                                <img src={OrderBtnDelete} />
                                <p>删除</p>
                              </dd>
                            </div>
                          ) : item.status === '新建' || item.status == null ? (
                            <div>
                              <dd
                                className="OrderBtnDismantling"
                                onClick={() => {
                                  this.toDetail(item)
                                }}
                              >
                                <img src={OrderBtnDismantling} />
                                <p>工艺管理</p>
                              </dd>

                              <dd
                                className="OrderBtnDelete"
                                onClick={() => {
                                  this.delOrder(item)
                                }}
                              >
                                <img src={OrderBtnDelete} />
                                <p>删除</p>
                              </dd>
                            </div>
                          ) : item.status === '结束' ? (
                            <div>
                              <dd
                                className="OrderBtnDismantling"
                                onClick={() => {
                                  this.toDetail(item)
                                }}
                              >
                                <img src={OrderBtnDismantling} />
                                <p>工艺管理</p>
                              </dd>
                              <dd
                                className="OrderBtnTransfer"
                                onClick={() => {
                                  this.toDetail(item)
                                }}
                              >
                                <img src={OrderBtnTransfer} />
                                <p>生产报表</p>
                              </dd>
                            </div>
                          ) : item.status === '在产' ? (
                            <div>
                              <dd
                                className="OrderBtnDismantling"
                                onClick={() => {
                                  this.toDetail(item)
                                }}
                              >
                                <img src={OrderBtnDismantling} />
                                <p>工艺管理</p>
                              </dd>
                              <dd
                                className="OrderBtnTransfer"
                                onClick={() => {
                                  this.orderTrack.current.toOrderDetail(item.id)
                                }}
                              >
                                <img src={OrderBtnTransfer} />
                                <p>生产跟踪</p>
                              </dd>
                            </div>
                          ) : (
                            ''
                          )}
                        </dl>
                      </div>
                    </div>
                  </Badge.Ribbon>
                </List.Item>
              )}
            />
          )}
        </div>
        <OrderDetail ref={this.orderDetail} table={this}></OrderDetail>
        <OrderTrack ref={this.orderTrack} table={this}></OrderTrack>
        <OrderForm ref={this.form} table={this}></OrderForm>
      </Panel>
    )
  }
}

export default OrderList
