//使用抽屉进行编辑
import {
  DownloadOutlined,
  FrownOutlined,
  MehOutlined,
  SmileOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "@ant-design/pro-card/dist/card.css";
import ProList from "@ant-design/pro-list";
import "@ant-design/pro-form/dist/form.css";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  message,
  Modal,
  Progress,
  Radio,
  Row,
  Select,
  Spin,
  Steps,
  Tag,
  Upload,
} from "antd";
import { Meta } from "antd/lib/list/Item";
import React, { Component } from "react";
import Panel from "../../component/etooling-ui/Panel";
import Storage from "../../utils/Storage";
import http, { getApiMainPath } from "../api/http";
import IconFont from "../api/IconFont";
const { Step } = Steps;
const { TextArea } = Input;
const { Option } = Select;
class UploadComponent extends Component {
  constructor(props) {
    super(props);
    this.orderBaseForm = React.createRef();
    this.orderBomForm = React.createRef();
    this.state = {
      visible: false,
      loading: false,
      newClientName: "",
      newClientMobile: "",
      newOrderCode: "",
      title: "新增订单",
      selectClientId: "",
      selectItem: {},
      current: 0,
      uploading: false,
      bomInputType: "上传文件批量",
      doneUploadBoxFile: false,
      doneUploadPdfFile: false,
      bomExcelFileUrl: "",
      bomExcelRowNum: 0,
      bomExcelFileId: "",
      bomExcelFileList: [],
      clientOptions: [],
      orderId: "",
      fileRootPath: "",
      impExcelFileDetail: {},
      impExcelFileId: "",
      impPdfFiles: [],
      newComponents: [],
      bomExcelErrComponents: [],
      pdfErrComponents: [],
      checkData: false,
      poDetailPagination: [],
      componentList: [],
      componentPdf: [],
      match: false,
    };
  }
  downloadBom = () => {
    window.open(this.state.fileRootPath + "/tmplatefile/bom.xlsx");
  };

  downloadPdfZip = () => {
    window.open(this.state.fileRootPath + "/tmplatefile/drawing.zip");
  };
  onChange = (current) => {
    if (current === 1) {
      this.next();
    } else {
      this.setState({ current });
    }
  };
  onAreaChange = (area) => {
    console.log(area);
  };
  onLoading = () => {
    this.setState({
      loading: true,
    });
  };
  offLoading = () => {
    this.setState({
      loading: false,
    });
  };
  getClientList = (values) => {
    http
      .post("/admin/client/getAllClient", values)
      .then((res) => {
        console.log(res);
        this.setState({
          clientOptions: res.data,
        });
        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };
  addClient = () => {
    this.onLoading();
    let values = {};
    values.newClientName = this.state.newClientName;
    values.newClientMobile = this.state.newClientMobile;
    http
      .post("/admin/client/addTmpUser", values)
      .then((res) => {
        message.success("添加用户完成");
        let clientOptions = this.state.clientOptions;
        clientOptions.unshift(res.data);
        this.setState({
          clientOptions,
        });
        this.offLoading();
      })
      .catch((err) => {
        console.log("失败", err);
        message.error("新增数据失败!");
        this.offLoading();
      });
  };

  toAdd = (parentId, pOrderName, pOrderCode) => {
    this.setState({
      orderId: parentId,
      visible: true,
      title: pOrderName + "[" + pOrderCode + "]新增子订单",
      current: 1,
      newClientName: "",
      newClientMobile: "",
      newOrderCode: "",
      selectItem: null,
      uploading: false,
      bomInputType: "上传文件批量",
      doneUploadBoxFile: false,
      bomExcelFileUrl: "",
      bomExcelRowNum: 0,
      bomExcelFileId: "",
      bomExcelFileList: [],
      fileRootPath: "",
      impExcelFileDetail: {},
      impExcelFileId: "",
      impPdfFileIds: [],
      bomPdfFileList: [],
      impPdfFiles: [],
      newComponents: [],
      bomExcelErrComponents: [],
      pdfErrComponents: [],
      checkData: false,
      componentList: [],
      componentPdf: [],
      match: false,
      componentAndPdf: {},
    });
    this.getClientList();
    let values = {};
    values.id = parentId;
    http.post("/admin/order/clearComponents", values).then((res) => {});
  };

  toCheckUploadData = () => {
    this.onLoading();
    let values = {};
    values.orderId = this.state.orderId;
    http
      .post("/admin/order/checkUploadOrderData", values)
      .then((res) => {
        let checkData = res.data;
        let impPdfFiles = this.state.impPdfFiles;
        if (checkData.pdfErrComponents.length > 0) {
          impPdfFiles = [];
        }
        this.setState({
          newComponents: checkData.newComponents,
          bomExcelErrComponents: checkData.bomExcelErrComponents,
          pdfErrComponents: checkData.pdfErrComponents,
          impPdfFiles: impPdfFiles,
          checkData: checkData.pdfErrComponents.length === 0,
        });
        this.offLoading();
      })
      .catch(() => {
        console.log("失败");
        this.offLoading();
      });
  };
  toSubmitBomFomr = () => {
    let url = "/admin/order/saveComponentAndPdf";
    let values = {};
    values.id = this.state.orderId;
    values.componentAndPdf = JSON.stringify(this.state.componentAndPdf);
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: "更新数据完成",
        });
        this.props.table.toOrderDetail(this.state.orderId, null, null, null);
        this.setState({
          visible: false,
        });
        this.offLoading();
      })
      .catch((err) => {
        console.log(err);
        this.offLoading();
      });
    this.onLoading();
    this.offLoading();
  };

  getComponentPdf = () => {
    this.onLoading();
    let values = {};
    values.id = this.state.orderId;
    http.post("/admin/order/getComponentPdf", values).then((res) => {
      this.setState({ match: true, componentPdf: res.data });
      this.offLoading();
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    // this.props.table.getOrderList()
  };
  uploadBomExcel = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.

      let uploadData = info.file.response.data;
      console.log(uploadData);
      if (uploadData != null && "" !== uploadData) {
        message.success(`${info.file.name} 上传完成`);
        this.setState({
          uploading: false,
          impExcelFileId: uploadData.fileId,
          orderId: uploadData.orderId,
          impExcelFileDetail: uploadData.imporDetail,
          doneUploadBoxFile: true,
          current: 1,
          componentList: uploadData.componentList,
        });
      } else {
        message.error(`${info.file.name} 失败,请检查模板格式`);
      }
    }
  };
  beforeExcelUpload = (file) => {
    let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
    let checkFile = false;
    if (fileType === "xls") {
      checkFile = true;
    }
    if (fileType === "xlsx") {
      checkFile = true;
    }

    if (!checkFile) {
      message.error("只能上传excel文件!");
    } else {
      let fileList = this.state.bomExcelFileList;
      fileList.splice(0, fileList.length);
      fileList.push(file);
      this.setState({ bomExcelFileList: fileList });
    }
    return checkFile;
  };
  uploadBomPdf = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ uploading: true });

      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      // message.success(`${info.file.name} 上传完成`)

      let impPdfFiles = this.state.impPdfFiles;

      impPdfFiles.push(info.file);
      this.setState({
        impPdfFiles,
        doneUploadPdfFile: true,
      });
    }
  };
  beforeZipUpload = (file) => {
    let fileType = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    let checkFile = false;
    if (fileType === "pdf" || fileType === "PDF") {
      checkFile = true;
    }
    if (fileType === "jpg") {
      checkFile = true;
    }
    if (fileType === "jpeg") {
      checkFile = true;
    }
    if (fileType === "png") {
      checkFile = true;
    }
    if (!checkFile) {
      message.error("图片只接受pdf/jpg/jpeg/png文件上传");
    }
    return checkFile;
  };

  componentDidMount() {
    let fileRootPath = Storage.get("fileRootPath");
    // console.log('fileRootPath', fileRootPath)
    this.setState({
      fileRootPath,
    });
  }

  componentWillUnmount() {}

  //组件更新完成
  componentDidUpdate() {}

  render() {
    return (
      <div>
        <Spin spinning={this.state.loading} delay={500}>
          <Modal
            footer={
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                  取消
                </Button>
                {this.state.match ? (
                  <Button onClick={this.toSubmitBomFomr} type="primary">
                    保存
                  </Button>
                ) : (
                  <Button onClick={this.getComponentPdf} type="primary">
                    上传完成
                  </Button>
                )}
              </div>
            }
            forceRender={true}
            title={this.state.title}
            width={1300}
            style={{ marginTop: "5%" }}
            onClose={this.onClose}
            onCancel={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form
              layout="vertical"
              submitter={false}
              autoComplete="off"
              ref={this.orderBomForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              onFinish={this.submitBomForm}
              style={{
                marginTop: 20,
                display: this.state.current === 1 ? "" : "none",
              }}
            >
              <Row gutter={24}>
                <Panel layout="h-box">
                  {this.state.componentList.length > 0 ? (
                    <Panel title={"零件明细"}>
                      <ul
                        style={{
                          overflowX: "hidden",
                          overflowY: "auto",
                          height: "500px",
                          padding: "20px",
                        }}
                      >
                        {this.state.componentList.map((item, index) => (
                          <li
                            style={{
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <Checkbox
                              value={item}
                              onChange={(e) => {
                                let data = this.state.componentAndPdf;
                                this.setState({ component: e.target.value });
                                console.log(e.target.value);
                                let componentData =
                                  data[e.target.value.id] == null
                                    ? {}
                                    : data[e.target.value.id];
                                data[e.target.value.id] = componentData;
                                console.log(data);
                                this.setState({ componentAndPdf: data });
                              }}
                              style={{ float: "left", marginTop: "-4px" }}
                            ></Checkbox>
                            <p style={{ float: "left", marginLeft: "10px" }}>
                              {item.componentName}
                            </p>
                            <div>
                              {item.file != null ? 
                              <div>
                                <p style={{ float: "left", marginLeft: "10px" }}>
                                ({item.file.name})
                              </p>
                              <IconFont
                                type="icon-cuowu"
                                style={{
                                  display: "block",
                                  float: "left",
                                  margin: "0 0 0 3px",
                                  color: "red",
                                  fontSize: "16px",
                                }}
                                onClick={(e) => {
                                  let componentPdf = this.state.componentPdf
                                  let componentList = this.state.componentList
                                  for (let index = 0; index < componentPdf.length; index++) {
                                    if(componentPdf[index].id === item.file.id){
                                      componentPdf[index].status = '取消'
                                    }
                                  }
                                  for (let j = 0; j < componentList.length; j++) {
                                    if(componentList[j].file != null){
                                      if(componentList[j].file.id === item.file.id){
                                        componentList[j].file = null
                                      }
                                    }
                                  }
                                  this.setState({
                                    componentPdf,
                                    componentList
                                  })
                                }}
                              ></IconFont>
                              </div>
                              : ""}
                              
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Panel>
                  ) : (
                    <Upload
                      fileList={this.state.bomExcelFileList}
                      name="uploadFile"
                      maxCount={1}
                      action={
                        getApiMainPath() +
                        "/admin/order/uploadComponentExcel?clientId=" +
                        this.state.selectClientId +
                        "&orderCode=" +
                        this.state.newOrderCode +
                        "&orderId=" +
                        this.state.orderId
                      }
                      beforeUpload={this.beforeExcelUpload}
                      onChange={this.uploadBomExcel}
                    >
                      <Button icon={<UploadOutlined />}>上传零件excel</Button>
                    </Upload>
                  )}

                  {this.state.componentPdf.length > 0 ? (
                    <Panel flex="3" title={"零件图"}>
                      <div
                        style={{
                          overflowX: "hidden",
                          overflowY: "auto",
                          height: "500px",
                        }}
                      >
                        <List
                          pagination={false}
                          dataSource={this.state.componentPdf}
                          grid={{ gutter: 16, column: 4 }}
                          renderItem={(item) =>
                            item.status !== "选中" ? (
                              <List.Item>
                                <Badge size="default">
                                  <Card
                                    title={
                                      <>
                                        {this.state.component != null ? (
                                          <Checkbox
                                            value={item}
                                            onChange={(e) => {
                                              let pdfList =
                                                this.state.componentPdf;
                                              let data =
                                                this.state.componentAndPdf;
                                              data[this.state.component.id] =
                                                e.target.value.id;
                                              for (let index = 0;
                                                index < pdfList.length;
                                                index++) {
                                                const element = pdfList[index];
                                                if (
                                                  element.id ===
                                                  e.target.value.id
                                                ) {
                                                  element.componentName =
                                                    this.state.component.componentName;
                                                  element.status = "选中";
                                                }
                                              }
                                              let componentList = this.state.componentList
                                              for (let j = 0; j < componentList.length; j++) {
                                                if(componentList[j].id === this.state.component.id){
                                                  componentList[j].file = item
                                                }
                                              }
                                              this.setState({
                                                componentAndPdf: data,
                                                componentPdf: pdfList,
                                                componentList
                                              });
                                            }}
                                          >
                                          </Checkbox>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    }
                                    cover={
                                      <div
                                        style={{
                                          padding: "7px 5px",
                                          borderBott: "1px solid #eee",
                                        }}
                                      >
                                        {item.fileType === ".pdf" ||
                                        item.fileType === ".PDF" ? (
                                          <embed
                                            align="middle"
                                            allowscriptaccess="never"
                                            allowfullscreen="true"
                                            mode="transparent"
                                            src={
                                              item.webPath == null
                                                ? ""
                                                : item.webPath
                                            }
                                            style={{ minHeight: 200 }}
                                            width={"100%"}
                                          />
                                        ) : (
                                          <img
                                            style={{
                                              width: "100%",
                                              height: "150px",
                                            }}
                                            src={
                                              item.webPath == null
                                                ? "/.paf"
                                                : item.webPath
                                            }
                                          />
                                        )}
                                      </div>
                                    }
                                    hoverable={true}
                                  ></Card>
                                </Badge>
                              </List.Item>
                            ) : (
                              ""
                            )
                          }
                        />
                      </div>
                    </Panel>
                  ) : (
                    <div
                      style={{
                        overflowX: "hidden",
                        overflowY: "auto",
                        height: "500px",
                      }}
                    >
                      <Upload
                        name="uploadPdfFile"
                        directory
                        action={
                          getApiMainPath() +
                          "/admin/order/uploadBomPdf?clientId=" +
                          this.state.selectClientId +
                          "&orderCode=" +
                          this.state.newOrderCode +
                          "&orderId=" +
                          this.state.orderId
                        }
                        onChange={this.uploadBomPdf}
                        beforeUpload={this.beforeZipUpload}
                      >
                        <Button icon={<UploadOutlined />}>
                          选择零件工艺文件夹批量上传
                        </Button>
                      </Upload>
                    </div>
                  )}
                </Panel>
              </Row>

              <Row gutter={24}>
                {this.state.bomInputType === "上传文件批量" &&
                this.state.doneUploadBoxFile ? (
                  <Col span={12}>
                    <Form.Item name="components_count" label="上传文件情况:">
                      <div style={{ float: "left", marginLeft: 10 }}>
                        上传记录数:
                        {this.state.impExcelFileDetail.imp_count != null
                          ? this.state.impExcelFileDetail.imp_count
                          : 0}
                      </div>
                      <div style={{ float: "left", marginLeft: 10 }}>
                        零件型号数：
                        {this.state.impExcelFileDetail.components_count != null
                          ? this.state.impExcelFileDetail.components_count
                          : 0}
                      </div>
                      <div style={{ float: "left", marginLeft: 10 }}>
                        零件总数：
                        {this.state.impExcelFileDetail.components_num != null
                          ? this.state.impExcelFileDetail.components_num
                          : 0}
                      </div>
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

              </Row>
            </Form>
          </Modal>
        </Spin>
      </div>
    );
  }
}

export default UploadComponent;
