import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import "@ant-design/pro-card/dist/card.css";
import {
  Button,
  Cascader,
  Input,
  message,
  Space,
  Table,
  DatePicker,
  Pagination,
} from "antd";
import ResizeTable from "../../component/etooling-ui/ResizeTable";
import Panel from "../../component/etooling-ui/Panel";
import moment from "moment";
import React, { Component } from "react";
import http from "../api/http";
const { RangePicker } = DatePicker;
let modelPath = "/admin/components/";

export default class EmployeeComponentList extends Component {
  constructor(props) {
    super(props);
    this.orderCompile = React.createRef();
    this.state = {
      pagination: { number: 1 },
      orderField: "",
      orderType: "",
      searchValue: "",
    
      batchFile: [],
      page: 1,
      pageSize: 15,
    };
  }
  list = (values) => {
    if (values == null) {
      values = {};
    }
    values.page = values.page;
    values.pageSize = this.state.pageSize;
    values.searchName = this.state.searchValue;
    
    values.startDate = this.state.startDate;
    values.endDate = this.state.endDate;
    http
      .post(modelPath + "pageQueryByEmployee", values)
      .then((res) => {
        let batchFile = [];
        for (let i = 0; i < res.data.content.length; i++) {
          let file = {};
          file.web_path = res.data.content[i].web_path;
          file.file_type = res.data.content[i].file_type;
          file.name = res.data.content[i].name;
          batchFile.push(file);
          this.setState({ batchFile });
        }
        let pagination = res.data;
        this.setState({ pagination });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toExcel = (values) => {
    if (values == null) {
      values = {};
    }
    values.searchName = this.state.searchValue;
   
    values.startDate = this.state.startDate;
    values.endDate = this.state.endDate;
    http
      .download(modelPath + "toExcelByEmploee", values, "零件台账清单.xlsx")
      .then((res) => {})
      .catch((err) => {
        message.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };

  patchDownload = (values) => {
    if (values == null) {
      values = {};
    }
    values.searchName = this.state.searchValue;
   
    http.post(modelPath + "componentList", values).then((res) => {
      let outPutZipFileName = "文件";
      http.batchDownload(outPutZipFileName, res.data);
    });
  };

  componentDidMount() {
    this.list();
  }

  render() {
    return (
      <Panel
        title="零件列表"
        fontWeight
        topBar={
          <Space size={16}>
            关键字搜索：
            <Input
              placeholder="图号或物料名称"
              value={this.state.searchValue}
              onChange={(e) => {
                this.setState({
                  searchValue: e.target.value,
                });
              }}
            />
            <RangePicker
              onChange={(date, dateString) => {
                let startDate = dateString[0];
                let endDate = dateString[1];
                console.log("start", startDate);
                console.log("endDate", endDate);
                this.setState({ startDate, endDate });
              }}
            ></RangePicker>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                this.list();
              }}
            >
              查询
            </Button>
            <Button
              type="primary"
              onClick={() => {
                // this.toExcel()
                this.toExcel();
              }}
              key="primary"
            >
              导出报表
            </Button>
      
          </Space>
        }
        footerBar={
          <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              this.list();
            }}
          >
            刷新
          </Button>
        }
        footerRight={
          <Pagination
            showQuickJumper={false}
            pageSize={
              this.state.pagination == null ? this.state.pagination.size : 10
            }
            current={
              this.state.pagination == null
                ? 1
                : this.state.pagination.number + 1
            }
            defaultPageSize={
              this.state.numberOfElements == null
                ? 10
                : this.state.pagination.numberOfElements
            }
            total={this.state.pagination.totalElements}
            showTotal={(total) => {
              return <>共 {total} 条</>;
            }}
            onChange={(page, pageSzie) => {
              let values = {};
              values.page = page;
              values.pageSize = this.state.pageSize;
              values.searchName = this.state.searchValue;
              this.list(values);
            }}
          />
        }
      >
        <ResizeTable
          bordered
          dataSource={
            this.state.pagination == null ? [] : this.state.pagination.content
          }
          pagination={false}
          size="small"
          columns={[
            {
                title: "员工姓名",
                dataIndex: "username",
                key: "username",
                ellipsis: true,
                sorter: (a, b) => {},
                width: 120,
              },
            {
              title: "订单名称",
              dataIndex: "order_name",
              key: "order_name",
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: "订单编码",
              dataIndex: "extra_order_code",
              key: "extra_order_code",
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: "零件名称",
              dataIndex: "component_name",
              key: "component_name",
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: "零件编码",
              dataIndex: "component_code",
              key: "component_code",
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },

            {
              title: "零件数",
              dataIndex: "components_num",
              key: "components_num",
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: "所属工艺",
              dataIndex: "craft_type_name",
              key: "craft_type_name",
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },

            {
              title: "完成时间",
              dataIndex: "done_time",
              key: "done_time",
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
              render: (value, record) => {
                return <>{moment(value).format("YYYY-MM-DD")}</>;
              },
            },
            {
              title: "质检状态",
              dataIndex: "final_qc_status",
              key: "final_qc_status",
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: "质检时间",
              dataIndex: "final_qc_time",
              key: "final_qc_time",
              ellipsis: true,
              render: (value, record) => {
                return <>{moment(value).format("YYYY-MM-DD")}</>;
              },
              sorter: (a, b) => {},
              width: 120,
            },
          ]}
        ></ResizeTable>
      </Panel>
    );
  }
}
