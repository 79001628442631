import { ExclamationCircleOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, DatePicker, message, Row, Spin, Table, Space, Radio, Pagination } from 'antd'
import React, { Component } from 'react'
import '../../App.css'
import http from '../api/http'
import ResizeTable from "../../component/etooling-ui/ResizeTable";
import Panel from "../../component/etooling-ui/Panel";
import DeliveryForm from './DeliveryFrom'
const { RangePicker } = DatePicker
const { confirm } = Modal
class ClientForm extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.searchForm = React.createRef()
    this.state = {
      orderName:"",
      sortedInfo: null,
      selectItem: {},
      loading: false,
      page: 1,
      pageSize: 15,
      filteredInfo: null,
      //测试数据
      data: [],
    }
  }

  //加载完成
  componentDidMount() {
    let values = {}
    values.page = this.state.page
    values.pageSize = this.state.pageSize
    this.getList(values)
  }

  onLoading() {
    this.setState({
      loading: true,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }
  getList = (values) => {
    this.onLoading()
    if (values == null) {
      values = {}
    }
    values.startDate = this.state.startDate
    values.endDate = this.state.endDate
    values.orderName = this.state.orderName
    http
      .post('/admin/delivery/pageQuery', values)
      .then((res) => {
        this.setState({
          data: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  changePage = (page) => {
    let values = {}
    values.page = page
    values.pageSize = this.state.pageSize
    this.getList(values)
  }

  toAdd = () => {
    this.form.current.toAdd()
  }
  toExcel = () => {
      let values = {}
      values.startDate = this.state.startDate
      values.endDate = this.state.endDate
      values.orderName = this.state.orderName
      http
        .download('/admin/delivery/deliveryToExcel', values, '项目自加工送货清单.xlsx')
        .then((res) => {})
        .catch((err) => {
          message.error({
            content: '数据加载失败请联系系统管理员',
          })
        })
  }
  toEdit = (record) => {
    this.form.current.toEdit(record)
  }
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    })
  }

  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    })
    this.getList()
  }

  render() {
    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}
    // 表头
    const columns = [
      {
        title: '图号',
        dataIndex: 'componentCode',
        key: 'componentCode',
        sorter: (a, b) => a.groupName.length - b.groupName.length,
        ellipsis: true,
        width: 140,
      },

      {
        title: '物料名称',
        dataIndex: 'componentName',
        key: 'componentName',
        sorter: (a, b) => a.displayValue - b.displayValue,
        ellipsis: true,
        width: 120,
      },
      {
        title: '材质',
        dataIndex: 'materialName',
        key: 'materialName',
        ellipsis: true,
        width: 120,
      },
      {
        title: '数量',
        dataIndex: 'num',
        key: 'num',
        ellipsis: true,
        width: 120,
      },
      {
        title: '入库日期',
        dataIndex: 'deliveryTime',
        key: 'deliveryTime',
        ellipsis: true,
        width: 120,
      },
      {
        title: '总数量',
        dataIndex: 'total',
        key: 'total',
        ellipsis: true,
        width: 120,
      },
      {
        title: '用途',
        dataIndex: 'orderName',
        key: 'orderName',
        width: 120,
        ellipsis: true,
      },
      {
        title: 'PR单号',
        dataIndex: 'prNumbers',
        key: 'prNumbers',
        width: 120,
        ellipsis: true,
      },
      {
        title: '备注',
        dataIndex: 'remarks',
        key: 'remarks',
        width: 120,
        ellipsis: true,
      },
      {
        title: '状态',
        dataIndex: 'state',
        key: 'state',
        width: 120,
        ellipsis: true,
      },
      {
        title: '处理人',
        dataIndex: 'userName',
        key: 'userName',
        width: 120,
        ellipsis: true,
      },
      {
        title: '操作',
        width: 140,
        key: 'action',
        render: (value, record) => {
          return (
            <div>
              <Button type="link" onClick={this.toEdit.bind(this, record)}>
                编辑
              </Button>
            </div>
          )
        },
      },
    ]

    return (
      <Panel
       title="入库清单"
       fontWeight
        bodyPadding="15"
        topBar={
          <>
            <Space size={16}>
              关键字搜索：
            <Input placeholder="图号或物料名称" onChange={(value) => {
              this.setState({orderName:value.target.value})
            }} />
                  <RangePicker
                  onChange={(date, dateString) => {
                    let startDate = dateString[0]
                    let endDate = dateString[1]
                    this.setState({ startDate, endDate })
                  }}
                ></RangePicker>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => {
                  this.getList();
                }}
              >
                查询
              </Button>
              <Button type="primary" onClick={()=>{
                      this.toExcel()

                  }} key="primary">
                   导出报表
                  </Button>
            </Space>
          </>
        }
        footerBar={
          <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              this.getList();
            }}
          >
            刷新
          </Button>
        }
        footerRight={
          <Pagination
            showQuickJumper={false}
            pageSize={this.state.data == null ? this.state.data.size : 15}
            current={this.state.data == null ? 1 : this.state.data.number + 1}
            defaultPageSize={
              this.state.numberOfElements == null
                ? 15
                : this.state.data.numberOfElements
            }
            total={this.state.data.totalElements}
            showTotal={(total) => {
              return <>共 {total} 条</>;
            }}
            onChange={(page, pageSzie) => {
              let values = {
                pageSize: pageSzie,
                page: page,
                searchName: this.state.searchValue,
              };
              this.getList(values)
            }}
          />
        }
      >
        <ResizeTable
          bordered
          dataSource={this.state.data == null ? [] : this.state.data.content}
          pagination={false}
          size="small"
          columns={columns}
        ></ResizeTable>
        <DeliveryForm ref={this.form} getList={this.getList} table={this} />
      </Panel>

    )
  }
}
ClientForm.propTypes = {}

export default ClientForm
