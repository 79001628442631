import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import { Button, Col, Form, Input, message, Modal, Row, Space, Table, Transfer, Tree } from "antd";
import confirm from "antd/lib/modal/confirm";
import React, { Component } from "react";
import http from "../api/http";
import EmployeeForm from "./EmployeeForm";

//容器组件
import Container from "../../component/ModuleAssembly/Container";

export default class EmpolyeeMgr extends Component {
  constructor(props) {
    super(props);
    this.form1 = React.createRef();
    this.state = {
      loading: false,
      data: {},
      orgTree: {},
      allRoles: [],
      targetRoleKeys: [],
      allCraftType: [],
      targetCraftKeys: [],
      pagination: {},
      selectOrg: null,
      selectUser: null,
    };
  }

  onLoading = () => {
    this.setState({
      loading: true,
    });
  };
  offLoading = () => {
    this.setState({
      loading: false,
    });
  };

  getOrgTree = () => {
    this.onLoading();
    http
      .post("/admin/org/getAntdTree", "")
      .then((res) => {
        if (res.data.treeData.length === 1) {
          this.setState({
            selectOrg: res.data.treeData[0],
          });
        }
        this.setState({
          treeData: res.data.treeData,
        });

        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        // Modal.error({
        //   content: '加载机构树出错',
        // })
      });
  };
  getRoles = () => {
    http
      .post("/admin/role/getAllRoles", "")
      .then((res) => {
        this.setState({
          allRoles: res.data,
        });

        this.offLoading();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCraftTypes = () => {
    http
      .post("/admin/craftType/getAllCraftTypes", "")
      .then((res) => {
        console.log(res);
        this.setState({
          allCraftType: res.data.craftsTypes,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onRoleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({
      targetRoleKeys: nextTargetKeys,
    });
  };

  onCraftTypeChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({
      targetCraftKeys: nextTargetKeys,
    });
  };

  toAdd = () => {
    if (this.state.selectOrg != null) {
      this.form1.current.toAdd(this.state.selectOrg.key);
    } else {
      message.warning("请选择机构,再进行员工添加");
    }
  };

  toEdit = (record) => {
    this.form1.current.toEdit(record);
  };

  del = (record) => {
    let list = this.getEmployeeList;
    let org = this.state.selectOrg;
    confirm({
      title: "这是配置信息，确认删除这条记录?",
      icon: <ExclamationCircleOutlined />,
      content: "这如果有其他外关联信息使用就无法删除",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        http
          .post("/admin/userMgr/del?id=" + record.id, "")
          .then((res) => {
            message.success("删除成功");
            list();
          })
          .catch((err) => {
            console.log(err);
            message.error("删除失败");
            list();
          });
      },
      onCancel() {},
    });
  };

  getEmployeeList = (values) => {
    if (values == null) {
      values = {};
      values.orgId = this.state.selectOrg.key;
      values.searchName=this.state.searchName;
    }
    http
      .post("/admin/userMgr/pageQuery", values)
      .then((res) => {
        console.log(res);
        this.setState({
          pagination: res.data,
        });

        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        // Modal.error({
        //   content: '加载机构树出错',
        // })
      });
  };
  componentDidMount() {
    this.getOrgTree();
    let values = {};
    values.page = this.state.page;
    values.pageSize = this.state.pageSize;
    this.getEmployeeList(values);
    this.getRoles();
    this.getCraftTypes();
  }

  saveRole = () => {
    let selectUser = this.state.selectUser;
    if (selectUser != null) {
      let targetRoleKeys = this.state.targetRoleKeys;
      let roleIds = "";
      targetRoleKeys.forEach((roleId) => {
        roleIds = roleIds + roleId + ",";
      });
      let values = {};
      values.roleIds = roleIds;
      values.userId = selectUser.id;
      http
        .post("/admin/userMgr/saveRoles", values)
        .then((res) => {
          selectUser.roles = res.data;
          this.setState({
            selectUser,
          });
          message.success("授权完成");
        })
        .catch((err) => {
          console.log(err);
          message.error("保存失败");
        });
    } else {
      message.error("请先选择用户，再进行授权");
    }
  };
  saveCraft = () => {
    let selectUser = this.state.selectUser;
    if (selectUser != null) {
      let targetCraftKeys = this.state.targetCraftKeys;
      let craftTypes = "";
      targetCraftKeys.forEach((craftType) => {
        craftTypes = craftTypes + craftType + ",";
      });
      let values = {};
      values.craftTypes = craftTypes;
      values.userId = selectUser.id;
      http
        .post("/admin/userMgr/saveCraftType", values)
        .then((res) => {
          selectUser.haveCraft = res.data;
          this.setState({
            selectUser,
          });
          message.success("授权完成");
        })
        .catch((err) => {
          console.log(err);
          message.error("保存失败");
        });
    } else {
      message.error("请先选择用户，再进行授权");
    }
  };

  render() {
    // 表头
    const columns = [
      {
        title: "用户名",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        // render: (text) => <a>{text}</a>, //重定义输出
      },
      {
        title: "登录帐号",
        dataIndex: "loginId",
        key: "loginId",
        ellipsis: true,
      },

      {
        title: "联系方式",
        dataIndex: "mobile",
        key: "mobile",
        ellipsis: true,
      },

      {
        title: "操作",
        dataIndex: "id",
        key: "action",
        render: (value, record) => {
          return (
            <div>
              <Button type="link" onClick={this.toEdit.bind(this, record)}>
                编辑
              </Button>
              <Button type="link" onClick={this.del.bind(this, record)}>
                删除
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <>
        <Container flexDirection={false}>
          <Container title="机构" fontWeight={true} bodyPadding="15" borderRight={true} borderColor="#ddd">
            <Tree
              defaultExpandAll={true}
              onSelect={(keys, info) => {
                let values = {};
                values.orgId = info.node.key;
                this.getEmployeeList(values);
                this.setState({ selectOrg: info.node });
              }}
              treeData={this.state.treeData}
            />
          </Container>
          <Container flex={4}>
            <Container
              title="人员管理"
              fontWeight={true}
              borderBottom={true}
              borderColor="#ddd"
              topCon={
                <Row>
                  <Col span={24}>
                    <Space>
                      <Input
                        placeholder="客户名称或手机"
                        onChange={(v) => {
                          this.setState({ searchName: v.target.value });
                        }}
                      />
                      <Button
                        type="primary"
                        onClick={() => {
                          this.getEmployeeList();
                        }}
                      >
                        查询
                      </Button>
                      <Button type="primary" icon={<PlusOutlined />} onClick={this.toAdd} key="primary">
                        新增员工
                      </Button>
                    </Space>
                  </Col>
                </Row>
              }
            >
              <Table
                rowSelection={{
                  type: "radio",
                  selectedRowKeys: this.state.selectedRowKeys,
                  onChange: (item, record) => {
                    let selectUser = record[0];
                    let roles = selectUser.roles;
                    let craftTypes = selectUser.haveCraft;
                    let targetRoleKeys = [];
                    if (roles != null) {
                      roles.forEach((role) => {
                        targetRoleKeys.push(role.id);
                      });
                    }
                    let targetCraftKeys = [];
                    if (craftTypes != null) {
                      craftTypes.forEach((craft) => {
                        targetCraftKeys.push(craft.id);
                      });
                    }
                    this.setState({
                      selectUser: selectUser,
                      targetRoleKeys,
                      targetCraftKeys,
                    });
                  },
                }}
                onChange={(reqPrPagintion, filters, sorter) => {
                  let values = {
                    pageSize: reqPrPagintion.pageSize,
                    page: reqPrPagintion.current,
                    searchName: this.state.searchValue,
                    orgId: this.state.selectOrg.key,
                  };
                  this.getEmployeeList(values);
                }}
                size="middle"
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={this.state.pagination.content}
                pagination={{
                  // 分页
                  showQuickJumper: false,
                  showTotal: (total) => {
                    return `共 ${total} 条`;
                  },
                  pageSize: this.state.pagination == null ? this.state.pagination.size : 15,
                  defaultPageSize: 15,
                  total: this.state.pagination.totalElements,
                }}
                onRow={(record) => ({
                  onDoubleClick: () => {
                    this.toEdit(record);
                  },
                })}
              />
              <EmployeeForm ref={this.form1} table={this}></EmployeeForm>
            </Container>
            <Container flexDirection={false}>
              <Container
                title="选择员工进行授权"
                fontWeight={true}
                bodyPadding="15"
                borderRight={true}
                borderColor="#ddd"
                titleCon={
                  <Button disabled={this.state.selectUser == null} onClick={this.saveRole} type="primary">
                    保存
                  </Button>
                }
              >
                <Transfer
                  listStyle={{
                    width: 200,
                    height: 300,
                  }}
                  disabled={this.state.selectUser == null}
                  dataSource={this.state.allRoles}
                  titles={["未授权角色", "已授权角色"]}
                  rowKey={(record) => record.id}
                  targetKeys={this.state.targetRoleKeys}
                  operations={["授权", "撤权"]}
                  onChange={this.onRoleChange}
                  onScroll={false}
                  render={(item) => item.name}
                />
              </Container>
              <Container
                title="工艺认证"
                fontWeight={true}
                bodyPadding="15"
                titleCon={
                  <Button disabled={this.state.selectUser == null} onClick={this.saveCraft} type="primary">
                    保存工艺
                  </Button>
                }
              >
                <Transfer
                  listStyle={{
                    width: 200,
                    height: 300,
                  }}
                  disabled={this.state.selectUser == null}
                  dataSource={this.state.allCraftType}
                  titles={["未认证工艺", "已认证工艺"]}
                  rowKey={(record) => record.id}
                  targetKeys={this.state.targetCraftKeys}
                  operations={["授予工艺", "撤消工艺"]}
                  onChange={this.onCraftTypeChange}
                  onScroll={false}
                  render={(item) => item.name}
                />
              </Container>
            </Container>
          </Container>
        </Container>
      </>
    );
  }
}
