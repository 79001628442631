//使用抽屉进行编辑
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, message, Modal, Radio, Steps, Upload, Row, Col } from 'antd'
import React, { Component } from 'react'
import http, { getApiMainPath, isEmptyObject } from '../api/http'

const { Step } = Steps
class CraftTypeForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      imageUrl: null,
      visible: false,
      imgFileWebPath: null,
      fileId: '',
      selectItem: null,
    }
  }
  toAdd() {
    this.setState({
      visible: true,
      selectItem: null,
      title: '新增工艺分类',
      imageUrl: '',
    })
    if (this.form1.current != null) {
      this.form1.current.resetFields()
    }
  }
  toEdit = (inEntity) => {
    if (!isEmptyObject(inEntity)) {
      console.log(inEntity)
      let imgWebPath = ''
      if (inEntity.icon != null) {
        imgWebPath = inEntity.icon.webPath
      }
      let imgFileWebPath = null
      if (inEntity.avatar != null) {
        imgFileWebPath = inEntity.avatar.webPath
      }
      this.setState({
        visible: true,
        selectItem: inEntity,
        imageUrl: imgWebPath,
        imgFileWebPath: imgFileWebPath,
        title: '修改工艺分类',
      })
    } else {
      Modal.error({
        title: '请先选中记录',
        content: '或双击记录可以直接编辑',
      })
    }
    this.form1.current.resetFields()
    this.form1.current.setFieldsValue(inEntity)
  }

  checkForm = () => {
    this.form1.current.submit()
  }
  submit = (values) => {
    let url = '/admin/craftType/add'
    if (this.state.selectItem != null) {
      url = '/admin/craftType/update'
      values.id = this.state.selectItem.id
    }
    values.iconId = this.state.fileId

    //提交
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.table.getTypeList()
      })
      .catch(() => {
        console.log('失败')
      })

    this.setState({
      visible: false,
    })
  }
  //上传头像
  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true })
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log('上传完成', info)
      this.setState({
        imageUrl: info.file.response.data.url,
        fileId: info.file.response.data.fileId,
      })
      this.setState({ uploading: false })
      // getBase64(info.file.originFileObj, (imageUrl) =>
      //   this.setState({
      //     imageUrl,
      //     loading: false,
      //   })
      // );
    }
  }
  beforeUpload2(file) {
    let fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    let checkFile = false
    if (fileType === 'pdf') {
      checkFile = true
    }
    if (fileType === 'jpg') {
      checkFile = true
    }
    if (fileType === 'jpeg') {
      checkFile = true
    }
    if (fileType === 'png') {
      checkFile = true
    }
    if (!checkFile) {
      message.error('图片只接受pdf/jpg/jpeg/png/pdf文件上传')
    }
    return checkFile
  }
  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('只能上传JPG/PNG文件!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('图片不能超过2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  normFile = (e) => {
    console.log('Upload event:', e)

    if (Array.isArray(e)) {
      return e
    }
  }
  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    const fileUpload = getApiMainPath() + '/admin/attachFile/upload'
    // const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {this.state.uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传工艺分类图</div>
      </div>
    )
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={480}
          onClose={this.onClose}
          onCancel={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="vertical" ref={this.form1} onFinish={this.submit}>
            <Form.Item name="name" label="工艺分类名称：" rules={[{ required: true, message: '工艺分类名称' }]}>
              <Input placeholder="工艺分类名称" />
            </Form.Item>
            <Form.Item name="salaryMonth" label="工艺分类工资：" rules={[{ required: true, message: '工艺分类工资不能为空' }]}>
              <InputNumber placeholder="工艺分类工资" style={{ width: '100%' }}></InputNumber>
            </Form.Item>

            <Form.Item name="monthWorkDayNum" label="每月工作天数：" rules={[{ required: true, message: '每月工作天数，不能为空' }]}>
              <InputNumber placeholder="每月工作天数" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="workTimeOfDay" label="每天工作时长" rules={[{ required: true, message: '每天工作时长，不能为空' }]}>
              <InputNumber placeholder="每天工作多少小时" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="时薪" name="hourSalary">
              <Input readOnly="true" type="number" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="limitShowSize" label="显示待接工单数:" initialValue="1" rules={[{ required: true, message: '待接工单数不能为空' }]}>
              <InputNumber readOnly="true" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="discountLimit" label="多件打折最大折扣数:"  >
              <InputNumber  style={{ width: '100%' }} />
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="isOutType" label="是否委外:" initialValue="否">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="batchExport" label="是否支持批量导出:" initialValue="否">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="uploadAvatar" label="上传图片">
                  <Upload
                    name="avatarFile"
                    maxCount={1}
                    fileList={this.state.impFiles}
                    action={getApiMainPath() + '/admin/craftType/uploadImage'}
                    onChange={this.handleChange}
                    beforeUpload={this.beforeUpload2}
                  >
                    {this.state.imgFileWebPath != null ? (
                      <img src={this.state.imgFileWebPath} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                      <Button icon={<UploadOutlined />}>上传图片</Button>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="transfer" label="是否支持移交工单:" initialValue="否">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="isStartCraft" label="是否产前工序:" initialValue="否">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="isBatchWorkOrder" label="是否支持接多工单:" initialValue="否">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="isQuality" label="是否为质检:" initialValue="否">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}

CraftTypeForm.propTypes = {}

export default CraftTypeForm
