//使用抽屉进行编辑
import { DownloadOutlined, FrownOutlined, MehOutlined, PlusOutlined, SmileOutlined, UploadOutlined } from '@ant-design/icons'
import '@ant-design/pro-card/dist/card.css'
import '@ant-design/pro-form/dist/form.css'
import { Button, Col, Divider, Form, Input, InputNumber, message, Modal, Radio, Rate, Row, Select, Spin, Steps, Tag, Upload } from 'antd'
import React, { Component } from 'react'
import Storage from '../../utils/Storage'
import http, { getApiMainPath, isEmptyObject } from '../api/http'
const { Step } = Steps
const { TextArea } = Input
const { Option } = Select
class OrderForm extends Component {
  constructor(props) {
    super(props)
    this.orderBaseForm = React.createRef()
    this.orderBomForm = React.createRef()
    this.state = {
      visible: false,
      loading: false,
      newClientName: '',
      newClientMobile: '',
      newOrderCode: '',
      title: '新增订单',
      selectClientId: '',
      selectItem: {},
      current: 0,
      uploading: false,
      bomInputType: '上传文件批量',
      doneUploadBoxFile: false,
      doneUploadPdfFile: false,
      bomExcelFileUrl: '',
      bomExcelRowNum: 0,
      bomExcelFileId: '',
      bomExcelFileList: [],
      clientOptions: [],
      orderId: '',
      fileRootPath: '',
      impExcelFileDetail: {},
      impExcelFileId: '',
      impPdfFiles: [],
      newComponents: [],
      bomExcelErrComponents: [],
      pdfErrComponents: [],
      checkData: false,
    }
  }
  downloadBom = () => {
    window.open(this.state.fileRootPath + '/tmplatefile/bom.xlsx')
  }

  downloadPdfZip = () => {
    window.open(this.state.fileRootPath + '/tmplatefile/drawing.zip')
  }
  onChange = (current) => {
    if (current === 1) {
      this.next()
    } else {
      this.setState({ current })
    }
  }
  onAreaChange = (area) => {
    console.log(area)
  }
  onLoading = () => {
    this.setState({
      loading: true,
    })
  }
  offLoading = () => {
    this.setState({
      loading: false,
    })
  }
  getClientList = (values) => {
    http
      .post('/admin/client/getAllClient', values)
      .then((res) => {
        console.log(res)
        this.setState({
          clientOptions: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  addClient = () => {
    this.onLoading()
    let values = {}
    values.newClientName = this.state.newClientName
    values.newClientMobile = this.state.newClientMobile
    http
      .post('/admin/client/addTmpUser', values)
      .then((res) => {
        message.success('添加用户完成')
        let clientOptions = this.state.clientOptions
        clientOptions.unshift(res.data)
        this.setState({
          clientOptions,
        })
        this.offLoading()
      })
      .catch((err) => {
        console.log('失败', err)
        message.error('新增数据失败!')
        this.offLoading()
      })
  }

  toAdd = () => {
    this.setState({
      visible: true,
      title: '新增订单',
      current: 0,
      newClientName: '',
      newClientMobile: '',
      newOrderCode: '',
      selectItem: null,
      uploading: false,
      bomInputType: '上传文件批量',
      doneUploadBoxFile: false,
      bomExcelFileUrl: '',
      bomExcelRowNum: 0,
      bomExcelFileId: '',
      bomExcelFileList: [],
      fileRootPath: '',
      impExcelFileDetail: {},
      impExcelFileId: '',
      impPdfFileIds: [],
      bomPdfFileList: [],
      impPdfFiles: [],
      newComponents: [],
      bomExcelErrComponents: [],
      pdfErrComponents: [],
      checkData: false,
    })
    this.getNewOrderCode()
    this.getClientList()
    this.orderBaseForm.current.resetFields()
  }

  getNewOrderCode = () => {
    this.onLoading()
    http
      .post('/admin/order/getNewOrderCode', null)
      .then((res) => {
        this.setState({
          newOrderCode: res.data,
        })
        // console.log(res.data)
        this.orderBaseForm.current.setFieldsValue({ orderCode: res.data })
        this.offLoading()
      })
      .catch((err) => {
        console.log(err)
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  toEdit = (inEntity) => {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        title: '修改订单',
      })
    } else {
      Modal.error({
        title: '请先选中记录',
        content: '或双击记录可以直接编辑',
      })
    }
    this.orderBaseForm.current.resetFields()
    this.orderBaseForm.current.setFieldsValue(inEntity)
  }

  next = () => {
    this.orderBaseForm.current.submit()
    // this.orderBaseForm.current
    //   .validateFields()
    //   .then((values) => {
    //     this.setState({
    //       current: 1,
    //     });
    //   })
    // .catch((errorInfo) => {});
  }
  submitBaseForm = (values) => {
    let url = '/admin/order/add'
    if (this.state.selectItem != null) {
      url = '/admin/order/update'
      values.id = this.state.selectItem.id
    }

    //提交
    this.onLoading()
    http
      .post(url, values)
      .then((res) => {
        this.setState({
          orderId: res.data != null ? res.data.id : '',
          selectItem: res.data,
          current: 1,
        })
        this.offLoading()
      })
      .catch(() => {
        console.log('失败')
        this.offLoading()
      })
  }

  toCheckUploadData = () => {
    this.onLoading()
    let values = {}
    values.orderId = this.state.orderId
    http
      .post('/admin/order/checkUploadOrderData', values)
      .then((res) => {
        let checkData = res.data
        let impPdfFiles = this.state.impPdfFiles
        if (checkData.pdfErrComponents.length > 0) {
          impPdfFiles = []
        }
        this.setState({
          newComponents: checkData.newComponents,
          bomExcelErrComponents: checkData.bomExcelErrComponents,
          pdfErrComponents: checkData.pdfErrComponents,
          impPdfFiles: impPdfFiles,
          checkData: checkData.pdfErrComponents.length === 0,
        })
        this.offLoading()
      })
      .catch(() => {
        console.log('失败')
        this.offLoading()
      })
  }
  toSubmitBomFomr = () => {
    let url = '/admin/order/save'
    let values = {}
    values.id = this.state.orderId
    http
      .post(url, values)
      .then((res) => {
        console.log(res)
        Modal.success({
          content: '更新数据完成',
        })

        this.setState({
          visible: false,
        })
        this.offLoading()
        this.props.table.getOrderList()
      })
      .catch((err) => {
        console.log(err)
        this.offLoading()
      })
    this.onLoading()
    this.offLoading()
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
    this.props.table.getOrderList()
  }
  uploadBomExcel = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true })
      return
    }
    if (info.file.status === 'done') {
      this.orderBaseForm.current.validateFields().then((values) => {
        // Get this url from response in real world.

        // console.log(info.file.response.data)
        let uploadData = info.file.response.data
        if (uploadData != null && '' !== uploadData) {
          message.success(`${info.file.name} 上传完成`)
          this.setState({
            uploading: false,
            impExcelFileId: uploadData.fileId,
            orderId: uploadData.orderId,
            impExcelFileDetail: uploadData.imporDetail,
            doneUploadBoxFile: true,
            current: 1,
          })
        } else {
          message.error(`${info.file.name} 失败,请检查模板格式`)
        }
      })
    }
  }
  beforeExcelUpload = (file) => {
    let fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
    let clientId = this.orderBaseForm.current.getFieldValue('clientId')
    let checkFile = false
    if (fileType === 'xls') {
      checkFile = true
    }
    if (fileType === 'xlsx') {
      checkFile = true
    }

    if (!checkFile && clientId != null && clientId !== '') {
      message.error('只能上传excel文件!')
    } else if (clientId != null && clientId !== '') {
      let fileList = this.state.bomExcelFileList
      fileList.splice(0, fileList.length)
      fileList.push(file)
      this.setState({ bomExcelFileList: fileList })
    } else {
      message.error('请先设置用户信息，再上传文件!')
      checkFile = false
    }
    return checkFile
  }
  uploadBomPdf = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true })

      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      // message.success(`${info.file.name} 上传完成`)

      let impPdfFiles = this.state.impPdfFiles
      impPdfFiles.push(info.file)
      let uploadData = info.file.response.data
      // console.log('uploadData', uploadData)
      this.setState({
        impPdfFiles,
        doneUploadPdfFile: true,
        // newComponents: uploadData.newComponents,
        // bomExcelErrComponents: uploadData.bomExcelErrComponents,
        // pdfErrComponents: uploadData.pdfErrComponents,
      })
      this.setState({ uploading: false })
    }
  }
  beforeZipUpload = (file) => {
    let fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    let checkFile = false
    if (fileType === 'pdf' || fileType === 'PDF') {
      checkFile = true
    }
    if (fileType === 'jpg') {
      checkFile = true
    }
    if (fileType === 'jpeg') {
      checkFile = true
    }
    if (fileType === 'png') {
      checkFile = true
    }
    if (!checkFile) {
      message.error('图片只接受pdf/jpg/jpeg/png文件上传')
    }
    return checkFile
  }

  componentDidMount() {
    let fileRootPath = Storage.get('fileRootPath')
    // console.log('fileRootPath', fileRootPath)
    this.setState({
      fileRootPath,
    })
  }

  componentWillUnmount() {}

  //组件更新完成
  componentDidUpdate() {}

  render() {
    const customIcons = {
      1: <FrownOutlined style={{ fontSize: '32px' }} />,
      2: <FrownOutlined style={{ fontSize: '32px' }} />,
      3: <MehOutlined style={{ fontSize: '32px' }} />,
      4: <SmileOutlined style={{ fontSize: '32px' }} />,
      5: <SmileOutlined style={{ fontSize: '32px' }} />,
    }

    return (
      <div>
        <Spin spinning={this.state.loading} delay={500}>
          <Modal
            footer={
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                  取消
                </Button>
                {this.state.current === 0 ? (
                  <Button onClick={this.next} type="primary">
                    下一步
                  </Button>
                ) : this.state.current === 1 ? (
                  this.state.checkData ? (
                    <Button onClick={this.toSubmitBomFomr} type="primary">
                      保存
                    </Button>
                  ) : (
                    <Button onClick={this.toCheckUploadData} type="primary">
                      数据校验
                    </Button>
                  )
                ) : (
                  ''
                )}
              </div>
            }
            forceRender={true}
            title={this.state.title}
            width={800}
            style={{ top: 20 }}
            onClose={this.onClose}
            onCancel={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Steps type="navigation" size="small" current={this.state.current} onChange={this.onChange}>
              <Step title={this.state.title} />
              <Step title="上传零件信息" />
            </Steps>
            <Form
              layout="vertical"
              submitter={false}
              autoComplete="off"
              ref={this.orderBaseForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              onFinish={this.submitBaseForm}
              style={{
                marginTop: 20,
                display: this.state.current === 0 ? '' : 'none',
              }}
            >
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item name="clientId" label="客户:" value={this.selectClientId} rules={[{ required: true, message: '客户信息不能为空' }]}>
                    <Select
                      showSearch
                      onChange={(value) => {
                        let selectClientId = value
                        // console.log(selectClientId)
                        this.setState({ selectClientId })
                      }}
                      placeholder="输入用户名选择"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'nowrap',
                              padding: 8,
                            }}
                          >
                            <Input
                              style={{ marginLeft: 10 }}
                              placeholder="输入新用户名"
                              value={this.state.newClientName}
                              onChange={(e) => {
                                this.setState({
                                  newClientName: e.target.value,
                                })
                              }}
                            />
                            <Input
                              style={{ marginLeft: 10 }}
                              placeholder="输入新用户名联系方式"
                              value={this.state.newClientMobile}
                              onChange={(e) => {
                                this.setState({
                                  newClientMobile: e.target.value,
                                })
                              }}
                            />
                            <a
                              style={{
                                flex: 'none',
                                padding: '8px',
                                display: 'block',
                                cursor: 'pointer',
                              }}
                              onClick={this.addClient}
                            >
                              <PlusOutlined />
                              新增用户
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      {this.state.clientOptions.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item name="orderName" label="订单名称:" rules={[{ required: true, message: '订单名称不能为空' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name="budgetPrice" label="预算(万元):">
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="orderLevel" initialValue={3} label="重要程度:">
                    <Rate character={({ index }) => customIcons[index + 1]}></Rate>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name="orderCode" label="系统订单编号:">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="extraOrderCode" label="项目编号:" rules={[{ required: true, message: '项目编号不能为空' }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item name="memo" label="订单描述">
                    <TextArea placeholder="订单描述" autoSize={{ minRows: 4, maxRows: 5 }} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Form
              layout="vertical"
              submitter={false}
              autoComplete="off"
              ref={this.orderBomForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 24 }}
              onFinish={this.submitBomForm}
              style={{
                marginTop: 20,
                display: this.state.current === 1 ? '' : 'none',
              }}
            >
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    label="添加零件方式:"
                    name="bomInputType"
                    initialValue="上传文件批量"
                    onChange={(e) => {
                      this.setState({ bomInputType: e.target.value })
                    }}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="手动">手动</Radio.Button>
                      <Radio.Button value="上传文件批量">上传文件批量</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {this.state.bomInputType === '上传文件批量' ? (
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item name="bomExcelFile" label="零件明细excel">
                      <Upload
                        fileList={this.state.bomExcelFileList}
                        name="uploadFile"
                        maxCount={1}
                        action={
                          getApiMainPath() +
                          '/admin/order/uploadExcelBomFile?clientId=' +
                          this.state.selectClientId +
                          '&orderCode=' +
                          this.state.newOrderCode +
                          '&orderId=' +
                          this.state.orderId
                        }
                        beforeUpload={this.beforeExcelUpload}
                        onChange={this.uploadBomExcel}
                      >
                        <Button icon={<UploadOutlined />}>上传零件excel</Button>
                        <Button type="link" onClick={this.downloadBom} icon={<DownloadOutlined />}>
                          下载模板(excel)
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="uploadPdfFile" label="零件图">
                      <Upload
                        name="uploadPdfFile"
                        maxCount={1}
                        directory
                        fileList={this.state.impPdfFiles}
                        action={
                          getApiMainPath() +
                          '/admin/order/uploadBomPdf?clientId=' +
                          this.state.selectClientId +
                          '&orderCode=' +
                          this.state.newOrderCode +
                          '&orderId=' +
                          this.state.orderId
                        }
                        onChange={this.uploadBomPdf}
                        beforeUpload={this.beforeZipUpload}
                      >
                        <Button icon={<UploadOutlined />}>选择零件工艺文件夹批量上传</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                ''
              )}

              <Row gutter={24}>
                {this.state.bomInputType === '上传文件批量' && this.state.doneUploadBoxFile ? (
                  <Col span={12}>
                    <Form.Item name="components_count" label="上传文件情况:">
                      <div style={{ float: 'left', marginLeft: 10 }}>
                        上传记录数:
                        {this.state.impExcelFileDetail.imp_count != null ? this.state.impExcelFileDetail.imp_count : 0}
                      </div>
                      <div style={{ float: 'left', marginLeft: 10 }}>
                        零件型号数：
                        {this.state.impExcelFileDetail.components_count != null ? this.state.impExcelFileDetail.components_count : 0}
                      </div>
                      <div style={{ float: 'left', marginLeft: 10 }}>
                        零件总数：
                        {this.state.impExcelFileDetail.components_num != null ? this.state.impExcelFileDetail.components_num : 0}
                      </div>
                    </Form.Item>
                  </Col>
                ) : (
                  ''
                )}

                {this.state.doneUploadPdfFile ? (
                  <Col span={12}>
                    <Form.Item name="components_count" label="零件校验情况:">
                      {this.state.pdfErrComponents.length > 0 ? (
                        <div>
                          以下零件缺少图纸文件
                          <br />
                          {this.state.pdfErrComponents.map((item) => (
                            <div style={{ float: 'left' }}>
                              <Tag color="#f50">{item}</Tag>
                            </div>
                          ))}
                          <br />
                        </div>
                      ) : (
                        ''
                      )}
                    </Form.Item>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </Form>
          </Modal>
        </Spin>
      </div>
    )
  }
}

OrderForm.propTypes = {}

export default OrderForm
