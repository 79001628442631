import { ExclamationCircleOutlined,ReloadOutlined } from "@ant-design/icons";
import {
  Button, Modal, Pagination
} from "antd";
import React, { Component } from "react";
import "../../App.css";
import http from "../api/http";
import SystemServeForm from "./SystemServeForm";
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable'


const { confirm } = Modal;
class SystemServe extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      sortedInfo: null,
      searchForm: null,
      selectItem: {},
      loading: false,
      page: 1,
      pageSize: 15,
      filteredInfo: null,
      //测试数据
      data: [],
    };
  }

  //加载完成
  componentDidMount() {
    this.getList();
  }

  onLoading() {
    this.setState({
      loading: true,
    });
  }
  offLoading() {
    this.setState({
      loading: false,
    });
  }
  getList = (values) => {
    this.onLoading();
    if (values == null) {
      values = {};
    }
    values.page = values.page;
    values.pageSize = this.state.pageSize;
    http
      .post("/admin/system/servePage", values)
      .then((res) => {
        this.setState({
          data: res.data,
        });
        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };

  //新增
  toAdd = () => {
    this.form.current.toAdd();
  };

  //编辑
  toEdit = (record) => {
    this.form.current.toEdit(record);
  };

  //删除
  del = (record) => {
    let list = this.getList
    confirm({
      title: "这是配置信息，确认删除这条记录?",
      icon: <ExclamationCircleOutlined />,
      content: "这如果有其他外关联信息使用就无法删除",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        http
          .post("/admin/system/delServe?id=" + record.id, "")
          .then((res) => {
            Modal.success({
              content: "删除成功",
            });
            list();
          })
          .catch(() => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            });
          });
      },
      onCancel() {},
    });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    });
    this.getList();
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    // 表头
    const columns = [
      {
        title: "服务名称",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.groupName.length - b.groupName.length,
        ellipsis: true,
        width: 200,
      },
      {
        title: "服务id",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.groupName.length - b.groupName.length,
        ellipsis: true,
        width: 150,
      },
      {
        title: "实现类路径",
        dataIndex: "servePath",
        key: "servePath",
        sorter: (a, b) => a.groupName.length - b.groupName.length,
        ellipsis: true,
        width: 200,
      },
      {
        title: '输入返回描述',
        dataIndex: 'importMemo',
        key: 'importMemo',
        ellipsis: true,
        width: 200,
      },
      {
        title: "输出返回描述",
        dataIndex: "outputMemo",
        key: "outputMemo",
        sorter: (a, b) => a.dirValue - b.dirValue,
        ellipsis: true,
        width: 200,
      },
      {
        title: "操作",
        key: "action",
        width: 250,
        render: (value, record) => {
          return (
            <div>
              <Button type="link" onClick={this.toEdit.bind(this, record)}>
                编辑
              </Button>
              <Button type="link" onClick={this.del.bind(this, record)}>
                删除
              </Button>
            </div>
          );
        },
      },
    ];

    return (
        <Panel
        title="系统参数管理"
          fontWeight="true"
          bodyPadding="15"
          topRight={
            <Button key="new" onClick={this.toAdd}>
            新增
          </Button>
          
        }
        footerBar={
          <Button
          icon={<ReloadOutlined />}
          type="text"
          onClick={() => {
            this.getList()
          }}
        >
          刷新
        </Button>
        }
          footerRight={
            <Pagination
               // 分页
               showTotal={ (total) => {
                return <>共 {total} 条</>
              }}
              showSizeChanger={false}
              showQuickJumper={true}
              defaultPageSize={this.state.data.size}
              total={ this.state.data.totalElements}
              onChange={(page)=>{
                let values = {}
                values.page = page
                values.pageSize = this.state.pageSize
                this.getList(values)
              }} 
            onRow={(record) => ({
              onDoubleClick: () => {
                this.toEdit(record);
              },
            })}
            />
          }
        >
          <ResizeTable
            bordered
            size="middle"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={this.state.data.content}
            pagination={false}
          />
          <SystemServeForm ref={this.form} getList={this.getList} table={this}></SystemServeForm>
        </Panel>
    );
  }
}
SystemServe.propTypes = {};

export default SystemServe;
