import React, { Component } from 'react'
import ProCard from '@ant-design/pro-card'
import Panel from "../../../../component/etooling-ui/Panel";
import { Button, DatePicker, message, Select, Space, Table } from 'antd'
import { Column } from '@ant-design/charts'
import http from '../../../api/http'
import { RedoOutlined, ReloadOutlined } from '@ant-design/icons'
import ResizeTable from "../../../../component/etooling-ui/ResizeTable";
import moment from 'moment'

class ProductReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      month: moment().format('YYYY-MM'),
      monthChart: [],
      data: [],
      isLoading:true
    }
  }

  componentWillMount() {
    this.loadData()
  }

  loadData = () => {
    let values = { dateMonth: this.state.month }
    http
      .post('/admin/report/employeeRewardCount', values)
      .then((res) => {
        let columns = []
        let monthChart = res.data.monthChart

        let employeeLists = res.data.employeeLists
        employeeLists.forEach((employee) => {
          let col = {
            title: employee,
            dataIndex: employee,
            align: 'center',
            key: employee,
            ellipsis: true,
            width: 120,
          }
          columns.push(col)
        })

        this.setState({
          columns,
          data: res.data.data,
          monthChart,
          isLoading:false
        })
      })
      .catch((err) => {
        console.log(err)
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  render() {
    let {columns,isLoading} = this.state

    if(isLoading){
      　return <p>isLoading...</p>
    }
    return (
      <Panel
        title={this.state.month + ':计件工资月报'}
        load={this.state.loading}
        titleRight={
          <Space>
            <DatePicker
              defaultValue={moment(this.state.month, 'YYYY-MM')}
              onChange={(date, dateString) => {
                console.log(dateString)
                this.setState({
                  month: dateString,
                })
              }}
              picker="month"
            />
            <Button
              onClick={() => {
                this.loadData()
              }}
              icon={<RedoOutlined />}
            >
              刷新
            </Button>
          </Space>
        }
        footerBar={
          <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              var values = {};
              values.status = this.state.status;
              this.loadData(values);
            }}
          >
            刷新
          </Button>
        }
      >
        <ResizeTable
          bordered
          dataSource={this.state.data == null ? [] : this.state.data}
          pagination={false}
          size="small"
          columns={columns}
        ></ResizeTable>
        <Column
          style={{ height: '250px' }}
          data={this.state.monthChart}
          xField="月份"
          yField="工资"
        />
      </Panel>
    )
  }
}

ProductReport.propTypes = {}

export default ProductReport