import React, { Component } from 'react'
import ProCard from '@ant-design/pro-card'
import '@ant-design/pro-card/dist/card.css'
import {  Tabs } from 'antd'
import Detail from './workorder/Detail'
import Collect from './workorder/Collect'
import Scheduling from './workorder/Scheduling'
import TabPane from '@ant-design/pro-card/lib/components/TabPane'
export default class WorkOrderList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewType: '订单跟踪',
      spinning:false
    }
  }

  render() {
    return (
      <ProCard split="horizontal" bordered headerBordered>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="查看员工任务清单" key="1">
            <Scheduling></Scheduling>
          </TabPane>
          <TabPane tab="订单跟踪" key="2">
            <Collect></Collect>
          </TabPane>
          <TabPane tab="工单明细" key="3">
            <Detail></Detail>
          </TabPane>
        </Tabs>
      </ProCard>
    )
  }
}
