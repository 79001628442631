//使用抽屉进行编辑
import React, { Component } from 'react'
import { Modal, Input,  Button, Form,  Row, Col,Select } from 'antd'
import { isEmptyObject } from '../api/http'
import '@ant-design/pro-form/dist/form.css'
import http from '../api/http'
const { Option } = Select;

class TimerForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      title: '新增服务',
      selectItem: {},
      system : [],
      timer : [],
      list:{}
    }
  }


  toAdd() {
    this.form1.current.resetFields()
    this.setState({
      visible: true,
      selectItem: null,
      title: '新增服务',
    })
  }
  
  toEdit = (inEntity) => {
    console.log(inEntity);
    this.form1.current.resetFields()
    this.form1.current.setFieldsValue(inEntity)
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        title: '修改服务信息',
      })
    } else {
      Modal.error({
        title: '请先选中记录',
      })
    }
    this.setState({list:inEntity})
  }

  checkForm = () => {
    console.log(this.form1.current)
    this.form1.current.submit()
  }
  submit = (values) => {
    console.log(values);
     let url = '/admin/cron/add'
    if (this.state.selectItem != null) {
      url = '/admin/cron/update'

    }
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.getList()
      })
      .catch(() => {
        console.log('失败')
      })

    this.setState({
      visible: false,
    })  
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {}


  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={800}
          onCancel={this.onClose}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="horizontal" autoComplete="off" style={{ marginTop: 10 }} ref={this.form1} 
          labelCol={{ span: 6 }}
           onFinish={this.submit}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="任务名称"
                  rules={[
                    {
                      required: true,
                      message: '名称不能为空',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="formula" label="公式"
   
                 rules={[
                    {
                      required: true,
                      message: '公式输入有误',
                      pattern: "^\\s*($|#|\\w+\\s*=|(\\?|\\*|(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?(?:,(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?)*)\\s+(\\?|\\*|(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?(?:,(?:[0-5]?\\d)(?:(?:-|\\/|\\,)(?:[0-5]?\\d))?)*)\\s+(\\?|\\*|(?:[01]?\\d|2[0-3])(?:(?:-|\\/|\\,)(?:[01]?\\d|2[0-3]))?(?:,(?:[01]?\\d|2[0-3])(?:(?:-|\\/|\\,)(?:[01]?\\d|2[0-3]))?)*)\\s+(\\?|\\*|(?:0?[1-9]|[12]\\d|3[01])(?:(?:-|\\/|\\,)(?:0?[1-9]|[12]\\d|3[01]))?(?:,(?:0?[1-9]|[12]\\d|3[01])(?:(?:-|\\/|\\,)(?:0?[1-9]|[12]\\d|3[01]))?)*)\\s+(\\?|\\*|(?:[1-9]|1[012])(?:(?:-|\\/|\\,)(?:[1-9]|1[012]))?(?:L|W)?(?:,(?:[1-9]|1[012])(?:(?:-|\\/|\\,)(?:[1-9]|1[012]))?(?:L|W)?)*|\\?|\\*|(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?(?:,(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:-)(?:JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?)*)\\s+(\\?|\\*|(?:[0-6])(?:(?:-|\\/|\\,|#)(?:[0-6]))?(?:L)?(?:,(?:[0-6])(?:(?:-|\\/|\\,|#)(?:[0-6]))?(?:L)?)*|\\?|\\*|(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?(?:,(?:MON|TUE|WED|THU|FRI|SAT|SUN)(?:(?:-)(?:MON|TUE|WED|THU|FRI|SAT|SUN))?)*)(|\\s)+(\\?|\\*|(?:|\\d{4})(?:(?:-|\\/|\\,)(?:|\\d{4}))?(?:,(?:|\\d{4})(?:(?:-|\\/|\\,)(?:|\\d{4}))?)*))$",
                    },
                  ]}
                  >
                    
                  <Input />
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </Modal>
      </div>
    )
  }
}

TimerForm.propTypes = {}

export default TimerForm
