import { UploadOutlined } from "@ant-design/icons";
import { Button, Cascader, Drawer, Form, Input, Upload } from "antd";
import confirm from "antd/lib/modal/confirm";
import React, { Component } from "react";
import "../../assets/css/paramSetting.css";
import http, { getApiMainPath } from "../api/http";
class ParameterSetting extends Component {
  constructor(props) {
    super(props);
    this.form1 = React.createRef();
    this.state = {
      workOrderId: "",
      workOrderGroupId: "",
      workOrder: {},
      WorkOrderGroup: {},
      craftParamList: [],
      craftParams: [],
      testValue: [],
      parameterVisible: false,
      uploading: false,
      imageUrl: "",
      fileId: "",
    };
  }

  showWin = () => {
    this.setState({ parameterVisible: true });
  };

  onChange(value) {
    console.log(value.pop());
    this.setState({
      testValue: value.pop(),
    });
  }

  submit = (e) => {
    let this_ = this
    confirm({
      title: "确认提交?",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      width: 600,
      onOk() {
        let craftParamList = this_.state.craftParamList;
        for (var i = 0; i < craftParamList.length; i++) {
          let key = craftParamList[i].id;
          if (e[key] != null) {
            craftParamList[i].value = e[key];
            if (craftParamList[i].type === "附件") {
              craftParamList[i].value = e[key].file.response.data;
            }
          }
        }
        let values = {};
        values.paramValuesJson = JSON.stringify(craftParamList);
        values.workOrderId = this_.state.workOrderId;
        values.orderComponentId = this_.state.workOrder.orderComponentId;
        values.craftId = this_.state.workOrder.craftId;
        http.post("/admin/workOrder/paramSettingTest", values).then((res) => {
          this_.setState({ parameterVisible: false });
          this_.props.getWorkOrder();
        });
      },
      onCancel() {},
    });
  };

  getTreeData(data) {
    let parents = [];
    for (let i = 0; i < data.length; i++) {
      let parent = {};
      parent.value = data[i].title;
      parent.label = data[i].title;
      if (data[i].children !== undefined) {
        parent.children = this.getTreeData(data[i].children);
      }
      parents.push(parent);
    }
    return parents;
  }

  //上传头像
  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      console.log(info);
      this.setState({
        imgFileId:
          info.file.response.data == null ? "" : info.file.response.data.id,
        imgFileWebPath:
          info.file.response.data == null
            ? null
            : info.file.response.data.webPath,
      });
    }
  };

  init_() {
    let values = {};
    values.workOrderGroupId = this.state.workOrderGroupId;
    values.workOrderId = this.state.workOrderId;
    http
      .post("/admin/workOrder/getWorkOrderGroupDetail", values)
      .then((res) => {
        console.log(res);
        this.setState({
          WorkOrderGroup: res.data.workOrderGroup,
          workOrder: res.data.workOrder,
          craftParamList: res.data.craftParamList,
          parameterVisible: true,
        });
        let craftParams = res.data.craftParamList;
        let data = [];
        for (var i = 0; i < craftParams.length; i++) {
          if (craftParams[i].paramType === "执行人员录入参数") {
            craftParams[i].treeData = this.getTreeData(craftParams[i].treeData);
            data.push(craftParams[i]);
          }
        }
        this.setState({ craftParams: data });
      });
  }
  submitDone = () => {
    this.form1.current.submit();
  };

  render() {
    return (
      <Drawer
        width={450}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => {
                this.setState({ parameterVisible: false });
              }}
              style={{ marginRight: 8 }}
            >
              取消
            </Button>
            <Button onClick={this.submitDone} type="primary">
              提交
            </Button>
          </div>
        }
        onClose={() => {
          this.setState({ parameterVisible: false });
        }}
        placement="left"
        visible={this.state.parameterVisible}
        forceRender={true}
        title={this.state.title}
      >
        <div>
          <div class="container">
            <div class="order">
              <div style={{ padding: "4%", background: "#eee" }}>
                <div class="order_change">
                  {this.state.workOrderGroup !== {} ? (
                    <div class="order_title">
                      <div class="title">
                        <text>
                          在制 : {this.state.WorkOrderGroup.craftTypeName}{" "}
                        </text>
                      </div>
                      <text class="title_m">
                        项目号 : {this.state.WorkOrderGroup.extraOrderCode}
                      </text>
                      <text class="title_m">
                        图号 : {this.state.WorkOrderGroup.componentName}[
                        {this.state.WorkOrderGroup.componentCode}]
                      </text>
                      <div class="list">
                        <div class="item">
                          <text>
                            计划时间 :{" "}
                            {this.state.WorkOrderGroup.planTime != null &&
                            this.state.WorkOrderGroup.planTime !== undefined
                              ? this.state.WorkOrderGroup.planTime
                              : ""}
                          </text>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div class="order_con" id="selector1">
                    <div class="list">
                      <div class="item">
                        <div class="order_conduct1">
                          <div class="title">
                            <div
                              class="icon_x"
                              style={{ boxShadow: "0 0 10rpx #3c65bd" }}
                            ></div>
                            <div class="text">
                              <text>{this.state.workOrder.craftName}</text>
                              <text>
                                计划时间 : {this.state.workOrder.planTime}
                              </text>
                            </div>
                          </div>
                          <div
                            style={{
                              overflow: "hidden",
                              marginBottom: "40rpx",
                            }}
                          >
                            <Form
                              layout="vertical"
                              ref={this.form1}
                              onFinish={this.submit}
                            >
                              {this.state.craftParams !== []
                                ? this.state.craftParams.map((item, index) =>
                                    item.treeData.map((data, i) => (
                                      <div>
                                        {data.children !== undefined ? (
                                          <Form.Item
                                            name={item.id}
                                            label={item.name}
                                            rules={[
                                              {
                                                required: true,
                                                message: item.name + "不能为空",
                                              },
                                            ]}
                                          >
                                            <Cascader
                                              options={data.children}
                                              placeholder="请选择数据"
                                            />
                                          </Form.Item>
                                        ) : (
                                          <>
                                            {item.type === "数字" ? (
                                              <Form.Item
                                                name={item.id}
                                                label={item.name}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      item.name + "不能为空",
                                                  },
                                                ]}
                                              >
                                                <Input
                                                  type="number"
                                                  style={{
                                                    flex: "1",
                                                    border: "1px solid #e5e5e5",
                                                    borderRadius: "5rpx",
                                                    height: "60rpx",
                                                    padding: " 0 14rpx",
                                                  }}
                                                  value={item.value}
                                                  placeholder="请输入数据"
                                                  cancelButton="none"
                                                />
                                              </Form.Item>
                                            ) : item.type === "文本" ? (
                                              <Form.Item
                                                name={item.id}
                                                label={item.name}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      item.name + "不能为空",
                                                  },
                                                ]}
                                              >
                                                <Input
                                                  style={{
                                                    flex: "1",
                                                    border: "1px solid #e5e5e5",
                                                    borderRadius: "5rpx",
                                                    height: "60rpx",
                                                    padding: " 0 14rpx",
                                                  }}
                                                  value={item.value}
                                                  placeholder="请输入数据"
                                                  cancelButton="none"
                                                />
                                              </Form.Item>
                                            ) : item.type === "附件" ? (
                                              <Form.Item
                                                name={item.id}
                                                label={item.name}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      item.name + "不能为空",
                                                  },
                                                ]}
                                              >
                                                <Upload
                                                  name="avatarFile"
                                                  maxCount={1}
                                                  fileList={this.state.impFiles}
                                                  action={
                                                    getApiMainPath() +
                                                    "/admin/workOrder/uploadImage"
                                                  }
                                                  onChange={this.handleChange}
                                                >
                                                  <Button
                                                    icon={<UploadOutlined />}
                                                  >
                                                    上传文件
                                                  </Button>
                                                </Upload>
                                              </Form.Item>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ))
                                  )
                                : ""}
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    );
  }
}

export default ParameterSetting;
