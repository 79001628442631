import { pdfjs } from 'react-pdf'
import { Drawer } from 'antd'
import Document from 'react-pdf/dist/umd/Document'
import { Empty, Modal, Tabs, Timeline } from 'antd'
import React, { Component } from 'react'
import SwiperCore, { Navigation, Zoom } from 'swiper'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/zoom/zoom.min.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import '../../assets/css/orderCompile.css'
import http from '../api/http'
import Page from 'react-pdf/dist/umd/Page'
SwiperCore.use([Zoom, Navigation])
const { confirm } = Modal
const { TabPane } = Tabs
class OrderCompileTab extends Component {
  constructor(props) {
    // tab切换
    super(props)
    this.newTabIndex = 0
    const panes = []
    this.state = {
      // activeKey: panes[0].key,
      panes,

      visible: false,
      order: {},
      orderComponents: {},
      components: {},
      componentsCrafts: [],
      materialCostsDetail: null,
      selectMaterial: null,
      selectMaterialType: null,
      componentCraftDetail: null,
      lastComponentCraftDetail: null,
      selectCraftType: { name: '' },
      selectCraft: { name: '' },
      materialPrice: 0.0,
      workerPrice: 0.0,
      steps: [],
      hisComponents: [],
      workerTime: 0.0,
      selectStep: {},
      componentsEmployeeMap: {},
    }
  }

  toCompile = (orderComponentsId, newOpen) => {
    // alert(orderComponentsId)
    let selectStep = this.state.selectStep

    //当前是否新零件
    let materialStep = { id: '选料', name: '选料', type: 'option' }
    let steps = this.state.steps
    steps.splice(0, steps.length)

    steps.push(materialStep)
    http
      .post('/admin/components/toComponentCompile?orderComponentId=' + orderComponentsId, null)
      .then((res) => {
        let componentsCrafts = res.data.componentsCrafts
        let componentsEmployeeMap = res.data.componentsEmployeeMap
        let hisComponents = res.data.hisComponents
        let workerPrice = this.state.workerPrice
        let workerTime = 0.0
        let orderComponents = res.data.orderComponents

        console.log(orderComponents)
        let components = orderComponents.components
        let selectMaterial = null
        let selectMaterialType = null
        let materialCostsDetail = {}

        if (components.material != null) {
          selectMaterial = components.material

          selectMaterialType = selectMaterial.materialType
          this.setState({
            selectMaterialType,
            selectMaterial,
          })
          // console.log('selectMaterialType', selectMaterialType)
          if (components.material.materialParams != null) {
            let materialParams = components.material.materialParams.split('|')
            materialCostsDetail.materialParams = materialParams
          }
        }

        if (components.materialParamValues) {
          let materialParamsValues = components.materialParamValues.split('|')
          materialCostsDetail.materialParamsValues = materialParamsValues
        }
        if (components.materialPrice != null) {
          materialCostsDetail.materialCosts = components.materialPrice
          materialCostsDetail.materialWeight = components.materialWeight
        }
        this.setState({
          orderComponents,
          components: components,
          materialCostsDetail,
          visible: true,
        })

        //初始化工步
        if (componentsCrafts != null) {
          componentsCrafts.forEach((item) => {
            let componentCraftDetail = {}
            componentCraftDetail.selectCraft = item.craft
            componentCraftDetail.id = item.id
            componentCraftDetail.workerTime = item.workerTime
            componentCraftDetail.selectCraftType = item.craft.craftType
            componentCraftDetail.craftParam = item.craft.craftParamList
            componentCraftDetail.carftParamsValues = JSON.parse(item.paramValuesJson)
            componentCraftDetail.price = item.price
            componentCraftDetail.memo = item.memo

            workerPrice = workerPrice + item.price
            // alert(item.workerTime)
            workerTime = parseFloat(workerTime) + parseFloat(item.workerTime)

            //组织数据
            let lastCraftTypeId = steps[steps.length - 1].craftTypeId
            // console.log(lastCraftTypeId + ';' + componentCraftDetail.selectCraftType.id)
            if (lastCraftTypeId !== componentCraftDetail.selectCraftType.id) {
              let componentCrafts = []
              componentCrafts.push(componentCraftDetail)
              let step = {
                id: componentCraftDetail.id,
                seq: steps.length,
                craftTypeId: componentCraftDetail.selectCraftType.id,
                name: componentCraftDetail.selectCraftType.name,
                type: 'craft',
                componentCrafts,
              }
              steps.push(step)
            } else {
              let lastLastStep = steps[steps.length - 1]
              let componentCrafts = lastLastStep.componentCrafts
              componentCrafts.push(componentCraftDetail)
              lastLastStep.componentCrafts = componentCrafts
            }
          })
        }
        let lastLastStep = this.state.lastLastStep
        if (newOpen != null) {
          selectStep = {}
          lastLastStep = {}
          componentsCrafts = {}
        }
        this.setState({
          steps,
          workerTime,
          hisComponents,
          orderComponents,
          componentsEmployeeMap,
          workerPrice,
          componentsCrafts: componentsCrafts,
          selectStep,
          lastLastStep,
        })

        this.offLoading()
      })
      .catch((err) => {
        this.offLoading()
        //console.log(err)
      })
  }
  onLoading = () => {
    this.setState({
      loading: false,
    })
  }
  offLoading = () => {
    this.setState({
      loading: false,
    })
  }

  componentDidMount() {
    if (this.props.orderComponentsId != null) {
      this.toCompile(this.props.orderComponentsId, true)
    }
  }

  render() {
    //const [page, setPage] = useState(1);
    //信息通知
    return (
      <div className="order_dismantling">
        <div className="order_dismantling_con">
          <div className="con">
            <div className="o">
              <div className="banner">
                <embed
                  align="middle"
                  allowscriptaccess="never"
                  allowfullscreen="true"
                  mode="transparent"
                  src={this.state.components.componetImg == null ? '' : this.state.components.componetImg.webPath}
                  style={{ minHeight: 650 }}
                  width={'100%'}
                />
              </div>
            </div>
            <div className="l">
              <div className="title">
                <p>工艺设置</p>
              </div>
              <div className="step">
                <Timeline mode="left">
                  {this.state.steps.map((item, index) => (
                    <ul>
                      <Timeline.Item
                        color={this.state.selectStep.id === item.id ? 'orange' : '#008fd7'}
                        label={
                          <div>
                            <p className="t">{index}0</p>
                            <span
                              className="d"
                              onClick={() => {
                                if (item.id === '选料') {
                                  this.setState({
                                    selectStep: item,
                                  })
                                }
                              }}
                              style={{ backgroundColor: this.state.selectStep.id === item.id ? 'orange' : '#008fd7' }}
                            >
                              {item.name}
                            </span>
                          </div>
                        }
                      ></Timeline.Item>
                      <ul>
                        {item.id === '选料' && this.state.selectMaterial != null ? (
                          <Timeline.Item
                            onClick={() => {
                              if (item.id === '选料') {
                                this.setState({
                                  selectStep: item,
                                })
                              }
                            }}
                            style={{ fontSize: this.state.selectStep.id === '选料' ? '14px' : '', fontWeight: this.state.selectStep.id === '选料' ? 'bold' : '' }}
                          >
                            {this.state.selectMaterialType.name}-{this.state.selectMaterial.name}
                          </Timeline.Item>
                        ) : item.componentCrafts != null ? (
                          item.componentCrafts.map((craft, index) => (
                            <Timeline.Item
                              style={{
                                fontSize: this.state.componentCraftDetail != null && craft.id === this.state.componentCraftDetail.id ? '14px' : '',
                                fontWeight: this.state.componentCraftDetail != null && craft.id === this.state.componentCraftDetail.id ? 'bold' : '',
                              }}
                              color={this.state.componentCraftDetail != null && craft.id === this.state.componentCraftDetail.id ? 'orange' : '#1890ff'}
                              key={craft.id}
                              onClick={() => {
                                this.setState({
                                  componentCraftDetail: craft,
                                  selectStep: item,
                                  selectCraft: craft.selectCraft,
                                  selectCraftType: craft.selectCraftType,
                                })
                              }}
                            >
                              {craft.selectCraft.name}
                            </Timeline.Item>
                          ))
                        ) : (
                          ''
                        )}
                      </ul>
                    </ul>
                  ))}
                </Timeline>
              </div>
            </div>
            <div className="r">
              {this.state.selectStep.id === '选料' ? (
                <div className="title">
                  <p>
                    {this.state.selectMaterial === null || this.state.selectMaterialType == null
                      ? ''
                      : this.state.selectMaterialType.name + '-' + this.state.selectMaterial.name + '-参数设置'}
                  </p>
                </div>
              ) : (
                <div className="title">
                  <p>
                    {this.state.selectCraftType === null || this.state.selectCraft == null ? '' : this.state.selectCraftType.name + '-' + this.state.selectCraft.name + '-参数设置'}
                  </p>
                </div>
              )}
              <div className="step_parameter">
                {/* 第一步 添加步骤按钮 */}
                {this.state.selectStep.id === '选料' ? (
                  this.state.selectMaterial == null ? (
                    <Empty
                      onClick={this.openSelectMaterial}
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 120,
                      }}
                      description={
                        <span>
                          <a>点击添加原料</a>
                        </span>
                      }
                    ></Empty>
                  ) : (
                    <div className="step_parameter_con">
                      <ul>
                        {this.state.materialCostsDetail == null
                          ? ''
                          : this.state.materialCostsDetail.materialParams == null
                          ? ''
                          : this.state.materialCostsDetail.materialParams.map((param, index) => (
                              <li>
                                <p> {param}</p>{' '}
                                <div>
                                  <input
                                    type="number"
                                    onChange={() => {}}
                                    value={this.state.materialCostsDetail.materialParamsValues.length > 0 ? this.state.materialCostsDetail.materialParamsValues[index] : ''}
                                  />
                                </div>
                                <p style={{ marginLeft: 6 }}> {this.state.selectMaterial == null ? '' : this.state.selectMaterial.unit}</p>
                              </li>
                            ))}
                      </ul>
                      <div className="btn">
                        <a onClick={this.saveMaterialParamsValues}>保存</a>
                        <a onClick={this.openSelectMaterial}>重选</a>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="step_parameter_con">
                    <ul>
                      {this.state.componentCraftDetail == null
                        ? ''
                        : this.state.componentCraftDetail == null
                        ? ''
                        : this.state.componentCraftDetail.craftParam.map((param, index) => (
                            <li>
                              <p> {param.name}</p>
                              <div>
                                <input type="number" onChange={() => {}} value={this.state.componentCraftDetail.carftParamsValues[index].value} />
                              </div>
                              <p style={{ marginLeft: 6 }}> {param.unit}</p>
                            </li>
                          ))}
                      <li>
                        <p> 工本</p>
                        <div>
                          <input
                            type="number"
                            onChange={() => {}}
                            value={this.state.componentCraftDetail == null ? '' : parseFloat(this.state.componentCraftDetail.price).toFixed(2)}
                          />
                        </div>
                        <p style={{ marginLeft: 6 }}> 元</p>
                      </li>
                      <li>
                        <p>描述</p>
                        <div>
                          <textarea onChange={() => {}} value={this.state.componentCraftDetail == null ? '' : this.state.componentCraftDetail.memo}></textarea>
                        </div>
                      </li>
                      <li>
                        <p> 工时</p>
                        <div>
                          <input
                            type="number"
                            onChange={() => {}}
                            value={this.state.componentCraftDetail == null ? '' : parseFloat(this.state.componentCraftDetail.workerTime * 60).toFixed(2)}
                          />
                        </div>
                        <p style={{ marginLeft: 6 }}> 分钟</p>
                      </li>
                    </ul>
                    <div className="btn">
                      <a
                        onClick={() => {
                          this.props.table.copyHisVersionToNow(this.state.orderComponents.id)
                        }}
                      >
                        使用这历史工艺
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderCompileTab
