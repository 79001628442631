import {
  AlignLeftOutlined,
  CheckCircleTwoTone,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EllipsisOutlined,
  ExceptionOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FrownOutlined,
  HighlightTwoTone,
  MehOutlined,
  PlusOutlined,
  SearchOutlined,
  SmileOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import "@ant-design/pro-card/dist/card.css";
import ProDescriptions from "@ant-design/pro-descriptions";
import {
  Avatar,
  BackTop,
  Badge,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Descriptions,
  Drawer,
  Dropdown,
  Form,
  Input,
  List,
  message,
  Modal,
  Progress,
  Space,
  Table,
  Typography,
  Menu,
  Upload,
  Pagination,
  Tag,
} from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import React, { Component } from "react";
import { pdfjs } from "react-pdf";
import Document from "react-pdf/dist/umd/Document";
import Page from "react-pdf/dist/umd/Page";
import "../../assets/css/OrderDetail.css";
import Storage from "../../utils/Storage";
import http from "../api/http";
import IconFont from "../api/IconFont";
import { getApiMainPath } from "../api/http";
import ChildOrderForm from "./ChildOrderForm";
import UploadComponent from "./UploadComponent";
import OrderCompile from "./OrderCompile";
import OrderComponentForm from "./OrderComponentForm";
import OrderOffer from "./QuotationList";
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from "../../component/etooling-ui/ResizeTable";
pdfjs.GlobalWorkerOptions.workerSrc = `//etooling.irobot-auto.com/pdf.worker.min.js`;
const { Meta } = Card;
const { Text, Link } = Typography;
const { confirm } = Modal;
class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.orderCompile = React.createRef();
    this.planTimeSettting = React.createRef();
    this.orderPlanForm = React.createRef();
    this.orderComponentForm = React.createRef();
    this.childOrderForm = React.createRef();
    this.uploadComponent = React.createRef();
    this.orderOffer = React.createRef();
    this.state = {
      loading: false,
      visible: false,
      orderComponents: [],
      toWorkOrderIds: [],
      isShowPdf: false,
      client: {},
      orderComponentsStatus: {},
      order: {},
      pagination: { pageSize: 15, page: 1, total: 0 },
      displayStatus: "",
      searchValue: "",
      orderComponentSeq: {},
      editMap: {},
    };
  }
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onLoading() {
    this.setState({
      loading: true,
    });
  }
  offLoading() {
    this.setState({
      loading: false,
    });
  }
  componentDidMount() {
    this.isShowPdf = Storage.get("isShowPdf");
  }
  toCompile = (orderComponent) => {
    this.orderCompile.current.toCompile(this.state.order, orderComponent, true, this.state.pagination);
  };
  backForList = () => {
    this.props.table.showList();
  };
  toOrderDetail = (orderId, values, isNew, isForeign, page) => {
    console.log(page);

    if (this.state.visible === false) {
      this.setState({
        visible: true,
      });
    }

    if (values == null && isNew == null) {
      values = { status: "" };
      values.status = this.state.displayStatus;
    }
    if (isNew != null) {
      values = null;
    }
    if (page != null) {
      values.page = page.page;
      values.pageSize = page.pageSize;
    }
    if (isForeign != null) {
      values = { isForeign: "废弃" };
    }

    http
      .post("/admin/order/toOrderDetail?id=" + orderId, values)
      .then((res) => {
        let orderData = res.data;
        let pagination = this.state.pagination;
        pagination.total = orderData.orderComponents.totalElements;
        pagination.pageSize = orderData.orderComponents.size;
        pagination.page = orderData.orderComponents.number + 1;
        this.setState({
          order: orderData.order,
          orderComponents: orderData.orderComponents.content,
          client: orderData.order.client,
          orderComponentsStatus: "",
          pagination,
        });
        this.offLoading();
      })
      .catch((err) => {
        this.offLoading();
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };
  onChangeComponent = (toWorkOrderIds) => {
    this.setState({ toWorkOrderIds });
  };

  toOrderOffer = () => {
    console.log(this.state.orderComponents);
    this.orderOffer.current.toOffer(this.state.order, this.state.orderComponents, this.state.order.id, this.state.order.foreignStatus);
  };

  del = (record) => {
    let list = this.toOrderDetail;
    let orderId = this.state.order.id;
    confirm({
      title: "确认删除这条记录?",
      icon: <ExclamationCircleOutlined />,
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        http
          .post("/admin/order/delOrderComponent?id=" + record.id, "")
          .then((res) => {
            Modal.success({
              content: "删除成功",
            });
            list(orderId);
          })
          .catch(() => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            });
          });
      },
      onCancel() {},
    });
  };

  toDone(item) {
    console.log(item);
    let list = this.toOrderDetail;
    let orderId = this.state.order.id;
    confirm({
      title: "确认完成此零件?",
      icon: <ExclamationCircleOutlined />,
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        http
          .post("/admin/order/doneOrderComponent?id=" + item.id, "")
          .then((res) => {
            Modal.success({
              content: "处理成功",
            });
            list(orderId);
          })
          .catch(() => {
            Modal.error({
              content: "处理失败,请联系管理员！！！！！！",
            });
          });
      },
      onCancel() {},
    });
  }

  inputChange2(orderComponentId, e) {
    let orderComponentSeq = this.state.orderComponentSeq;
    orderComponentSeq[orderComponentId] = e.target.value;
    this.setState({
      orderComponentSeq,
    });
  }

  newWorkOrder = (item) => {
    if (this.state.order.planTime != null && this.state.order.planTime !== "") {
      let list = this.toOrderDetail;
      let orderId = this.state.order.id;
      let parent = this;
      confirm({
        title: "是否要投产该零件",
        icon: <IconFont type="icon-gongyede-36" size="20" color="#0000FF" />,
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          http
            .post("/admin/workOrder/orderComponentsIdToWork?orderComponentsId=" + item.id, "")
            .then((res) => {
              Modal.success({
                content: "投产成功",
              });
              list(orderId);
            })
            .catch((err) => {
              message.error("提交失败，请联系管理员");
            });
        },
        onCancel() {},
      });
    } else {
      message.error("投产前，请先设定订单的交期并保存");
    }
  };
  orderComponentSort = (orderComponentId) => {
    let values = {};
    values.orderComponentId = orderComponentId;
    values.seq = this.state.orderComponentSeq[orderComponentId];
    http.post("/admin/order/sortOrderComponent", values).then((res) => {
      Modal.success({
        content: "排序成功",
      });
      this.toOrderDetail(this.state.order.id);
    });
  };

  beforeUpload(file) {
    let fileType = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();
    let checkFile = false;
    if (fileType === "pdf") {
      checkFile = true;
    }
    if (fileType === "jpg") {
      checkFile = true;
    }
    if (fileType === "jpeg") {
      checkFile = true;
    }
    if (fileType === "png") {
      checkFile = true;
    }
    if (!checkFile) {
      message.error("图片只接受pdf/jpg/jpeg/png/pdf文件上传");
    }
    return checkFile;
  }

  //上传零件图
  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      this.setState({
        imgFileId: info.file.response.data == null ? "" : info.file.response.data.id,
      });
      this.setState({ uploading: false });
    }
  };

  batchNewWorkOrder = () => {
    if (this.state.order.planTime != null && this.state.order.planTime !== "") {
      let list = this.toOrderDetail;
      let orderId = this.state.order.id;
      confirm({
        title: "是否要投产所有已经拆解的零件？",
        icon: <IconFont type="icon-gongyede-36" size="20" color="#0000FF" />,
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          let values = {};
          values.orderId = orderId;
          http
            .post("/admin/workOrder/orderComponentsIdToWork", values)
            .then((res) => {
              Modal.success({
                content: "投产成功，请到进度管理中看到进度.",
              });
              list(orderId);
            })
            .catch((err) => {
              message.error("提交失败，请联系管理员");
            });
        },
        onCancel() {},
      });
    } else {
      message.error("投产前，请先设定订单的交期并保存");
    }
  };
  render() {
    const customIcons = {
      1: <FrownOutlined style={{ fontSize: "24px" }} />,
      2: <FrownOutlined style={{ fontSize: "24px" }} />,
      3: <MehOutlined style={{ fontSize: "24px" }} />,
      4: <SmileOutlined style={{ fontSize: "24px" }} />,
      5: <SmileOutlined style={{ fontSize: "24px" }} />,
    };

    return (
      <>
        <div>
          <Drawer
            className="OrderDetailDrawer"
            getContainer={false}
            style={{ position: "absolute", padding: "0", fontWeight: "bold" }}
            mask={false}
            closable={false}
            forceRender={true}
            title="工艺管理"
            width={"100%"}
            visible={this.state.visible}
            onClose={this.onClose}
            maskClosable={false}
            onCancel={this.onClose}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  onClick={() => {
                    this.onClose();
                    // this.props.table.getOrderList()
                  }}
                  style={{ marginRight: 8 }}
                >
                  取消
                </Button>
                <Button
                  onClick={() => {
                    this.onClose();
                    this.props.table.getOrderList();
                  }}
                  type="primary"
                >
                  提交
                </Button>
              </div>
            }
          >
            <BackTop />

            <Panel>
              <Panel layout="h-box">
                <Panel title="订单详情" flex="1" bodyPadding="15">
                  <ProCard split="vertical">
                    <ProDescriptions column={1}>
                      <ProDescriptions.Item label="订单编号">{this.state.order.orderCode}</ProDescriptions.Item>
                      <ProDescriptions.Item label="重要级别">{this.state.order.orderLevel}</ProDescriptions.Item>
                      <ProDescriptions.Item valueType="date" label="创建时间">
                        {this.state.order.createTime}
                      </ProDescriptions.Item>
                      <ProDescriptions.Item label="预算成本金额" tooltip="仅供参考，以实际为准">
                        {parseFloat(this.state.order.evalCost).toFixed(2)}元
                      </ProDescriptions.Item>
                      <ProDescriptions.Item label="物料成本">{parseFloat(this.state.order.materialCost).toFixed(2)}元</ProDescriptions.Item>
                      <ProDescriptions.Item label="人力成本">{parseFloat(this.state.order.workerCost).toFixed(2)} 元</ProDescriptions.Item>
                      <ProDescriptions.Item label="物料重量"> {parseFloat(this.state.order.materialWeight).toFixed(2)}kg</ProDescriptions.Item>
                      <ProDescriptions.Item label="零件型号数量">{this.state.order.componentsNum}件</ProDescriptions.Item>
                    </ProDescriptions>
                    <ProDescriptions column={1}>
                      <ProDescriptions.Item label="客户编号">
                        {this.state.client === undefined ? "" : this.state.client.clientCode}
                      </ProDescriptions.Item>
                      <ProDescriptions.Item label="客户名称">{this.state.client === undefined ? "" : this.state.client.name}</ProDescriptions.Item>
                      <ProDescriptions.Item label="联系电话">{this.state.client === undefined ? "" : this.state.client.phone}</ProDescriptions.Item>
                      <ProDescriptions.Item label="区域">{this.state.client === undefined ? "" : this.state.client.area}</ProDescriptions.Item>
                    </ProDescriptions>
                    <Descriptions
                      column={1}
                      title={
                        <div>
                          <span
                            style={{
                              display: "block",
                              overflow: "hidden",
                              float: "left",
                              margin: "0 5px 0 0",
                            }}
                          >
                            <IconFont size="20px" type="icon-jindu" />
                          </span>
                          进度跟踪
                        </div>
                      }
                    >
                      <ProDescriptions.Item>
                        <Form layout="inline" ref={this.orderPlanForm}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "请填写项目交期",
                              },
                            ]}
                          >
                            <DatePicker
                              allowClear={false}
                              onChange={(date) => {
                                let order = this.state.order;
                                order.planTime = moment(date).format("YYYY-MM-DD HH:mm:ss");
                                this.setState({ order });
                              }}
                              value={this.state.order.planTime == null ? "" : moment(this.state.order.planTime)}
                              placeholder="设置项目交期"
                            />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              type="link"
                              onClick={() => {
                                this.orderPlanForm.current
                                  .validateFields()
                                  .then((values) => {
                                    values.orderId = this.state.order.id;
                                    values.planTime = moment(this.state.order.planTime).format("YYYY-MM-DD HH:mm:ss");
                                    http
                                      .post("/admin/order/saveOrderTime", values)
                                      .then((res) => {
                                        message.success({
                                          content: "数据更新完成",
                                        });
                                      })
                                      .catch(() => {});
                                  })
                                  .catch((errorInfo) => {});
                              }}
                            >
                              保存
                            </Button>
                          </Form.Item>
                        </Form>
                      </ProDescriptions.Item>

                      <ProDescriptions.Item label={<div>1下单</div>}>
                        <Progress type="line" percent={100} />
                      </ProDescriptions.Item>
                      <ProDescriptions.Item label={<div>2拆解</div>}>
                        <Progress
                          type="line"
                          style={{ width: "90%" }}
                          percent={(this.state.order.doneCompileCompCnt / this.state.order.componentTypeNum) * 100}
                          format={() => {
                            return (
                              <span>
                                {this.state.order.doneCompileCompCnt}/{this.state.order.componentTypeNum}
                              </span>
                            );
                          }}
                        />
                      </ProDescriptions.Item>
                      <ProDescriptions.Item label={<div>3投产</div>}>
                        <Progress
                          type="line"
                          style={{ width: "90%" }}
                          percent={(this.state.order.operationCompCnt / this.state.order.componentsNum) * 100}
                          format={() => {
                            return (
                              <span>
                                {this.state.order.operationCompCnt}/{this.state.order.componentsNum}
                              </span>
                            );
                          }}
                        />
                      </ProDescriptions.Item>
                      <ProDescriptions.Item label={<div>4生产完成</div>}>
                        <Progress type="line" percent={(this.state.order.doneCompCnt / this.state.order.componentsNum) * 100} />
                      </ProDescriptions.Item>
                    </Descriptions>
                  </ProCard>
                </Panel>
                <Panel
                  flex="4"
                  title="零件信息"
                  titleRight={
                    <>
                      {/* <div style={{display:'flex',flexDirection:'column'}}>
          <div style={{marginBottom:'10px'}}> */}
                      <Checkbox
                        checked={!this.state.isShowPdf}
                        onChange={() => {
                          this.setState({ isShowPdf: !this.state.isShowPdf });
                          Storage.set("isShowPdf", !this.state.isShowPdf);
                        }}
                      >
                        不显示图片
                      </Checkbox>
                      <Button
                        icon={<FileDoneOutlined style={{ color: "#0000FF" }} />}
                        onClick={() => {
                          let values = {};
                          values.status = "已完成";
                          this.setState({ displayStatus: "已完成" });
                          this.toOrderDetail(this.state.order.id, values);
                        }}
                      >
                        已完成
                      </Button>
                      <Button
                        icon={<HighlightTwoTone style={{ color: "#0000FF" }} />}
                        onClick={() => {
                          let values = {};
                          values.status = "已投产";
                          this.setState({ displayStatus: "已投产" });
                          this.toOrderDetail(this.state.order.id, values);
                        }}
                      >
                        已投产
                      </Button>

                      <Button
                        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                        onClick={() => {
                          let values = {};
                          values.status = "已拆解";
                          this.setState({ displayStatus: "已拆解" });
                          this.toOrderDetail(this.state.order.id, values);
                        }}
                      >
                        已拆解
                      </Button>
                      <Button
                        icon={<ClockCircleOutlined style={{ color: "#f5222d" }} />}
                        onClick={() => {
                          let values = {};
                          values.status = "待拆解";
                          this.setState({ displayStatus: "待拆解" });
                          this.toOrderDetail(this.state.order.id, values);
                        }}
                      >
                        待拆解
                      </Button>
                      {/* </div>
                    <div> */}
                      <Button
                        icon={<IconFont type="icon-calculatorcount" size="20" color="#ff8000" />}
                        onClick={() => {
                          this.toOrderOffer();
                        }}
                      >
                        报价清单
                      </Button>
                      <Button
                        icon={<IconFont type="icon-gongyede-40" size="20" color="#0000FF" />}
                        onClick={() => {
                          this.batchNewWorkOrder();
                        }}
                      >
                        批量投产
                      </Button>
                      <Button
                        icon={<AlignLeftOutlined />}
                        onClick={() => {
                          let values = {};
                          values.status = "";
                          this.setState({ displayStatus: "" });
                          this.toOrderDetail(this.state.order.id, values);
                        }}
                      >
                        全部零件
                      </Button>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.orderComponentForm.current.toAdd(this.state.order);
                        }}
                      >
                        新增零件
                      </Button>
                      {/* </div>
                    </div> */}
                    </>
                  }
                  topBar={
                    <Space>
                      <Input
                        value={this.state.searchValue}
                        onChange={(e) => {
                          let value = e.target.value;
                          this.setState({ searchValue: value });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            let values = {};
                            values.searchValue = this.state.searchValue;
                            this.toOrderDetail(this.state.order.id, values);
                          }
                        }}
                        placeholder="零件编号或零件名称"
                      ></Input>
                      <Button
                        icon={<SearchOutlined />}
                        onClick={() => {
                          let values = {};
                          values.searchValue = this.state.searchValue;
                          this.toOrderDetail(this.state.order.id, values);
                        }}
                      >
                        查询
                      </Button>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.childOrderForm.current.toAdd(
                            this.state.order.id,
                            this.state.order.orderName,
                            this.state.order.extraOrderCode == null ? this.state.order.orderCode : this.state.order.extraOrderCode
                          );
                        }}
                      >
                        批量上传零件(自动匹配)
                      </Button>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.uploadComponent.current.toAdd(
                            this.state.order.id,
                            this.state.order.orderName,
                            this.state.order.extraOrderCode == null ? this.state.order.orderCode : this.state.order.extraOrderCode
                          );
                        }}
                      >
                        批量上传零件(手动选择)
                      </Button>
                    </Space>
                  }
                  footerRight={
                    <Pagination
                      size="small"
                      // 分页
                      showTotal={(total) => {
                        return `共 ${total} 条`;
                      }}
                      current={this.state.pagination.page}
                      showSizeChanger={false}
                      showQuickJumper={true}
                      pageSize={this.state.pagination.pageSize}
                      defaultPageSize={this.state.pagination.pageSize}
                      total={this.state.pagination.total}
                      onChange={(page) => {
                        let pagination = this.state.pagination;
                        pagination.page = page;
                        this.setState({
                          pagination,
                        });
                        let values = {};
                        values.page = page;
                        values.pageSize = this.state.pagination.pageSize;
                        values.searchValue = this.state.searchValue;
                        values.status = this.state.displayStatus;
                        this.toOrderDetail(this.state.order.id, values);
                      }}
                    ></Pagination>
                  }
                >
                  <ResizeTable
                    bordered
                    pagination={false}
                    onRow={(record) => ({
                      onDoubleClick: () => {
                        this.toCompile(record, true);
                      },
                    })}
                    dataSource={this.state.orderComponents}
                    columns={[
                                    {
                        title: "项目",
                        dataIndex: "components",
                        key: "components",
                        ellipsis: true,
                        width: 120,
                        render: (value, record) => {
                          let val = "";
                          if (record.projectName != undefined) {
                            {
                              val = record.projectName;
                            }
                          }
                          if (record.projectCode != undefined) {
                            {
                              val +=
                                val.length > 0
                                  ? "/" + record.projectCode
                                  : record.projectCode;
                            }
                          }
                          if (val.length < 1) {
                            {
                              val = "---";
                            }
                          }
                          return <>{val}</>;
                        },
                      },
                      {
                        title: "零件名称",
                        dataIndex: "components",
                        key: "components",
                        ellipsis: true,
                        width: 120,
                        render: (value, record) => {
                          return <>{value.componentName}</>;
                        },
                      },
                      {
                        title: "零件图号",
                        dataIndex: "components",
                        key: "components",
                        ellipsis: true,
                        render: (value, record) => {
                          return <>{value.componentCode}</>;
                        },
                        width: 200,
                      },

                      {
                        title: "工步数",
                        dataIndex: "components",
                        key: "components",
                        ellipsis: true,
                        render: (value, record) => {
                          return <>{value.craftNum}</>;
                        },
                        width: 80,
                      },
                      {
                        title: "人工成本",
                        dataIndex: "components",
                        key: "components",
                        ellipsis: true,
                        render: (value, item) => {
                          return <>{item.components.workerPrice == null ? "-" : parseFloat(item.components.workerPrice).toFixed(2)}</>;
                        },
                        width: 100,
                      },
                      {
                        title: "原料成本",
                        dataIndex: "components",
                        key: "components",
                        ellipsis: true,
                        render: (value, item) => {
                          return <>{item.components.materialPrice == null ? "-" : parseFloat(item.components.materialPrice).toFixed(2)}</>;
                        },
                        width: 100,
                      },
                      {
                        title: "顺序",
                        dataIndex: "seq",
                        key: "seq",
                        ellipsis: true,
                        width: 80,
                        render: (value, record) => {
                          if (this.state.editMap[record.id] == null) {
                            return (
                              <Link
                                onClick={() => {
                                  let editMap = this.state.editMap;
                                  editMap[record.id] = record;
                                  this.setState({ editMap });
                                }}
                              >
                                {value == null ? 1 : value}
                              </Link>
                            );
                          } else {
                            let editMap = this.state.editMap;
                            return (
                              <>
                                <Space>
                                  <Input
                                    type="number"
                                    defaultValue={editMap[record.id].seq == null ? 1 : editMap[record.id].seq}
                                    onChange={(e) => this.inputChange2(record.id, e)}
                                  ></Input>
                                  <CheckOutlined
                                    onClick={() => {
                                      this.orderComponentSort(record.id);
                                      let editMap = this.state.editMap;
                                      editMap[record.id] = null;
                                      this.setState({ editMap });
                                    }}
                                  />
                                  <CloseOutlined
                                    onClick={() => {
                                      let editMap = this.state.editMap;
                                      editMap[record.id] = null;
                                      this.setState({ editMap });
                                    }}
                                  />
                                </Space>
                              </>
                            );
                          }
                        },
                      },
                      {
                        title: "状态",
                        dataIndex: "status",
                        key: "status",
                        ellipsis: true,
                        render: (value, record) => {
                          if (value == null || value === "") {
                            value = "待拆解";
                          }
                          return (
                            <>
                              {value === "已拆解" ? (
                                <CheckCircleTwoTone twoToneColor="#52c41a" />
                              ) : value === "已投产" ? (
                                <HighlightTwoTone style={{ color: "#0000FF" }} />
                              ) : value === "已完成" ? (
                                <FileDoneOutlined style={{ color: "#0000FF" }} />
                              ) : (
                                <ClockCircleOutlined style={{ color: "#f5222d" }} />
                              )}
                              {value}
                            </>
                          );
                        },
                        width: 100,
                      },
                      {
                        title: "外协状态",
                        dataIndex: "foreignStatus",
                        key: "foreignStatus",
                        ellipsis: true,
                        render: (value, record) => {
                          return (
                            <>
                              {value === "待报价" ? (
                                <CheckCircleTwoTone twoToneColor="#52c41a" />
                              ) : value === "已报价" ? (
                                <HighlightTwoTone style={{ color: "#0000FF" }} />
                              ) : value === "已确认报价" ? (
                                <FileDoneOutlined style={{ color: "#0000FF" }} />
                              ) : value === "废弃" ? (
                                <ClockCircleOutlined style={{ color: "#f5222d" }} />
                              ) : (
                                <ClockCircleOutlined style={{ color: "#f5222d" }} />
                              )}
                              {value}
                            </>
                          );
                        },
                        width: 100,
                      },
                      {
                        title: "操作",
                        width: 200,
                        key: "action",
                        render: (value, item) => {
                          return (
                            <Space>
                              {item.status === "已拆解" || item.status === "" || item.status === "待拆解" ? (
                                <>
                                  <Tag
                                    icon={<FileSyncOutlined />}
                                    onClick={() => {
                                      this.toCompile(item, true);
                                    }}
                                  >
                                    拆解
                                  </Tag>
                                </>
                              ) : (
                                ""
                              )}

                              {/* <Button icon={<FileSearchOutlined />}>查看</Button> */}
                              {item.status === "已拆解" &&
                              item.processingType !== "供料加工" &&
                              item.processingType !== "包工包料" &&
                              item.foreignStatus !== "废弃" ? (
                                <>
                                  <Tag
                                    icon={<FileProtectOutlined />}
                                    onClick={() => {
                                      this.newWorkOrder(item);
                                    }}
                                  >
                                    投产
                                  </Tag>
                                  <Tag>
                                    <Dropdown
                                      overlay={
                                        <Menu>
                                          <Menu.Item key="0">
                                            <a
                                              onClick={() => {
                                                let del = this.del;
                                                confirm({
                                                  title: "你确认要删除该零件?",
                                                  icon: <ExclamationCircleOutlined />,
                                                  okText: "确认",
                                                  okType: "danger",
                                                  cancelText: "取消",
                                                  onOk() {
                                                    del(item);
                                                  },
                                                  onCancel() {},
                                                });
                                              }}
                                            >
                                              删除
                                            </a>
                                          </Menu.Item>
                                          <Menu.Item>
                                            <Upload
                                              name="uploadPdfFile"
                                              maxCount={1}
                                              fileList={this.state.impPdfFiles}
                                              action={getApiMainPath() + "/admin/order/uploadCompImg?orderComponentId=" + item.id}
                                              onChange={this.handleChange}
                                              beforeUpload={this.beforeUpload}
                                            >
                                              <a icon={<UploadOutlined />}>选择零件工艺图上传</a>
                                            </Upload>
                                          </Menu.Item>
                                        </Menu>
                                      }
                                      trigger={["click"]}
                                    >
                                      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                        其它 <DownOutlined />
                                      </a>
                                    </Dropdown>
                                  </Tag>
                                </>
                              ) : item.status !== "已投产" && item.status != "CNC待拆解" ? (
                                <>
                                  <Tag
                                    icon={<FileSyncOutlined />}
                                    onClick={() => {
                                      console.log(item);
                                      this.toCompile(item);
                                    }}
                                  >
                                    查看
                                  </Tag>

                                  <Upload
                                    name="uploadPdfFile"
                                    maxCount={1}
                                    fileList={this.state.impPdfFiles}
                                    action={getApiMainPath() + "/admin/order/uploadCompImg?orderComponentId=" + item.id}
                                    onChange={this.handleChange}
                                    beforeUpload={this.beforeUpload}
                                  >
                                    <Tag icon={<UploadOutlined />}>换图</Tag>
                                  </Upload>
                                  <Tag
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                      this.del(item);
                                    }}
                                  >
                                    删除
                                  </Tag>
                                </>
                              ) : item.status === "已完成" ? (
                                <Tag
                                  icon={<FileSyncOutlined />}
                                  onClick={() => {
                                    this.toCompile(item);
                                  }}
                                >
                                  查看
                                </Tag>
                              ) : item.status === "CNC待拆解" ? (
                                <>
                                  <Tag
                                    icon={<FileSyncOutlined />}
                                    onClick={() => {
                                      this.toCompile(item);
                                    }}
                                  >
                                    CNC拆解
                                  </Tag>
                                </>
                              ) : item.status === "已投产" || item.foreignStatus == "废弃" ? (
                                <>
                                  <Tag
                                    icon={<FileSyncOutlined />}
                                    onClick={() => {
                                      console.log(item);
                                      this.toCompile(item);
                                    }}
                                  >
                                    查看
                                  </Tag>
                                  <Upload
                                    name="uploadPdfFile"
                                    maxCount={1}
                                    fileList={this.state.impPdfFiles}
                                    action={getApiMainPath() + "/admin/order/uploadCompImg?orderComponentId=" + item.id}
                                    onChange={this.handleChange}
                                    beforeUpload={this.beforeUpload}
                                  >
                                    <Tag icon={<UploadOutlined />}>换图</Tag>
                                  </Upload>
                                </>
                              ) : item.status === "已完成" ? (
                                <Tag
                                  icon={<FileSyncOutlined />}
                                  onClick={() => {
                                    this.toCompile(item);
                                  }}
                                >
                                  查看
                                </Tag>
                              ) : item.processingType === "供料加工" || item.processingType === "包工包料" ? (
                                <>
                                  <Tag
                                    icon={<FileSyncOutlined />}
                                    onClick={() => {
                                      this.toDone(item);
                                    }}
                                  >
                                    完成
                                  </Tag>
                                  <Tag
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                      this.del(item);
                                    }}
                                  >
                                    删除
                                  </Tag>
                                </>
                              ) : (
                                <>
                                  <Tag
                                    icon={<FileSyncOutlined />}
                                    onClick={() => {
                                      this.toCompile(item, true);
                                    }}
                                  >
                                    拆解
                                  </Tag>
                                  <Tag
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                      this.del(item);
                                    }}
                                  >
                                    删除
                                  </Tag>
                                  <Upload
                                    name="uploadPdfFile"
                                    maxCount={1}
                                    fileList={this.state.impPdfFiles}
                                    action={getApiMainPath() + "/admin/order/uploadCompImg?orderComponentId=" + item.id}
                                    onChange={this.handleChange}
                                    beforeUpload={this.beforeUpload}
                                  >
                                    <Tag icon={<UploadOutlined />}>换图</Tag>
                                  </Upload>
                                </>
                              )}
                            </Space>
                          );
                        },
                      },
                    ]}
                  ></ResizeTable>
                </Panel>
              </Panel>
            </Panel>
          </Drawer>
          <OrderCompile ref={this.orderCompile} table={this}></OrderCompile>
          <ChildOrderForm ref={this.childOrderForm} table={this}></ChildOrderForm>
          <UploadComponent ref={this.uploadComponent} table={this}></UploadComponent>
          <OrderComponentForm ref={this.orderComponentForm} table={this}></OrderComponentForm>
          <OrderOffer ref={this.orderOffer} table={this}></OrderOffer>
        </div>
      </>
    );
  }
}

OrderDetail.propTypes = {};

export default OrderDetail;
