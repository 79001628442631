import { ExclamationCircleOutlined, PlusOutlined,ReloadOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, Space, Spin,Pagination } from 'antd'
import React, { Component } from 'react'
import http from '../api/http'
import FreeItemForm from './FreeItemForm'
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable';
const { confirm } = Modal
//收费项管理
class FreeItemList extends Component {
  constructor(props) {
    super(props)
    this.freeItmeForm = React.createRef()
    this.state = {
      sortedInfo: null,
      loading: false,
      page: 1,
      pageSize: 15,
      filteredInfo: null,
      //测试数据
      data: [],
      searchValue:''
    }
  }
  onLoading() {
    this.setState({
      loading: true,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }

  getList = (values) => {
    this.onLoading()
    if (values == null) {
      values = {}
    }
      values.page = values.page
      values.pageSize = this.state.pageSize
      values.searchValue = this.state.searchValue
    http
      .post('/admin/freeItem/pageQuery', values)
      .then((res) => {
        this.setState({
          data: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  changePage = (page) => {
    let values = {}
    values.page = page
    values.pageSize = this.state.pageSize
    this.getList(values)
  }

  toAdd = () => {
    this.freeItmeForm.current.toAdd()
  }
  
  toEdit = (record) => {
    this.freeItmeForm.current.toEdit(record)
  }

  del = (record) => {
    let list = this.getList
    confirm({
      title: '这是配置信息，确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,
      content: '这如果有其他外关联信息使用就无法删除',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/freeItem/del?id=' + record.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list()
          })
          .catch(() => {
            Modal.error({
              content: '删除失败,请联系管理员！！！！！！',
            })
          })
      },
      onCancel() {},
    })
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    })
  }

  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    })
    this.getList()
  }

  componentDidMount() {
    this.getList()
  }
  render() {
    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}
    // 表头
    const columns = [
      {
        title: '费项分组名称',
        dataIndex: 'groupName',
        key: 'groupName',
        ellipsis: true,
        width: 200,
      },
      {
        title: '费项名称',
        dataIndex: 'freeItemName',
        key: 'freeItemName',
        ellipsis: true,
        width: 200,
      },
      {
        title: '数值类型',
        dataIndex: 'freeItemType',
        key: 'freeItemType',
        ellipsis: true,
        width: 150,
      },
      {
        title: '费用值',
        dataIndex: 'freeValue',
        key: 'freeValue',
        ellipsis: true,
        width: 150,
      },
      {
        title: '能否调整',
        dataIndex: 'isRewrite',
        key: 'isRewrite',
        ellipsis: true,
        width: 150,
      },

      {
        title: '操作',
        width: 200,
        key: 'action',
        render: (value, record) => {
          return (
            <div>
              <Button type="link" onClick={this.toEdit.bind(this, record)}>
                编辑
              </Button>
              <Button type="link" onClick={this.del.bind(this, record)}>
                删除
              </Button>
            </div>
          )
        },
      },
    ]
    return (
      <Panel
      title="订单报价费项管理"
      fontWeight
      topBar={
        <Space>
        关键词搜索
        <Input
          value={this.state.searchValue}
          onChange={(evn) => {
            let searchValue = evn.target.value
            this.setState({ searchValue: searchValue })
          }}
        />
        <Button
          type="primary"
          onClick={() => {
            this.getList()
          }}
        >
          查询
        </Button>
      </Space>
      }
      topRight={
        <Button type="primary" icon={<PlusOutlined />} onClick={this.toAdd} key="primary">
        新增
      </Button>
      }
      footerBar={
        <Button
        icon={<ReloadOutlined />}
        type="text"
        onClick={() => {
          this.getList()
        }}
      >
        刷新
      </Button>
      }
        footerRight={
          <Pagination
            // 分页
            showTotal={ (total) => {
              return <>共 {total} 条</>
            }}
            showSizeChanger={false}
            showQuickJumper={true}
            defaultPageSize={15}
            total={ this.state.data.totalElements}
            onChange={(page)=>{
              let values = {}
              values.page = page
              values.pageSize = this.state.pageSize
              this.getList(values)
            }}
            onRow={(record) => ({
              onDoubleClick: () => {
                this.toEdit(record)
              },
            })}
          />
        }
      >
        <ResizeTable
        bordered
          search={false}
          size="small"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={this.state.data.content}
          pagination={false}
        />
        <FreeItemForm ref={this.freeItmeForm} table={this} />
        </Panel>
      
    )
  }
}

export default FreeItemList
