//使用抽屉进行编辑
import React, { Component } from 'react'
import { Modal, Input, Radio, Cascader, Button, Form, Steps, Row, Col } from 'antd'
import { isEmptyObject } from '../api/http'
import ProForm from '@ant-design/pro-form'
import '@ant-design/pro-form/dist/form.css'
import http from '../api/http'

class WarehouseFrom extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      title: '新增客户',
      selectItem: null,
      areaOptions: [
        {
        },
      ],
    }
  }
  checkForm = () => {
    console.log(this.form1.current)
    this.form1.current.submit()
  }
  toEdit = (inEntity) => {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        title: '修改信息',
      })
    } else {
      Modal.error({
        title: '请先选中记录',
        content: '或双击记录可以直接编辑',
      })
    }
    this.form1.current.resetFields()
    this.form1.current.setFieldsValue(inEntity)
  }
  submit = (values) => {
      let url = '/admin/quality/update'
    if (this.state.selectItem != null) {
      values.id = this.state.selectItem.id
      values.remarks = this.state.selectItem.remarks;
    }
    //提交
    this.props.table.onLoading()
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.table.offLoading()
        this.props.getList()
      })
      .catch(() => {
        console.log('失败')
        this.props.table.offLoading()
      })

    this.setState({
      visible: false,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={800}
          onCancel={this.onClose}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="horizontal" autoComplete="off" style={{ marginTop: 10 }} ref={this.form1} labelCol={{ span: 6 }} onFinish={this.submit}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="photoFileName" label="图号">
                  <Input disabled="disabled"/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="componentName" label="零件名称">
                  <Input disabled="disabled" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="componentNum" label="数量">
                  <Input disabled="disabled"/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="componentCode" label="物料编号">
                  <Input disabled="disabled"/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="unitPrice" label="单价">
                  <Input disabled="disabled"/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="componentType" label="类型">
                  <Input disabled="disabled" />
                </Form.Item>
              </Col>
            {/* </Row>

            <Row gutter={24}> */}
              
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}

WarehouseFrom.propTypes = {}

export default WarehouseFrom
