import React, { Component } from 'react'
import { Tabs } from 'antd'
import TabPane from '@ant-design/pro-card/lib/components/TabPane'
import ProductReport from './report/ProductReport'
import RewardReport from './report/RewardReport'
import YearRewardReport from './report/YearRewardReport'
import CraftRewardReport from './report/CraftRewardReport'

class Analysis extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentWillMount() {}

  render() {
    return (
      <Tabs defaultActiveKey="1" centered>
        <TabPane forceRender={true} tab="产品汇总报表" key="产品汇总报表">
          <ProductReport></ProductReport>
        </TabPane>
        <TabPane forceRender={true} tab="计件工资月报" key="计件工资月报">
          <RewardReport></RewardReport>
        </TabPane>
        <TabPane forceRender={true} tab="计件工资年报" key="计件工资年报">
          <YearRewardReport></YearRewardReport>
        </TabPane>
        <TabPane forceRender={true} tab="各工序人工月报表" key="各工序人工月报表">
          <CraftRewardReport></CraftRewardReport>
        </TabPane>
      </Tabs>
    )
  }
}

Analysis.propTypes = {}

export default Analysis
