//使用抽屉进行编辑
import React, { Component } from 'react'
import { Modal, Input,  Button, Form,  Row, Col,Select } from 'antd'
import { isEmptyObject } from '../../api/http'
import '@ant-design/pro-form/dist/form.css'
import http from '../../api/http'
const { Option } = Select;

class ExternalServiceForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      title: '新增服务',
      selectItem: {},
      system : [],
      timer : [],
      list:{}
    }
  }

  timerList(){
    let url = '/admin/externalService/timerList'
    http
      .post(url)
      .then((res) => {
        this.setState({timer : res.data})
      })
      .catch(() => {
        console.log('失败')
      })
  }

  systemList(){
    let url = '/admin/externalService/systemList'
    http
      .post(url)
      .then((res) => {
        this.setState({system : res.data})
      })
      .catch(() => {
        console.log('失败')
      })
  }

  toAdd() {
    this.form1.current.resetFields()
    this.systemList();
    this.timerList();
    this.setState({
      visible: true,
      selectItem: null,
      title: '新增服务',
    })
  }
  
  toEdit = (inEntity) => {
    inEntity.externalSystemId = inEntity.externalSystem.id
    console.log(inEntity);
    this.systemList();
    this.timerList();
    this.form1.current.resetFields()
    this.form1.current.setFieldsValue(inEntity)
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        title: '修改服务信息',
      })
    } else {
      Modal.error({
        title: '请先选中记录',
      })
    }
    this.setState({list:inEntity})
  }

  checkForm = () => {
    console.log(this.form1.current)
    this.form1.current.submit()
  }
  submit = (values) => {
    console.log(values);
     let url = '/admin/externalService/add'
    if (this.state.selectItem != null) {
      url = '/admin/externalService/update'
      values.id = this.state.selectItem.id
    }

    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.getList()
      })
      .catch(() => {
        console.log('失败')
      })

    this.setState({
      visible: false,
    }) 
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {}


  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={800}
          onCancel={this.onClose}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="horizontal" autoComplete="off" style={{ marginTop: 10 }} ref={this.form1} 
          labelCol={{ span: 6 }}
           onFinish={this.submit}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="服务名称"
                  rules={[
                    {
                      required: true,
                      message: '服务名称不能为空',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="servicePath" label="服务地址"
                 rules={[
                    {
                      required: true,
                      message: '服务地址不能为空',
                    },
                  ]}
                  >
                    
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
            <Col span={12}>
                <Form.Item name="externalSystemId" label="接入系统"
                 rules={[
                    {
                      required: true,
                      message: '接入系统不能为空',
                    },
                  ]}>
                <Select
                    style={{
                    width: 273,
                    }}
                >
                    {
                      this.state.system.map((item,index)=>{
                      return <Option key={index} value={item.id}>{item.name}</Option>

                    })
                    }
                </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="timerId" label="定时任务"
                rules={[
                    {
                      required: true,
                      message: '定时任务不能为空',
                    },
                  ]}>
                <Select
                    style={{
                    width: 273,
                    }}
                >
                    {
                      this.state.timer.map((item,index)=>{
                      return <Option key={index} value={item.id}>{item.name}</Option>

                    })
                    }
                </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="serviceImplPath"
                  label="实现类"
                  rules={[
                    {
                      required: true,
                      message: '实现类不能为空',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>       


          </Form>
        </Modal>
      </div>
    )
  }
}

ExternalServiceForm.propTypes = {}

export default ExternalServiceForm
