import Texty from 'rc-texty'
import 'rc-texty/assets/index.css'
import Animation from 'rc-tween-one'
import React, { Component } from 'react'
import '../../assets/css/DefaultView.css'
import animation1 from '../../assets/images/img/default_view_d1.png'
import animation2 from '../../assets/images/img/default_view_d2.png'
import animation3 from '../../assets/images/img/default_view_d3.png'
import animation4 from '../../assets/images/img/default_view_d4.png'

class DefaultView extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div className="default_view">
        <div className="default_view_con">
          <div className="animation">
            <Animation
              className="animation1"
              animation={{
                y: 10,
                yoyo: true,
                repeat: -1,
                duration: 1700,
              }}
            >
              <img src={animation1} alt="" />
            </Animation>
            <Animation
              className="animation2"
              animation={{
                x: 40,
                yoyo: true,
                repeat: -1,
                duration: 2500,
              }}
            >
              <img src={animation2} alt="" />
            </Animation>
            <Animation
              className="animation3"
              animation={{
                scaleX: 1.1,
                scaleY: 1.1,
                yoyo: true,
                repeat: -1,
                duration: 2000,
              }}
            >
              <img src={animation3} alt="" />
            </Animation>
            <Animation
              className="animation4"
              animation={{
                x: 40,
                scaleX: 1.2,
                scaleY: 1.2,
                yoyo: true,
                repeat: -1,
                duration: 2800,
              }}
            >
              <img src={animation4} alt="" />
            </Animation>
          </div>

          <Texty className="title">欢 迎 使 用</Texty>

          <p className="label">由艾乐博公司提供技术支持</p>
        </div>
      </div>
    )
  }
}

export default DefaultView
