import {
  AlignLeftOutlined,
  CheckCircleTwoTone,
  ClockCircleOutlined,
  EllipsisOutlined,
  ExceptionOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FrownOutlined,
  HighlightTwoTone,
  MehOutlined,
  PlusOutlined,
  SmileOutlined,
} from '@ant-design/icons'
import ProCard from '@ant-design/pro-card'
import '@ant-design/pro-card/dist/card.css'
import ProDescriptions from '@ant-design/pro-descriptions'
import { pdfjs } from 'react-pdf'
import Document from 'react-pdf/dist/umd/Document'
import Page from 'react-pdf/dist/umd/Page'
import { Avatar, BackTop, Badge, Button, Card, Checkbox, Dropdown, List, message, Modal, Progress, Space, Spin } from 'antd'
import React, { Component } from 'react'
import '../../assets/css/OrderDetail.css'
import http from '../api/http'
import IconFont from '../api/IconFont'
import OrderCompile from './OrderCompile'
import OrderComponentForm from './OrderComponentForm'
import OrderOffer from './OrderOffer'
pdfjs.GlobalWorkerOptions.workerSrc = `//etooling.irobot-auto.com/pdf.worker.min.js`
const { Meta } = Card
const { confirm } = Modal
class OrderTrack extends Component {
  constructor(props) {
    super(props)
    this.orderCompile = React.createRef()
    this.orderComponentForm = React.createRef()
    this.orderOffer = React.createRef()
    this.state = {
      loading: false,
      visible: false,
      orderComponents: [],
      toWorkOrderIds: [],
      statusLimitComponents: [],
      client: {},
      order: {},
      displayStatus: '',
    }
  }
  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  onLoading() {
    this.setState({
      loading: true,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }
  toCompile = (orderComponent) => {
    // this.setState({
    //   visible: false,
    // })
    console.log(orderComponent)
    this.orderCompile.current.toCompile(this.state.order, orderComponent)
  }
  backForList = () => {
    this.props.table.showList()
  }
  toOrderDetail = (orderId) => {
    this.setState({
      visible: true,
    })
    http
      .post('/admin/order/toOrderTrack?id=' + orderId, null)
      .then((res) => {
        let orderData = res.data
        console.log('orderData', orderData)
        this.setState({
          order: orderData.order,
          orderComponents: orderData.orderComponents.content,
          statusLimitComponents: orderData.orderComponents.content,
          client: orderData.order.client,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  onChangeComponent = (toWorkOrderIds) => {
    this.setState({ toWorkOrderIds })
  }

  toOrderOffer = () => {
    this.orderOffer.current.toOffer(this.state.order, this.state.orderComponents)
  }

  newWorkOrder = (item) => {
    let list = this.toOrderDetail
    let orderId = this.state.order.id
    confirm({
      title: '是否要投产该零件',
      icon: <IconFont type="icon-gongyede-36" size="20" color="#0000FF" />,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/workOrder/orderComponentsIdToWork?orderComponentsIds=' + item.id, '')
          .then((res) => {
            Modal.success({
              content: '投产成功，如要指定人员请到工单管理中指派.',
            })
            list(orderId)
          })
          .catch((err) => {
            console.log(err)
            message.error('提交失败，请联系管理员')
          })
      },
      onCancel() {},
    })
  }
  batchNewWorkOrder = () => {
    let list = this.toOrderDetail
    let orderId = this.state.order.id
    let orderComponentsIds = ''
    let toWorkOrderIds = this.state.toWorkOrderIds
    if (toWorkOrderIds.length === 0) {
      message.error('请先选择已拆解好的，零件进行投产')
    } else {
      toWorkOrderIds.forEach((id) => {
        orderComponentsIds = orderComponentsIds + id + ','
      })
      let values = {}
      values.orderComponentsIds = orderComponentsIds
      confirm({
        title: '是否要投产选择中的(' + toWorkOrderIds.length + ')零件',
        icon: <IconFont type="icon-gongyede-36" size="20" color="#0000FF" />,
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          http
            .post('/admin/workOrder/orderComponentsIdToWork', values)
            .then((res) => {
              Modal.success({
                content: '投产成功，如要指定人员请到工单管理中指派.',
              })
              list(orderId)
            })
            .catch((err) => {
              console.log(err)
              message.error('提交失败，请联系管理员')
            })
        },
        onCancel() {},
      })
    }
  }
  render() {
    const customIcons = {
      1: <FrownOutlined style={{ fontSize: '24px' }} />,
      2: <FrownOutlined style={{ fontSize: '24px' }} />,
      3: <MehOutlined style={{ fontSize: '24px' }} />,
      4: <SmileOutlined style={{ fontSize: '24px' }} />,
      5: <SmileOutlined style={{ fontSize: '24px' }} />,
    }

    return (
      <div>
        <Spin spinning={this.state.loading} delay={500}>
          <Modal
            maskClosable={false}
            forceRender={true}
            title={this.state.title}
            width={1480}
            onCancel={this.onClose}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                  取消
                </Button>
                <Button onClick={this.checkForm} type="primary">
                  提交
                </Button>
              </div>
            }
          >
            <ProCard split="vertical" style={{ display: this.state.visible ? '' : 'none' }}>
              <ProCard colSpan="20%">
                <ProDescriptions
                  column={1}
                  title={
                    <div>
                      <span style={{ display: 'block', overflow: 'hidden', float: 'left', margin: '1px 5px 0 0' }}>
                        <IconFont size="20px" type="icon-order-copy" />
                      </span>
                      <p>订单详情</p>
                    </div>
                  }
                >
                  <ProDescriptions.Item label="订单编号">{this.state.order.orderCode}</ProDescriptions.Item>
                  <ProDescriptions.Item label="重要级别">{this.state.order.orderLevel}</ProDescriptions.Item>
                  <ProDescriptions.Item valueType="date" label="创建时间">
                    {this.state.order.createTime}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="预算成本金额" tooltip="仅供参考，以实际为准">
                    {parseFloat(this.state.order.evalCost).toFixed(2)}元
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="物料成本">{parseFloat(this.state.order.materialCost).toFixed(2)}元</ProDescriptions.Item>
                  <ProDescriptions.Item label="人力成本">{parseFloat(this.state.order.workerCost).toFixed(2)} 元</ProDescriptions.Item>
                  <ProDescriptions.Item label="物料重量"> {parseFloat(this.state.order.materialWeight).toFixed(2)}kg</ProDescriptions.Item>
                  <ProDescriptions.Item label="零件型号数量">{this.state.order.componentsNum}件</ProDescriptions.Item>
                </ProDescriptions>
                <ProDescriptions
                  column={1}
                  title={
                    <div>
                      <span style={{ display: 'block', overflow: 'hidden', float: 'left', margin: '1px 5px 0 0' }}>
                        <IconFont size="20px" type="icon-kehu" />
                      </span>
                      客户信息
                    </div>
                  }
                >
                  <ProDescriptions.Item label="客户编号">{this.state.client.clientCode}</ProDescriptions.Item>
                  <ProDescriptions.Item label="客户名称">{this.state.client.name}</ProDescriptions.Item>
                  <ProDescriptions.Item label="联系电话">{this.state.client.phone}</ProDescriptions.Item>
                  <ProDescriptions.Item label="区域">{this.state.client.area}</ProDescriptions.Item>
                </ProDescriptions>
                <ProDescriptions
                  column={1}
                  title={
                    <div>
                      <span style={{ display: 'block', overflow: 'hidden', float: 'left', margin: '0 5px 0 0' }}>
                        <IconFont size="20px" type="icon-jindu" />
                      </span>
                      进度跟踪
                    </div>
                  }
                >
                  <ProDescriptions.Item label={<div>1下单</div>}>
                    <Progress type="line" percent={100} />
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label={<div>2拆解</div>}>
                    <Progress
                      type="line"
                      style={{ width: '90%' }}
                      percent={(this.state.order.doneCompileCompCnt / this.state.order.componentTypeNum) * 100}
                      format={() => {
                        return (
                          <span>
                            {this.state.order.doneCompileCompCnt}/{this.state.order.componentTypeNum}
                          </span>
                        )
                      }}
                    />
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label={<div>3投产</div>}>
                    <Progress
                      type="line"
                      style={{ width: '90%' }}
                      percent={(this.state.order.operationCompCnt / this.state.order.componentsNum) * 100}
                      format={() => {
                        return (
                          <span>
                            {this.state.order.operationCompCnt}/{this.state.order.componentsNum}
                          </span>
                        )
                      }}
                    />
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label={<div>4生产</div>}>
                    <Progress type="line" />
                  </ProDescriptions.Item>
                </ProDescriptions>
              </ProCard>
              <ProCard
                headerBordered
                title={<>零件信息(双击进行拆解)</>}
                extra={
                  <>
                    <Space>
                      <Button
                        icon={<HighlightTwoTone style={{ color: '#0000FF' }} />}
                        onClick={() => {
                          let orderComponents = this.state.orderComponents
                          let statusLimitComponents = []
                          orderComponents.forEach((item) => {
                            if (item.status === '已投产') {
                              statusLimitComponents.push(item)
                            }
                          })

                          this.setState({
                            statusLimitComponents,
                          })
                        }}
                      >
                        已投产
                      </Button>
                      <Button
                        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                        onClick={() => {
                          // this.orderComponentForm.current.toAdd(this.state.order)

                          let orderComponents = this.state.orderComponents
                          let statusLimitComponents = []
                          orderComponents.forEach((item) => {
                            if (item.status === '已拆解') {
                              statusLimitComponents.push(item)
                            }
                          })

                          this.setState({
                            statusLimitComponents,
                          })
                        }}
                      >
                        已拆解
                      </Button>

                      <Button
                        icon={<ClockCircleOutlined style={{ color: '#f5222d' }} />}
                        onClick={() => {
                          let orderComponents = this.state.orderComponents
                          let statusLimitComponents = []
                          orderComponents.forEach((item) => {
                            if (item.status === '' || item.status == null) {
                              statusLimitComponents.push(item)
                            }
                          })

                          this.setState({
                            statusLimitComponents,
                          })
                        }}
                      >
                        待拆解
                      </Button>
                      {this.state.order.doneCompileCompCnt === this.state.order.componentTypeNum ? (
                        <>
                          <Button
                            icon={<IconFont type="icon-calculatorcount" size="20" color="#ff8000" />}
                            onClick={() => {
                              this.toOrderOffer()
                            }}
                          >
                            报价清单
                          </Button>
                          <Button
                            icon={<IconFont type="icon-gongyede-40" size="20" color="#0000FF" />}
                            onClick={() => {
                              this.batchNewWorkOrder()
                            }}
                          >
                            批量投产
                          </Button>
                        </>
                      ) : (
                        ''
                      )}

                      <Button
                        icon={<AlignLeftOutlined />}
                        onClick={() => {
                          this.setState({
                            statusLimitComponents: this.state.orderComponents,
                          })
                        }}
                      >
                        全部零件
                      </Button>

                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          this.orderComponentForm.current.toAdd(this.state.order)
                        }}
                      >
                        新增零件
                      </Button>
                    </Space>
                  </>
                }
              >
                <div className="OrderDetailList">
                  <Checkbox.Group style={{ width: '100%' }} onChange={this.onChangeComponent}>
                    <List
                      grid={{ gutter: 16, column: 4 }}
                      dataSource={this.state.statusLimitComponents}
                      renderItem={(item) => (
                        <List.Item>
                          <Badge
                            size="default"
                            count={
                              item.status === '已拆解' ? (
                                <CheckCircleTwoTone twoToneColor="#52c41a" />
                              ) : item.status === '已投产' ? (
                                <HighlightTwoTone style={{ color: '#0000FF' }} />
                              ) : (
                                <ClockCircleOutlined style={{ color: '#f5222d' }} />
                              )
                            }
                          >
                            <Card
                              onDoubleClick={() => {
                                this.toCompile(item)
                              }}
                              title={item.status === '已拆解' ? <Checkbox value={item.id}>{item.components.componentCode}</Checkbox> : item.components.componentCode}
                              cover={
                                <div style={{ padding: '7px 5px', borderBottom: '1px solid #eee' }}>
                                  {/* <Document file={item.components.componetImg.webPath == null ? '/.paf' : item.components.componetImg.webPath}>
                                    <Page pageNumber={1} />
                                  </Document> */}
                                  <embed
                          align="middle"
                          allowscriptaccess="never"
                          allowfullscreen="true"
                          mode="transparent"
                          src={item.components.componetImg.webPath == null ? '' : item.components.componetImg.webPath}
                          style={{ minHeight:130 }}
                          width={'100%'}
                        />
                                </div>
                              }
                              actions={[
                                <div>
                                  <span>当前工步</span>
                                  <br />
                                  {item.components.craftNum == null ? '-' : item.components.craftNum}
                                </div>,
                                <div>
                                  <span>人工成本</span>
                                  <br />
                                  {item.components.workerPrice == null ? '-' : item.components.workerPrice}
                                </div>,
                                <div>
                                  <span>原料成本</span>
                                  <br />
                                  {item.components.materialPrice == null ? '-' : item.components.materialPrice}
                                </div>,
                              ]}
                              hoverable={true}
                              extra={
                                <Avatar
                                  style={{
                                    backgroundColor: '#f56a00',
                                    verticalAlign: 'middle',
                                  }}
                                  size="large"
                                >
                                  x{item.componentsNum}
                                </Avatar>
                              }
                            >
                              <Meta title={item.components.componentName} description={item.memo} />
                              <Dropdown
                                overlay={
                                  <div className="orderOperation">
                                    {item.status === '已拆解' || item.status === '' || item.status === '待拆解' ? (
                                      <Button
                                        icon={<FileSyncOutlined />}
                                        onClick={() => {
                                          this.toCompile(item)
                                        }}
                                      >
                                        拆解
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                    {/* <Button icon={<FileSearchOutlined />}>查看</Button> */}
                                    {item.status === '已拆解' ? (
                                      <Button
                                        icon={<FileProtectOutlined />}
                                        onClick={() => {
                                          this.newWorkOrder(item)
                                        }}
                                      >
                                        投产
                                      </Button>
                                    ) : (
                                      <>
                                        <Button icon={<FileSearchOutlined />}>查看</Button>
                                        <Button icon={<ExceptionOutlined />}>进度</Button>
                                      </>
                                    )}
                                  </div>
                                }
                                trigger={['click']}
                                placement="topRight"
                              >
                                <div className="btn">
                                  <EllipsisOutlined />
                                </div>
                              </Dropdown>
                            </Card>
                          </Badge>
                        </List.Item>
                      )}
                    />
                  </Checkbox.Group>
                </div>
              </ProCard>
            </ProCard>
            <BackTop />
          </Modal>
        </Spin>
        <OrderCompile ref={this.orderCompile} table={this}></OrderCompile>
        <OrderComponentForm ref={this.orderComponentForm} table={this}></OrderComponentForm>
        <OrderOffer ref={this.orderOffer} table={this}></OrderOffer>
      </div>
    )
  }
}

OrderTrack.propTypes = {}

export default OrderTrack
