import { Button, Space } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { Component } from 'react'
import ReactToPrint from 'react-to-print'
import http from '../api/http'
export default class ComponentCraftPrint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      componentNum: 0,
      steps: [],
      componentsCrafts: [],
      workerPrice: 0,
      materialParams: [],
      materialParmamValues: [],
      component: { material: { name: {}, materialType: { name: '' } } },
      order: { client: { name: '' }, org: { name: '' } },
    }
  }
  toPrint = (component, order, componentNum, componentsCrafts) => {
    console.log(componentsCrafts)
    let steps = this.state.steps
    steps.splice(0, steps.length)
    let workerPrice = 0

    componentsCrafts.forEach((item) => {
      let componentCraftDetail = {}
      componentCraftDetail.selectCraft = item.craft
      componentCraftDetail.id = item.id
      componentCraftDetail.selectCraftType = item.craft.craftType
      componentCraftDetail.craftParam = item.craft.craftParamList
      componentCraftDetail.carftParamsValues = JSON.parse(item.paramValuesJson)
      componentCraftDetail.price = item.price
      componentCraftDetail.memo = item.memo
      workerPrice = workerPrice + item.price
      console.log(steps)

      // let step = {
      //   id: componentCraftDetail.id,
      //   seq: componentCraftDetail.seq,
      //   name: componentCraftDetail.selectCraft.name,
      //   type: 'craft',
      //   componentCraft: componentCraftDetail,
      // }
      // steps.push(step)

      //组织数据
      let lastCraftTypeId = ''
      if (steps[steps.length - 1] != null) {
        lastCraftTypeId = steps[steps.length - 1].craftTypeId
      }
      console.log(lastCraftTypeId + ';' + componentCraftDetail.selectCraftType.id)
      if (lastCraftTypeId !== componentCraftDetail.selectCraftType.id) {
        let componentCrafts = []
        componentCrafts.push(componentCraftDetail)
        let step = {
          id: componentCraftDetail.id,
          seq: steps.length,
          craftTypeId: componentCraftDetail.selectCraftType.id,
          name: componentCraftDetail.selectCraftType.name,
          type: 'craft',
          componentCrafts,
        }
        steps.push(step)
      } else {
        let lastLastStep = steps[steps.length - 1]
        let componentCrafts = lastLastStep.componentCrafts
        componentCrafts.push(componentCraftDetail)
        lastLastStep.componentCrafts = componentCrafts
      }
    })

    this.setState({
      component,
      order,
      componentsCrafts,
      componentNum,
      visible: true,
      materialParams: component.material == null ? [] : component.material.materialParams.split('|'),
      materialParmamValues: component.materialParamValues == null ? [] : component.materialParamValues.split('|'),
    })
  }

  handleDownExcel = (url, fileName) => {
    console.log(url)
    http.downloadExtranetUrl(url, '', fileName)
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  render() {
    return (
      <Modal
        visible={this.state.visible}
        width="1367px"
        closable={false}
        maskClosable={false}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              取消
            </Button>

            <ReactToPrint
              trigger={() => (
                <a href="#a">
                  <Button type="primary">打印</Button>
                </a>
              )}
              content={() => this.componentRef}
            />
          </div>
        }
      >
        <div className="order_printing">
          <div ref={(el) => (this.componentRef = el)}>
            <table width="1280px" border="1px" style={{ boxSizing: 'border-box', textAlign: 'center', borderColor: '#999', margin: '0 auto', boxShadow: '0 2px 5px #ccc' }}>
              <colgroup>
                <col width="135" />
                <col width="250" />
                <col width="250" />
                <col width="210" />
                <col width="177" />
                <col width="275" />
                <col width="232" />
                <col width="250" />
                <col width="218" />
              </colgroup>
              <tbody>
                <tr class="firstRow">
                  <th colspan="2" rowspan="3" width="185" style={{ lineHeight: '25px', textAlign: 'center' }}>
                    {this.state.order.org !== null && this.state.order.org !== undefined ? this.state.order.org.name : ''}
                  </th>
                  <th colspan="2" rowspan="3" width="160" style={{ lineHeight: '25px', textAlign: 'center' }}>
                    机械加工工艺工序卡
                  </th>
                  <td width="100" style={{ fontSize: '13px' }}>
                    客户名称
                  </td>
                  <td width="138" style={{ fontSize: '13px' }}>
                    {this.state.order.client.name}
                  </td>
                  <td width="116" style={{ fontSize: '13px' }}>
                    客户编号
                  </td>
                  <td width="152" style={{ fontSize: '13px' }}>
                    {this.state.order.client.clientCode}
                  </td>
                  <td rowspan="2" width="109" style={{ fontSize: '13px' }}>
                    工艺卡编号
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: '13px' }}>订单名称</td>
                  <td style={{ fontSize: '13px' }}>{this.state.order.orderName}</td>
                  <td style={{ fontSize: '13px' }}>名称</td>
                  <td width="145" style={{ fontSize: '13px' }}>
                    {this.state.component.componentName}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: '13px' }}>订单号</td>
                  <td style={{ fontSize: '13px' }}> {this.state.order.orderCode}</td>
                  <td style={{ fontSize: '13px' }}>图号</td>
                  <td width="149" style={{ fontSize: '13px' }}>
                    {this.state.component.componentCode}
                  </td>
                  <td width="11" style={{ fontSize: '13px' }}>
                    {this.state.component.componentCode}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: '13px' }}>序号</td>
                  <td colspan="2" style={{ fontSize: '13px' }}>
                    材料分类
                  </td>
                  <td colspan="2" style={{ fontSize: '13px' }}>
                    材料名称
                  </td>
                  <td colspan="2" style={{ fontSize: '13px' }}>
                    材料尺寸
                  </td>
                  <td style={{ fontSize: '13px' }}>估计重量</td>
                  <td style={{ fontSize: '13px' }}>数量</td>
                </tr>
                {this.state.component.materials == null
                  ? ''
                  : this.state.component.materials.map((cm, index) => (
                      <tr>
                        <td style={{ fontSize: '13px' }}>{index + 1}</td>
                        <td colspan="2" style={{ fontSize: '13px' }}>
                          {cm.material.materialType.name}
                        </td>
                        <td colspan="2" style={{ fontSize: '13px' }}>
                          {cm.material.name}[{cm.isFax}]
                        </td>
                        <td colspan="2" style={{ fontSize: '13px' }}>
                          {cm.componentMaterialParamValues.map((paramValue, index) => (
                            <>
                              <Space>
                                {paramValue.materialParams.name}:{paramValue.value}
                                {'  '}
                                {cm.isFax === '精料' ? (
                                  <span>
                                    [-{paramValue.negative}~+{paramValue.positive}]
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Space>
                              {cm.isFax === '精料' ? <br /> : ''}
                            </>
                          ))}
                        </td>
                        <td style={{ fontSize: '13px' }}>
                          {cm.weight != null ? parseFloat(cm.weight).toFixed(2) : ''}
                          {cm.material.materialType.unit}
                        </td>
                        <td style={{ fontSize: '13px' }}>
                          {cm.num}x{this.state.componentNum}={cm.num * this.state.componentNum}
                        </td>
                      </tr>
                    ))}

                <tr>
                  <td style={{ fontSize: '13px' }}>工序号</td>
                  <td style={{ fontSize: '13px' }}>工序名称</td>

                  <td style={{ fontSize: '13px' }}>工步号</td>
                  <td colspan="2" style={{ fontSize: '13px' }}>
                    工步内容
                  </td>
                  <td colspan="2" style={{ fontSize: '13px' }}>
                    操作参数
                  </td>
                  <td colspan="2" style={{ fontSize: '13px' }}>
                    工步描述
                  </td>
                </tr>
                {this.state.steps.map((item, index) => (
                  <>
                    <tr>
                      <td rowspan={item.componentCrafts.length} style={{ fontSize: '13px' }}>
                        {index}0
                      </td>
                      <td rowspan={item.componentCrafts.length} style={{ fontSize: '13px' }}>
                        {item.name}
                      </td>
                      <td style={{ fontSize: '13px' }}>{item.componentCrafts.length > 0 ? '1' : ''}</td>
                      <td colspan="2" style={{ fontSize: '13px' }}>
                        {item.componentCrafts[0].selectCraft.name}
                      </td>
                      <td colspan="2" style={{ fontSize: '13px' }}>
                        {item.componentCrafts[0].carftParamsValues != null
                          ? item.componentCrafts[0].carftParamsValues.map((craftParam, index) => (
                              <>
                                {craftParam.name} :
                                {craftParam.type !== '附件' ? (
                                  craftParam.value
                                ) : (
                                  // <a onclick={() => {this.handleDownExcel(craftParam.value.web_path)}}>{craftParam.value.name}</a>
                                  <Space>
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        this.handleDownExcel(craftParam.value.webPath, craftParam.value.name)
                                      }}
                                    >
                                      {craftParam.value.name}
                                    </Button>
                                  </Space>
                                )}
                                <br />
                              </>
                            ))
                          : ''}
                      </td>
                      <td colspan="2" style={{ fontSize: '13px' }}>
                        {item.componentCrafts[0].memo}
                      </td>
                    </tr>
                    {item.componentCrafts.length > 1 ? (
                      <>
                        {item.componentCrafts.map((craft, index) =>
                          index > 0 ? (
                            <tr>
                              <td style={{ fontSize: '13px' }}>{index + 1}</td>
                              <td colspan="2" width="432" style={{ fontSize: '13px' }}>
                                {craft.selectCraft.name}
                              </td>
                              <td colspan="2" style={{ fontSize: '13px' }}>
                                {craft.carftParamsValues != null
                                  ? craft.carftParamsValues.map((craftParam, index) => (
                                      <>
                                        {craftParam.name}:{craftParam.value}
                                      </>
                                    ))
                                  : ''}
                              </td>
                              <td colspan="2" style={{ fontSize: '13px' }}>
                                {item.componentCrafts.memo}
                              </td>
                            </tr>
                          ) : (
                            ''
                          )
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    )
  }
}
