import React, { Component } from 'react'
import { Button, Col, Form, Input, Modal, Pagination, Space } from 'antd'
import { ExclamationCircleOutlined, ReloadOutlined,PlusOutlined } from '@ant-design/icons'
import http from '../api/http'
import CraftPriceFuncSettingForm from './CraftPriceFuncSettingForm'
import confirm from 'antd/lib/modal/confirm'
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable';

export default class CraftPriceFuncSetting extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.searchForm = React.createRef()
    this.state = {
      searchValue:'',
      sortedInfo: null,
      selectItem: {},
      loading: false,
      page: 1,
      pageSize: 15,
      filteredInfo: null,
      //测试数据
      data: [],
    }
  }

  //加载完成
  componentDidMount() {
    let values = {}
    values.page = this.state.page
    values.pageSize = this.state.pageSize
    this.getList(values)
  }

  onLoading() {
    this.setState({
      loading: true,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }
  getList = (values) => {
    this.onLoading()
    if (values == null) {
      values = {}
    }
    values.page = values.page
    values.pageSize = this.state.pageSize
    values.searchValue = this.state.searchValue
    http
      .post('/admin/craftPriceFuncSetting/pageQuery', values)
      .then((res) => {
        this.setState({
          data: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  changePage = (page) => {
    let values = {}
    values.page = page
    values.pageSize = this.state.pageSize
    this.getList(values)
  }

  toAdd = () => {
    this.form.current.toAdd()
  }

  toEdit = (record) => {
    this.form.current.toEdit(record)
  }
  
  del = (record) => {
    let list = this.getList
    confirm({
      title: '这是配置信息，确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,
      content: '这如果有其他外关联信息使用就无法删除',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/craftPriceFuncSetting/del?id=' + record.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list()
          })
          .catch(() => {
            Modal.error({
              content: '删除失败,请联系管理员！！！！！！',
            })
          })
      },
      onCancel() {},
    })
  }

  render() {
    return (
      <>
      <Panel
      title="工艺计价公式管理"
      fontWeight
        topBar={
          <Space>
          关键词搜索
          <Input
            value={this.state.searchValue}
            onChange={(evn) => {
              let searchValue = evn.target.value
              this.setState({ searchValue: searchValue })
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              this.getList()
            }}
          >
            查询
          </Button>
        </Space>
          }
          topRight={
            <Button type="primary" icon={<PlusOutlined />} onClick={this.toAdd} key="primary">
                    新增计价公式
                  </Button>
          }
          footerBar={
            <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              this.getList()
            }}
          >
            刷新
          </Button>
          }
          footerRight={
            <Pagination
            // 分页
            showTotal= {(total) => {
             return <>共 {total} 条</>
           }}
           showSizeChanger={false}
           showQuickJumper={true}
           defaultPageSize={15}
           total={this.state.data.totalElements}
           onChange={(page)=>{
            let values = {}
            values.page = page
            values.pageSize = this.state.pageSize
            this.getList(values)
           }}
           onRow={(record) => ({
             onDoubleClick: () => {
               this.toEdit(record)
             },
           })}
           />
          }
        >
        <ResizeTable
        
         bordered
          search={false}
          size="small"
          rowKey={(record) => record.id}
          columns={[
            {
              title: '名称',
              dataIndex: 'name',
              key: 'name',
              ellipsis: true,
              width: 200,
            },
            {
              title: '条件',
              dataIndex: 'condition',
              key: 'condition',
              sorter: (a, b) => a.groupName.length - b.groupName.length,
              ellipsis: true,
              width: 200,
            },

            {
              title: '返回值',
              dataIndex: 'returnValue',
              key: 'returnValue',
              sorter: (a, b) => a.displayValue - b.displayValue,
              ellipsis: true,
              width: 150,
            },
            {
              title: '操作',
              width: 200,
              key: 'action',
              render: (value, record) => {
                return (
                  <div>
                    <Button type="link" onClick={this.toEdit.bind(this, record)}>
                      编辑
                    </Button>
                    <Button type="link" onClick={this.del.bind(this, record)}>
                      删除
                    </Button>
                  </div>
                )
              },
            },
          ]}
          dataSource={this.state.data.content}
          pagination={false}
        ></ResizeTable>
        <CraftPriceFuncSettingForm ref={this.form} getList={this.getList} table={this} />
        </Panel>
      </>
    )
  }
}
