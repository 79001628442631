import React, { Component } from 'react'
import SwiperCore, { Navigation, Zoom } from 'swiper'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/zoom/zoom.min.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import IconFont from '../api/IconFont'
import { Button, List, Avatar, Modal, Tag, Spin, message, Drawer } from 'antd'

import '../../assets/css/OrderMaterial.css'
import http from '../api/http'
import TextArea from 'antd/lib/input/TextArea'

SwiperCore.use([Zoom, Navigation])

class OrderCraftEmployee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '指派技工',
      selectCractType: null,
      selectEmpolyee: null,
      componentCraftId: null,
      craftTypeEmpolyees: [],
      craftsTypes: [],
      current: 0,
      visible: false,
      loading: false,
      orderComponentsId: null,
      memo: '',
      order: null,
      orderComponents: null,
      order: null,
    }
  }
  onLoading() {
    this.setState({
      loading: false,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }
  componentDidMount() {}
  getCraftTypeList = (values) => {
    http
      .post('/admin/craftType/getAllCrafts')
      .then((res) => {
        console.log(res)
        this.setState({
          craftsTypes: res.data.craftsTypes,
        })
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  toEdit = (componentCraftDetail, order, orderComponents) => {
    let values = {}

    this.getCraftTypeList()
    values.pageSize = '100'
    values.craftTypeId = componentCraftDetail.selectCraftType.id
    //获取人员
    http
      .post('/admin/craftTypeEmployee/pageQuery', values)
      .then((res) => {
        console.log(res.data.content)
        this.setState({
          visible: true,
          selectEmpolyee: null,
          selectCractType: componentCraftDetail.selectCraftType,
          componentCraftId: componentCraftDetail.id,
          orderComponentsId: orderComponents.id,
          orderComponents,
          order,
          craftTypeEmpolyees: res.data == null ? [] : res.data.content,
        })
        this.offLoading()
      })
      .catch(() => {
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  render() {
    return (
      <>
        {this.state.visible ? (
          <Drawer
            mask={false}
            closable={false}
            forceRender={true}
            title={this.state.title}
            width={640}
            visible={this.state.visible}
            onClose={this.onClose}
            maskClosable={false}
            onCancel={this.onClose}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                  取消
                </Button>
                <Button
                  onClick={() => {
                    if (this.state.selectEmpolyee == null) {
                      message.error({
                        content: '请先选择工艺',
                      })
                    } else {
                      let values = {}
                      values.orderComponentId = this.state.orderComponentsId
                      values.componentCraftId = this.state.componentCraftId
                      values.employeeId = this.state.selectEmpolyee.id
                      //获取人员
                      http
                        .post('/admin/components/saveEmployeeToOrderCraft', values)
                        .then((res) => {
                          message.success({
                            content: '更新数据完成',
                          })
                          this.props.table.toCompile(this.state.order, this.state.orderComponents)
                          this.setState({
                            visible: false,
                          })
                          this.offLoading()
                        })
                        .catch(() => {
                          Modal.error({
                            content: '数据加载失败请联系系统管理员',
                          })
                        })
                    }
                  }}
                  type="primary"
                >
                  完成
                </Button>
              </div>
            }
          >
            <div className="order_material">
              <div className="step">
                <ul className="step">
                  {this.state.craftsTypes.map((item, index) => (
                    <li>
                      <a
                        onClick={() => {
                          this.setState({ selectCractType: item })
                          let values = {}
                          values.pageSize = '100'
                          values.craftTypeId = item.id
                          http
                            .post('/admin/craftTypeEmployee/pageQuery', values)
                            .then((res) => {
                              console.log(res.data.content)
                              this.setState({
                                visible: true,
                                selectEmpolyee: null,
                                craftTypeEmpolyees: res.data == null ? [] : res.data.content,
                              })
                              this.offLoading()
                            })
                            .catch(() => {
                              Modal.error({
                                content: '数据加载失败请联系系统管理员',
                              })
                            })
                        }}
                        style={{ backgroundColor: item === this.state.selectCractType ? 'orange' : '' }}
                      >
                        <p>{item.name}</p>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {this.state.selectCractType === null ? (
                ''
              ) : (
                <div className="con">
                  <div className="title">
                    <div>
                      <span>{this.state.selectCractType.name}</span>
                    </div>

                    <div>
                      <IconFont type="icon-ico"></IconFont>
                      <p>岗位月薪[{this.state.selectCractType == null ? '' : this.state.selectCractType.salaryMonth}]元</p>
                    </div>
                  </div>
                  <dl className="choice">
                    {this.state.craftTypeEmpolyees.map((emp, index) => (
                      <dd
                        key={emp.id}
                        onClick={() => {
                          this.setState({
                            selectEmpolyee: emp,
                          })
                        }}
                      >
                        <Button size={36} style={{ backgroundColor: this.state.selectEmpolyee == null ? '' : this.state.selectEmpolyee.id === emp.id ? 'orange' : '' }}>
                          {emp.name}
                        </Button>
                      </dd>
                    ))}
                  </dl>
                </div>
              )}

              {this.state.selectCraft == null ? (
                ''
              ) : (
                <div>
                  <div className="parameter">
                    <p className="p1">参数输入</p>
                    <dl>
                      {this.state.craftlParams.map((param, index) => (
                        <dd>
                          <p>
                            {param.name}
                            {param.unit}:
                          </p>
                          <input
                            type="number"
                            value={this.state.carftParamsValues[index].value}
                            onChange={(e) => {
                              let carftParamsValues = this.state.carftParamsValues
                              let oldValue = carftParamsValues[index]
                              let newValue = { id: oldValue.id, paramType: oldValue.paramType, name: oldValue.name, value: parseFloat(e.target.value) }
                              carftParamsValues[index] = newValue
                              this.setState({
                                carftParamsValues: carftParamsValues,
                              })
                            }}
                          />
                        </dd>
                      ))}
                    </dl>
                  </div>
                  <div className="parameter">
                    <p className="p1">描述 :</p>
                  </div>
                  <div className="price">
                    <p>
                      <TextArea
                        value={this.state.memo}
                        onChange={(e) => {
                          this.setState({
                            memo: e.target.value,
                          })
                        }}
                      ></TextArea>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Drawer>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default OrderCraftEmployee
