//使用抽屉进行编辑
import React, { Component } from 'react'
import { Drawer, Form, Button, Upload, Col, Radio, Row, Input, message, Modal } from 'antd'
import { getApiMainPath } from '../api/http'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

import http from '../api/http'

class RoleForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      imageUrl: null,
      uploading: false,
      title: '新增角色',
      selectItem: null,
    }
  }
  toAdd() {
    this.setState({
      visible: true,
      selectItem: null,
      imageUrl: null,
      title: '新增角色',
    })
    if (this.form1.current != null) {
      this.form1.current.resetFields()
    }
  }
  toEdit = (inEntity) => {
    let imgWebPath = ''
    if (inEntity.avatar != null) {
      imgWebPath = inEntity.avatar.webPath
    }

    this.setState({
      visible: true,
      selectItem: inEntity,
      imageUrl: imgWebPath,
      title: '修改角色',
    })
    this.form1.current.resetFields()

    this.form1.current.setFieldsValue(inEntity)
  }

  checkForm = () => {
    this.form1.current.submit()
  }
  submit = (values) => {
    let url = '/admin/role/add'
    if (this.state.selectItem != null) {
      url = '/admin/role/update'
      values.id = this.state.selectItem.id
      values.avatarFileId = this.state.fileId
    }
    //提交
    this.props.table.onLoading()
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.table.offLoading()
        this.props.getRoleList()
      })
      .catch(() => {
        console.log('失败')
        this.props.table.offLoading()
      })

    this.setState({
      visible: false,
    })
  }
  //上传头像
  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true })
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log('上传完成', info)
      this.setState({
        imageUrl: info.file.response.data.url,
        fileId: info.file.response.data.fileId,
      })
      this.setState({ uploading: false })
      // getBase64(info.file.originFileObj, (imageUrl) =>
      //   this.setState({
      //     imageUrl,
      //     loading: false,
      //   })
      // );
    }
  }
  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('只能上传JPG/PNG文件!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('角色头像不能超过2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  normFile = (e) => {
    console.log('Upload event:', e)

    if (Array.isArray(e)) {
      return e
    }
  }
  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    const fileUpload = getApiMainPath() + '/admin/attachFile/uploadFileToQiNiu'
    // const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {this.state.uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传头像</div>
      </div>
    )
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={720}
          onClose={this.onClose}
          onCancel={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="vertical" ref={this.form1} onFinish={this.submit}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="name" label="角色名" rules={[{ required: true, message: '角色名不能为空' }]}>
                  <Input placeholder="角色名" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="status" label="状态：" initialValue="启用">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="启用">启用</Radio.Button>
                    <Radio.Button value="禁用">禁用</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="uploadAvatar" valuePropName="fileList" getValueFromEvent={this.normFile}>
                  <Upload
                    name="uploadFile"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={fileUpload}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                  >
                    {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}

RoleForm.propTypes = {}

export default RoleForm
