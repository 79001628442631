import React, { Component } from 'react'
import SwiperCore, { Navigation, Zoom } from 'swiper'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/zoom/zoom.min.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import IconFont from '../api/IconFont'
import { Button, List, Avatar, Modal, Tag, Spin, message, Drawer, Form, Input, InputNumber, Cascader, Radio } from 'antd'

import '../../assets/css/OrderMaterial.css'
import http from '../api/http'
import ProCard from '@ant-design/pro-card'
import { width } from 'dom7'
import Select from 'rc-select'

SwiperCore.use([Zoom, Navigation])

class OrderMaterial extends Component {
  constructor(props) {
    super(props)
    this.materialForm = React.createRef()
    this.state = {
      componentId: '',
      visible: false,
      material: {},
      materialSelectData: [],
    }
  }

  offLoading() {
    this.setState({
      loading: false,
    })
  }
  getMaterialTypeList = (values) => {
    http
      .post('/admin/material/getMaterialTypeList', values)
      .then((res) => {
        console.log(res)
        this.setState({
          materialTypes: res.data,
          selectMaterialType: null,
          selectMaterial: null,
          materialParams: [],
          materialParamsValues: [],
        })
      })
      .catch(() => {
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  //计算成本
  getMaterialCosts = () => {
    if (this.state.selectMaterial != null) {
      let paramNames = this.state.materialParams
      let materialParamsValues = this.state.materialParamsValues
      let materialCostsFunc = this.state.selectMaterial.materialCostsFunc
      let jsFunctionStr = ''
      for (let index = 0; index < paramNames.length; index++) {
        // alert(index)
        let value = materialParamsValues[index]
        if (value == null || value == '' || value == undefined) {
          value = '1'
        }
        jsFunctionStr = jsFunctionStr + ' var ' + paramNames[index] + '=' + value + '; '
      }
      jsFunctionStr = jsFunctionStr + materialCostsFunc
      let rs = eval(jsFunctionStr)
      this.setState({
        materialCosts: (parseFloat(rs) * this.state.selectMaterial.unitPrice).toFixed(2),
        materialWeight: parseFloat(rs).toFixed(2),
      })
    }
  }

  getMaterialList = (typeId) => {
    http
      .post('/admin/material/getMaterialList?typeId=' + typeId, null)
      .then((res) => {
        this.setState({
          materials: res.data,
        })
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  changMaterialSelecter = (material) => {
    console.log(material)
    if (material != null) {
      // this.props.table.selectMaterial(material, this.state.selectMaterialType)
      let materialParamsStr = material.materialParams
      let materialParams = []
      let materialParamsValues = []

      // param_json
      if (material.material_tab_params != null) {
        materialParams = material.material_tab_params
        this.setState({ materialParams })
      }
    } else {
      this.setState({ materialParams: [] })
    }
  }

  getMaterialTreeDataMap = () => {
    http
      .post('/admin/material/getMaterialTreeDataMap', null)
      .then((res) => {
        let materialSelectData = res.data
        materialSelectData.forEach((item) => {
          item.children = item.material_json != null ? JSON.parse(item.material_json) : null
        })
        this.setState({
          materialSelectData: materialSelectData,
        })
      })
      .catch(() => {
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  saveMaterialParamsValues = (values) => {
    console.log(values)
    if (values.materialSubclass != null) {
      values.materialId = values.materialSubclass[values.materialSubclass.length - 1]
    }
    values.componentId = this.state.componentId
    let url = '/admin/components/saveComponentMaterialParams'
    if (this.state.selectItem != null) {
      url = '/admin/components/updateComponentMaterialParams'
      values.componentMaterialId = this.state.selectItem.id
    }
    http
      .post(url, values)
      .then((res) => {
        message.success({
          content: '更新数据完成',
        })
        this.props.table.toCompile()
      })
      .catch(() => {
        //console.log('失败')
      })
  }

  toShow = (componentId, componentMaterial) => {
    this.materialForm.current.resetFields()
    let materialSelectType = '毛胚料'
    if (componentMaterial != null) {
      let formValue = {}
      formValue.materialSelectType = componentMaterial.isFax
      materialSelectType = componentMaterial.isFax
      let materialSubclass = []
      materialSubclass.push(componentMaterial.material.materialType.id)
      materialSubclass.push(componentMaterial.material.id)
      formValue.materialSubclass = materialSubclass
      if (componentMaterial.num != null) {
        formValue.num = componentMaterial.num
      } else {
        formValue.num = 1
      }

      let materialParams = componentMaterial.material.materialParamsList
      let materialParamsValues = componentMaterial.componentMaterialParamValues
      if (materialParamsValues != null) {
        materialParamsValues.forEach((pv) => {
          let key = pv.materialParams.id
          formValue[key] = pv.value
          let negativeKey = 'negative-' + pv.materialParams.id
          formValue[negativeKey] = pv.negative
          let positiveKey = 'positive-' + pv.materialParams.id
          formValue[positiveKey] = pv.positive
        })
      }
      this.materialForm.current.setFieldsValue(formValue)
      // console.log(formValue)
      this.setState({ materialParams })
    }
    this.setState({ visible: true, componentId, materialSelectType, selectItem: componentMaterial })
    this.getMaterialTreeDataMap()
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  render() {
    return (
      <Drawer
        title={this.state.selectItem == null ? '添加材质' : '修改材质'}
        getContainer={false}
        style={{ position: 'absolute' }}
        mask={false}
        closable={false}
        forceRender={true}
        width={'100%'}
        visible={this.state.visible}
        onClose={this.onClose}
        maskClosable={false}
        onCancel={this.onClose}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button
              onClick={() => {
                this.materialForm.current.submit()
              }}
              type="primary"
            >
              确认
            </Button>
          </div>
        }
      >
        <Form layout="vertical" ref={this.materialForm} onFinish={this.saveMaterialParamsValues}>
          <Form.Item
            initialValue={1}
            rules={[
              {
                required: true,
                message: '请填物料数量',
              },
            ]}
            label={<>单件物料量</>}
            name="num"
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="materialSelectType" label="原料分类">
            <Radio.Group
              defaultValue="毛胚料"
              onChange={(v) => {
                this.setState({ materialSelectType: v.target.value })
              }}
            >
              <Radio value="精料">精料</Radio>
              <Radio value="毛胚料">毛胚料</Radio>
              <Radio value="图形料">图形料</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="materialSubclass" label="材质选择">
            <Cascader
              fieldNames={{ label: 'name', value: 'id' }}
              onChange={(values, selectedOptions) => {
                let selectMaterial = selectedOptions[selectedOptions.length - 1]
                this.changMaterialSelecter(selectMaterial)
              }}
              options={this.state.materialSelectData}
            />
          </Form.Item>
          {this.state.materialParams == null
            ? ''
            : this.state.materialParams.map((materialParam, index) => (
                <>
                  {this.state.materialSelectType === '毛胚料' || this.state.materialSelectType === '图形料' ? (
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: '请填写材料尺寸',
                        },
                      ]}
                      label={
                        <>
                          {materialParam.name}({materialParam.unit})
                        </>
                      }
                      name={materialParam.id}
                    >
                      {materialParam.form_field_type === '数字' ? <InputNumber style={{ width: '100%' }} /> : <Input style={{ width: '100%' }} />}
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label={
                        <>
                          {materialParam.name}({materialParam.unit})
                        </>
                      }
                    >
                      <Input.Group compact>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: '请填写材料尺寸',
                            },
                          ]}
                          name={materialParam.id}
                          style={{ width: '50%', textAlign: 'center' }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item name={'negative-' + materialParam.id} style={{ width: '20%', textAlign: 'center' }}>
                          <Input style={{ textAlign: 'center' }} placeholder="-" />
                        </Form.Item>
                        <Input
                          style={{
                            width: '10%',
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                          }}
                          placeholder="~"
                          disabled
                        />
                        <Form.Item name={'positive-' + materialParam.id} style={{ width: '20%', textAlign: 'center' }}>
                          <Input placeholder="+" />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  )}
                </>
              ))}
        </Form>
      </Drawer>
    )
  }
}

export default OrderMaterial
