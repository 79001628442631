//使用抽屉进行编辑
import React, { Component } from 'react'
import { Modal, Input, Radio, Cascader, Button, Form, Steps, Row, Col } from 'antd'
import { isEmptyObject } from '../api/http'
import ProForm from '@ant-design/pro-form'
import '@ant-design/pro-form/dist/form.css'
import http from '../api/http'

class ClientForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      title: '新增客户',
      selectItem: null,
      areaOptions: [
        {
          value: '广东省1',
          label: '广东省',
          children: [
            {
              value: '佛山市',
              label: '佛山市',
              children: [
                {
                  value: '南海区',
                  label: '南海区',
                },
              ],
            },
          ],
        },
      ],
    }
  }
  toAdd() {
    this.setState({
      visible: true,
      selectItem: null,
      title: '新增客户',
    })
    if (this.form1.current != null) {
      this.form1.current.resetFields()
    }
  }
  toEdit = (inEntity) => {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        title: '修改客户信息',
      })
    } else {
      Modal.error({
        title: '请先选中记录',
        content: '或双击记录可以直接编辑',
      })
    }
    this.form1.current.resetFields()
    this.form1.current.setFieldsValue(inEntity)
  }

  checkForm = () => {
    console.log(this.form1.current)
    this.form1.current.submit()
  }
  submit = (values) => {
    let url = '/admin/client/add'
    if (this.state.selectItem != null) {
      url = '/admin/client/update'
      values.id = this.state.selectItem.id
    }
    //提交
    this.props.table.onLoading()
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.table.offLoading()
        this.props.getList()
      })
      .catch(() => {
        console.log('失败')
        this.props.table.offLoading()
      })

    this.setState({
      visible: false,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={800}
          onCancel={this.onClose}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="horizontal" autoComplete="off" style={{ marginTop: 10 }} ref={this.form1} labelCol={{ span: 6 }} onFinish={this.submit}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="clientCode" label="客户编码">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="客户名称"
                  rules={[
                    {
                      required: true,
                      message: '客户名称不能为空',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="handler" label="联系人名称">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="handlerMobile" label="联系人电话">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="handlerJob" label="联系人岗位">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" label="电子邮箱">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="area" label="地区">
                  <Cascader options={this.state.areaOptions} onChange={this.onAreaChange} placeholder="请选择区域" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="clietLevel" label="客户评级:" initialValue="普通客户">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="普通客户">普通客户</Radio.Button>
                    <Radio.Button value="潜力客户">潜力客户</Radio.Button>
                    <Radio.Button value="核心客户">核心客户</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="addr" label="通讯电址">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="memo" label="描述:">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}

ClientForm.propTypes = {}

export default ClientForm
