//使用抽屉进行编辑
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import '@ant-design/pro-card/dist/card.css'
import { Button, Col, Form, Input, InputNumber, Modal, Radio, Row, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { Component } from 'react'
import '../../assets/css/paramSetting.css'
import http, { HashMap } from '../api/http'
class CraftPriceSetting extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      fileId: '',
      craft: { craftParamList: [] },
      craftType: null,
      paramOptions: [],
      paramMap: null,
      changeParmIds: [],
      trBetweenValue: 10,
      tdBetweenValue: 10,
      paramBetweenSettings: [],
      trNum: [],
      tdNum: [],
      trtd: [], //二围数组
    }
  }

  toEdit = (inEntity, inCraftType) => {
    //定义参数选择
    let paramOptions = []
    let changeParmIds = []
    let paramMap = new HashMap()
    if (inEntity.craftParamList != null) {
      inEntity.craftParamList.forEach((param) => {
        paramOptions.push({ label: param.name, value: param.id })
        paramMap = new HashMap()
        paramMap.put(param.id, param)

        changeParmIds.push(param.id)
      })
      console.log(inEntity)
      //根据工艺去取对应的设置
      this.form1.current.setFieldsValue(inEntity)
      this.setState({
        visible: true,
        craft: inEntity,
        craftType: inCraftType,
        title: '人工计价设置',
        paramMap: paramMap,
        paramOptions: paramOptions,
        changeParmIds: changeParmIds,
        trNum: [],
        tdNum: [],
        trtdMap: null,
        trtd: [],
      })

      this.getParamBetweenSettings(inEntity)
    }
  }

  createNewTable = () => {
    let values = this.form1.current.getFieldsValue()
    console.log(values)
    if (values.columNum != null) {
      if (this.state.tdNum.length < values.columNum) {
        for (let index = 0; index < values.columNum.length - this.state.tdNum.length; index++) {
          this.AddColum()
        }
      }
      if (this.state.tdNum.length > values.columNum) {
        for (let index = 0; index < this.state.tdNum.length - values.columNum; index++) {
          this.delColum()
        }
      }
    }

    if (values.rowNum != null) {
      if (this.state.trNum.length < values.rowNum) {
        for (let index = 0; index < values.rowNum.length - this.state.trNum.length; index++) {
          this.AddRow()
        }
      }
      if (this.state.trNum.length > values.rowNum) {
        for (let index = 0; index < this.state.trNum.length - values.rowNum; index++) {
          this.delRow()
        }
      }
    }

    if (values.columNum != null) {
      if (this.state.tdNum.length < values.columNum) {
        for (let index = 0; index < values.columNum - this.state.tdNum.length; index++) {
          this.AddColum()
        }
      }
      if (this.state.tdNum.length > values.columNum) {
        for (let index = 0; index < this.state.tdNum.length - values.columNum; index++) {
          this.delColum()
        }
      }
    }
    if (values.columBetweenValue != null) {
      this.setState({ tdBetweenValue: values.columBetweenValue })
      let lastTdValue = 0
      let tdNum = this.state.tdNum
      for (let td = 0; td < tdNum.length; td++) {
        const element = tdNum[td]
        let newValue = {
          paramId: this.state.changeParmIds[0],
          paramStart: lastTdValue,
          paramEnd: lastTdValue + values.columBetweenValue,
        }
        tdNum.splice(td, 1, newValue)
        lastTdValue = lastTdValue + values.columBetweenValue
      }
      this.setState({ tdNum })
    }

    if (values.rowBetweenValue != null) {
      this.setState({ trBetweenValue: values.rowBetweenValue })
      let lastTrValue = 0
      let trNum = this.state.trNum
      for (let tr = 0; tr < trNum.length; tr++) {
        const element = trNum[tr]
        let newValue = {
          paramId: this.state.changeParmIds[1],
          paramStart: lastTrValue,
          paramEnd: lastTrValue + values.rowBetweenValue,
        }
        trNum.splice(tr, 1, newValue)
        lastTrValue = lastTrValue + values.rowBetweenValue
      }
      this.setState({ trNum })
    }
  }

  getParamBetweenSettings(craft) {
    let values = {}
    values.craftId = craft.id
    http
      .post('/admin/craftPriceSettings/getList', values)
      .then((res) => {
        if (res.data.tdNum.length > 0) {
          let trtd = []
          if (res.data.trtd == null) {
            res.data.trNum.forEach((element) => {
              let tdValues = []
              res.data.tdNum.forEach((td) => {
                tdValues.push(0)
              })
              trtd.push(tdValues)
            })
          } else {
            trtd = res.data.trtd
          }
          this.setState({
            tdNum: res.data.tdNum,
            trNum: res.data.trNum,
            trtd,
          })
        } else {
          //如果未设置过就初始化
          for (var i = 0; i < 5; i++) {
            this.AddRow()
          }
          for (var i = 0; i < 5; i++) {
            this.AddColum()
          }
        }
      })
      .catch((err) => {
        console.log(err)
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  checkForm = () => {
    this.form1.current.submit()
  }

  submit = (values) => {
    let url = '/admin/craftPriceSettings/update'
    values.id = this.state.craft.id
    console.log(values.craftParamList)
    let craftParamsJson = JSON.stringify(values.craftParamList)
    values.craftParamsJson = craftParamsJson

    let valueMap = this.state.trtdMap
    // let keySet = valueMap.keySet();
    // let submitValues = [];
    let tdNum = JSON.stringify(this.state.tdNum)
    let trNum = JSON.stringify(this.state.trNum)
    values.tdNumJson = tdNum
    values.trNumJson = trNum
    values.trtdValuesJson = JSON.stringify(this.state.trtd)
    values.craftId = this.state.craft.id
    console.log(values)
    //提交
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.table.getCrafts(this.state.craftType)
        this.setState({
          visible: false,
        })
      })
      .catch((eer) => {
        console.log(eer)
      })

    this.setState({
      visible: false,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  AddRow = () => {
    let trn = this.state.trNum
    // console.log(trn);
    let newTr = {
      paramId: this.state.changeParmIds[1],
      paramStart: 0,
      paramEnd: 0,
    }
    //生成内部的值
    let trtd = this.state.trtd
    let tdValues = []
    for (let td = 0; td <= this.state.tdNum.length; td++) {
      tdValues.push(0)
    }

    trtd.push(tdValues)
    trn.push(newTr)
    this.setState(trn)
  }
  delRow = () => {
    let trn = this.state.trNum
    if (trn.length >= 1) {
      trn.pop()
      this.setState({ trNum: trn })
    }
  }
  AddColum = () => {
    let tdn = this.state.tdNum
    // console.log(repacelTd);
    //替换最后一列
    console.log(tdn)
    let newTdn = {
      paramId: this.state.changeParmIds[0],
      paramStart: 0,
      paramEnd: 0,
    }
    tdn.push(newTdn)

    let trtd = this.state.trtd
    for (let tr = 0; tr <= this.state.trNum.length; tr++) {
      let tdValues = this.state.trtd[tr]
      if (tdValues == null) {
        tdValues = []
      }
      tdValues.push(0)
      trtd.splice(tr, 1, tdValues)
    }

    //设置里面的内容
    this.setState({ tdNum: tdn, trtd })
  }

  delColum = () => {
    let tdn = this.state.tdNum
    tdn.pop()
    this.setState({ tdNum: tdn })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    const gridStyle = {
      width: '20%',
      textAlign: 'center',
    }
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={1024}
          onClose={this.onClose}
          onCancel={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form ref={this.form1} onFinish={this.submit}>
            <Form.List name="craftParamList">
              {(fields, { add, remove }) => (
                <>
                  <Form.Item label="工艺参数"></Form.Item>
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item {...field} name={[field.id, 'id']} fieldKey={[field.fieldKey, 'id']}>
                        <Input type="hidden" />
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'name']} fieldKey={[field.fieldKey, 'name']} rules={[{ required: true, message: '请输入参数名' }]}>
                        <Input placeholder="参数名" />
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'unit']} fieldKey={[field.id, 'unit']} rules={[{ required: true, message: '请输入参数单位' }]}>
                        <Input placeholder="参数单位" />
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'paramType']} fieldKey={[field.id, 'paramType']} tooltip="" initialValue="系数">
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value="参数">工艺基本参数</Radio.Button>
                          <Radio.Button value="系数">系数</Radio.Button>
                          {/* <Radio.Button value="计算系统数">
                            计算系参数
                          </Radio.Button> */}
                        </Radio.Group>
                      </Form.Item>
                      {field.name > 1 ? (
                        <MinusCircleOutlined
                          onClick={(e) => {
                            remove(field.name)
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </Space>
                  ))}
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    新增参数
                  </Button>
                </>
              )}
            </Form.List>
            <Row gutter={24} style={{ marginTop: 50 }}>
              <Col span={5}>
                <Form.Item name="columBetweenValue" label="列起始间隔">
                  <InputNumber style={{ width: '90%' }} placeholder="列起始间隔" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="columNum" label="列数">
                  <InputNumber style={{ width: '90%' }} placeholder="列数" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="rowBetweenValue" label="行起始间隔">
                  <InputNumber style={{ width: '90%' }} placeholder="行起始间隔" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="rowNum" label="行数">
                  <InputNumber style={{ width: '90%' }} placeholder="行数" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button type="primary" onClick={this.createNewTable}>
                  重新生成表格
                </Button>
              </Col>
            </Row>
            <Form.Item>
              <div class="table">
                <div style={{ overflow: 'hidden', padding: '5px' }}>
                  <br />
                  <table className="table_con" border="1px">
                    {this.state.trNum.slice(0, 1).map((trValue, trOneIndex) => {
                      return (
                        <tr>
                          <td>
                            {
                              <div>
                                {this.state.craft.craftParamList.map((po, parNameIndex) => {
                                  if (po.paramType === '参数') {
                                    return <p>{po.name}</p>
                                  }
                                })}
                              </div>
                            }
                          </td>
                          {this.state.tdNum.map((tdValue, tdIndex) => {
                            return (
                              <td>
                                <div>
                                  <input
                                    style={{ width: '50px' }}
                                    type="number"
                                    min="0"
                                    onChange={(e) => {
                                      let newValue = {
                                        paramId: tdValue.paramId,
                                        paramStart: parseFloat(e.target.value),
                                        paramEnd: tdValue.paramEnd,
                                      }
                                      let tdNum = this.state.tdNum
                                      tdNum.splice(tdIndex, 1, newValue)
                                      this.setState({ tdNum })
                                    }}
                                    value={tdValue.paramStart}
                                  />
                                  <span>-</span>
                                  <input
                                    style={{ width: '50px' }}
                                    type="number"
                                    onChange={(e) => {
                                      let newValue = {
                                        paramId: tdValue.paramId,
                                        paramStart: tdValue.paramStart,
                                        paramEnd: parseFloat(e.target.value),
                                      }
                                      let tdNum = this.state.tdNum
                                      tdNum.splice(tdIndex, 1, newValue)
                                      this.setState({ tdNum })
                                    }}
                                    value={tdValue.paramEnd}
                                    min="0"
                                  />
                                  <span>mm</span>
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                    {this.state.trNum.slice(0).map((trValue, trIndex) => {
                      return (
                        <tr>
                          <td>
                            <div>
                              <input
                                type="number"
                                style={{ width: '50px' }}
                                value={trValue.paramStart}
                                min="0"
                                onChange={(e) => {
                                  let newValue = {
                                    paramId: trValue.paramId,
                                    paramStart: parseFloat(e.target.value),
                                    paramEnd: trValue.paramEnd,
                                  }
                                  let trNum = this.state.trNum
                                  trNum.splice(trIndex, 1, newValue)
                                  this.setState({ trNum })
                                }}
                              />
                              <span>-</span>
                              <input
                                type="number"
                                min="0"
                                style={{ width: '50px' }}
                                onChange={(e) => {
                                  let newValue = {
                                    paramId: trValue.paramId,
                                    paramStart: trValue.paramStart,
                                    paramEnd: parseFloat(e.target.value),
                                  }
                                  let trNum = this.state.trNum
                                  trNum.splice(trIndex, 1, newValue)
                                  this.setState({ trNum })
                                }}
                                value={trValue.paramEnd}
                              />
                              <span>mm</span>
                            </div>
                          </td>
                          {this.state.tdNum.map((tdValue, tdIndex) => {
                            return (
                              <td>
                                <div>
                                  <input
                                    type="number"
                                    onChange={(e) => {
                                      // map.put(key, parseFloat(e.target.value));
                                      let trtd = this.state.trtd
                                      let newValues = e.target.value
                                      if (e.target.value == null || e.target.value === '') {
                                        newValues = 0
                                      } else {
                                        while (newValues.length > 1 && newValues.startsWith('0') & !newValues.startsWith('0.')) {
                                          newValues = newValues.slice(1)
                                        }
                                      }

                                      trtd[trIndex][tdIndex] = newValues
                                      // let tdArray = trtd[trIndex];
                                      this.setState({
                                        trtd,
                                      })
                                    }}
                                    value={this.state.trtd[trIndex] != null ? (this.state.trtd[trIndex][tdIndex] != null ? this.state.trtd[trIndex][tdIndex] : '') : ''}
                                    style={{ width: '50px' }}
                                  />
                                  <span>元</span>
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </table>
                </div>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="operation" style={{ width: '100%', textAlign: 'center' }}>
                <Button onClick={this.AddRow}>增加一行</Button>
                <Button style={{ marginLeft: 20 }} onClick={this.AddColum}>
                  增加一列
                </Button>
                <Button style={{ marginLeft: 20 }} onClick={this.delRow}>
                  删除一行
                </Button>
                <Button style={{ marginLeft: 20 }} onClick={this.delColum}>
                  删除一列
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

CraftPriceSetting.propTypes = {}

export default CraftPriceSetting
