import React, { Component } from "react";
import { Button, Modal } from "antd";
import http from "../api/http";
import OrgForm from "./OrgForm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable'

const { confirm } = Modal;
class OrgTree extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      sortedInfo: null,
      selectItem: {},
      expandedRowKeys: [],
      filteredInfo: null,
      //测试数据
      data: [],
    };
  }

  //加载完成
  componentDidMount() {
    this.getList();
  }

  componentWillUnmount() {}
  selectRow = (record) => {
    this.setState({ selectItem: record });
  };
  
  getList = (values) => {
    
    http
      .post("/admin/org/getOrgTree", values)
      .then((res) => {
        console.log(res.data);
        this.setState({
          data: res.data.treeData,
          expandedRowKeys: res.data.havaChildItmes,
        });
      })
      .catch((err) => {
        console.error(err)
      });
  };

  //新增
  toAdd = (record) => {
    this.form.current.toAdd(record);
  };

  //编辑
  toEdit = (record) => {
    this.form.current.toEdit(record);
  };

  //删除
  del = (record) => {
    let list = this.getList;
    confirm({
      title: "这是配置信息，确认删除这条记录?",
      icon: <ExclamationCircleOutlined />,
      content: "这如果有其他外关联信息使用就无法删除",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        http
          .post("/admin/org/del?id=" + record.id, "")
          .then((res) => {
            Modal.success({
              content: "删除成功",
            });
            list();
          })
          .catch(() => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            });
          });
      },
      onCancel() {},
    });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  render() {
    // 表头
    const columns = [
      {
        title: "机构名称",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        width:230,
      },
      {
        title: "机构类别",
        dataIndex: "companyType",
        key: "companyType",
        ellipsis: true,
        width:100,
      },
      {
        title: "别名",
        dataIndex: "alias",
        key: "alias",
        ellipsis: true,
        width:150,
      },
      {
        title: "联系电话",
        dataIndex: "phone",
        key: "phone",
        ellipsis: true,
        width:200,
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        width:100,
      },
      {
        title: "操作",
        key: "action",
        width:200,
        render: (value, record) => {
          return (
            <div>
              <Button type="link" onClick={this.toAdd.bind(this, record)}>
                新增
              </Button>
              <Button type="link" onClick={this.toEdit.bind(this, record)}>
                编辑
              </Button>
              <Button type="link" onClick={this.del.bind(this, record)}>
                删除
              </Button>
            </div>
          );
        },
      },
    ];
    let checkStrictly = true;
    return (
      <>
        <Panel title="机构管理" fontWeight={true} bodyPadding="15">
          <ResizeTable
            bordered
            rowKey={(record) => record.id}
            expandedRowKeys={this.state.expandedRowKeys}
            columns={columns}
            dataSource={this.state.data}
            onChange={this.handleChange}
            pagination={false}
          />
          <OrgForm ref={this.form} getList={this.getList} table={this} />
        </Panel>
      </>
    );
  }
}
OrgTree.propTypes = {};

export default OrgTree;
