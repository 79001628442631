import { Modal, Radio,Button,message  } from 'antd'
import React, { Component } from 'react'
import '../../assets/css/QualityProcessCard.css'
import http from '../api/http'
const { confirm } = Modal
class QualityProcessCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
    demo:"",
    qcFailType:"",
    craftTypeId:"",
    craftTypeName:"",
    orderComponentId: "",
	  orderComponentQcId:"",
    order: {
      client: {},
    },
    craftTypeList: [],
    componentsCrafts: [],
    groupMap:{},
    orderComponents: {
      components: {},
    },
    }
  }

  getDetail(orderItem, orderIndex) {
    let values = {};
    values.orderComponentId = this.state.orderComponentId;
    http
      .post("/admin/workOrder/getOrderComponentCraftCard", values)
       .then((res) => {
        this.setState({
          order:res.data.order,
          groupMap:res.data.groupMap,
          craftTypeList:res.data.craftTypeList,
          orderComponents:res.data.orderComponents,
        })
      })
      .catch((err) => {
      });
  }
  quality() {
    var value = {};
    value.craftTypeId=this.state.craftTypeId;
    value.craftTypeName=this.state.craftTypeName;
    value.orderComponentQcId=this.state.orderComponentQcId;
    value.orderComponentId=this.state.orderComponentId;
    value.demo=this.state.demo;
    value.qcFailType=this.state.qcFailType;
    http
    .post("/admin/quality/qualityOrderComponent",value)
    .then((res) => {
      Modal.success({
        title: "提交数据完成",
        icon: "none",
      });
      this.props.getQuality()
      this.props.ProcessCardCloseWin()
    })
  }

  sortContainer(){
    return (
        <div class="qaStlye">
				<div style={{ padding:'8%' }}>
				<input type="success" mode="input"  title="质检描述" placeholder="请输入质检描述" onChange={(e) => {
                    this.setState({
                      demo:e.target.value
                    })
                }} ></input>
				<div>
					<p>质检错误类型：</p>
					<Radio.Group onChange={(e) => {
                  this.setState({
                    qcFailType:e.target.value
                  })
              }} >
							<Radio value={'返工'} >返工</Radio>
							<Radio value={'报废'} >报废</Radio>
					</Radio.Group>
				</div>
				</div>
			</div>
    )
  }

  render() {
    return (
      <div>
        <div class="statistics">
			<div class="title">
				<p>项目号 : { this.state.order.extraOrderCode!=null ? this.state.order.extraOrderCode : this.state.order.orderCode}</p>
			</div>
			<div class="con">
				<div>
					<p>项目名称 : {this.state.order.orderName}</p>
					<p>客户名称 : {this.state.order.client.name}</p>
				</div>
				<div>
					<p>零件图号 : {this.state.orderComponents.components.componentCode}</p>
					<p>零件名称 : {this.state.orderComponents.components.componentName}</p>
				</div>
				<div>
					<p>数量 : {this.state.orderComponents.componentsNum}</p>
					<p>工艺工步数 : {this.state.orderComponents.craftNum}</p>
				</div>
			</div>
		</div>
    <div class="order" >
    <div style={{ padding: '4%', background: '#eee' }}>
    {this.state.craftTypeList != null ? this.state.craftTypeList.map((craftType,index) => (
			<div class="order_change">
				<div class="order_title" style={{ background: '#f17d00' }}>
					<div class="title" style={{color: '#fff'}}>
						<p>{craftType.name}</p>
						<p>工艺号 : {index}0</p>
					</div>
				</div>
				<div class="order_con">
					<div class="list">
            { this.state.groupMap[craftType.id] != null ? this.state.groupMap[craftType.id].map((componentCraft,ccIndex) =>(
              <div class="item">
							<div class="order_conduct1">
								<div class="title">
									<div class="icon_x progress">
									</div>
									<div class="text">
										<p>{componentCraft.craft.name}</p>
										<p>工步号 : {index}{ccIndex + 1 }</p>
										<p>操作参数 : 
                      { JSON.parse(componentCraft.paramValuesJson) != null ? JSON.parse(componentCraft.paramValuesJson).map((craftParam,cpIndex) =>(
                        <span>{craftParam.value} 
                        { cpIndex+1<JSON.parse(componentCraft.paramValuesJson).length ? (
                         <span>*</span>
                        ) : '' }
                          </span>
                      )) : '' }
                      <br />
                      { JSON.parse(componentCraft.paramValuesJson) != null ? JSON.parse(componentCraft.paramValuesJson).map((craftParam,cpIndex) =>(
                        <span>{craftParam.name} 
                        { cpIndex+1<JSON.parse(componentCraft.paramValuesJson).length ? (
                         <span>*</span>
                        ) : '' }
                          </span>
                      )) : '' }
                      </p>
										<p>工步描述 :{componentCraft.memo}</p>
										<div class="operation1">
											<div class="btn">
												<p onClick={(e) => {
                          console.log(componentCraft)
                          this.setState({
                            craftTypeId:componentCraft.craft.craftType.id,
                            craftTypeName:componentCraft.craft.craftType.name
                          })
                           let _this = this
                           confirm({
                             title: '填写不通过原因',
                             okText: '确认',
                             okType: 'danger',
                             cancelText: '取消',
                             content:_this.sortContainer(),
                             onOk() {
                                _this.quality();
                             },
                           })
                        }}>质检信息提交 </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
            )) : '' }
					</div>
				</div>
			</div>
    )) : ''}
    	</div>
      </div>
    </div>
    )
  }
}

export default QualityProcessCard
