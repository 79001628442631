import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Pagination,
  Radio,
  Space,
} from "antd";
import React, { Component } from "react";
import "../../App.css";
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from "../../component/etooling-ui/ResizeTable";
import http from "../api/http";
const { RangePicker } = DatePicker;
const { confirm } = Modal;
class OrderMaterialMgr extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.searchForm = React.createRef();
    this.qualityreport = React.createRef();
    this.state = {
      orderComponentsQcDetail: {},
      order: {},
      orderComponents: {},
      components: {},
      componentsCrafts: [],
      qcType: "已质检",
      sortedInfo: null,
      selectItem: {},
      loading: false,
      page: 1,
      pageSize: 15,
      filteredInfo: null,
      orderName: "",
      //测试数据
      data: {},
      orderComponentsList: [],
      searchValue:''
    };
  }

  //加载完成
  componentDidMount() {
    let values = {};
    values.page = this.state.page;
    values.pageSize = this.state.pageSize;
    this.getList(values);
  }

  onLoading() {
    this.setState({
      loading: true,
    });
  }
  offLoading() {
    this.setState({
      loading: false,
    });
  }
  getList = (values) => {
    this.onLoading();
    if (values == null) {
      values = {};
     
    } 
      values.searchValue = this.state.searchValue;

    http
      .post("/admin/orderComponents/foreignPageQuery", values)
      .then((res) => {
        this.setState({
          data: res.data.orderComponents,
          orderComponentsList: res.data.list,
        });
        this.offLoading();
      })
      .catch((e) => {
        console.log("first", e);
        this.offLoading();
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };

  toExcel = (e) => {
    let values = {};
    values.searchValue = this.state.searchValue;
    values.startDate = this.state.startDate;
    values.endDate = this.state.endDate;
    http
      .download(
        "/admin/orderComponents/toForeignExcel",
        values,
        "材料清单.xlsx"
      )
      .then((res) => {})
      .catch((err) => {
        message.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };

  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    });
    if (this.state.qcType === "已质检") {
      this.getList();
    }
    if (this.state.qcType === "待质检") {
      this.getQuality();
    }
    if (this.state.qcType === "不通过") {
      this.getErrQualityList();
    }
  }

  render() {
    // 表头
    const columns = [
      {
        title: "订单名称",
        dataIndex: "order",
        key: "order",
        width: 120,
        ellipsis: true,
        render: (value, record, index) => {
          return value.orderName;
        },
      },
      {
        title: "订单编号",
        dataIndex: "order",
        key: "order",
        width: 120,
        ellipsis: true,
        render: (value, record, index) => {
          return value.orderCode;
        },
      },
      {
        title: "图号",
        dataIndex: "orderComponents",
        key: "orderComponents",
        sorter: (a, b) => a.groupName.length - b.groupName.length,
        ellipsis: true,
        width: 140,
        render: (value, record, index) => {
          return value.componentCode;
        },
      },
      {
        title: "零件名称",
        dataIndex: "orderComponents",
        key: "orderComponents",
        ellipsis: true,
        width: 120,
        render: (value, record, index) => {
          return value.componentName;
        },
      },
      {
        title: "零件数量",
        dataIndex: "orderComponents",
        key: "orderComponents",
        ellipsis: true,
        width: 120,
        render: (value, record, index) => {
          return value.componentsNum;
        },
      },
      {
        title: "材料",
        dataIndex: "orderComponents",
        key: "orderComponents",
        ellipsis: true,
        width: 120,
        render: (value, record, index) => {
          return value.components != null
            ? value.components.materials.map((item, index) => (
                <div>
                  <p style={{ float: "left" }}>{item.material.name} : </p>
                  {item.componentMaterialParamValues.map((data, i) => (
                    <p>
                      {data.materialParams.name} : {data.value}
                    </p>
                  ))}
                </div>
              ))
            : "";
        },
      },
    ];

    return (
      <Panel
        title={"材料清单"}
        fontWeight
        topBar={
          <>
            <Space size={16}>
              关键字搜索：
              <Input
                placeholder="图号或物料或订单编号"
                onChange={(value) => {
                  this.setState({ searchValue: value.target.value });
                }}
              />
              <RangePicker
                onChange={(date, dateString) => {
                  let startDate = dateString[0];
                  let endDate = dateString[1];
                  this.setState({ startDate, endDate });
                }}
              ></RangePicker>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => {
                  this.getList();
                }}
              >
                查询
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  this.toExcel(this.state.qcType);
                }}
                key="primary"
              >
                导出报表
              </Button>
            </Space>
          </>
        }
        footerBar={
          <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              this.getList();
            }}
          >
            刷新
          </Button>
        }
        footerRight={
          <Pagination
            showQuickJumper={false}
            pageSize={this.state.data == null ? this.state.data.size : 15}
            current={this.state.data == null ? 1 : this.state.data.number + 1}
            defaultPageSize={
              this.state.numberOfElements == null
                ? 15
                : this.state.data.numberOfElements
            }
            total={this.state.data.totalElements}
            showTotal={(total) => {
              return <>共 {total} 条</>;
            }}
            onChange={(page, pageSzie) => {
              let values = {
                pageSize: pageSzie,
                page: page,
                searchName: this.state.searchValue,
              };
              if (this.state.qcType === "已质检") {
                this.getList(values);
              }
              if (this.state.qcType === "待质检") {
                this.getQuality(values);
              }
              if (this.state.qcType === "不通过") {
                this.getErrQualityList(values);
              }
            }}
          />
        }
      >
        <ResizeTable
          bordered
          dataSource={
            this.state.orderComponentsList == null
              ? []
              : this.state.orderComponentsList
          }
          pagination={false}
          size="small"
          columns={columns}
        ></ResizeTable>
      </Panel>
    );
  }
}
OrderMaterialMgr.propTypes = {};

export default OrderMaterialMgr;
