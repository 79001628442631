import { ExclamationCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Input, message, Pagination, Select, Space, Table } from "antd";
import { Option } from "antd/lib/mentions";
import ResizeTable from "../../../component/etooling-ui/ResizeTable";
import Panel from "../../../component/etooling-ui/Panel";
import React, { Component } from "react";
import http from "../../api/http";
import MobileOrder from "./MobileOrder";
import confirm from "antd/lib/modal/confirm";
class Scheduling extends Component {
  constructor(props) {
    super(props);
    this.mobileOrder = React.createRef();
    this.state = {
      employees: [],
      craftTypes: [],
      selectCraftType: null,
      selectEmployeeId: null,
      selectEmployeeName: "",
      orderSearchValue: null,
      componentSearchValue: null,
      workGroupData: {},
    };
  }
  componentDidMount() {
    http
      .post("/admin/workOrder/getAllEmployee", null)
      .then((res) => {
        this.setState({
          employees: res.data.employees,
        });
      })
      .catch((err) => {
        message.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
    http
      .post("/admin/workOrder/getAllCrafts", null)
      .then((res) => {
        this.setState({
          craftTypes: res.data.craftTypes,
        });
      })
      .catch((err) => {
        message.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
    this.getWorkGroupList();
  }

  getWorkGroupList = (values) => {
    http
      .post("/admin/workOrder/getWorkGroupByTypeId", values)
      .then((res) => {
        this.setState({
          workGroupData: res.data,
        });
      })
      .catch((err) => {
        message.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };
  render() {
    return (
      <>
        <Panel layout="h-box" style="padding:0px" >
          <Panel
            flex={0.75}
            title={"工单池"}
            load={this.state.loading}
            topBar={
              <>
                <Space>
                  订单：
                  <Input
                    onKeyDown={(key) => {
                      if (key.keyCode == 13) {
                        let values = {};
                        values.craftTypeId = this.state.selectCraftType;
                        values.orderSearchValue = this.state.orderSearchValue;
                        values.componentSearchValue =
                          this.state.componentSearchValue;
                        this.getWorkGroupList(values);
                      }
                    }}
                    onChange={(e) => {
                      this.setState({
                        orderSearchValue: e.target.value,
                      });
                    }}
                    placeholder="订单关键字"
                  />
                  零件：
                  <Input
                    onChange={(e) => {
                      this.setState({
                        componentSearchValue: e.target.value,
                      });
                    }}
                    placeholder="零件关键字"
                  />
                  <Select
                    showSearch
                    style={{ width: 120 }}
                    defaultValue=""
                    placeholder="选择工种"
                    optionFilterProp="children"
                    onChange={(value) => {
                      let values = {};
                      values.craftTypeId = value;
                      this.getWorkGroupList(values);
                      this.setState({
                        selectCraftType: value,
                      });
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.craftTypes != null
                      ? this.state.craftTypes.map((item, index) => (
                          <Option value={item.id}>{item.name}</Option>
                        ))
                      : ""}
                  </Select>
                  <Button
                    onClick={() => {
                      let values = {};
                      values.craftTypeId = this.state.selectCraftType;
                      values.orderSearchValue = this.state.orderSearchValue;
                      values.componentSearchValue =
                        this.state.componentSearchValue;
                      this.getWorkGroupList(values);
                    }}
                  >
                    查询
                  </Button>
                </Space>
              </>
            }
            footerBar={
              <Button
                icon={<ReloadOutlined />}
                type="text"
                onClick={() => {
                  this.getWorkGroupList();
                }}
              >
                刷新
              </Button>
            }
            footerRight={
              <Pagination
                showQuickJumper={false}
                pageSize={
                  this.state.workGroupData == null ? this.state.workGroupData.size : 10
                }
                current={
                  this.state.workGroupData == null
                    ? 1
                    : this.state.workGroupData.number + 1
                }
                defaultPageSize={
                  this.state.numberOfElements == null
                    ? 10
                    : this.state.workGroupData.numberOfElements
                }
                total={this.state.workGroupData.totalElements}
                showTotal={(total) => {
                  return <>共 {total} 条</>;
                }}
                onChange={(page, pageSzie) => {
                      let values = {};
                      values.craftTypeId = this.state.selectCraftType;
                      values.orderSearchValue = this.state.orderSearchValue;
                      values.componentSearchValue =
                        this.state.componentSearchValue;
                        values.pageSize = pageSzie
                        values.page = page
                      this.getWorkGroupList(values);
                }}
              />
            }
          >
            <ResizeTable
              bordered
              dataSource={
                this.state.workGroupData == null
                  ? []
                  : this.state.workGroupData.content
              }
              pagination={false}
              size="small"
              columns={[
                {
                  title: "订单名称",
                  dataIndex: "order_name",
                  key: "order_name",
                  ellipsis: true,
                  width: 100,
                  render: (value, record) => {
                    return (
                      <>
                        {record.order_name}
                        <br />[{record.extra_order_code}]
                      </>
                    );
                  },
                },
                  {
                  title: "项目",
                  dataIndex: "components",
                  key: "components",
                  ellipsis: true,
                  width: 120,
                  render: (value, record) => {
                    let val = "";
                    if (record.projectName != undefined) {
                      {
                        val = record.projectName;
                      }
                    }
                    if (record.projectCode != undefined) {
                      {
                        val +=
                          val.length > 0
                            ? "/" + record.projectCode
                            : record.projectCode;
                      }
                    }
                    if (val.length < 1) {
                      {
                        val = "---";
                      }
                    }
                    return <>{val}</>;
                  },
                },
                {
                  title: "零件名称",
                  dataIndex: "component_name",
                  key: "component_name",
                  ellipsis: true,
                  width: 150,
                  render: (value, record) => {
                    return (
                      <>
                        {record.component_name}
                        <br />[{record.component_code}]
                      </>
                    );
                  },
                },
                {
                  title: "数量",
                  dataIndex: "component_num",
                  key: "component_num",
                  ellipsis: true,
                  width: 80,
                },
                {
                  title: "工艺",
                  dataIndex: "craft_type_name",
                  key: "craft_type_name",
                  ellipsis: true,
                  width: 80,
                },

                {
                  title: "处理人",
                  dataIndex: "accept_user_name",
                  key: "accept_user_name",
                  ellipsis: true,
                  width: 100,
                },
                {
                  title: "备注",
                  dataIndex: "memo",
                  key: "memo",
                  ellipsis: true,
                  width: 200,
                },
                {
                  title: "生成时间",
                  dataIndex: "assign_time",
                  key: "assign_time",
                  ellipsis: true,
                  width: 100,
                },
                {
                  title: "状态",
                  dataIndex: "status",
                  key: "status",
                  ellipsis: true,
                  width: 100,
                },
                {
                  title: "操作",
                  width: 120,
                  key: "action",
                  fixed: "right",
                  render: (value, record) => {
                    let valuesList = {};
                    valuesList.craftTypeId = this.state.selectCraftType;
                    valuesList.orderSearchValue = this.state.orderSearchValue;
                    valuesList.componentSearchValue =
                      this.state.componentSearchValue;
                    let list = this.getWorkGroupList;
                    return (
                      <div>
                        <Button
                          type="link"
                          onClick={() => {
                            let values = {};
                            values.wogId = record.id;
                            values.employeeId = this.state.selectEmployeeId;
                            confirm({
                              title: "提示?",
                              icon: <ExclamationCircleOutlined />,
                              content:
                                "确认要" +
                                (this.state.selectEmployeeName == null ||
                                this.state.selectEmployeeName === ""
                                  ? "清空处理人"
                                  : "指派给" + this.state.selectEmployeeName),
                              okText: "确认",
                              okType: "danger",
                              cancelText: "取消",
                              onOk() {
                                http
                                  .post(
                                    "/admin/workOrder/sendOrderToEmployee",
                                    values
                                  )
                                  .then((res) => {
                                    message.success({
                                      content: "处理成功",
                                    });
                                    list(valuesList);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    message.error({
                                      content: "数据加载失败请联系系统管理员",
                                    });
                                  });
                              },
                              onCancel() {},
                            });
                          }}
                        >
                          {this.state.selectEmployeeName == null ||
                          this.state.selectEmployeeName === ""
                            ? "清空处理人"
                            : "指派给" + this.state.selectEmployeeName}
                        </Button>
                      </div>
                    );
                  },
                },
              ]}
            ></ResizeTable>
          </Panel>



          <Panel title="员工任务列表" 
          flex={0.25}
          titleRight={
            <>
                  <Select
                    showSearch
                    style={{ width: 120 }}
                    defaultValue=""
                    placeholder="选择员工"
                    optionFilterProp="children"
                    onChange={(e, v) => {
                      this.mobileOrder.current.getWorkOrderListByEmployee(e);

                      this.setState({
                        selectEmployeeId: e,
                        selectEmployeeName: e === "" ? "" : v.children,
                      });
                    }}
                  >
                    <Option value="">全部</Option>
                    {this.state.employees != null
                      ? this.state.employees.map((item, index) => (
                          <Option value={item.id}>{item.name}</Option>
                        ))
                      : ""}
                  </Select>
                  <Button
                    onClick={() => {
                      this.mobileOrder.current.getWorkOrderListByEmployee(
                        this.state.selectEmployeeId
                      );
                    }}
                  >
                    刷新
                  </Button>
                </>
          }
          >
             <MobileOrder ref={this.mobileOrder}></MobileOrder>
          </Panel>



        </Panel>
      </>
    );
  }
}

Scheduling.propTypes = {};

export default Scheduling;
