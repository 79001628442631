import { ExclamationCircleOutlined,ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Cascader,
  Modal,
  Radio,
  Row,
  Spin,
  Pagination,
} from "antd";
import React, { Component } from "react";
import "../../App.css";
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable';
import http from "../api/http";
import SurfaceFrom from "./SurfaceFrom";
const { RangePicker } = DatePicker;
const { confirm } = Modal;
class SurfaceList extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.searchForm = React.createRef();
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      status: "待接单",
      selectKeyS: [],
      workOrderIds: [],
      craftList: [],
      craftTypeId: "",
      selectValue: "",
      orderComponentsQcDetail: {},
      order: {},
      treeData: [],
      orderComponents: {},
      components: {},
      componentsCrafts: [],
      qcType: "",
      sortedInfo: null,
      selectItem: {},
      loading: false,
      page: 1,
      pageSize: 15,
      startDate: "",
      endDate: "",
      filteredInfo: null,
      //测试数据
      data: [],
    };
  }

  componentWillMount() {
    this.getCraftBySurface();
  }
  //加载完成
  componentDidMount() {
    this.getCraftBySurface();
    let values = {};
    values.page = this.state.page;
    values.pageSize = this.state.pageSize;
    values.craftId = this.state.qcType;
    this.getList(values);
  }

  onLoading() {
    this.setState({
      loading: true,
    });
  }
  offLoading() {
    this.setState({
      loading: false,
    });
  }
  ship = () => {
    let values = {};
    values.workOrderIds = this.state.selectedRowKeys.join(",");

    http
    .post("/admin/craft/allShip", values)
    .then((res) => {
      this.setState({
        selectedRowKeys: [],
        workOrderIds: [],
        loading: false,
      });
      this.getList();
    });
  };

  receipt = () => {
    let values = {};
    values.workOrderIds = this.state.workOrderIds.join(",");
    http.post("/admin/craft/receipt", values).then((res) => {
      this.setState({
        selectedRowKeys: [],
        workOrderIds: [],
        loading: false,
      });
      this.getList();
    });
  };

  getCraftBySurface = (values) => {
    this.onLoading();
    http
      .post("/admin/craft/getCraftBySurface", values)
      .then((res) => {
        let values = {};
        let treeData = this.getTreeData(
          res.data.allTypeList,
          res.data.allCraft
        );
        this.setState({
          craftList: res.data.allTypeList,
          treeData,
        });
        this.offLoading();
        values.craftId = this.state.qcType;
        this.getList(values);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  getTreeData = (craftType, craft) => {
    let parents = [];
    for (let i = 0; i < craftType.length; i++) {
      let parent = {};
      parent.label = craftType[i].name;
      parent.value = craftType[i].id;
      parent.children = [];
      for (let a = 0; a < craft.length; a++) {
        if (craft[a].craftType.id === craftType[i].id) {
          parent.children.push({ label: craft[a].name, value: craft[a].id });
        }
      }
      parents.push(parent);
    }
    return parents;
  };
  getList = (values) => {
    this.onLoading();
    if (values == null) {
      values = {};
    }
    values.page = values.page
    values.pageSize = this.state.pageSize
    values.startDate = this.state.startDate;
    values.endDate = this.state.endDate;
    if (
      values.craftId === "" ||
      (values.craftId === undefined || values.craftId === null)
    ) {
      values.craftId = this.state.qcType;
    }
    if (values.status === undefined || values.status === null) {
      values.status = this.state.status;
    }
    values.orderName = this.state.selectValue;
    if (
      values.craftTypeId === undefined ||
      values.craftTypeId === "" ||
      values.craftTypeId === null
    ) {
      values.craftTypeId = this.state.craftTypeId;
    }
    http
      .post("/admin/craft/getSurfaceList", values)
      .then((res) => {
        if (res.data !== undefined) {
          this.setState({
            data: res.data,
          });
        }
        this.offLoading();
      })
      .catch((err) => {
        this.offLoading();
      });
  };
  changePage = (page) => {
    let values = {};
    values.page = page;
    values.pageSize = this.state.pageSize;
    this.getList(values);
  };

  toAdd = () => {
    this.form.current.toAdd();
  };
  toExcel = (e) => {
    this.searchForm.current.validateFields().then((values) => {
      values.startDate = this.state.startDate;
      values.endDate = this.state.endDate;
      values.craftId = this.state.qcType;
      values.orderName = this.state.selectValue;
      values.status = this.state.status;
      values.craftTypeId = this.state.craftTypeId;
      http
        .download("/admin/craft/toExcel", values, "工艺材料清单.xlsx")
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    });
  };
  toEdit = (record) => {
    this.form.current.toEdit(record);
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };
  CraftChange = (value) => {
    this.setState({
      craftTypeId: value[0],
      qcType: value[1],
    });
    var values = {};
    values.craftId = value[1];
    values.craftTypeId = value[0];
    this.getList(values);
  };

  render() {
    const { loading, selectedRowKeys } = this.state;
    const hasSelected = selectedRowKeys.length > 0;
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    // 表头
    const columns = [
      {
        title: "图号",
        dataIndex: "component_code",
        key: "component_code",
        ellipsis: true,
        width: 200,
      },
      {
        title: "物料名称",
        dataIndex: "component_name",
        key: "component_name",
        ellipsis: true,
        width: 200,
      },
      {
        title: "数量",
        dataIndex: "component_num",
        key: "component_num",
        ellipsis: true,
        width: 100,
      },
      {
        title: "完成数量",
        dataIndex: "done_num",
        key: "done_num",
        ellipsis: true,
        width: 100,
      },
      {
        title: "订单名称",
        dataIndex: "order_name",
        key: "order_name",
        width: 150,
        ellipsis: true,
      },
      {
        title: "客户名称",
        dataIndex: "client_name",
        key: "client_name",
        width: 150,
        ellipsis: true,
      },
      {
        title: "订单编号",
        dataIndex: "extra_order_code",
        key: "extra_order_code",
        width: 200,
        ellipsis: true,
      },
      {
        title: "完成数量",
        dataIndex: "done_num",
        key: "done_num",
        ellipsis: true,
        width: 100,
      },
      {
        title: "PR单号",
        dataIndex: "pr_code",
        key: "pr_code",
        width: 150,
        ellipsis: true,
      },
      {
        title: "下一步工序",
        dataIndex: "craftname",
        key: "craftname",
        width: 150,
        ellipsis: true,
      },
      {
        title: "制作完成时间",
        dataIndex: "maketime",
        key: "maketime",
        width: 150,
        ellipsis: true,
      },
      {
        title: "发货时间",
        dataIndex: "accepttime",
        key: "accepttime",
        width: 150,
        ellipsis: true,
      },
      {
        title: "完成时间",
        dataIndex: "donetime",
        key: "doneTime",
        width: 150,
        ellipsis: true,
      },
    ];

    return (
      <Panel
      title="委外批量操作"
      fontWeight
      topBar={
        <Row>
        <Col span={24} offset={1}>
          <Form
            layout="inline"
            ref={this.searchForm}
            onFinish={(value) => {
              this.setState({
                page:1
              })
              this.setState({
                selectValue: value.orderName,
              });
              this.getList(value);
            }}
          >
            <Form.Item label="筛选条件" />
            <Form.Item>
              <Cascader
                options={this.state.treeData}
                changeOnSelect
                placeholder="请选择工艺"
                onChange={this.CraftChange}
              />
            </Form.Item>
            <Form.Item label="订单名称" name="orderName">
              <Input placeholder="图号、物料、订单、编号" />
            </Form.Item>
            <Form.Item label="时段">
              <RangePicker
                onChange={(date, dateString) => {
                  let startDate = dateString[0];
                  let endDate = dateString[1];
                  this.setState({ startDate, endDate });
                }}
              ></RangePicker>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={() => {
                  this.toExcel(this.state.qcType);
                }}
                key="primary"
              >
                导出报表
              </Button>
            </Form.Item>
            <Form.Item>
              <Radio.Group
                value={this.state.status}
                onChange={(value) => {
                  this.setState({
                    status: value.target.value,
                  });
                  var values = {};
                  values.status = value.target.value;
                  if (value.target.value === "全部") {
                    values.status = "";
                  }
                  this.getList(values);
                }}
              >
                <Radio value={"待接单"}>待接单</Radio>
                <Radio value={"已接单"}>已接单</Radio>
                <Radio value={"完成"}>完成</Radio>
                <Radio value={""}>全部</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              {this.state.status === "已接单" ? (
                <Button
                  type="primary"
                  onClick={() => {
                    let this_ = this;
                    confirm({
                      title: "确认要提交以下工单?",
                      icon: <ExclamationCircleOutlined />,
                      okText: "确认",
                      okType: "danger",
                      cancelText: "取消",
                      onOk() {
                        let values = {};
                        values.workOrderIds =
                        this_.state.workOrderIds.join(",");
                        http
                          .post("/admin/craft/receipt", values)
                          .then((res) => {
                            this_.setState({
                              selectedRowKeys: [],
                              workOrderIds: [],
                              loading: false,
                            });
                            this_.getList();
                          });
                      },
                      onCancel() {},
                    });
                  }}
                  disabled={!hasSelected}
                >
                  提交
                </Button>
              ) : this.state.status === "待接单" ? (
                <Button
                  type="primary"
                  onClick={() => {
                    let this_ = this;
                    confirm({
                      title: "确认要接单?",
                      icon: <ExclamationCircleOutlined />,
                      okText: "确认",
                      okType: "danger",
                      cancelText: "取消",
                      onOk() {
                        let values = {};
                        values.workOrderIds =
                          this_.state.selectedRowKeys.join(",");
                        http
                          .post("/admin/craft/allShip", values)
                          .then((res) => {
                            this_.setState({
                              selectedRowKeys: [],
                              workOrderIds: [],
                              loading: false,
                              selectKeyS: [],
                            });
                            this_.getList();
                          });
                      },
                      onCancel() {},
                    });
                  }}
                  disabled={!hasSelected}
                >
                  接单
                </Button>
              ) : (
                ""
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
      }
      footerBar={
        <Button
        icon={<ReloadOutlined />}
        type="text"
        onClick={() => {
          this.getList()
        }}
      >
        刷新
      </Button>
      }
      footerRight={
          <Pagination
          // 分页
          showTotal={ (total) => {
            return <>共 {total} 条</>
          }}
          showSizeChanger={ false}
          showQuickJumper= {true}
          defaultPageSize={15}
          total={ this.state.data.totalElements}
          onChange={ (page) => {
            let values = {};
            values.page = page;
            values.pageSize = this.state.pageSize;
            values.craftId = this.state.qcType;
            this.getList(values);
            this.setState({
              selectKeyS: [],
              selectedRowKeys: [],
              workOrderIds: [],
              loading: false,
            })
          }}
          onRow={(record) => ({
            onDoubleClick: () => {
              this.toEdit(record);
            },
          })}
          />
      }
      >
          

          <ResizeTable
            search={false}
            size="small"
            rowKey={(record) => record.id}
            columns={columns}
            rowSelection={{
              onChange: (selectedRowKeys, selectedRow) => {
                var workOrderGoupIds = [];
                var workOrderIds = [];
                selectedRow.map(
                  (item, index) => (
                    workOrderGoupIds.push(item.group_id),
                    workOrderIds.push(item.id)
                  )
                );
                selectedRowKeys = workOrderGoupIds;
                this.setState({
                  selectedRowKeys: workOrderGoupIds,
                  workOrderIds: workOrderIds,
                  selectKeyS: selectedRowKeys,
                });
                selectedRowKeys = [];
              },
            }}
            dataSource={this.state.data.content}
            pagination={false}
          />
          <SurfaceFrom ref={this.form} getList={this.getList} table={this} />
        </Panel>
    );
  }
}
SurfaceFrom.propTypes = {};

export default SurfaceList;
