//使用抽屉进行编辑
import React, { Component } from "react";
import {
  Drawer,
  Form,
  Button,
  InputNumber,
  Col,
  Row,
  Input,
  Modal,
} from "antd";
import { isEmptyObject } from "../api/http";

import http from "../api/http";

class DataDirForm extends Component {
  constructor(props) {
    super(props);
    this.form1 = React.createRef();
    this.state = {
      visible: false,
      title: "新增配置",
      selectItem: null,
    };
  }
  toAdd() {
    this.setState({
      visible: true,
      selectItem: null,
      title: "新增配置",
    });
    if (this.form1.current != null) {
      this.form1.current.resetFields();
    }
  }
  toEdit = (inEntity) => {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        title: "修改配置",
      });
    } else {
      Modal.error({
        title: "请先选中记录",
        content: "或双击记录可以直接编辑",
      });
    }
    this.form1.current.resetFields();
    this.form1.current.setFieldsValue(inEntity);
  };

  checkForm = () => {
    this.form1.current.submit();
  };
  submit = (values) => {
    let url = "/admin/DataDir/add";
    if (this.state.selectItem != null) {
      url = "/admin/DataDir/update";
      values.id = this.state.selectItem.id;
    }
    //提交
    this.props.table.onLoading();
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: "更新数据完成",
        });
        this.props.table.offLoading();
        this.props.getList();
      })
      .catch(() => {
        console.log("失败");
        this.props.table.offLoading();
      });

    this.setState({
      visible: false,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={720}
          onCancel={this.onClose}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="vertical" ref={this.form1} onFinish={this.submit}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="groupName"
                  label="分组名"
                  rules={[{ required: true, message: "分组名不能为空" }]}
                >
                  <Input placeholder="分组名" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="seq" label="显示顺序">
                  <InputNumber placeholder="显示顺序" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="displayValue"
                  label="显示值"
                  rules={[{ required: true, message: "显示值不能为空" }]}
                >
                  <Input placeholder="显示值"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="dirValue"
                  label="实际值"
                  rules={[{ required: true, message: "实际值不能为空" }]}
                >
                  <Input placeholder="实际值"></Input>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

DataDirForm.propTypes = {};

export default DataDirForm;
