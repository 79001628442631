import React, { Component } from 'react'
import http from '../api/http'
class MobileDelivery extends Component {
  constructor(props) {
    super(props)
    this.state = {
        toDeliveryCnt:"",
        doneDeliveryCnt:"",
      toDoList:[],
      workOrderCntByUser: {
        waitAcceptOrderCntInfo: {},
        todayDoneCraft: {},
      },
    }
  }

  
  DivOnClick = (item,orderIndex,index,orderItem) => {
    this.props.getDeliveryImg(this,item,orderIndex,index,orderItem)
  }

  getDeliveryList() {
    let values = {};
    http
      .post("/admin/warehouse/getDeliverOrderList", values)
      .then((res) => {
        let deliveryList = []
        let tmpList = res.data.toDeliveryList;
	    for (var t = 0; t < tmpList.length; t++) {
	    	let orderItem = tmpList[t];
        if(orderItem.deliverComponentVoList.length > 0){
	    	deliveryList.push(orderItem)
        }
	    }
        this.setState({
          toDoList:deliveryList,
          doneDeliveryCnt:res.data.doneDeliveryCnt,
          toDeliveryCnt:res.data.toDeliveryCnt
        })
      })
      .catch((err) => {
      });
  }

  render() {
    return (
      <div>
      <div className="statistics">
        <div className="statistics_switch">
          <div className="switch">
            <div className="orders">
              <div className="list">
                <div className="item">
                  <p>
                    {this.state.workOrderCntByUser != null ? this.state.workOrderCntByUser.waitAcceptOrderCntInfo.cnt : '0'}
                    <span>{this.state.toDeliveryCnt.odr_cnt}单</span>
                  </p>
                  <p>待入库</p>
                  <p>{this.state.toDeliveryCnt.oc_cnt}类零件/共{this.state.toDeliveryCnt.comp_num}件</p>
                </div>
                <div className="item">
                  <p>
                    {this.state.workOrderCntByUser == null
                      ? '0'
                      : this.state.workOrderCntByUser.todayDoneCraft.cnt == null
                      ? '0'
                      : this.state.workOrderCntByUser.todayDoneCraft.cnt}
                    <span>{ this.state.doneDeliveryCnt.odr_cnt }单</span>
                  </p>
                  <p>已入库</p>
                  <p>{this.state.doneDeliveryCnt.oc_cnt}类零件/共{this.state.doneDeliveryCnt.comp_num}件</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="order">
        <div style={{ padding: '4%', background: '#eee' }}>
          {this.state.toDoList != null
            ? this.state.toDoList.map((orderItem, orderIndex) => (
                <div className="order_change" >
                  <div className="order_title">
                    <div className="title">
                      <p>项目名称 : {orderItem.orderName}</p>
                    </div>
                    <p>项目号 : {orderItem.orderCode}</p>
                    <p>零件品类数 : {orderItem.allComponentTypeNum}</p>
                    <div className="list">
                      <div className="item">
                        <p>
                          完成数 : {orderItem.delverNum}/{orderItem.allComponentNum}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="order_con" id="selector1">
                    <div className="list">
                      {orderItem.deliverComponentVoList != null
                        ? orderItem.deliverComponentVoList.map((item, index) => (
                            <div className="item" onClick={() => this.DivOnClick(item,orderIndex,index,orderItem)}>
                              <div className="order_conduct1">
                                {/* <div className="lable"><p>11</p></div> */}
                                <div className="title">
                                  <div className="icon_x" style={{ backgroundColor: '#3c65bd', boxShadow: '0 0 5px #3c65bd' }}>
                                    {/* <uni-icons type="star-filled" size="26" color="#fff" /> */}
                                  </div>
                                  <div className="text">
                                    <p>
                                      {item.componentName}
                                    </p>
                                    <p>
                                     图号 {item.componentCode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : ''}
                    </div>
                  </div>
                </div>
              ))
            : ''}
        </div>
      </div>
    </div>
    )
  }
}

export default MobileDelivery
