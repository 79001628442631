const dateFm = {}
dateFm.format = (date, fmt) => {
  var o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
  return fmt
}

dateFm.dateAdd = (date, interval, number, fmt) => {
  var d = date
  var k = { y: 'FullYear', q: 'Month', m: 'Month', w: 'Date', d: 'Date', h: 'Hours', n: 'Minutes', s: 'Seconds', ms: 'MilliSeconds' }
  var n = { q: 3, w: 7 }
  eval('d.set' + k[interval] + '(d.get' + k[interval] + '()+' + (n[interval] || 1) * number + ')')
  if (fmt) {
    return dateFm.format(d, fmt)
  }
  return d
}
dateFm.getWeekDate = (date) => {
  var now = date
  var day = now.getDay()
  var weeks = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六')
  var week = weeks[day]
  return week
}
export default dateFm
