import { ReloadOutlined, SearchOutlined } from '@ant-design/icons'
import '@ant-design/pro-card/dist/card.css'
import { Button, Cascader, Input, message, Space, Table, DatePicker, Pagination, Drawer } from 'antd'
import ResizeTable from '../../component/etooling-ui/ResizeTable'
import Panel from '../../component/etooling-ui/Panel'
import moment from 'moment'
import React, { Component } from 'react'
import http from '../api/http'
import OrderCompileTab from './OrderCompileTab'
const { RangePicker } = DatePicker
let modelPath = '/admin/components/'

export default class ComponentList extends Component {
  constructor(props) {
    super(props)
    this.orderCompile = React.createRef()

    this.state = {
      pagination: { number: 1 },
      orderField: '',
      orderType: '',
      searchValue: '',
      processingType: '自制',
      batchFile: [],
      page: 1,
      pageSize: 15,
      componentOpen: false,
    }
  }
  list = (values) => {
    if (values == null) {
      values = {}
    }
    values.page = values.page
    values.pageSize = this.state.pageSize
    values.searchName = this.state.searchValue
    if (values.processingType == null || values.processingType === undefined) {
      values.processingType = this.state.processingType
    }
    values.startDate = this.state.startDate
    values.endDate = this.state.endDate
    http
      .post(modelPath + 'pageQuery', values)
      .then((res) => {
        let batchFile = []
        for (let i = 0; i < res.data.content.length; i++) {
          let file = {}
          file.web_path = res.data.content[i].web_path
          file.file_type = res.data.content[i].file_type
          file.name = res.data.content[i].name
          batchFile.push(file)
          this.setState({ batchFile })
        }
        let pagination = res.data
        this.setState({ pagination })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  toExcel = (values) => {
    if (values == null) {
      values = {}
    }
    values.searchName = this.state.searchValue
    if (values.processingType == null || values.processingType === undefined) {
      values.processingType = this.state.processingType
    }
    values.startDate = this.state.startDate
    values.endDate = this.state.endDate
    http
      .download(modelPath + 'toExcel', values, '零件清单.xlsx')
      .then((res) => {})
      .catch((err) => {
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  patchDownload = (values) => {
    if (values == null) {
      values = {}
    }
    values.searchName = this.state.searchValue
    if (values.processingType == null || values.processingType === undefined) {
      values.processingType = this.state.processingType
    }
    http.post(modelPath + 'componentList', values).then((res) => {
      let outPutZipFileName = '文件'
      http.batchDownload(outPutZipFileName, res.data)
    })
  }

  closeCraftView = () => {
    this.setState({
      componentOpen: false,
    })
  }

  componentDidMount() {
    this.list()
  }

  render() {
    const options = [
      {
        value: '自制',
        label: '自制',
      },
      {
        value: '委外',
        label: '委外',
        children: [
          {
            value: '供料加工',
            label: '供料加工',
          },
          {
            value: '包工包料',
            label: '包工包料',
          },
        ],
      },
    ]
    return (
      <Panel
        title="零件列表"
        fontWeight
        topBar={
          <Space size={16}>
            关键字搜索：
            <Input
              placeholder="图号或物料名称"
              value={this.state.searchValue}
              onChange={(e) => {
                this.setState({
                  searchValue: e.target.value,
                })
              }}
            />
            <RangePicker
              onChange={(date, dateString) => {
                let startDate = dateString[0]
                let endDate = dateString[1]
                console.log('start', startDate)
                console.log('endDate', endDate)
                this.setState({ startDate, endDate })
              }}
            ></RangePicker>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                this.list()
              }}
            >
              查询
            </Button>
            <Button
              type="primary"
              onClick={() => {
                // this.toExcel()
                this.toExcel()
              }}
              key="primary"
            >
              导出报表
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.patchDownload()
              }}
              key="primary"
            >
              批量下载文件
            </Button>
            <Cascader
              options={options}
              onChange={(e) => {
                let processingType = e[e.length - 1]
                this.setState({ processingType })
                let values = {}
                values.processingType = processingType
                this.list(values)
              }}
              placeholder="请选择类型"
            />
          </Space>
        }
        footerBar={
          <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              this.list()
            }}
          >
            刷新
          </Button>
        }
        footerRight={
          <Pagination
            showQuickJumper={false}
            pageSize={this.state.pagination == null ? this.state.pagination.size : 10}
            current={this.state.pagination == null ? 1 : this.state.pagination.number + 1}
            defaultPageSize={this.state.numberOfElements == null ? 10 : this.state.pagination.numberOfElements}
            total={this.state.pagination.totalElements}
            showTotal={(total) => {
              return <>共 {total} 条</>
            }}
            onChange={(page, pageSzie) => {
              let values = {}
              values.page = page
              values.pageSize = this.state.pageSize
              values.searchName = this.state.searchValue
              this.list(values)
            }}
          />
        }
      >
        <ResizeTable
          bordered
          dataSource={this.state.pagination == null ? [] : this.state.pagination.content}
          pagination={false}
          size="small"
          columns={[
            {
              title: '订单名称',
              dataIndex: 'order_name',
              key: 'order_name',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: '订单编码',
              dataIndex: 'extra_order_code',
              key: 'extra_order_code',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: '零件名称',
              dataIndex: 'component_name',
              key: 'component_name',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: '零件编码',
              dataIndex: 'component_code',
              key: 'component_code',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },

            {
              title: '零件数',
              dataIndex: 'components_num',
              key: 'components_num',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: '单件材料成本',
              dataIndex: 'material_price',
              key: 'material_price',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
              render: (value, record) => {
                return <>{parseFloat(record.material_price).toFixed(2)}</>
              },
            },
            {
              title: '加工成本/件',
              dataIndex: 'worker_price',
              key: 'worker_price',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
              render: (value, record) => {
                return <>{parseFloat(record.worker_price).toFixed(2)}</>
              },
            },
            {
              title: '单价成本',
              dataIndex: 'worker_price',
              key: 'worker_price',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
              render: (value, record) => {
                return <div style={{ color: 'red' }}>{parseFloat(record.worker_price + record.material_price).toFixed(2)}</div>
              },
            },
            {
              title: '交期',
              dataIndex: 'plan_time',
              key: 'plan_time',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
              render: (value, record) => {
                return <>{moment(value).format('YYYY-MM-DD')}</>
              },
            },
            {
              title: '加工状态',
              dataIndex: 'oc_status',
              key: 'oc_status',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: '最后加工环节',
              dataIndex: 'action_craft_type_name',
              key: 'action_craft_type_name',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: '完成时间',
              dataIndex: 'done_time',
              key: 'done_time',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
              render: (value, record) => {
                return <>{moment(value).format('YYYY-MM-DD')}</>
              },
            },
            {
              title: '质检状态',
              dataIndex: 'final_qc_status',
              key: 'final_qc_status',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: '质检时间',
              dataIndex: 'final_qc_time',
              key: 'final_qc_time',
              ellipsis: true,
              render: (value, record) => {
                return <>{moment(value).format('YYYY-MM-DD')}</>
              },
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: '仓库状态',
              dataIndex: 'warehouse_status',
              key: 'warehouse_status',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
            },
            {
              title: '入库时间',
              dataIndex: 'warehouse_time',
              key: 'warehouse_time',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
              render: (value, record) => {
                return <>{moment(value).format('YYYY-MM-DD')}</>
              },
            },
            {
              title: '操作',
              dataIndex: 'oc_id',
              key: 'oc_id',
              ellipsis: true,
              sorter: (a, b) => {},
              width: 120,
              render: (oc_id, record) => {
                // alert(oc_id)
                return (
                  <Button
                    onClick={() => {
                      this.setState({ componentOpen: true })
                      this.orderCompile.current.toCompile(oc_id, true)
                    }}
                  >
                    查看
                  </Button>
                )
              },
            },
          ]}
        ></ResizeTable>
        <Drawer
          style={{ position: 'absolute' }}
          mask={false}
          closable={false}
          forceRender={true}
          title={'图片查看'}
          width='100%'
          visible={this.state.componentOpen}
          onClose={() => {
            this.closeCraftView()
          }}
          maskClosable={false}
          onCancel={() => {
            this.closeCraftView()
          }}
          footer={
            <Space>
              <Button
                onClick={() => {
                  this.closeCraftView()
                }}
              >
                关闭
              </Button>
            </Space>
          }
        >
          <OrderCompileTab ref={this.orderCompile} table={this}></OrderCompileTab>
        </Drawer>
      </Panel>
    )
  }
}
