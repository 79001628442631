import { ExclamationCircleOutlined, KeyOutlined, LaptopOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons'
import { ModalForm, ProFormText } from '@ant-design/pro-form'
import { Badge, Dropdown, Layout, Menu, message, Modal, PageHeader, Tabs } from 'antd'
import React, { Component } from 'react'
import '../../assets/css/admin_body.css'
import operation1 from '../../assets/images/img/login_menu_t1_b.png'
import logo from '../../assets/images/img/logo.png'
import messageEject_t1 from '../../assets/images/img/messageEject_test1.png'
import messageEject_t2 from '../../assets/images/img/messageEject_test2.png'
import Storage from '../../utils/Storage'
import PddGoods from '../analysis/PddGoods'
import http from '../api/http'
import QualityList from '../quality/QualityList'
import DeliverList from '../delivery/DeliveryList'
import SurfaceList from '../surface/SurfaceList'
import ClientMgr from '../etooling/ClientMgr'
import CraftPriceFuncSetting from '../etooling/CraftPriceFuncSetting'
import CraftTable from '../etooling/CraftTable'
import DefaultView from '../etooling/DefaultView'
import Material from '../etooling/Material'
import OrderList from '../etooling/OrderList'
import Analysis from '../etooling/workorder/Analysis'
import WorkOrderList from '../etooling/WorkOrderList'
import Login from '../login/login'
import LoginMenu from '../login_menu/login_menu'
import OrgTree from '../orgresource/OrgTree'
import DataDir from '../system/DataDir'
import EmpolyeeMgr from '../system/EmpolyeeMgr'
import FileSource from '../system/FileSource'
import FreeItemList from '../system/FreeItemList'
import FuncModule from '../system/FuncModule'
import RoleMgr from '../system/roleMgr'
import FullScreenIndex from '../analysis/FullScreenIndex'
import ProjectSchedule from '../etooling/projectSchedule/ProjectPlan'
import OrderProgress from '../etooling/OrderProgress'
import WarehouseList from '../etooling/WarehouseList'
import ProjectPlanList from '../etooling/projectSchedule/ProjectPlanList'
import ComponentList from '../etooling/ComponentList'
import MachineList from '../etooling/MachineList'
import LoginLogMgr from '../system/LoginLogMgr'
import ForeignOrder from '../etooling/ForeignOrder'
import SystemMgr from '../system/SystemMgr'
import SystemServeMgr from '../system/SystemServeMgr'
import OrderMaterial from '../etooling/orderMaterialMgr'  
import ExternalList from '../etooling/externalSystem/ExternalList'  
import ExternalServiceList from '../etooling/externalSystem/ExternalServiceList'
import TimerMgr from '../system/TimerMgr'
import EmployeeComponentList from '../etooling/EmployeeComponentList'
const { SubMenu } = Menu
const { Sider, Content } = Layout
const { TabPane } = Tabs

const { confirm } = Modal
var components = {
  loginLog: <LoginLogMgr />,
  login: <Login />,
  org: <OrgTree />,
  loginMenu: <LoginMenu />,
  dataDir: <DataDir />,
  fileSource: <FileSource />,
  fmodule: <FuncModule />,
  roleMgr: <RoleMgr />,
  orderList: <OrderList />,
  craftTable: <CraftTable />,
  clientMgr: <ClientMgr />,
  material: <Material />,
  defaultView: <DefaultView />,
  userMgr: <EmpolyeeMgr />,
  freeItemMgr: <FreeItemList />,
  workOrderList: <WorkOrderList />,
  craftPriceFuncSetting: <CraftPriceFuncSetting />,
  analysis: <Analysis />,
  deliverList: <DeliverList />,
  qualityList: <QualityList />,
  pddGoods: <PddGoods />,
  fullScreenIndex: <FullScreenIndex />,
  surfaceList: <SurfaceList />,
  projectSchedule: <ProjectSchedule />,
  OrderProgress: <OrderProgress />,
  warehouseList: <WarehouseList />,
  ProjectPlanList: <ProjectPlanList />,
  componentList: <ComponentList />,
  machineList: <MachineList />,
  foreignOrder: <ForeignOrder />,
  sysMgr: <SystemMgr />,
  systemServe: <SystemServeMgr />,
  orderMaterialMgr:<OrderMaterial />,
  externalList:<ExternalList />,
  externalServiceList:<ExternalServiceList />,
  timerMgr:<TimerMgr />,
  employeeComponentList:<EmployeeComponentList/>
}

export default class admin_body extends Component {
  constructor(props) {
    super(props)

    // tab切换
    this.newTabIndex = 0
    const panes = []

    this.state = {
      //名称
      enTitle: 'ETOOLING',
      cnTitle: '易图灵',
      // 左菜单按钮默认状态1
      collapsed: false,
      //左菜单
      userMenus: [],
      height: 800,
      //用户信息
      loginUser: null,
      // 顶部菜单
      badge: 5,

      // tab切换
      //   activeKey: panes[0].key,
      panes,
    }
  }
  componentDidMount() {
    let roleMenus = Storage.get('userFuncs')
    if (Storage.get('userFuncs') != null) {
      roleMenus = JSON.parse(roleMenus)
    }
    // console.log("菜单", roleMenus);
    // console.log(Storage.get('userFuncs'))
    let user = Storage.get('loginUser')
    if (Storage.get('loginUser') != null) {
      user = JSON.parse(user)
    }
    // console.log('user', user)
    this.setState({ userMenus: roleMenus.children, loginUser: user })
  } //初始化
  // 左菜单按钮
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  handleClick = (e) => {
    let clickMenu = e.item.props.menu
    if (clickMenu.winType === '新全屏窗口') {
      window.open('/' + clickMenu.url)
    } else {
      this.add(clickMenu)
    }
  }
  // tab切换
  onChange = (activeKey) => {
    this.setState({ activeKey })
  }
  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }
  add = (menu) => {
    const { panes } = this.state
    const activeKey = menu.id
    let b = true
    panes.forEach((element) => {
      if (element.key === activeKey) {
        this.setState({ activeKey })
        b = false
        return
      }
    })
    if (b) {
      panes.push({ title: menu.name, url: menu.url, key: activeKey })
      this.setState({ panes, activeKey })
    }
  }
  remove = (targetKey) => {
    let { activeKey } = this.state
    let lastIndex
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1
      }
    })
    const panes = this.state.panes.filter((pane) => pane.key !== targetKey)
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].key
      } else {
        activeKey = panes[0].key
      }
    }
    this.setState({ panes, activeKey })
  }

  render() {
    //信息通知
    const messageEject = (
      <div className="messageEject">
        <dl>
          <dd>
            <img src={messageEject_t1} />
            <div>
              <p>您收到了14份新周报您收到了14份新周报</p>
              <span>10 分钟前</span>
            </div>
          </dd>
          <dd>
            <img src={messageEject_t2} />
            <div>
              <p>您收到了14份新周报</p>
              <span>10 分钟前</span>
            </div>
          </dd>
          <dd>
            <img src={messageEject_t1} />
            <div>
              <p>您收到了14份新周报</p>
              <span>10 分钟前</span>
            </div>
          </dd>
          <dd>
            <img src={messageEject_t2} />
            <div>
              <p>您收到了14份新周报</p>
              <span>10 分钟前</span>
            </div>
          </dd>
        </dl>
        <div className="operation">
          <a>清空 通知</a>
        </div>
      </div>
    )

    //个人设置
    const personalSettings = (
      <div className="personalSettings">
        <dl>
          <dd>
            <ModalForm
              width={450}
              title="修改密码"
              trigger={
                <div>
                  <KeyOutlined />
                  <p className="name">{this.state.loginUser == null ? '' : '修改密码'}</p>
                </div>
              }
              submitter={{
                searchConfig: {
                  submitText: '确认',
                  resetText: '取消',
                },
              }}
              onFinish={async (values) => {
                //提交
                http
                  .post('/admin/userMgr/resetPwd', values)
                  .then((res) => {
                    message.success('修改密码成功')
                    this.setState({
                      visible: false,
                    })
                    return true
                  })
                  .catch((err) => {
                    // message.error('提交失败')
                    // console.log('失败')
                    // console.log(err)
                    return false
                  })
                // console.log(values)
              }}
            >
              <ProFormText width="md" name="initPwd" label="原密码" placeholder="请输入原密码" />
              <ProFormText width="md" name="newPwd" label="新密码" placeholder="请输入新密码" />
            </ModalForm>
          </dd>
          <dd>
            <div
              onClick={() => {
                confirm({
                  title: '真的要退出系统吗?',
                  icon: <ExclamationCircleOutlined />,
                  okText: '确认',
                  okType: 'danger',
                  cancelText: '取消',
                  onOk() {
                    Storage.set('loginUser', null)
                    Storage.set('token', null)
                    Storage.set('fileRootPath', null)
                    Storage.set('userFuncs', null)
                    window.location.href = '/login'
                  },
                  onCancel() {},
                })
              }}
            >
              <PoweroffOutlined />
              <p className="name">退出</p>
            </div>
          </dd>
        </dl>
      </div>
    )

    return (
      <div className="admin_body">
        <Layout
          style={{
            position: 'fixed',
            top: '0',
            width: '100%',
            height: '100%',
          }}
        >
          <Sider width={200} className="site-layout-background" collapsed={this.state.collapsed}>
            <div
              className="logo"
              style={{
                overflow: 'hidden',
                position: 'relative',
                background: 'rgb(48, 108, 230)',
                height: '65px',
              }}
            >
              <img
                style={{
                  display: 'block',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%,-50%)',
                  width: '70%',
                  maxHeight: '80%',
                }}
                src={logo}
              />
              {/* <p>{this.state.enTitle} {this.state.cnTitle}</p> */}
            </div>
            <Menu
              mode="inline"
              onClick={this.handleClick}
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{
                height: '100%',
                paddingBottom: '65px',
                borderRight: 0,
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              {this.state.userMenus == null
                ? ''
                : this.state.userMenus.map(function (menu, key) {
                    // return <li key={key}>{value.name}</li>;
                    let childrenMenu = []
                    if (menu.children != null) {
                      menu.children.map(function (cm, key) {
                        childrenMenu.push(
                          <Menu.Item key={cm.id} menu={cm} icon={<LaptopOutlined />}>
                            {cm.name}
                          </Menu.Item>
                        )
                      })
                    }
                    let returnMenu = (
                      <SubMenu icon={<UserOutlined />} key={menu.id} title={menu.name}>
                        {childrenMenu}
                      </SubMenu>
                    )

                    return returnMenu
                  })}
            </Menu>
          </Sider>

          <Layout className="site-layout">
            <Tabs
              className="topMenu"
              style={{ overflowX: 'scroll' }}
              hideAdd
              onChange={this.onChange}
              activeKey={this.state.activeKey}
              type="editable-card"
              onEdit={this.onEdit}
              tabBarStyle={{
                margin: '0',
                paddingLeft: '15px',
                paddingTop: '15px',
                paddingRight: '15px',
                paddingBottom: '15px',
                height: '66px',
                background: 'rgb(48, 108, 230)',
              }}
              tabBarExtraContent={{
                left: (
                  <div className="menu_btn">
                    {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, { className: 'trigger', onClick: this.toggle })}
                  </div>
                ),
                right: (
                  <>
                    <dl className="operation" key="topMenus">
                      <dd>
                        <Dropdown overlay={personalSettings} trigger={['click']} placement="bottomRight">
                          <div onClick={(e) => e.preventDefault()}>
                            <UserOutlined />
                            <p className="name">{this.state.loginUser == null ? '' : this.state.loginUser.name}</p>
                          </div>
                        </Dropdown>
                      </dd>
                    </dl>
                  </>
                ),
              }}
            >
              <TabPane tab="首页" key="home" closable={false}>
                <DefaultView></DefaultView>
              </TabPane>
              {this.state.panes.map((pane) => (
                <TabPane
                  tab={
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="关闭其他"
                            onClick={() => {
                              let panes = []
                              panes.push(pane)
                              this.setState({ panes })
                            }}
                          >
                            关闭其他
                          </Menu.Item>
                          <Menu.Item
                            key="全部关闭"
                            onClick={() => {
                              let panes = this.state.panes
                              panes = []
                              this.setState({ panes })
                            }}
                          >
                            全部关闭
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={['contextMenu']}
                    >
                      <div> {pane.title}</div>
                    </Dropdown>
                  }
                  key={pane.key}
                >
                  <Content>{components[pane.url]}</Content>
                </TabPane>
              ))}
              {/* <TabPane>
              <DefaultView/>
              </TabPane> */}
            </Tabs>
          </Layout>
        </Layout>
      </div>
    )
  }
}
