import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons'
import ProCard from '@ant-design/pro-card'
import { Avatar, Button, Form, Input, InputNumber, List, message, Modal, Space, Spin, Upload, Checkbox, Radio, Badge } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { Component } from 'react'
import http from '../api/http'
import { getApiMainPath } from '../api/http'
import MaterialForm from './MaterialForm'
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable';

const { confirm } = Modal
class Material extends Component {
  constructor(props) {
    super(props)
    this.typeForm = React.createRef()
    this.materialForm = React.createRef()
    this.state = {
      imgFileId: null,
      imgFileWebPath: null,
      materialTypes: [],
      materials: [],
      showMaterialTypeForm: false,
      showMaterialForm: false,
      selectMaterialType: null,
      selectMaterial: null,
      selectMaterialParams: null,
      materialParams: [],
      checkedValues: [],
    }
  }
  onLoading() {
    this.setState({
      loading: true,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }
  //加载完成
  toMaterTypeUpate = (materialType) => {
    let selectMaterialType = this.state.selectMaterialType
    this.typeForm.current.resetFields()
    this.typeForm.current.setFieldsValue(materialType)
    let imgFileWebPath = null
    if (materialType.img != null) {
      imgFileWebPath = materialType.img.webPath
    }
    this.setState({
      imgFileWebPath: imgFileWebPath,
      showMaterialTypeForm: true,
      selectMaterialType: materialType,
    })
  }

  toMaterTypeAdd = () => {
    //新增
    this.typeForm.current.resetFields()
    this.setState({
      showMaterialTypeForm: true,
      selectMaterialType: null,
    })
  }

  //图片上传
  beforeUpload(file) {
    let fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    let checkFile = false
    if (fileType === 'pdf') {
      checkFile = true
    }
    if (fileType === 'jpg') {
      checkFile = true
    }
    if (fileType === 'jpeg') {
      checkFile = true
    }
    if (fileType === 'png') {
      checkFile = true
    }
    if (!checkFile) {
      message.error('图片只接受pdf/jpg/jpeg/png/pdf文件上传')
    }

    return checkFile
  }
  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true })
      return
    }
    if (info.file.status === 'done') {
      this.setState({
        imgFileId: info.file.response.data == null ? '' : info.file.response.data.id,
        imgFileWebPath: info.file.response.data == null ? null : info.file.response.data.webPath,
      })
      this.setState({ uploading: false })
    }
  }

  colseMaterialTypeForm = () => {
    this.setState({
      showMaterialTypeForm: false,
    })
  }

  delMaterialType = (record) => {
    let list = this.getMaterialTypeList
    confirm({
      title: '这是配置信息，确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,
      content: '这如果有其他外关联信息使用就无法删除',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/material/delType?id=' + record.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list()
          })
          .catch(() => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            });
          })
      },
      onCancel() {},
    })
  }

  onMaterialTypeFormSubmit = () => {
    this.typeForm.current.validateFields().then((values) => {
      let url = '/admin/material/addMaterialType'
      values.imgFileId = this.state.imgFileId
      if (this.state.selectMaterialType != null) {
        url = '/admin/material/updateMaterialType'
        values.id = this.state.selectMaterialType.id
        values.imgFileId = this.state.imgFileId
      } else {
        this.setState({
          selectMaterialType: null,
        })
      }
      http
        .post(url, values)
        .then((res) => {
          Modal.success({
            content: '更新数据完成',
          })
          this.offLoading()
          this.colseMaterialTypeForm()
          this.getMaterialTypeList()
        })
        .catch((err) => {
          Modal.error({
            content: "更新失败,请联系管理员！！！！！！",
          });
          this.offLoading()
        })
    })
  }

  componentDidMount() {
    this.getMaterialTypeList()
  }
  getMaterialTypeList = (values) => {
    http
      .post('/admin/material/getMaterialTypeList', values)
      .then((res) => {
        console.log(res)
        this.setState({
          materialTypes: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  onMaterialFormSubmit = () => {
    this.form.current.validateFields().then((values) => {
      let url = '/admin/material/addMaterial?typeId=' + this.state.selectMaterialType.id
      if (this.state.selectMaterial != null) {
        url = '/admin/material/updateMaterial'
        values.id = this.state.selectMaterial.id
        values.typeId = this.state.selectMaterialType.id
        console.log(values.childParam)
        if (values.childParam !== null && values.childParam !== undefined) {
          let data = ''
          for (let index = 0; index < values.childParam.length; index++) {
            data = data + values.childParam[index] + '|'
          }
          values.childParam = data
        }
      }
      http
        .post(url, values)
        .then((res) => {
          Modal.success({
            content: '更新数据完成',
          })
          this.offLoading()
          this.colseMaterialForm()

          this.getMaterialList(this.state.selectMaterialType.id)
        })
        .catch((err) => {
          Modal.error({
            content: "更新失败,请联系管理员！！！！！！",
          });
          this.offLoading()
        })
    })
  }

  colseMaterialForm = () => {
    this.setState({
      showMaterialForm: false,
    })
  }

  toAddMaterialForm = () => {
    let selectMaterialType = this.state.selectMaterialType
    if (selectMaterialType == null) {
      message.error(`请选选择物料分类再进行添加`)
    } else {
      this.form.current.resetFields()
      this.setState({
        showMaterialForm: true,
        selectMaterial: null,
      })
    }
  }

  getMaterialList = (typeId) => {
    http
      .post('/admin/material/getMaterialList?typeId=' + typeId, null)
      .then((res) => {
        this.setState({
          materials: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  delMaterial = (record) => {
    let list = this.getMaterialList
    let typeId = this.state.selectMaterialType.id
    confirm({
      title: '这是配置信息，确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/material/delMaterial?id=' + record.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list(typeId)
          })
          .catch(() => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            })
          })
      },
      onCancel() {},
    })
  }

  onChange(checkedValues) {
  }

  render() {
    return (
        <Panel
        footerBar={
          <Button
          icon={<ReloadOutlined />}
          type="text"
          onClick={() => {
            this.getMaterialTypeList()
          }}
        >
          刷新
        </Button>
        }
        >
          <ProCard>
            <ProCard
              headerBordered
              bordered
              colSpan="30%"
              title="原料大类"
              extra={
                <Space>
                  <Button icon={<PlusOutlined />} onClick={this.toMaterTypeAdd} headerBordered>
                    新增
                  </Button>
                  <Button
                   icon={<ReloadOutlined />}
                 
                   onClick={()=>{
                    this.getMaterialTypeList()
                  }}  headerBordered>
                    刷新
                  </Button>
                </Space>
              }
            >
              <List
                style={{ minHeight: 800 }}
                dataSource={this.state.materialTypes}
                itemLayout="horizontal"
                renderItem={(item) => (
                  <List.Item
                    onClick={() => {
                      this.getMaterialList(item.id)
                      this.setState({
                        selectMaterialType: item,
                      })
                    }}
                    key={item.id}
                    actions={[
                      <Space>
                        <EditOutlined
                          style={{ fontSize: '16px' }}
                          onClick={() => {
                            this.toMaterTypeUpate(item)
                          }}
                        />
                      </Space>,
                      <Space>
                        <DeleteOutlined
                          onClick={() => {
                            this.delMaterialType(item)
                          }}
                          style={{ fontSize: '16px' }}
                        />
                      </Space>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Badge color={item === this.state.selectMaterialType ? 'orange' : '#1890ff'} text={<Avatar size={40}  src={item.img != null ? item.img.webPath : 'http://file.irobot-auto.com/irobot-auto/20220530/8f6f7cd0-00b7-48ca-8e54-fec1b3a8de0a.png'} >
                        </Avatar>} />
                      }
                      description={'单位:' + item.unit}
                      title={item.name}
                    />
                    <div></div>
                  </List.Item>
                )}
              ></List>
              {/* 分类form */}
              <Modal
                maskClosable={false}
                forceRender={true}
                title={this.state.title}
                width={480}
                onClose={this.colseMaterialTypeForm}
                onCancel={this.colseMaterialTypeForm}
                visible={this.state.showMaterialTypeForm}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Button onClick={this.colseMaterialTypeForm} style={{ marginRight: 8 }}>
                      取消
                    </Button>
                    <Button onClick={this.onMaterialTypeFormSubmit} type="primary">
                      提交
                    </Button>
                  </div>
                }
              >
                <Form layout="vertical" ref={this.typeForm} onFinish={this.submit}>
                  <Form.Item name="name" label="原料分类名称：" rules={[{ required: true, message: '原料分类名称不能为空' }]}>
                    <Input placeholder="原料分类名称" />
                  </Form.Item>
                  <Form.Item name="unit" label="原料分类单位：">
                    <Input placeholder="原料分类单位"></Input>
                  </Form.Item>
                  <Form.Item name="uploadAvatar" label="上传图片">
                    <Upload
                      name="avatarFile"
                      maxCount={1}
                      fileList={this.state.impFiles}
                      action={getApiMainPath() + '/admin/material/uploadMaterialImage'}
                      onChange={this.handleChange}
                      beforeUpload={this.beforeUpload}
                    >
                      {this.state.imgFileWebPath != null ? (
                        <img src={this.state.imgFileWebPath} alt="avatar" style={{ width: '100%' }} />
                      ) : (
                        <Button icon={<UploadOutlined />}>上传图片</Button>
                      )}
                    </Upload>
                  </Form.Item>
                  <Form.Item name="materialCostsFunc" label="分类成本计算工式(物料中类不设以分类为准)">
                    <TextArea rows={5}></TextArea>
                  </Form.Item>
                </Form>
              </Modal>
            </ProCard>
            <ProCard
              headerBordered
              bordered={true}
              title={this.state.selectMaterialType == null ? '请先选择分类再进行添加' : this.state.selectMaterialType.name + '分类的原料'}
              extra={
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => {
                    this.materialForm.current.showMaterialForm(this.state.selectMaterialType)
                  }}
                >
                  新增
                </Button>
              }
            >
              <List
                dataSource={this.state.materials}
                itemLayout="horizontal"
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <Space>
                        <EditOutlined
                          style={{ fontSize: '16px' }}
                          onClick={() => {
                            this.materialForm.current.showMaterialForm(this.state.selectMaterialType, item)
                          }}
                        />
                      </Space>,
                      <Space>
                        <DeleteOutlined
                          onClick={() => {
                            this.delMaterial(item)
                          }}
                          style={{ fontSize: '16px' }}
                        />
                      </Space>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar size={40} style={{ backgroundColor: item === this.state.selectMaterialParams ? 'orange' : '#1890ff' }}>
                          {item.name}
                        </Avatar>
                      }
                      description={item.materialCostsFunc == null ? '成本计算公式:' : '成本计算公式:' + item.materialCostsFunc}
                      title={
                        item.materialParamsList == null ? (
                          '参数：'
                        ) : (
                          <>
                            参数：
                            {item.materialParamsList.map(function (materialParam, key) {
                              return <>{materialParam.name}|</>
                            })}
                          </>
                        )
                      }
                    />
                  </List.Item>
                )}
              ></List>
              <MaterialForm ref={this.materialForm} parent={this}></MaterialForm>
            </ProCard>
          </ProCard>
        </Panel>
    )
  }
}

Material.propTypes = {}

export default Material
