import { UploadOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, DatePicker, message, Row, Spin, Table,Upload } from 'antd'
import React, { Component } from 'react'
// import Radio from '@material-ui/core/Radio';
import '../../App.css'
import Storage from '../../utils/Storage'
import http, { getApiMainPath } from '../api/http'
import WarehouseFrom from './WarehouseFrom'
const { RangePicker } = DatePicker
const { confirm } = Modal
class WarehouseList extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    // this.searchForm = React.createRef()
    this.state = {
      data:[],
      page: 1,
      componentName:"",
      pageSize: 15,
      visible: false,
      loading: false,
      uploading: false,
      bomInputType: '上传文件批量',
      doneUploadBoxFile: false,
      doneUploadPdfFile: false,
      bomExcelFileUrl: '',
      bomExcelRowNum: 0,
      bomExcelFileId: '',
      bomExcelFileList: [],
      fileRootPath: '',
      impExcelFileDetail: {},
      impExcelFileId: '',
    }
  }

  //加载完成
  componentDidMount() {
    let fileRootPath = Storage.get('fileRootPath')
    this.setState({
      fileRootPath,
    })
    let values = {}
    values.page = this.state.page
    values.pageSize = this.state.pageSize
    this.getList(values)
  }

  onLoading() {
    this.setState({
      loading: true,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }
  getList = (values) => {
    this.onLoading()
    if (values == null) {
      values = {}
    }
    values.startDate = this.state.startDate
    values.endDate = this.state.endDate
    values.componentName = this.state.componentName
    http
      .post('/admin/warehouse/getWarehouseList', values)
      .then((res) => {
        this.setState({
          data: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  changePage = (page) => {
    let values = {}
    values.page = page
    values.pageSize = this.state.pageSize
      this.getList(values)
  }

  toAdd = () => {
    this.form.current.toAdd()
  }
  uploadBomExcel = (info) => {
    console.log(info)
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true })
      return
    }
    if (info.file.status === 'done') {

        console.log(info.file.response.data)
        let uploadData = info.file.response.data
        if (uploadData != null && '' !== uploadData) {
          message.success(`${info.file.name} 上传完成`)
          this.setState({
            uploading: false,
            impExcelFileId: uploadData.fileId,
            impExcelFileDetail: uploadData.imporDetail,
            doneUploadBoxFile: true,
            current: 1,
          })
          this.getList();
        } else {
          message.error(`${info.file.name} 失败,请检查模板格式`)
        }
    }
  }
  beforeExcelUpload = (file) => {
    let fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
    let checkFile = false
    if (fileType === 'xls') {
      checkFile = true
    }
    if (fileType === 'xlsx') {
      checkFile = true
    }
    if (!checkFile) {
      message.error('只能上传excel文件!')
    } 
    return checkFile
  }
  toEdit = (record) => {
    this.form.current.toEdit(record)
  }
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    })
  }
  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    })
      this.getList()
  }

  render() {
    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}
    // 表头
    const columns = [
      {
        title: '图号',
        dataIndex: 'photoFileName',
        key: 'photoFileName',
        ellipsis: true,
        width: 20,
      },
      {
        title: '物料编号',
        dataIndex: 'componentCode',
        key: 'componentCode',
        ellipsis: true,
        width: 40,
      },
      {
        title: '物料名称',
        dataIndex: 'componentName',
        key: 'componentName',
        ellipsis: true,
        width: 20,
      },
      {
        title: '数量',
        dataIndex: 'componentNum',
        key: 'componentNum',
        ellipsis: true,
        width: 20,
      },
      {
        title: '单价',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        width: 20,
        ellipsis: true,
      },
      {
        title: '类型',
        dataIndex: 'componentType',
        key: 'componentType',
        width: 20,
        ellipsis: true,
      },
      {
        title: '操作',
        width: 40,
        key: 'action',
        render: (value, record) => {
          return (
            <div>
              <Button type="link" onClick={this.toEdit.bind(this, record)}>
                编辑
              </Button>
            </div>
          )
        },
      },
    ]

    return (
      <div>
        <Spin spinning={this.state.loading} delay={500}>
          <Row>
            <Col span={24} offset={1}>
              <Form layout="inline" ref={this.searchForm} onFinish={(value) => {
                this.setState({
                  componentName : value.componentName
                })
                    this.getList(value)
              }}>
                <Form.Item label="筛选条件" />
                <Form.Item label="物料名称" name="componentName">
                  <Input placeholder="图号或物料名称" />
                </Form.Item>
                {/* <Form.Item label="时段">
                <RangePicker
                  onChange={(date, dateString) => {
                    let startDate = dateString[0]
                    let endDate = dateString[1]
                    this.setState({ startDate, endDate })
                  }}
                ></RangePicker>
              </Form.Item> */}

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
              </Form.Item>
                {/* <Form.Item>
                  <Button type="primary" onClick={()=>{
                      this.toExcel(this.state.qcType)
                       
                  }} key="primary">
                   导出报表
                  </Button>
                </Form.Item> */}
                <Form.Item name="bomExcelFile">
                      <Upload
                        fileList={this.state.bomExcelFileList}
                        name="uploadFile"
                        maxCount={1}
                        action={
                          getApiMainPath() + 
                          '/admin/warehouse/uploadExcelExport' 
                        }
                        beforeUpload={this.beforeExcelUpload}
                        onChange={this.uploadBomExcel}
                      >
                        <Button type="primary" icon={<UploadOutlined />}>上传仓库盘点表</Button>
                      </Upload>
                    </Form.Item>
              </Form>
            </Col>
          </Row>

          <Table
            search={false}
            size="small"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={this.state.data.content}
            pagination={{
              // 分页
              showTotal: (total) => {
                return `共 ${total} 条`
              },
              showSizeChanger: false,
              showQuickJumper: true,
              defaultPageSize: 15,
              total: this.state.data.totalElements,
              onChange: (page) => {
                let values = {}
                values.page = page
                values.pageSize = this.state.pageSize
                  this.getList(values)
              },
            }}
            onRow={(record) => ({
              onDoubleClick: () => {
                this.toEdit(record)
              },
            })}
          />
          <WarehouseFrom ref={this.form} getList={this.getList} table={this} />
        </Spin>
      </div>
    )
  }
}
WarehouseFrom.propTypes = {}

export default WarehouseList
