import React, { Component } from 'react'
import ProCard from '@ant-design/pro-card'
import { Button, DatePicker, message, Select, Space, Spin, Table } from 'antd'
import { Column } from '@ant-design/charts'
import http from '../../../api/http'
import { RedoOutlined, ReloadOutlined } from '@ant-design/icons'
import ResizeTable from "../../../../component/etooling-ui/ResizeTable";
import Panel from "../../../../component/etooling-ui/Panel";
import moment from 'moment'

class ProductReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      month: moment().format('YYYY-MM'),
      monthChart: [],
      data: [],
      isLoading:true
    }
  }

  componentWillMount() {
    this.loadData()
  }

  loadData = () => {
    let values = { dateMonth: this.state.month }
    http
      .post('/admin/report/employeeCraftCnt', values)
      .then((res) => {
        let columns = []
        let monthChart = res.data.monthChart

        let tableHeadList = res.data.tableHeadList
        tableHeadList.forEach((head) => {
          let col = {
            title: head,
            dataIndex: head,
            align: 'center',
            key: head,
            ellipsis: true,
            width: 120,
          }
          columns.push(col)
        })
        console.log(columns)
        this.setState({
          columns,
          data: res.data.data,
          monthChart,
          isLoading:false
        })
      })
      .catch((err) => {
        console.log(err)
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  render() {
    let {columns,isLoading} = this.state

    if(isLoading){
      　return (<div
        style={{
          display: "block",
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          width: "100%",
          height: "100%",
          background: "rgba(255,255,255,0.94)",
          zIndex: "99",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Spin size="large" />
        </div>
      </div>)
    }
    return (

      <Panel
        title={this.state.month + ':计件工资月报'}
        titleRight={
                       <Space>
            <DatePicker
              defaultValue={moment(this.state.month, 'YYYY-MM')}
              onChange={(date, dateString) => {
                this.setState({
                  month: dateString,
                })
              }}
              picker="month"
            />
            <Button
              onClick={() => {
                this.loadData()
              }}
              icon={<RedoOutlined />}
            >
              刷新
            </Button>
          </Space>
        }
        footerBar={
          <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              var values = {};
              values.status = this.state.status;
              this.loadData(values);
            }}
          >
            刷新
          </Button>
        }
      >
        <ResizeTable
          bordered
          dataSource={this.state.data == null ? [] : this.state.data}
          pagination={false}
          size="small"
          columns={columns}
        ></ResizeTable>
           <Column
          style={{ height: '300px' }}
          data={this.state.monthChart}
          isGroup={true}
          xField="月份"
          yField="支出"
          seriesField="name"
          label={{
            layout: [{ type: 'interval-adjust-position' }, { type: 'interval-hide-overlap' }, { type: 'adjust-color' }],
          }}
        />
      </Panel>
    )
  }
}

ProductReport.propTypes = {}

export default ProductReport
