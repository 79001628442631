import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal} from "antd";
import React, { Component } from "react";
import http from "../api/http";
import FuncModuleForm from "./FuncModuleForm";
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable'

const { confirm } = Modal;

class FuncModule extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      sortedInfo: null,
      selectItem: {},
      selectedRowKeys: [],
      expandedRowKeys: [],
      //测试数据
      data: [],
    };
  }

  //加载完成
  componentDidMount() {
    this.getList();
  }

  componentWillUnmount() {}
  selectRow = (record) => {
    this.setState({ selectItem: record });
  };
   

  getList = (values) => {
    http
      .post("/admin/funcModule/getFuncModuleTree", values)
      .then((res) => {
        this.setState({
          data: res.data.treeData,
          expandedRowKeys: res.data.havaChildItmes,
        });
      })
      .catch(() => {
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });

    this.setState({ selectedRowKeys: [], selectItem: {} });
  };

  //新增
  toAdd = (record) => {
    this.form.current.toAdd(record);
  };

  //编辑
  toEdit = (record) => {
    this.form.current.toEdit(record);
  };
  
  //删除
  del = (record) => {
    let list = this.getList;
    confirm({
      title: "这是配置信息，确认删除这条记录?",
      icon: <ExclamationCircleOutlined />,
      content: "这如果有其他外关联信息使用就无法删除",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        http
          .post("/admin/funcModule/del?id=" + record.id, "")
          .then((res) => {
            Modal.success({
              content: "删除成功",
            });
            list();
          })
          .catch(() => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            })
          });
      },
      onCancel() {},
    });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  render() {
    // 表头
    const columns = [
      {
        title: "名称",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
      },
      {
        title: "分类",
        dataIndex: "funcType",
        key: "funcType",
        ellipsis: true,
        width: 80,
      },

      {
        title: "连接打开方式",
        dataIndex: "urlOpenType",
        key: "urlOpenType",
        ellipsis: true,
        width: 120,
      },
      {
        title: "url",
        dataIndex: "url",
        key: "url",
        ellipsis: true,
        width: 200,
      },
      {
        title: "显示顺序",
        dataIndex: "seq",
        key: "seq",
        ellipsis: true,
        width: 80,
      },
      {
        title: "是否超级用户",
        dataIndex: "isSuperFunc",
        key: "isSuperFunc",
        ellipsis: true,
        width: 120,
      },
      {
        title: "显示方式",
        dataIndex: "winType",
        key: "winType",
        ellipsis: true,
        width: 120,
        render: (value, record) => {
          if (value == null && record.funcType === "菜单") {
            return "内窗口";
          } else {
            return value;
          }
        },
      },
      {
        title: "操作",
        key: "action",
        render: (value, record) => {
          if (record.id === "root") {
            return (
              <div>
                <Button type="link" onClick={this.toAdd.bind(this, record)}>
                  新增
                </Button>
                <Button type="link" onClick={this.toEdit.bind(this, record)}>
                  编辑
                </Button>
              </div>
            );
          } else {
            return (
              <div>
                <Button type="link" onClick={this.toAdd.bind(this, record)}>
                  新增
                </Button>
                <Button type="link" onClick={this.toEdit.bind(this, record)}>
                  编辑
                </Button>
                <Button type="link" onClick={this.del.bind(this, record)}>
                  删除
                </Button>
              </div>
            );
          }
        },
      },
    ];
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys,
        });
      },
      onSelect: (record, selected, selectedRows) => {
        this.setState({
          selectItem: record,
        });
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
      },
    };
    let checkStrictly = false;
    return (
      <>
        <Panel title="系统功能模块管理" fontWeight={true} bodyPadding="15">
          {this.state.data.length > 0 ? (
            <>
              <ResizeTable
              bordered
                size="small"
                rowKey={(record) => record.id}
                // expandedRowKeys={this.state.expandedRowKeys}
                defaultExpandedRowKeys={this.state.expandedRowKeys}
                defaultExpandAllRows={true}
                columns={[
                  {
                    title: "名称",
                    dataIndex: "name",
                    key: "name",
                    ellipsis: true,
                    width:270,
                  },
                  {
                    title: "分类",
                    dataIndex: "funcType",
                    key: "funcType",
                    ellipsis: true,
                    width: 80,
                  },

                  {
                    title: "连接打开方式",
                    dataIndex: "urlOpenType",
                    key: "urlOpenType",
                    ellipsis: true,
                    width: 120,
                  },
                  {
                    title: "url",
                    dataIndex: "url",
                    key: "url",
                    ellipsis: true,
                    width: 220,
                  },
                  {
                    title: "显示顺序",
                    dataIndex: "seq",
                    key: "seq",
                    ellipsis: true,
                    width: 80,
                  },
                  {
                    title: "显示方式",
                    dataIndex: "winType",
                    key: "winType",
                    ellipsis: true,
                    width: 120,
                    render: (value, record) => {
                      if (value == null && record.funcType === "菜单") {
                        return "内窗口";
                      } else {
                        return value;
                      }
                    },
                  },
                  {
                    title: "操作",
                    key: "action",
                    render: (value, record) => {
                      if (record.id === "root") {
                        return (
                          <div>
                            <Button
                              type="link"
                              onClick={this.toAdd.bind(this, record)}
                            >
                              新增
                            </Button>
                            <Button
                              type="link"
                              onClick={this.toEdit.bind(this, record)}
                            >
                              编辑
                            </Button>
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <Button
                              type="link"
                              onClick={this.toAdd.bind(this, record)}
                            >
                              新增
                            </Button>
                            <Button
                              type="link"
                              onClick={this.toEdit.bind(this, record)}
                            >
                              编辑
                            </Button>
                            <Button
                              type="link"
                              onClick={this.del.bind(this, record)}
                            >
                              删除
                            </Button>
                          </div>
                        );
                      }
                    },
                  },
                ]}
                dataSource={this.state.data}
                onChange={(pagination, filters, sorter) => {
                  this.setState({
                    sortedInfo: sorter,
                  });
                }}
                pagination={false}
              />
              <FuncModuleForm
                ref={this.form}
                getList={this.getList}
                table={this}
              />
            </>
          ) : (
            ""
          )}
        </Panel>
      </>
    );
  }
}
FuncModule.propTypes = {};

export default FuncModule;
