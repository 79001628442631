import ProCard, { StatisticCard } from '@ant-design/pro-card'
import React, { Component } from 'react'
import bg1 from '../../assets/images/fullscreen/bg1.jpg'
import RcResizeObserver from 'rc-resize-observer'
import logo from '../../assets/images/fullscreen/logo.png'
import { RightOutlined, EllipsisOutlined } from '@ant-design/icons'
import '../../assets/css/main.css'
import { Avatar, Progress, Space } from 'antd'
import { TinyArea, TinyColumn } from '@ant-design/charts'
import { Liquid } from '@ant-design/charts'
const { Statistic } = StatisticCard
class FullScreenIndex extends Component {
  render() {
    const imgStyle = {
      display: 'block',
      width: 42,
      height: 42,
    }

    return (
      <ProCard
        split="horizontal"
        bordered={false}
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
        }}
      >
        <ProCard title={<img alt="" bordered={false} src={logo} class="mainTitleImg" />}></ProCard>
        <ProCard style={{ margin: '15px' }} ghost={true} bordered={false} split="vertical">
          <ProCard colSpan="70%" ghost={true} bordered={false} split="vertical">
            <StatisticCard
              ghost={true}
              bordered={false}
              colSpan="20%"
              chart={
                <div>
                  <Progress height={30} percent="70" />
                  <TinyArea padding={10} height={64} data={[264, 417, 438, 887, 309, 397, 550, 575, 563, 430, 525, 592, 492, 467, 513, 546, 983, 340, 539, 243, 226, 192]} />
                </div>
              }
              statistic={{
                title: '总订单数',
                value: 2176,
                description: (
                  <Space>
                    <Statistic title="周新增" value="7单" trend="up" />
                    <Statistic title="月同比" value="6.15%" trend="down" />
                  </Space>
                ),
              }}
              footer={
                <>
                  <Statistic value={15.1} title="零件数" suffix="件" layout="horizontal" />
                  <Statistic value={15.1} title="总营收" suffix="元" layout="horizontal" />
                </>
              }
            />
            <ProCard split="horizontal" ghost={true} bordered={false}>
              <StatisticCard.Group direction={'row'}>
                <StatisticCard
                  bordered={false}
                  statistic={{
                    title: '待产',
                    description: (
                      <Space>
                        <Statistic title="零件数" value="7" suffix="件" />
                        <Statistic title="月同比" value="6.15%" trend="down" />
                      </Space>
                    ),
                    value: 475,
                    status: 'default',
                  }}
                />
                <StatisticCard
                  statistic={{
                    title: '在制',
                    value: 87,
                    status: 'processing',
                  }}
                />
                <StatisticCard
                  statistic={{
                    title: '告警',
                    value: 1754,
                    status: 'error',
                  }}
                />
                <StatisticCard
                  statistic={{
                    title: '完成',
                    value: 1754,
                    status: 'success',
                  }}
                />
              </StatisticCard.Group>
              <StatisticCard.Group title="工种工况" bordered={false} direction={'row'}>
                <StatisticCard
                  bordered={false}
                  direction
                  chart={
                    <Liquid
                      height={120}
                      statistic={{
                        title: {
                          formatter: function formatter() {
                            return '车'
                          },
                          style: function style(_ref) {
                            var percent = _ref.percent
                            return { fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)' }
                          },
                        },
                        content: {
                          style: function style(_ref2) {
                            var percent = _ref2.percent
                            return {
                              fontSize: 14,
                              lineHeight: 1,
                              fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)',
                            }
                          },
                          customHtml: (container, view, { percent }) => {
                            return '12%'
                          },
                        },
                      }}
                      percent={0.25}
                      shape="diamond"
                    />
                  }
                ></StatisticCard>
                <StatisticCard
                  bordered={false}
                  direction
                  chart={
                    <Liquid
                      height={120}
                      statistic={{
                        title: {
                          formatter: function formatter() {
                            return '车'
                          },
                          style: function style(_ref) {
                            var percent = _ref.percent
                            return { fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)' }
                          },
                        },
                        content: {
                          style: function style(_ref2) {
                            var percent = _ref2.percent
                            return {
                              fontSize: 14,
                              lineHeight: 1,
                              fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)',
                            }
                          },
                          customHtml: (container, view, { percent }) => {
                            return '12%'
                          },
                        },
                      }}
                      percent={0.25}
                      shape="diamond"
                    />
                  }
                ></StatisticCard>
                <StatisticCard
                  bordered={false}
                  direction
                  chart={
                    <Liquid
                      height={120}
                      statistic={{
                        title: {
                          formatter: function formatter() {
                            return '车'
                          },
                          style: function style(_ref) {
                            var percent = _ref.percent
                            return { fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)' }
                          },
                        },
                        content: {
                          style: function style(_ref2) {
                            var percent = _ref2.percent
                            return {
                              fontSize: 14,
                              lineHeight: 1,
                              fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)',
                            }
                          },
                          customHtml: (container, view, { percent }) => {
                            return '12%'
                          },
                        },
                      }}
                      percent={0.25}
                      shape="diamond"
                    />
                  }
                ></StatisticCard>
                <StatisticCard
                  bordered={false}
                  direction
                  chart={
                    <Liquid
                      height={120}
                      statistic={{
                        title: {
                          formatter: function formatter() {
                            return '车'
                          },
                          style: function style(_ref) {
                            var percent = _ref.percent
                            return { fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)' }
                          },
                        },
                        content: {
                          style: function style(_ref2) {
                            var percent = _ref2.percent
                            return {
                              fontSize: 14,
                              lineHeight: 1,
                              fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)',
                            }
                          },
                          customHtml: (container, view, { percent }) => {
                            return '12%'
                          },
                        },
                      }}
                      percent={0.25}
                      shape="diamond"
                    />
                  }
                ></StatisticCard>
                <StatisticCard
                  bordered={false}
                  direction
                  chart={
                    <Liquid
                      height={120}
                      statistic={{
                        title: {
                          formatter: function formatter() {
                            return '车'
                          },
                          style: function style(_ref) {
                            var percent = _ref.percent
                            return { fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)' }
                          },
                        },
                        content: {
                          style: function style(_ref2) {
                            var percent = _ref2.percent
                            return {
                              fontSize: 14,
                              lineHeight: 1,
                              fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)',
                            }
                          },
                          customHtml: (container, view, { percent }) => {
                            return '12%'
                          },
                        },
                      }}
                      percent={0.25}
                      shape="diamond"
                    />
                  }
                ></StatisticCard>
                <StatisticCard
                  bordered={false}
                  direction
                  chart={
                    <Liquid
                      height={120}
                      statistic={{
                        title: {
                          formatter: function formatter() {
                            return '车'
                          },
                          style: function style(_ref) {
                            var percent = _ref.percent
                            return { fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)' }
                          },
                        },
                        content: {
                          style: function style(_ref2) {
                            var percent = _ref2.percent
                            return {
                              fontSize: 14,
                              lineHeight: 1,
                              fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)',
                            }
                          },
                          customHtml: (container, view, { percent }) => {
                            return '12%'
                          },
                        },
                      }}
                      percent={0.25}
                      shape="diamond"
                    />
                  }
                ></StatisticCard>
                <StatisticCard
                  bordered={false}
                  direction
                  chart={
                    <Liquid
                      height={120}
                      statistic={{
                        title: {
                          formatter: function formatter() {
                            return '车'
                          },
                          style: function style(_ref) {
                            var percent = _ref.percent
                            return { fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)' }
                          },
                        },
                        content: {
                          style: function style(_ref2) {
                            var percent = _ref2.percent
                            return {
                              fontSize: 14,
                              lineHeight: 1,
                              fill: percent > 0.65 ? 'white' : 'rgba(44,53,66,0.85)',
                            }
                          },
                          customHtml: (container, view, { percent }) => {
                            return '12%'
                          },
                        },
                      }}
                      percent={0.25}
                      shape="diamond"
                    />
                  }
                ></StatisticCard>
              </StatisticCard.Group>
            </ProCard>
          </ProCard>
          <ProCard title="2" bordered={false}></ProCard>
        </ProCard>
      </ProCard>
    )
  }
}

export default FullScreenIndex
