import { Button, Col, Form, Input, DatePicker, message, Row, Select, Table, Space, Pagination, Spin } from 'antd'
import { Option } from 'antd/lib/mentions'
import React, { Component } from 'react'
import http from '../../api/http'
import ResizeTable from "../../../component/etooling-ui/ResizeTable";
import Panel from "../../../component/etooling-ui/Panel";
import moment from 'moment'
import { ReloadOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker
export default class Detail extends Component {
  constructor(props) {
    super(props)
    this.searchForm = React.createRef()
    this.state = {
      selectValue: '',
      sortedInfo: null,
      selectItem: {},
      loading: false,
      page: 1,
      pageSize: 15,
      startDate: '',
      endDate: '',
      filteredInfo: null,
      //测试数据
      data: {},
      spinning:true
    }
  }

  //加载完成
  componentDidMount() {
    let values = {}
    values.page = this.state.page
    values.pageSize = this.state.pageSize
    this.getList(values)
  }
  getList = (values) => {
    if (values == null) {
      values = {}
    }
    if (values.page == null) {
      values.page = 1
    }
    values.searchValue = this.state.selectValue
    values.startDate = this.state.startDate
    values.endDate = this.state.endDate
    http
      .post('/admin/workOrder/detailPageQuery', values)
      .then((res) => {
        console.log(res.data)
        this.setState({
          data: res.data,
          page: res.data.number + 1,
          pageSize: 15,
          spinning:false
        })
      })
      .catch((err) => {
        console.log(err)
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  render() {
    return (
      <>
      <Panel
            flex={0.75}
            title={"工单池"}
            titleRight={
              <>
                <Row>
          <Col span={24} offset={1}>
            <Form
              layout="inline"
              ref={this.searchForm}
              onFinish={(value) => {
                this.state.selectValue = value.searchValue
                this.getList(value)
              }}
            >
              <Form.Item label="订单、工单、零件信息" name="searchValue">
                <Input placeholder="客户、订单、零件、人员姓名" />
              </Form.Item>
              <Form.Item label="时段">
                <RangePicker
                  onChange={(date, dateString) => {
                    let startDate = dateString[0]
                    let endDate = dateString[1]
                    this.setState({ startDate, endDate })
                  }}
                ></RangePicker>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    this.searchForm.current.validateFields().then((values) => {
                      values.startDate = this.state.startDate
                      values.endDate = this.state.endDate
                      http
                        .download('/admin/workOrder/expDetailToExcel', values, '工单数据.xlsx')
                        .then((res) => {})
                        .catch((err) => {
                          console.log(err)
                          message.error({
                            content: '数据加载失败请联系系统管理员',
                          })
                        })
                    })
                  }}
                >
                  将查询结果导出
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    this.searchForm.current.validateFields().then((values) => {
                      values.startDate = this.state.startDate
                      values.endDate = this.state.endDate
                      http
                        .download('/admin/workOrder/expComponentDetailToExcel', values, '零件数据.xlsx')
                        .then((res) => {})
                        .catch((err) => {
                          console.log(err)
                          message.error({
                            content: '数据加载失败请联系系统管理员',
                          })
                        })
                    })
                  }}
                >
                  导出零件汇总
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
              </>
            }
            footerBar={
              <Button
                icon={<ReloadOutlined />}
                type="text"
                onClick={() => {
                  this.getList();
                }}
              >
                刷新
              </Button>
            }
            footerRight={
              <Pagination
                showQuickJumper={false}
                pageSize={
                  this.state.data == null ? this.state.data.size : 10
                }
                current={
                  this.state.data == null
                    ? 1
                    : this.state.data.number + 1
                }
                defaultPageSize={
                  this.state.numberOfElements == null
                    ? 10
                    : this.state.data.numberOfElements
                }
                total={this.state.data.totalElements}
                showTotal={(total) => {
                  return <>共 {total} 条</>;
                }}
                onChange={(page, pageSzie) => {
                  this.setState({
                    spinning:true
                  })
                  let values = {
                    pageSize: pageSzie,
                    page: page,
                    searchName: this.state.searchValue,
                  };
                  this.getList(values);
                }}
              />
            }
          >
            <Spin tip="Loading..." spinning={this.state.spinning}>
            <ResizeTable
              bordered
              dataSource={
                this.state.data == null
                  ? []
                  : this.state.data.content
              }
              pagination={false}
              size="small"
              columns={[
                {
                  title: '项目编号',
                  dataIndex: 'order_code',
                  key: 'order_code',
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '项目名称',
                  dataIndex: 'order_name',
                  key: 'order_name',
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '客户名称',
                  dataIndex: 'client_name',
                  key: 'client_name',
                  sorter: (a, b) => {},
                  ellipsis: true,
                  width: 120,
                },
    
                {
                  title: '零件号',
                  dataIndex: 'component_code',
                  key: 'component_code',
                  sorter: (a, b) => a.displayValue - b.displayValue,
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '品名',
                  dataIndex: 'component_name',
                  key: 'component_name',
                  sorter: (a, b) => a.displayValue - b.displayValue,
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '材质',
                  dataIndex: 'material_name',
                  key: 'material_name',
                  sorter: (a, b) => a.displayValue - b.displayValue,
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '材质重量kg',
                  dataIndex: 'material_weight',
                  key: 'material_weight',
                  sorter: (a, b) => a.displayValue - b.displayValue,
                  ellipsis: true,
                  width: 120,
                  render: (value, record) => {
                    return parseFloat(value).toFixed(2)
                  },
                },
                {
                  title: '单件材料成本',
                  dataIndex: 'material_price',
                  key: 'name',
                  sorter: (a, b) => a.displayValue - b.displayValue,
                  ellipsis: true,
                  width: 120,
                  render: (value, record) => {
                    return parseFloat(value).toFixed(2)
                  },
                },
                {
                  title: '单件制作成本',
                  dataIndex: 'worker_price',
                  key: 'name',
                  sorter: (a, b) => a.displayValue - b.displayValue,
                  ellipsis: true,
                  width: 120,
                  render: (value, record) => {
                    return parseFloat(value).toFixed(2)
                  },
                },
                {
                  title: '级差',
                  dataIndex: 'price_condition_name',
                  key: 'price_condition_name',
                  sorter: (a, b) => a.displayValue - b.displayValue,
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '单件阶梯成本',
                  dataIndex: 'price_condition_price',
                  key: 'price_condition_price',
                  sorter: (a, b) => a.displayValue - b.displayValue,
                  ellipsis: true,
                  width: 120,
                  render: (value, record) => {
                    return parseFloat(value).toFixed(2)
                  },
                },
                {
                  title: '工件数',
                  dataIndex: 'components_num',
                  key: 'components_num',
                  sorter: (a, b) => a.displayValue - b.displayValue,
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '派单时间',
                  dataIndex: 'assign_time',
                  key: 'assign_time',
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: '工艺',
                  dataIndex: 'craft_type_name',
                  key: 'craft_type_name',
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '工艺描述',
                  dataIndex: 'memo',
                  key: 'memo',
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '工序',
                  dataIndex: 'craft_name',
                  key: 'craft_name',
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '规格名',
                  dataIndex: 'params_name',
                  key: 'params_name',
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '规格值',
                  dataIndex: 'params',
                  key: 'params',
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '工序单价',
                  dataIndex: 'price',
                  key: 'price',
                  ellipsis: true,
                  width: 120,
                },
    
                {
                  title: '接单人',
                  dataIndex: 'accept_user_name',
                  key: 'accept_user_name',
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: '质检人',
                  dataIndex: 'qc_user_name',
                  key: 'qc_user_name',
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: '项目交期',
                  dataIndex: 'plan_time',
                  key: 'plan_time',
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: '零件完成时间',
                  dataIndex: 'oc_done_time',
                  key: 'oc_done_time',
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: '工单完成时间',
                  dataIndex: 'wo_done_time',
                  key: 'wo_done_time',
                  width: 120,
                  ellipsis: true,
                  render: (value, record) => {
                    return <>{moment(value).format('YYYY-MM-DD')}</>
                  },
                },
    
                {
                  title: '工单状态',
                  dataIndex: 'wo_status',
                  key: 'wo_status',
                  ellipsis: true,
                  width: 120,
                },
                {
                  title: '零件状态',
                  dataIndex: 'oc_status',
                  key: 'oc_status',
                  ellipsis: true,
                  width: 120,
                  render: (value, record) => {
                    if (value === '已投产') {
                      return '生产中'
                    } else {
                      return value
                    }
                  },
                },
                {
                  title: '结算状态',
                  dataIndex: 'account_status',
                  key: 'account_status',
                  width: 120,
                  ellipsis: true,
                },
                {
                  title: '结算日期',
                  dataIndex: 'account_time',
                  key: 'account_time',
                  width: 120,
                  ellipsis: true,
                },
    
                {
                  title: '操作',
                  width: 120,
                  key: 'action',
                  fixed: 'right',
                  render: (value, record) => {
                    return (
                      <div>
                        <Button type="link">查看</Button>
                        <Button type="link">移交</Button>
                      </div>
                    )
                  },
                },
              ]}
            ></ResizeTable>
            </Spin>
          </Panel>
      </>
    )
  }
}
