import { ExclamationCircleOutlined } from '@ant-design/icons'
import { message } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import axios from 'axios'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import qs from 'qs'
axios.defaults.timeout = 30000
// const mainUrl = 'http://192.168.51.81:7070'
// const mainUrl = 'http://localhost:7070'
const mainUrl = 'http://etooling.etooling-auto.com:7070'
const http = {}
http.toBeanJson = (obj) => {
  if (obj instanceof Array) {
    obj.forEach(function (v, i) {
      http.toBeanJson(v)
    })
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach(function (key) {
      var newKey = underline2Hump(key)
      if (newKey !== key) {
        obj[newKey] = obj[key]
        delete obj[key]
      }
      http.toBeanJson(obj[newKey])
    })
    return obj
  }
}
export function underline2Hump(s) {
  return s.replace(/_(\w)/g, function (all, letter) {
    return letter.toUpperCase()
  })
}

http.get = (url) => {
  return new Promise((resolve, rejiect) => {
    let token = sessionStorage.getItem('token')
    axios.defaults.headers.common['token'] = token
    axios.defaults.withCredentials = true
    axios({
      method: 'get',
      url: mainUrl + url,
    })
      .then((res) => {
        resSuccess(resolve, rejiect, res)
      })
      .catch(function (error) {
        rejiect(error)
      })
  })
}
http.post = (url, data) => {
  return new Promise((resolve, rejiect) => {
    let token = sessionStorage.getItem('token')
    axios.defaults.headers.common['token'] = token
    axios.defaults.withCredentials = true
    axios({
      method: 'post',
      url: mainUrl + url,
      data: qs.stringify(data, { indices: false }),
    })
      .then((res) => {
        resSuccess(resolve, rejiect, res)
      })
      .catch((error) => {
        rejiect(error)
      })
  })
}

http.getFile = (url, fileName) => {
  axios.defaults.withCredentials = false
  axios.defaults.timeout = 1800000
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'blob',
    })
      .then((data) => {
        resolve(data.data)
        axios.defaults.timeout = 30000
      })
      .catch((error) => {
        reject(error.toString())
      })
  })
}

http.batchDownload = (outPutZipFileName, dataList) => {
  let zip = new JSZip()
  let zipFile = zip.folder(outPutZipFileName)
  let promises = []
  dataList.forEach((item) => {
    let promise = http.getFile(item.web_path).then((data) => {
      // 下载文件, 文件名必须带有文件类型后缀
      let file_name = `${item.name}${item.file_type}`
      // 逐个添加文件
      zipFile?.file(file_name, data, { binary: true })
    })
    promises.push(promise)
  })
  Promise.all(promises)
    .then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        // 生成二进制流,利用file-saver保存文件
        saveAs(content, '批量下载.zip')
      })
    })
    .catch(() => {
      // 如果有资源文件下载失败，就直接用a链接下载
      dataList.forEach((item) => {
        File.download({ url: item.web_path })
      })
    })
}

http.download = (url, data, fileName) => {
  return new Promise((resolve, rejiect) => {
    let token = sessionStorage.getItem('token')
    axios.defaults.headers.common['token'] = token
    axios.defaults.withCredentials = true
    axios({
      method: 'post',
      responseType: 'blob',
      url: mainUrl + url,
      data: qs.stringify(data),
    })
      .then((res) => {
        download(res, fileName)
      })
      .catch((error) => {
        // Modal.error({
        //   title: '网络异常',
        //   content: '有可能服务端在更 新，或请检查网络是否可用',
        // })
        rejiect(error)
      })
  })
}

http.downloadExtranetUrl = (url, data, fileName) => {
  return new Promise((resolve, rejiect) => {
    let token = sessionStorage.getItem('token')
    axios.defaults.headers.common['token'] = token
    axios.defaults.withCredentials = false
    axios({
      method: 'post',
      responseType: 'blob',
      url: url,
      data: qs.stringify(data),
    })
      .then((res) => {
        download(res, fileName)
      })
      .catch((error) => {
        // Modal.error({
        //   title: '网络异常',
        //   content: '有可能服务端在更 新，或请检查网络是否可用',
        // })
        rejiect(error)
      })
  })
}

function download(response, fileName) {
  if (!response) {
    return
  }
  // 如果是execl => type: application/vnd.ms-excel
  let url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
  let link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function resSuccess(resolve, rejiect, res) {
  let datas = res.data
  if (datas.code === 200) {
    resolve(datas)
  } else if (datas.code === 407) {
    confirm({
      title: '登录超时，为了安全请重新登录?',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        window.location.href = '/login'
        Storage.clear()
      },
      onCancel() {},
    })
  } else {
    message.error({
      content: datas.msg,
    })
    rejiect(datas)
  }
}

export function isEmptyObject(obj) {
  for (var n in obj) {
    return false
  }
  return true
}

export function getApiMainPath() {
  return mainUrl
}
export function HashMap() {
  //定义长度
  var length = 0
  //创建一个对象
  var obj = new Object()

  /**
   * 判断Map是否为空
   */
  this.isEmpty = function () {
    return length === 0
  }

  /**
   * 判断对象中是否包含给定Key
   */
  this.containsKey = function (key) {
    return key in obj
  }

  /**
   * 判断对象中是否包含给定的Value
   */
  this.containsValue = function (value) {
    for (var key in obj) {
      if (obj[key] === value) {
        return true
      }
    }
    return false
  }

  /**
   *向map中添加数据
   */
  this.put = function (key, value) {
    if (!this.containsKey(key)) {
      length++
    }
    obj[key] = value
  }

  /**
   * 根据给定的Key获得Value
   */
  this.get = function (key) {
    return this.containsKey(key) ? obj[key] : null
  }

  /**
   * 根据给定的Key删除一个值
   */
  this.remove = function (key) {
    if (this.containsKey(key) && delete obj[key]) {
      length--
    }
  }

  /**
   * 获得Map中的所有Value
   */
  this.values = function () {
    var _values = new Array()
    for (var key in obj) {
      _values.push(obj[key])
    }
    return _values
  }

  /**
   * 获得Map中的所有Key
   */
  this.keySet = function () {
    var _keys = new Array()
    for (var key in obj) {
      _keys.push(key)
    }
    return _keys
  }

  /**
   * 获得Map的长度
   */
  this.size = function () {
    return length
  }

  /**
   * 清空Map
   */
  this.clear = function () {
    length = 0
    obj = new Object()
  }
}
export default http
