//使用抽屉进行编辑
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Steps } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { Component } from 'react'
import http, { getApiMainPath, isEmptyObject } from '../api/http'

const { Step } = Steps
class CraftTypeForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      imageUrl: null,
      visible: false,
      selectItem: null,
      craftType: null,
    }
  }
  toAdd(selectType) {
    this.setState({
      visible: true,
      selectItem: null,
      craftType: selectType,
      craftParams: [],
      title: '在[' + selectType.name + ']下：新增工艺',
    })
    if (this.form1.current != null) {
      this.form1.current.resetFields()
    }
  }
  toEdit = (inEntity, selectType) => {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        craftType: selectType,
        title: '修改工艺',
      })
    } else {
      Modal.error({
        title: '请先选中记录',
        content: '或双击记录可以直接编辑',
      })
    }
    let cps = []
    if (inEntity.craftParamList != null) {
      inEntity.craftParamList.forEach((item) => {
        let cp = {}
        cp.id = item.id
        cp.craftParamName = item.name
        cp.craftParamUnit = item.unit
        cp.seq = item.seq
        cps.push(cp)
      })
    }
    inEntity.craftParams = cps
    this.form1.current.resetFields()
    this.form1.current.setFieldsValue(inEntity)
  }

  checkForm = () => {
    this.form1.current.submit()
  }
  submit = (values) => {
    let url = '/admin/craft/add'
    //组织json

    if (values.craftParams != null) {
      let craftParamsJson = JSON.stringify(values.craftParams)
      values.craftParamsJson = craftParamsJson
    }
    if (this.state.craftType != null) {
      values.typeId = this.state.craftType.id
    }
    if (this.state.selectItem != null) {
      url = '/admin/craft/update'
      values.id = this.state.selectItem.id
    }
    console.log(values)
    // 提交;
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.table.getCrafts(this.state.craftType)
      })
      .catch((err) => {
        console.log(err)
        console.log('失败')
      })

    this.setState({
      visible: false,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    const fileUpload = getApiMainPath() + '/admin/attachFile/upload'
    // const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {this.state.uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传工艺分类图</div>
      </div>
    )
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={600}
          onClose={this.onClose}
          onCancel={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="horizontal" ref={this.form1} onFinish={this.submit} labelCol={{ span: 6 }} wrapperCol={{ span: 24 }}>
            <Form.Item name="name" label="工艺名称：" rules={[{ required: true, message: '工艺名称不能为空' }]}>
              <Input placeholder="工艺名称：" />
            </Form.Item>
            <Form.Item name="laborCostsfunc" label="成本计算公式：">
              <TextArea Row={4}></TextArea>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

CraftTypeForm.propTypes = {}

export default CraftTypeForm
