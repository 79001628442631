import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, message, Modal, Upload } from 'antd'
import React, { Component } from 'react'
import { getApiMainPath } from '../api/http'
import http from '../api/http'
import TextArea from 'antd/lib/input/TextArea'
class OrderComponentForm extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.state = {
      visible: false,
      title: '新增零件',
      selectItem: null,
      componentId: null,
      imgFileId: null,
      order: {},
    }
  }
  toAdd(order) {
    this.setState({
      visible: true,
      selectItem: null,
      order,
      title: '新增零件',
    })
    if (this.form.current != null) {
      this.form.current.resetFields()
    }
  }

  beforeUpload(file) {
    let fileType = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    let checkFile = false
    if (fileType === 'pdf') {
      checkFile = true
    }
    if (fileType === 'jpg') {
      checkFile = true
    }
    if (fileType === 'jpeg') {
      checkFile = true
    }
    if (fileType === 'png') {
      checkFile = true
    }
    if (!checkFile) {
      message.error('图片只接受pdf/jpg/jpeg/png/pdf文件上传')
    }
    return checkFile
  }

  //上传零件图
  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ uploading: true })
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.setState({
        imgFileId: info.file.response.data == null ? '' : info.file.response.data.id,
      })
      this.setState({ uploading: false })
    }
  }

  submitForm = () => {
    this.form.current
      .validateFields()
      .then((values) => {
        values.orderId = this.state.order.id
        values.fileId = this.state.imgFileId
        http
          .post('/admin/order/AddCompToOrder', values)
          .then((res) => {
            console.log(res)

            message.success({ content: '数据更新完成' })
            this.props.table.toOrderDetail(this.state.order.id)
            this.setState({
              visible: false,
            })
          })
          .catch(() => {})
      })
      .catch((errorInfo) => {})
  }
  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  render() {
    return (
      <Modal
        maskClosable={false}
        forceRender={true}
        title={this.state.title}
        width={480}
        onClose={this.onClose}
        onCancel={this.onClose}
        visible={this.state.visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button onClick={this.submitForm} type="primary">
              提交
            </Button>
          </div>
        }
      >
        <Form layout="vertical" ref={this.form}>
          <Form.Item
            name="componentImg"
            label="零件图："
            rules={[
              {
                required: true,
                message: '零件图不能为空',
              },
            ]}
          >
            <Upload
              name="uploadPdfFile"
              maxCount={1}
              fileList={this.state.impPdfFiles}
              action={getApiMainPath() + '/admin/order/uploadCompImgToOrder?orderId=' + this.state.order.id}
              onChange={this.handleChange}
              beforeUpload={this.beforeUpload}
            >
              <Button icon={<UploadOutlined />}>选择零件工艺图上传</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="componentName"
            label="零件名:"
            rules={[
              {
                required: true,
                message: '零件名不能为空',
              },
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="componentCode"
            label="零件编号:"
            rules={[
              {
                required: true,
                message: '零件编号不能为空',
              },
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="componetsNum"
            label="数量:"
            rules={[
              {
                required: true,
                message: '零件数量不能为空',
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="memo" label="描述:">
            <TextArea placeholder="添加描述" autoSize={{ minRows: 4, maxRows: 5 }} />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default OrderComponentForm
