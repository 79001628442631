import React, { Component } from "react";
//import $ from 'jquery';
import { Form, Spin } from "antd";

import logo from "../../assets/images/img/logo1.png";
import "../login/login.css";
import { getApiMainPath } from "../api/http";
import Storage from "../../utils/Storage";
import http from "../api/http";
export default class IpadLogin extends Component {
  constructor(props) {
    super(props);
    this.passWordForm = React.createRef();
    this.msgForm = React.createRef();
    this.state = {
      enTitle: "ETOOLING",
      cnTitle: "易图灵",
      tabs: [
        // { tabName: "账户密码登录", id: 1 },
        // { tabName: "手机号登录", id: 2 },
      ],
      loading: false,
      currentIndex: 1,
      verifyCodeUrl: getApiMainPath() + "/getVerifyCode",
      // 验证码获取
      btnText: "获取验证码",
      seconds: 60,
      liked: true,
    };
  }

  onLoading() {
    this.setState({
      loading: true,
    });
  }
  offLoading() {
    this.setState({
      loading: false,
    });
  }
  // tab切换
  tabChoiced = (id) => {
    this.setState({
      currentIndex: id,
    });
  };

  // 验证码倒计时
  sendCode = () => {
    let siv = setInterval(() => {
      this.setState(
        {
          liked: false,
          seconds: this.state.seconds - 1,
        },
        () => {
          if (this.state.seconds == 0) {
            clearInterval(siv);
            this.setState({
              liked: true,
              seconds: 60,
            });
          }
        }
      );
    }, 1000);
  };

  // 验证码刷新
  codeRefresh = () => {
    let timestamp = new Date().getTime();
    this.setState({
      verifyCodeUrl: getApiMainPath() + "/getVerifyCode?t=" + timestamp,
    });
  };

  checkPassWordForm = () => {
    this.passWordForm.current.submit();
  };

  //登录
  passwordFormSubmit = (values) => {
    this.onLoading();
    http
      .post("/ipadLogin", values)
      .then((res) => {
        console.log(res);
        if (res.data != null) {
          Storage.set("loginUser", JSON.stringify(res.data.loginEmployee));
          Storage.set("token", res.data.token);
          Storage.set("fileRootPath", res.data.fileRootPath);
          Storage.set("userFuncs", JSON.stringify(res.data.userFuncs));
          Storage.set("isQualityProcess",res.data.isQualityProcess)
        }
        window.location.href = "./TestCnc";
        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
      });
    this.offLoading();
  };
  render() {
    // tab切换
    var _this = this;
    var isBox1Show = this.state.currentIndex == 1 ? "block" : "none";
    var isbox2Show = this.state.currentIndex == 2 ? "block" : "none";

    var tabList = this.state.tabs.map(
      function (res, index) {
        var tabStyle = res.id == this.state.currentIndex ? "cur" : "";
        return (
          <a
            key={index}
            onClick={this.tabChoiced.bind(_this, res.id)}
            className={tabStyle}
          >
            {res.tabName}
          </a>
        );
      }.bind(_this)
    );

    return (
      <div className="login_con">
        <Spin spinning={this.state.loading} delay={500}>
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="con">
            <div className="tab">{tabList}</div>
            <div className="list">
              <div className="item" style={{ display: isBox1Show }}>
                <ul>
                  <Form
                    autoComplete="off"
                    ref={this.passWordForm}
                    onFinish={this.passwordFormSubmit}
                  >
                    <li>
                      <Form.Item
                        name="loginId"
                        rules={[
                          {
                            required: true,
                            message: "请输入您的账户",
                          },
                        ]}
                      >
                        <input className="user" placeholder="账户" />
                      </Form.Item>
                    </li>
                    <li>
                      <Form.Item
                        name="pwd"
                        rules={[
                          {
                            required: true,
                            message: "请输入您的密码",
                          },
                        ]}
                      >
                        <input
                          className="password"
                          type="password"
                          placeholder="密码"
                        />
                      </Form.Item>
                    </li>
                    <li>
                      <div className="btn" onClick={this.checkPassWordForm}>
                        登 录
                      </div>
                    </li>
                  </Form>
                </ul>
              </div>
              {/* <div className="item" style={{ display: isbox2Show }}>
                <ul>
                  <Form autoComplete="off" ref={this.msgForm}>
                    <li>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "请输入您的手机号码",
                          },
                        ]}
                      >
                        <input className="user" placeholder="手机号码" />
                      </Form.Item>
                    </li>
                    <li>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "请输入验证码",
                          },
                        ]}
                      >
                        <input
                          className="password"
                          type="password"
                          placeholder="验证码"
                        />
                      </Form.Item>
                      <img
                        className="codeRefresh"
                        onClick={this.codeRefresh}
                        src={this.state.verifyCodeUrl}
                      />
                    </li>
                    <li>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "请输入手机验证码",
                          },
                        ]}
                      >
                        <input
                          className="password"
                          type="password"
                          placeholder="手机验证码"
                        />
                      </Form.Item>

                      <a
                        id="btnSendCode"
                        onClick={this.sendCode}
                        disabled={!this.state.liked}
                      >
                        {this.state.liked ? (
                          <span>{this.state.btnText}</span>
                        ) : (
                          <span>{this.state.seconds + " 秒后再获取"}</span>
                        )}
                      </a>
                    </li>

                    <li>
                      <div className="btn">登 录</div>
                    </li>
                  </Form>
                </ul>
              </div> */}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
