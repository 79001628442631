//使用抽屉进行编辑
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import '@ant-design/pro-card/dist/card.css'
import { Button, Col, Form, Input, Tree, Modal, Radio, Select, Cascader, Space } from 'antd'
import React, { Component } from 'react'
import '../../assets/css/paramSetting.css'
import http, { HashMap } from '../api/http'
const { DirectoryTree } = Tree
const { Option } = Select

class CraftDataSetting extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      fileId: '',
      craft: { craftParamList: [] },
      craftType: null,
      paramOptions: [],
      paramMap: null,
      changeParmIds: [],
      trBetweenValue: 10,
      treeData: null,
      tdBetweenValue: 10,
      trtd: [], //二围数组
    }
  }

  toEdit = (inEntity, inCraftType) => {
    console.log(inEntity)
    //定义参数选择
    let paramOptions = []
    let changeParmIds = []
    let paramMap = new HashMap()
    if (inEntity.craftParamList != null) {
      inEntity.craftParamList.forEach((param) => {
        paramOptions.push({ label: param.name, value: param.id })
        paramMap = new HashMap()
        paramMap.put(param.id, param)

        changeParmIds.push(param.id)
      })
      //根据工艺去取对应的设置
      this.form1.current.setFieldsValue(inEntity)
      this.setState({
        visible: true,
        craft: inEntity,
        craftType: inCraftType,
        title: '计时设置',
        paramMap: paramMap,
        paramOptions: paramOptions,
        changeParmIds: changeParmIds,
        trNum: [],
        tdNum: [],
        trtdMap: null,
        trtd: [],
      })

      this.getParamBetweenSettings(inEntity)
    }
  }

  getParamBetweenSettings(craft) {
    let values = {}
    values.craftId = craft.id
    http
      .post('/admin/craftPriceSettings/getList', values)
      .then((res) => {
        if (res.data.tdNum.length > 0) {
          let trtd = []
          if (res.data.trtd == null) {
            res.data.trNum.forEach((element) => {
              let tdValues = []
              res.data.tdNum.forEach((td) => {
                tdValues.push(0)
              })
              trtd.push(tdValues)
            })
          } else {
            trtd = res.data.trtd
          }
          this.setState({
            tdNum: res.data.tdNum,
            trNum: res.data.trNum,
            trtd,
          })
        } else {
        }
      })
      .catch((err) => {
        console.log(err)
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  checkForm = () => {
    this.form1.current.submit()
  }

  submit = (values) => {
    let url = '/admin/craftPriceSettings/update'
    values.id = this.state.craft.id
    console.log(values.craftParamList)
    let craftParamsJson = JSON.stringify(values.craftParamList)
    values.craftParamsJson = craftParamsJson

    values.trtdValuesJson = JSON.stringify(this.state.trtd)
    values.craftId = this.state.craft.id
    //提交
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.table.getCrafts(this.state.craftType)
        this.setState({
          visible: false,
        })
      })
      .catch((eer) => {
        console.log(eer)
      })

    this.setState({
      visible: false,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={1224}
          onClose={this.onClose}
          onCancel={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form ref={this.form1} onFinish={this.submit}>
            <Form.List name="craftParamList">
              {(fields, { add, remove }) => (
                <>
                  <Form.Item label="工艺参数"></Form.Item>
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{
                        display: 'flex',
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item {...field} name={[field.id, 'id']} fieldKey={[field.fieldKey, 'id']}>
                        <Input type="hidden" />
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'name']} fieldKey={[field.fieldKey, 'name']} rules={[{ required: true, message: '请输入参数名' }]}>
                        <Input placeholder="参数名" />
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'unit']} fieldKey={[field.id, 'unit']} rules={[{ required: true, message: '请输入参数单位' }]}>
                        <Input placeholder="参数单位" />
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'defaults']} fieldKey={[field.id, 'Defaults']}>
                        <Input placeholder="默认值" />
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'formFieldType']} fieldKey={[field.id, 'formFieldType']}>
                        <Select defaultValue="文本" style={{ width: 120 }}>
                          <Option value="文本">文本</Option>
                          <Option value="数字">数字</Option>
                          <Option value="附件">附件</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item {...field} name={[field.name, 'paramType']} fieldKey={[field.id, 'paramType']} tooltip="" initialValue="参数">
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value="参数">参数</Radio.Button>
                          <Radio.Button value="工时">工时</Radio.Button>
                          <Radio.Button value="执行人员录入参数">执行人员录入参数</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      {/* {field.name > 1 ? ( */}
                      <MinusCircleOutlined
                        onClick={(e) => {
                          remove(field.name)
                        }}
                      />
                    </Space>
                  ))}
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    新增参数
                  </Button>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      </div>
    )
  }
}

CraftDataSetting.propTypes = {}

export default CraftDataSetting
