import ProCard from '@ant-design/pro-card'
import { Button, DatePicker, InputNumber, Popover, Radio, Space, Table, Modal } from 'antd'
import arrayMove from 'array-move'
import React, { Component } from 'react'
import { SketchPicker } from 'react-color'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import dateFm from '../../api/dateFm'
import http from '../../api/http'
import { MenuOutlined,ExclamationCircleOutlined } from '@ant-design/icons'
import './ProjectSchedule.css'
import Schedule from './Schedule'
const { confirm } = Modal

const { RangePicker } = DatePicker

//排序所需
const SortableItem = sortableElement((props) => <tr {...props} />)
const SortableContainer = sortableContainer((props) => <tbody {...props} />)
const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)

class ProjectSchedule extends Component {
  constructor(props) {
    super(props)
    this.schedule = React.createRef()
    // console.log(props.projectPlanId)
    this.state = {
      loading:false,
      pagination: [],
      orderField: '',
      startDate: '',
      endDate: '2021-07-10',
      order: '',
      sheduleCraftTypes: [],
      shedulePlanDate: [],
      maxDate: 6,
      workTime: 8,
      overTime: 2,
      columns: [],
      isUnion: 'union',
      color: '#D0021B',
      orderColor: {},
      inScheduleOrders: {},
      projectPlanId:"",
      orderIds:"",
      dataCol:{},
      craftTime:null
    }
  }
  DraggableContainer = (props) => <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={this.onSortEnd} {...props} />
  DraggableBodyRow = ({ className, style, ...restProps }) => {
    let data = this.state.pagination.list
    if (data != null) {
      let index = data.findIndex((x) => {
        // console.log(restProps)
        return x.order_id === restProps['data-row-key']
      })
      return <SortableItem index={index} {...restProps} />
    } else {
      return <> </>
    }
  }

  componentDidMount(){
    var url = this.props.location.search; //获取url中"?"符后的字串
    if( url !=="" && url !== undefined && url !== null){
      var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    //获取工作时间
    var workDayTime = theRequest.workDayTime;
    var dateString = theRequest.start_date
    this.setState({
      workTime:workDayTime
    })
    this.schedule.current.setState({
      dateMaxValue:workDayTime
    })
    }
    let values = { isUnion: this.state.isUnion,workTime:workDayTime,dateString:dateString}
    this.getInsideCraftTypes(values)
    this.getWattingOrder(values)
  }

  initPlanSchedule = (startDate, endDate) => {}

  onSortEnd = ({ oldIndex, newIndex }) => {
    let data = this.state.pagination.list
    let pagination = this.state.pagination
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(data), oldIndex, newIndex).filter((el) => !!el)

      let orderData = this.schedule.current.state.orderData
      this.schedule.current.setState({
        data:[],
        orderData:[]
      })
      for (let index = 0; index < newData.length; index++) {
        for (let a = 0; a < orderData.length; a++) {
         if (newData[index].order_id === orderData[a].order_id) {
             let inScheduleOrders = this.state.inScheduleOrders
             inScheduleOrders[newData[index].order_id] = newData[index]
             this.schedule.current.addOrderToPlan(newData[index], this.state.orderColor)
             this.setState({ inScheduleOrders })
         }
          
        }
      }
            pagination.list = newData
      this.setState({ pagination })
    }
  }

  getProjectPlanDetail = (value) => {
    if (value != null && value !== "") {
    let values = {}
    values.projectPlanId = value
    http
        .post('/admin/plan/getDetail',values)
        .then((res) => {
          console.log(res.data.craftTime)
          var jsarr=JSON.parse(res.data.projectPlan.paramValuesJson)
          let orderColor = {}
          let inScheduleOrders = this.state.inScheduleOrders
          for (let index = 0; index < jsarr.length; index++) {
            orderColor[jsarr[index].order_id] = jsarr[index].orderColor
            inScheduleOrders[jsarr[index].order_id] = jsarr[index]
            this.schedule.current.addOrderToPlan(jsarr[index],orderColor)        
          }
          let order = {}
          order.list = jsarr

           let values = {}
          let id = []
          for (let index = 0; index < jsarr.length; index++) {
            id.push(jsarr[index].order_id)            
          }
          values.id = id.join(',')
          this.setState({
            orderIds:values.id,
            craftTime:res.data.craftTime
          })
          this.schedule.current.initShedule(this.state.shedulePlanDate, res.data.craftTime,res.data.dataCol)
          let data  = order.list
          console.log(order)
          for (let index = 0; index < data.length; index++) {
            orderColor[data[index].order_id] = data[index].orderColor
            inScheduleOrders[data[index].order_id] = data[index]
            this.schedule.current.addOrderToPlan(data[index],orderColor)        
          }
          this.setState({ orderColor })
          this.setState({ inScheduleOrders })
          this.setState({
            pagination:order
          })
        })
    }
  }
  
  getInsideCraftTypes = (values) => {
    let url = ""
    if(values.projectPlanId !== "" && values.projectPlanId !== undefined && values.projectPlanId !== null){
      url = '/admin/plan/getInsideCraftTypesDetail'
    }else{
      url = '/admin/plan/getInsideCraftTypes'
    }
    http
      .post(url, values)
      .then((res) => {
        let craftTypes = res.data.insideCraftTypes
        let columns = [
          {
           title: '操作',
           dataIndex: 'order_id',
           align: 'center',
           key: 'order_id',
           ellipsis: true,
           width: 2,
           render: (value, record) => {
             return (
               <>
                 <DragHandle />
                 {this.state.inScheduleOrders[record.order_id] == null ? (
                   <Button
                     type="link"
                     onClick={() => {
                       //拿这行工艺的数据
                       let inScheduleOrders = this.state.inScheduleOrders
                       inScheduleOrders[record.order_id] = record
                       this.schedule.current.addOrderToPlan(record, this.state.orderColor)
                       this.setState({ inScheduleOrders })
                     }}
                   >
                     添加
                   </Button>
                 ) : (
                   <Button
                     type="link"
                     onClick={() => {
                       let inScheduleOrders = this.state.inScheduleOrders
                       inScheduleOrders[record.order_id] = null
                       this.schedule.current.removeOrderToPlan(record, this.state.orderColor)
                       this.setState({ inScheduleOrders })
                     }}
                   >
                     移除
                   </Button>
                 )}

                 <Popover
                   content={
                     <SketchPicker
                       color={this.state.color}
                       onChange={(color) => {
                         // this.setState({ color: color.hex })
                         let orderColor = this.state.orderColor
                         orderColor[record.order_id] = color.hex
                         this.setState({ orderColor })
                         this.schedule.current.setOrderColor(orderColor)
                       }}
                     />
                   }
                   trigger="hover"
                   placement="rightTop"
                   onVisibleChange={this.handleVisibleChange}
                 >
                   <Button type="link" className="colorChoice">
                     <span
                       className="swatch"
                       style={{
                         background: this.state.orderColor[record.order_id] == null ? this.state.color : this.state.orderColor[record.order_id],
                       }}
                     ></span>
                   </Button>
                 </Popover>
               </>
             )
         },
        },
          {
            title: '订单编号',
            dataIndex: 'extra_order_code',
            align: 'center',
            key: 'extra_order_code',
            ellipsis: true,
            width: 2,
          },
          {
            title: '订单名称',
            dataIndex: 'order_name',
            align: 'center',
            key: 'order_name',
            ellipsis: true,
            width: 3,
          },
          {
            title: '需求交期',
            sorter: (a, b) => {},
            dataIndex: 'plan_time',
            align: 'center',
            key: 'plan_time',
            width: 2,

            ellipsis: true,
          },
          {
            title: '合计',
            width: 1,
            sorter: (a, b) => {},
            dataIndex: 'odr_time',
            align: 'center',
            key: 'odr_time',
            ellipsis: true,
            render: (value, record) => {
              return value == null ? '' : parseFloat(value).toFixed(2)
            },
          },
        ]
        let allTime = 0
        let maxCraftWorkTime = 0
        let sheduleCraftTypes = []
        craftTypes.forEach((craftType, index) => {
          sheduleCraftTypes.push({
            key: craftType.id,
            title: craftType.name,
            color: '#666',
            allValue: craftType.work_time,
          })
          allTime = allTime + craftType.work_time
          let col = {
            title: craftType.name + '[' + parseFloat(craftType.work_time).toFixed(2) + ']',
            dataIndex: res.data.col[craftType.name],
            align: 'center',
            key: craftType.name,
            width: 1,
            ellipsis: true,
            sorter: (a, b) => {},
            render: (value, record) => {
              return value == null ? '' : parseFloat(value).toFixed(2)
            },
          }
          columns.push(col)
          if (craftType.work_time > maxCraftWorkTime) {
            maxCraftWorkTime = craftType.work_time
          }
        })

        columns[4].title = '合计[' + parseFloat(allTime).toFixed(2) + ']小时'

        //初始化上面的起始日期
        let shedulePlanDate = this.state.shedulePlanDate
        let workTime = 0
        if( values.workTime !== undefined && values.workTime !== null && values.workTime !== "" ){
          workTime += values.workTime
        }else{
          workTime += this.state.workTime
        }
        if (maxCraftWorkTime > 0) {
          let workDate = Math.ceil(maxCraftWorkTime / workTime)
          
          var nowTemp = ""
          if (values.dateString === null || values.dateString === "" || values.dateString === undefined) {
            nowTemp = new Date()
          }else{
            nowTemp = values.dateString
          }
           

          let startDate = dateFm.dateAdd(new Date(nowTemp), 'd', 0, 'yyyy-MM-dd')
         // 
          let sd = new Date(startDate)
           this.setState({
             startDate:dateFm.format(sd, 'yyyy年MM月dd日')
           })
          // 剔除星期日 
          var count = 0
          for (let index = 0; index < workDate; index++) {
            startDate = dateFm.dateAdd(sd, 'd', 1, 'yyyy-MM-dd')
            if (dateFm.getWeekDate(sd) === '星期日') {
              count++
            }
          }   
          startDate = dateFm.dateAdd(new Date(nowTemp), 'd', 0, 'yyyy-MM-dd')
          sd = new Date(startDate)
          workDate = workDate+count+1

          shedulePlanDate.push({ 
            key: startDate,
            title: dateFm.format(sd, 'MM月dd日'),
            subtitle: dateFm.getWeekDate(sd),
          })
          for (let index = 0; index < workDate; index++) {
            startDate = dateFm.dateAdd(sd, 'd', 1, 'yyyy-MM-dd')
           
            if (dateFm.getWeekDate(sd) !== '星期日') {
              shedulePlanDate.push({
                key: startDate,
                title: dateFm.format(sd, 'MM月dd日'),
                subtitle: dateFm.getWeekDate(sd),
              })
            }
          }
          this.setState({
            endDate:dateFm.format(sd, 'yyyy年MM月dd日')
          })
        }
        this.setState({ columns: columns, sheduleCraftTypes, shedulePlanDate })
        this.schedule.current.initShedule(shedulePlanDate, sheduleCraftTypes,res.data.col)
        let inScheduleOrders = this.state.inScheduleOrders
        if(this.state.projectPlanId !== ""){
          let orderColor = {}
          let data  = this.state.pagination.list
          for (let index = 0; index < data.length; index++) {
            orderColor[data[index].order_id] = data[index].orderColor
            inScheduleOrders[data[index].order_id] = data[index]
            this.schedule.current.addOrderToPlan(data[index],orderColor)        
          }
          this.setState({
            inScheduleOrders,
            dataCol:res.data.col
          })
        }
      })
      .catch((err) => {
       })
  }

  getWattingOrder = (values) => {
      http
      .post('/admin/plan/getToDoProject', values)
      .then((res) => {
        console.log(res.data)
        let pagination = res.data
        this.setState({ pagination,dataCol:res.data.col })
        var url = this.props.location.search;
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
            var str = url.substr(1);
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
            }
        }
        var projectPlanId = theRequest.projectPlanId;
    if (projectPlanId !== "" && projectPlanId !== undefined) {
        this.getProjectPlanDetail(projectPlanId) 
        this.setState({
          projectPlanId:projectPlanId
        })
    }
      })
      .catch(() => {})
  }
  implementProjectPlan = () => {
    let values = {}
    values.orderList = []
    for (let index = 0; index < this.state.pagination.list.length; index++) {
      values.orderList.push(this.state.pagination.list[index].order_id)
    }
    values.orderList = values.orderList.join(',')
    values.projectPlanId = this.state.projectPlanId
    http
        .post("/admin/plan/orderSort",values)
        .then((res) =>{
          window.location.href = './'
        })
  }

  addOrderToPlan = (order) => {}
  render() {
    return (
      <ProCard split="horizontal">
        <ProCard
          title="本周计划"
          extra={
            <Space>
              每天工作时长
              <InputNumber
                max="24"
                maxLength="2"
                min="1"
                onChange={(e) => {
                  this.setState({ workTime: e })
                  this.setState({ columns: [], sheduleCraftTypes:[], shedulePlanDate:[]})
                  let values = { isUnion: this.state.isUnion,projectPlanId:this.state.projectPlanId,id:this.state.orderIds }
                  this.getInsideCraftTypes(values)
                  this.schedule.current.setState({
                    dateMaxValue:e
                  })
                }}
                defaultValue={this.state.workTime}
                value={this.state.workTime}
                formatter={(value) => `${value}小时`}
              ></InputNumber>
                  <DatePicker 
                    onChange={(date, dateString) => {
                      let values = { isUnion: this.state.isUnion,projectPlanId:this.state.projectPlanId,id:this.state.orderIds }
                      values.dateString = dateString
                      this.setState({ columns: [], sheduleCraftTypes:[], shedulePlanDate:[],inScheduleOrders:[] })
                      this.schedule.current.setState({
                        orderData:[]
                      })
                      this.getInsideCraftTypes(values)
                    }}
                  ></DatePicker >
            </Space>
          }
        >
          <Schedule ref={this.schedule} xField={this.state.shedulePlanDate} yField={this.state.sheduleCraftTypes}></Schedule>
        </ProCard>
        <ProCard
          title="在制项目"
          extra={
            <Space>
              <Radio.Group
                onChange={(e) => {
                  let values = { isUnion: e.target.value,projectPlanId:this.state.projectPlanId,id:this.state.orderIds }
                  this.getInsideCraftTypes(values)
                  this.getWattingOrder(values)
                }}
                defaultValue="union"
              >
                <Space>
                  <Radio.Button value="union">合并多人工时</Radio.Button>
                  <Radio.Button value="all">全工时</Radio.Button>
                  {this.props.location.search === "" ? ( <Button  onClick={() => {
                    const _this = this
                    confirm({
                      title: '是否保存',
                      icon: <ExclamationCircleOutlined />,
                      okText: '确认',
                      okType: 'danger',
                      cancelText: '取消',
                      width: 600,
                      onOk() {
                        _this.schedule.current.saveOrderSort(_this.state.startDate,_this.state.endDate,_this.state.workTime)
                      },
                      onCancel() {},
                    })
                      }}>保存</Button>) : (<></>)}
                      {this.props.location.search !== "" ? ( <Button  onClick={() => {
                        const _this = this
                         confirm({
                          title: '确认要实施该排产计划吗',
                          icon: <ExclamationCircleOutlined />,
                          okText: '确认',
                          okType: 'danger',
                          cancelText: '取消',
                          width: 600,
                          onOk() {
                            _this.implementProjectPlan()
                          },
                          onCancel() {},
                        })
                      }}>实施</Button>) : (<></>)}
                      
                </Space>
              </Radio.Group>
            </Space>
          }
        >
          <Table
            rowKey="order_id"
            components={{
              body: {
                wrapper: this.DraggableContainer,
                row: this.DraggableBodyRow,
              },
            }}
            scroll={{ y: 450 }}
            onChange={(pagination, filters, sorter) => {
              let values = {
                orderField: sorter.field,
                order: sorter.order,
                isUnion: this.state.isUnion,
              }
              this.setState({ orderField: sorter.field, order: sorter.order })
              this.getWattingOrder(values)
            }}
            columns={this.state.columns}
            dataSource={this.state.pagination.list}
            pagination={{
              hideOnSinglePage: true,
              // 分页
              current: this.state.pagination.pageNumber,
              showSizeChanger: false,
              showQuickJumper: false,
              simple: true,
              showTotal: (total) => {
                return `共 ${this.state.pagination.totalRow} 条`
              },
              defaultPageSize: this.state.pagination.pageSize,
              total: this.state.pagination.totalRow,
              onChange: (page) => {
                let values = {}
                values.page = page
                values.isUnion = this.state.isUnion
                values.orderField = this.state.orderField
                values.order = this.state.order
                this.getWattingOrder(values)
              },
            }}
          ></Table>
        </ProCard>
      </ProCard>
    )
  }
}

ProjectSchedule.propTypes = {}

export default ProjectSchedule
