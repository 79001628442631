import { message } from 'antd'
import React, { Component } from 'react'
import http from '../api/http'
class MobileQuality extends Component {
  constructor(props) {
    super(props)
    this.state = {
      qualityCnt:"",
      isQualityCnt:"",
      toDoList:[],
      workOrderCntByUser: {
        waitAcceptOrderCntInfo: {},
        todayDoneCraft: {},
      },
    }
  }

  
  DivOnClick = (item,orderIndex,index) => {
    this.props.getQualityImg(this,item,orderIndex,index)
  }
  getDetail(orderItem, orderIndex) {
    let values = {}
    values.orderId = orderItem.id
    values.status='待质检'
    if (orderItem.deliverComponentVoList.length == 0) {
      let data = this.state.toDoList
      http
        .post("/admin/quality/getQualityDetail", values)
        .then((res) => {
          console.log(res)
          data[orderIndex].deliverComponentVoList = res.data
          this.setState({
            toDoList:data
          })
        })
        .catch((err) => {
        });
    }
  }

  getQualityList() {
    let values = {};
    values.status='待质检'
    http
      .post("/admin/quality/QualityList", values)
      .then((res) => {
        let qualityList = []
        let tmpList = res.data;
        for (var t = 0; t < tmpList.length; t++) {
          let orderItem = tmpList[t];
          orderItem.deliverComponentVoList = []
          qualityList.push(orderItem)
        }
        this.setState({
          toDoList:qualityList
        })
      })
      .catch((err) => {
      });
      http
      .post("/admin/quality/qualityCnt")
      .then((res) => {
        this.setState({
          qualityCnt:res.data.qualityCnt,
          isQualityCnt:res.data.isQualityCnt
        })
      })
      .catch((err) => {
      });
  }

  render() {
    return (
      <div>
      <div className="statistics">
        <div className="statistics_switch">
          <div className="switch">
            <div className="orders">
              <div className="list">
                <div className="item">
                  <p>
                    {this.state.workOrderCntByUser != null ? this.state.workOrderCntByUser.waitAcceptOrderCntInfo.cnt : '0'}
                    <span>{this.state.qualityCnt.odr_cnt}单</span>
                  </p>
                  <p>待质检</p>
                  <p>{this.state.qualityCnt.oc_cnt}类零件/共{this.state.qualityCnt.comp_num}件</p>
                </div>
                <div className="item">
                  <p>
                    {this.state.workOrderCntByUser == null
                      ? '0'
                      : this.state.workOrderCntByUser.todayDoneCraft.cnt == null
                      ? '0'
                      : this.state.workOrderCntByUser.todayDoneCraft.cnt}
                    <span>{ this.state.isQualityCnt.odr_cnt }单</span>
                  </p>
                  <p>已质检</p>
                  <p>{this.state.isQualityCnt.oc_cnt}类零件/共{this.state.isQualityCnt.comp_num}件</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="order">
        <div style={{ padding: '4%', background: '#eee' }}>
          {this.state.toDoList != null
            ? this.state.toDoList.map((orderItem, orderIndex) => (
                <div className="order_change" >
                  <div className="order_title">
                    <div className="title" onClick={this.getDetail(orderItem,orderIndex)}>
                      <p>项目名称 : {orderItem.order_name}</p>
                    </div>
                    <p>项目号 : {orderItem.extra_order_code}</p>
                    <p>零件品类数 : {orderItem.component_type_num}</p>
                    <div className="list">
                      <div className="item">
                        <p>计划时间 :{orderItem.plan_time != null && orderItem.plan_time !== undefined ? orderItem.plan_time : ''}</p>
                        <p>
                          完成数 : {orderItem.done_comp_cnt}/{orderItem.components_num}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="order_con" id="selector1">
                    <div className="list">
                      {orderItem.deliverComponentVoList != null
                        ? orderItem.deliverComponentVoList.map((item, index) => (
                            <div className="item" onClick={() => this.DivOnClick(item,orderIndex,index)}>
                              <div className="order_conduct1">
                                {/* <div className="lable"><p>11</p></div> */}
                                <div className="title">
                                  <div className="icon_x" style={{ backgroundColor: '#3c65bd', boxShadow: '0 0 5px #3c65bd' }}>
                                    {/* <uni-icons type="star-filled" size="26" color="#fff" /> */}
                                  </div>
                                  <div className="text">
                                    <p>
                                      {item.component_name}
                                    </p>
                                    <p>
                                     图号 {item.component_code}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : ''}
                    </div>
                  </div>
                </div>
              ))
            : ''}
        </div>
      </div>
    </div>
    )
  }
}

export default MobileQuality
