import React, { Component } from "react";

import { Spin } from "antd";

class Panel extends Component {
  constructor(props) {
    super(props);
  }

  /* 容器区 */
  //flex   占用大小比例控制(number) / 默认1
  //layout   如果值为"h-box"则横向 / 默认"v-box" 纵向
  //height   高度 / 默认100%
  //flexBar   是否开启flex格式(boolean) /  默认true   注意:父级和子级同为false时生效

  /* load状态区 */
  //load   loading状态(boolean) / 默认false

  /* 标题内容区 */
  //title   标题(string)   注意:title不能与titleLeft同时存在
  //titleLeft   标题框左边内容区(React.ReactNode)   注意:title不能与titleLeft同时存在
  //titleCenter   标题框中间内容区(React.ReactNode)
  //titleRight   标题框右边内容区(React.ReactNode)
  //titleBackground   标题背景色(默认白底黑字 / green 绿色 , red 红色 , blue 蓝色 , orange 橙色)

  /* 顶部左右悬浮内容区 */
  //topXfBar   顶部左右悬浮内容区(React.ReactNode)
  //topXfBarL   向左(number) / 例子 : topXfBarL="1"
  //topXfBarR   向右(number) / 例子 : topXfBarR="1"
  //topXfShadow   阴影开关(boolean) / 默认true

  /* 顶部内容区 */
  //topBar   顶部内容区(React.ReactNode)
  //topLeft   顶部左边内容区(React.ReactNode)
  //topRight   顶部右边内容区(React.ReactNode)
  //topBorder   顶部内容区线(boolean) / 默认false

  /* 中间内容区 */
  //bodyPadding   中间内容区间距(number) / 默认0px

  /* 底部内容区 */
  //footerBar   底部内容区(React.ReactNode)
  //footerLeft   底部左边内容区(React.ReactNode)
  //footerRight   底部右边内容区(React.ReactNode)
  //footerBorder   底部内容区线(boolean) / 默认false

  render() {
    return (
      <>
        {/* 容器区 */}
        <div
          style={{
            overflow: this.props.overflow == false ? "" : "hidden",
            position: "relative",
            display: "flex",
            flex: this.props.height
              ? "initial"
              : this.props.flex
              ? this.props.flex
              : 1,
            width: "100%",
            height: this.props.height ? this.props.height + "px" : "100%",
            height: this.props.flexBar == false ? "initial" : "100%",
            marginTop: this.props.marginTop + "px",
            marginBottom: this.props.marginBottom + "px",
            marginLeft: this.props.marginLeft + "px",
            marginRight: this.props.marginRight + "px",
            flexDirection: this.props.layout == "h-box" ? "initial" : "column",
            padding: this.props.padding + "px",
            borderTopWidth:
              this.props.border || this.props.borderTop ? "1px" : "inherit",
            borderBottomWidth:
              this.props.border || this.props.borderBottom ? "1px" : "inherit",
            borderLeftWidth:
              this.props.border || this.props.borderLeft ? "1px" : "inherit",
            borderRightWidth:
              this.props.border || this.props.borderRight ? "1px" : "inherit",
            borderStyle: "solid",
            borderColor: this.props.borderColor
              ? this.props.borderColor
              : "#f0f0f0",
            boxSizing: "border-box",
            background: this.props.background ? this.props.background : "#fff",
            boxShadow:
              this.props.boxShadow == false || this.props.styleWH == false
                ? "initial"
                : "1px 0 10px 1px rgb(0 0 0 / 10%)",
          }}
        >
          {/* load内容区 */}
          <div
            style={{
              display: this.props.load == true ? "block" : "none",
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              width: "100%",
              height: "100%",
              background: "rgba(255,255,255,0.94)",
              zIndex: "99",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <Spin size="large" />
            </div>
          </div>

          {/* 顶部左右悬浮内容区 */}
          {this.props.topXfBar ? (
            <div
              style={{
                position: "absolute",
                left: this.props.topXfBarL
                  ? this.props.topXfBarL + "%"
                  : "initial",
                right: this.props.topXfBarR
                  ? this.props.topXfBarR + "%"
                  : "initial",
                top: "3%",
                padding: "3px",
                boxShadow:
                  this.props.topXfShadow == false
                    ? "initial"
                    : "1px 0 10px 1px rgb(0 0 0 / 10%)",
                zIndex: "10",
              }}
            >
              {this.props.topXfBar}
            </div>
          ) : (
            ""
          )}

          {/* 标题内容框 */}
          {this.props.title || this.props.rightTitle ? (
            <div
              style={{
                overflow: "hidden",
                position: "relative",
                // display: "flex",
                alignItems: "center",
                padding: "6px 13px",
                background:
                  this.props.titleBackground == "green"
                    ? "#a0d468"
                    : this.props.titleBackground == "red"
                    ? "#e46f61"
                    : this.props.titleBackground == "blue"
                    ? "#5db2ff"
                    : this.props.titleBackground == "orange"
                    ? "#f9b256"
                    : "none",
                borderBottomWidth: this.props.titleBorderColor ? "3px" : "1px",
                borderStyle: "solid",
                borderColor:
                  this.props.titleBorderColor == "green"
                    ? "#a0d468"
                    : this.props.titleBorderColor == "red"
                    ? "#e46f61"
                    : this.props.titleBorderColor == "blue"
                    ? "#5db2ff"
                    : this.props.titleBorderColor == "orange"
                    ? "#f9b256"
                    : "#ddd",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                boxSizing: "border-box",
              }}
            >
              {this.props.title || this.props.titleLeft ? (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    float: "left",
                    fontSize: "12px",
                    color: this.props.titleBackground ? "#fff" : "#000",
                  }}
                >
                  {this.props.title && !this.props.titleLeft ? (
                    <p
                      //标题
                      style={{
                        float: "left",
                        fontSize: "13px",
                        lineHeight: "30px",
                        color: this.props.titleBackground ? "#fff" : "#000",
                        fontWeight: this.props.fontWeight ? "bold" : "",
                      }}
                    >
                      {this.props.title}
                    </p>
                  ) : (
                    ""
                  )}
                  {this.props.titleLeft && !this.props.title ? (
                    <div
                      style={{
                        alignItems: "center",
                        float: "left",
                        fontSize: "12px",
                        color: this.props.titleBackground ? "#fff" : "#000",
                      }}
                    >
                      {this.props.titleLeft}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {this.props.titleCenter ? (
                <div
                  //标题框中间内容
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "inline-flex",
                    alignItems: "center",
                    fontSize: "12px",
                    color: this.props.titleBackground ? "#fff" : "#000",
                  }}
                >
                  {this.props.titleCenter}
                </div>
              ) : (
                ""
              )}
              {this.props.titleRight ? (
                <div
                  //标题框右边内容
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    float: "right",
                    fontSize: "12px",
                    color: this.props.titleBackground ? "#fff" : "#000",
                  }}
                >
                  {this.props.titleRight}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {/* 顶部内容区 */}
          {this.props.topBar || this.props.topLeft || this.props.topRight ? (
            <div
              style={{
                // overflow: "hidden",
                display: "flex",
                alignItems: "center",
                padding: this.props.topPadding
                  ? this.props.topPadding + "px"
                  : "5px 10px",
                fontSize: "12px",
                lineHeight: "26px",
                background:
                  this.props.background != null
                    ? this.props.background
                    : "none",
                // boxShadow: "0 0 5px #dcdcdc",
                zIndex: "10",
                borderBottom: "1px solid #f0f0f0",
                // borderBottom: this.props.topBorder
                //   ? "1px solid #f0f0f0"
                //   : "none",
                boxShadow:
                  this.props.topBarShadow == false
                    ? "initial"
                    : "0 4px 5px rgb(0 0 0 / 5%)",
              }}
            >
              {this.props.topLeft ? (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    float: "left",
                  }}
                >
                  {this.props.topLeft}
                </div>
              ) : (
                ""
              )}

              <div style={{ flex: "1" }}>{this.props.topBar}</div>

              {this.props.topRight ? (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    float: "right",
                  }}
                >
                  {this.props.topRight}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {/* 中间内容区 */}
          <div
            style={{
              overflow: this.props.overflow == false ? "" : "auto",
              overflowX: this.props.overflowX == false ? "scroll" : "auto",
              display: this.props.flexBar == false ? "block" : "flex",
              flex: "1",
              width: this.props.styleWH == false ? "auto" : "100%",
              height: this.props.styleWH == false ? "auto" : "100%",
              flexDirection:
                this.props.layout == "h-box" ? "initial" : "column",
              padding:
                (this.props.bodyPadding ? this.props.bodyPadding : "0") + "px",
              // paddingTop: "0px",
              // paddingBottom: "0px",
            }}
          >
            {this.props.children}
          </div>

          {/* 底部内容区 */}
          {this.props.footerBar || this.props.footerRight ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: this.props.footerPadding
                  ? this.props.footerPadding + "px"
                  : "5px 10px",
                fontSize: "12px",
                // lineHeight: "35px",
                background:
                  this.props.background != null
                    ? this.props.background
                    : "none",
                borderTop: this.props.footerBorder ? "1px solid #ddd" : "none",
                boxSizing: "border-box",
                boxShadow: "0 0 5px #dcdcdc",
                zIndex: "10",
              }}
            >
              {this.props.footerBar ? (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    float: "left",
                  }}
                >
                  {this.props.footerBar}
                </div>
              ) : (
                ""
              )}

              <div style={{ flex: "1" }}></div>

              {this.props.footerRight ? (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    float: "right",
                  }}
                >
                  {this.props.footerRight}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default Panel;
