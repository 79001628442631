import React, { Component } from 'react'
//import React, { useState } from 'react';
import ProCard, { StatisticCard } from '@ant-design/pro-card'
import moment from 'moment'
import http from '../../api/http'
import OrderCompile from '../OrderCompile'
import { Avatar, Button, Form, Input, List, message, Modal, Progress, Row, Col, DatePicker, Select, Table, Dropdown, Menu } from 'antd'
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Fragment } from 'react'
import { post } from 'jquery'
const { Statistic } = StatisticCard
const { confirm } = Modal
const { RangePicker } = DatePicker
const { Option } = Select
class Collect extends Component {
  constructor(props) {
    super(props)
    this.searchForm = React.createRef()
    this.orderCompile = React.createRef()
    this.state = {
      IsModalVisible: false,
      orderSeq: '',
      errType: '',
      errMemo: '',
      sortedInfo: null,
      selectStatus: {},
      loading: false,
      orderData: {},
      orderStatus: '',
      componentData: {},
      craftData: {},
      orderSearchValue: '',
      selectOrderId: '',
      order: '',
      selectOrderComponentId: '',
      selectOrderComponent: null,
      selectCraftId: '',
      componentSearchValue: '',
      getOrderCnt: {},
      options: [],
      transfer: {},
      columns: [
        {
          title: '接单时间',
          dataIndex: 'hand_create_time',
          key: 'hand_create_time',
          ellipsis: true,
          width: 40,
          render: (value, record) => {
            return <>{moment(value).format('YYYY-MM-DD')}</>
          },
        },
        {
          title: '移交时间',
          dataIndex: 'hand_done_time',
          key: 'hand_done_time',
          ellipsis: true,
          width: 40,
          render: (value, record) => {
            return <>{moment(value).format('YYYY-MM-DD')}</>
          },
        },
        {
          title: '移交者',
          dataIndex: 'hand_name',
          key: 'hand_name',
          ellipsis: true,
          width: 20,
        },
        {
          title: '接收者',
          dataIndex: 'accept_user_name',
          key: 'accept_user_name',
          ellipsis: true,
          width: 20,
        },
        {
          title: '接收时间',
          dataIndex: 'accept_create_time',
          key: 'accept_create_time',
          ellipsis: true,
          width: 20,
          render: (value, record) => {
            return <>{moment(value).format('YYYY-MM-DD')}</>
          },
        },
      ],
    }
  }

  getOrderList = (values) => {
    if (values == null) {
      values = {}
      values.status = this.state.orderStatus
    }
    values.searchValue = this.state.orderSearchValue

    http
      .post('/admin/workOrder/getOrderList', values)
      .then((res) => {
        console.log(res)
        let orderData = res.data
        this.setState({
          orderData,
          componentData: [],
          craftData: [],
        })
      })
      .catch((err) => {
        console.log(err)
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  toExcel = () => {
    this.searchForm.current.validateFields().then((values) => {
      values.componentSearchValue = this.state.componentSearchValue
      values.startDate = this.state.startDate
      values.endDate = this.state.endDate
      http
        .download('/admin/workOrder/orderComponentsToExcel', values, '零件异常清单.xlsx')
        .then((res) => {})
        .catch((err) => {
          console.log(err)
          message.error({
            content: '数据加载失败请联系系统管理员',
          })
        })
    })
  }

  getErrTyep = () => {
    http.post('/admin/workOrder/getOrderComErrType').then((res) => {
      console.log(res)
      this.setState({ options: [] })
      for (let index = 0; index < res.data.length; index++) {
        let typeData = {
          value: '',
          label: '',
          children: [],
        }
        typeData.value = res.data[index].id
        typeData.label = res.data[index].dirValue
        this.state.options.push(typeData)
      }
    })
  }

  getComponents = (values) => {
    if (values == null) {
      values = {}
      values.orderId = this.state.selectOrderId
    }

    http
      .post('/admin/workOrder/getOrderComponents', values)
      .then((res) => {
        let componentData = res.data
        this.setState({
          componentData,
        })
        console.log(componentData)
      })
      .catch((err) => {
        console.log(err)
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  getComponentsCraft = (values) => {
    if (values == null) {
      values = {}
    }
    values.searchValue = this.state.orderSearchValue
    values.status = this.state.orderStatus
    http
      .post('/admin/workOrder/getComponentCrafts', values)
      .then((res) => {
        console.log(res)
        let craftData = res.data
        this.setState({
          craftData,
        })
      })
      .catch((err) => {
        console.log(err)
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  stopOrderCom = (id) => {
    let values = {}
    values.id = id
    values.errType = this.state.errType
    values.errMemo = this.state.errMemo
    http
      .post('/admin/workOrder/stopOrderComponents', values)
      .then((res) => {
        message.success({
          content: '暂停成功',
        })
        this.getComponents()
      })
      .catch(() => {})
  }
  orderSort = (id) => {
    let values = {}
    values.orderId = id
    values.orderSeq = this.state.orderSeq
    http
      .post('/admin/order/orderSeq', values)
      .then((res) => {
        this.getOrderList()
      })
      .catch(() => {})
  }

  getOrderCnt = () => {
    http
      .post('/admin/workOrder/getOrderCnt', null)
      .then((res) => {
        let getOrderCnt = res.data
        this.setState({
          getOrderCnt,
        })
      })
      .catch((err) => {
        console.log(err)
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  getWorkOrderTransferDetail = (groupId) => {
    console.log(groupId)
    let values = {}
    values.workOrderGroupId = groupId
    http.post('/admin/workOrder/getWorkOrderTransferDetail', values).then((res) => {
      console.log(res.data)
      this.setState({ transfer: res.data })
    })
  }

  handleOk() {
    this.setState({
      isModalVisible: false,
    })
  }
  inputChange1(e) {
    // alert(e.target.value)
    this.setState({
      errMemo: e.target.value,
    })
  }
  inputChange2(e) {
    // alert(e.target.value)
    this.setState({
      orderSeq: e.target.value,
    })
  }

  //加载完成
  componentDidMount() {
    this.getErrTyep()
    this.getOrderList()
    this.getOrderCnt()
  }
  //暂停，弹出异常输入框
  Container() {
    return (
      <>
        <Form layout="horizontal" autoComplete="off" style={{ marginTop: 10 }} ref={this.form1} labelCol={{ span: 6 }} onFinish={this.submit}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="errType" label="异常类型">
                <Select
                  defaultValue={(this.state.options[0].label, (this.state.errType = this.state.options[0].value))}
                  placeholder="请选择"
                  filterOption={false}
                  onChange={(key) => {
                    if (key != null && key != undefined && key != '') {
                      this.setState({
                        errType: key,
                      })
                    }
                  }}
                >
                  {this.state.options.map((item, index) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="errMemo" label="异常备注">
                <Input onChange={(e) => this.inputChange1(e)} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }
  sortContainer() {
    return (
      <>
        <Form layout="horizontal" autoComplete="off" style={{ marginTop: 10 }} ref={this.form1} labelCol={{ span: 6 }} onFinish={this.submit}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="orderSort" label="订单排序">
                <Input onChange={(e) => this.inputChange2(e)} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }
  render() {
    const items = [
      { key: '', title: '全部', value: this.state.getOrderCnt.count != null ? this.state.getOrderCnt.count : '-', total: true },
      { key: '预算', status: 'default', title: '预算中', value: this.state.getOrderCnt.ys != null ? this.state.getOrderCnt.ys : '-' },
      { key: '在产', status: 'processing', title: '在产', value: this.state.getOrderCnt.zc != null ? this.state.getOrderCnt.zc : '-' },
      { key: '告警', status: 'error', title: '告警工单', value: this.state.getOrderCnt.errCnt != null ? this.state.getOrderCnt.errCnt : '-' },
      { key: '结束', status: 'success', title: '完成', value: this.state.getOrderCnt.wc != null ? this.state.getOrderCnt.wc : '-' },
    ]
    let winHeigh = document.documentElement.clientHeight * 0.6
    return (
      <ProCard
        style={{ minHeight: winHeigh }}
        tabs={{
          onChange: (key) => {
            // console.log('key', key)
            let values = { status: key }
            this.setState({ orderStatus: key })
            this.getOrderList(values)
          },
        }}
        extra={
          <>
            <Modal title="交班明细" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleOk.bind(this)}>
              <Table
                search={false}
                size="small"
                rowKey={(record) => record.id}
                columns={this.state.columns}
                dataSource={this.state.transfer.content}
                pagination={{
                  // 分页
                  showTotal: (total) => {
                    return `共 ${total} 条`
                  },
                  showSizeChanger: false,
                  showQuickJumper: true,
                  defaultPageSize: 15,
                  total: this.state.transfer.totalElements,
                  onChange: (page) => {},
                }}
                onRow={(record) => ({
                  onDoubleClick: () => {
                    this.toEdit(record)
                  },
                })}
              />
            </Modal>
            <Form layout="inline" ref={this.searchForm} onFinish={this.getList}></Form>
            <RangePicker
              style={{ width: '70%' }}
              onChange={(date, dateString) => {
                let startDate = dateString[0]
                let endDate = dateString[1]
                this.setState({ startDate, endDate })
              }}
            ></RangePicker>
            <Button
              type="primary"
              onClick={() => {
                this.toExcel()
              }}
              key="primary"
            >
              导出异常日志
            </Button>
          </>
        }
      >
        {items.map((item) => (
          <ProCard.TabPane
            style={{ width: '100%' }}
            key={item.key}
            tab={
              <Statistic
                layout="vertical"
                text
                title={item.title}
                value={item.value}
                status={item.status}
                style={{ textAlign: 'center', width: 120, borderRight: '1px solid #f0f0f0' }}
              />
            }
          >
            <ProCard split="vertical">
              <ProCard
                title="订单"
                colSpan="30%"
                extra={
                  <div>
                    <Input
                      style={{ width: '70%' }}
                      placeholder="客户、订单、零件、人员姓名"
                      onChange={(e) => {
                        this.setState({ orderSearchValue: e.target.value })
                      }}
                      value={this.state.orderSearchValue}
                    />
                    <Button
                      type="primary"
                      style={{ marginLeft: 10 }}
                      htmlType="button"
                      onClick={() => {
                        this.getOrderList()
                      }}
                    >
                      查询
                    </Button>
                  </div>
                }
                headerBordered
              >
                <List
                  size="small"
                  pagination={{
                    hideOnSinglePage: true,
                    // 分页
                    current: this.state.orderData.number + 1,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    simple: true,
                    showTotal: (total) => {
                      return `共 ${total} 条`
                    },
                    defaultPageSize: this.state.orderData.size,
                    total: this.state.orderData.totalElements,
                    onChange: (page) => {
                      let values = {}
                      values.page = page
                      values.pageSize = this.state.orderData.size
                      values.status = this.state.orderStatus
                      this.getOrderList(values)
                    },
                  }}
                  dataSource={this.state.orderData.content}
                  renderItem={(item) => (
                    <List.Item
                      extra={<Progress type="circle" percent={parseInt((item.doneCompCnt / item.componentsNum) * 100)} width={50} />}
                      style={{ backgroundColor: item.id === this.state.selectOrderId ? '#f0f0f5' : '' }}
                      onClick={() => {
                        this.setState({
                          selectOrderId: item.id,
                          order: item,
                        })

                        let values = {}
                        values.orderId = item.id
                        this.getComponents(values)
                      }}
                      onDoubleClick={() => {
                        let orderSort = this.orderSort
                        confirm({
                          title: '对该订单进行排序',
                          icon: <ExclamationCircleOutlined />,
                          okText: '确认',
                          okType: 'danger',
                          content: this.sortContainer(),
                          cancelText: '取消',
                          width: 600,
                          onOk() {
                            orderSort(item.id)
                          },
                          onCancel() {},
                        })
                      }}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            shape="square"
                            style={{
                              backgroundColor:
                                item.status === '逾期'
                                  ? 'red'
                                  : item.status === '告警'
                                  ? 'orange'
                                  : item.status === '预算'
                                  ? '#ff8000'
                                  : item.status === '在产'
                                  ? '#306ce5'
                                  : item.status === '已完成'
                                  ? '#38ad5f'
                                  : '#38ad5f',
                              verticalAlign: 'middle',
                            }}
                          >
                            {item.status}
                          </Avatar>
                        }
                        title={item.orderName}
                        description={
                          <>
                            {item.extraOrderCode != null ? item.extraOrderCode : item.orderCode} 计划时间：{item.planTime}
                            &nbsp; 排序:{item.orderSeq == null ? '' : item.orderSeq}
                          </>
                        }
                      />
                    </List.Item>
                  )}
                ></List>
              </ProCard>
              <ProCard
                colSpan="30%"
                title="零件"
                extra={
                  <div>
                    <Input
                      style={{ width: '70%' }}
                      placeholder="零件名名编号"
                      onChange={(e) => {
                        this.setState({ componentSearchValue: e.target.value })
                      }}
                      value={this.state.componentSearchValue}
                    />
                    <Button
                      type="primary"
                      style={{ marginLeft: 10 }}
                      htmlType="button"
                      onClick={() => {
                        let values = {}
                        values.orderId = this.state.selectOrderId
                        values.searchValue = this.state.componentSearchValue
                        this.getComponents(values)
                        this.setState({ craftData: {} })
                      }}
                    >
                      查询
                    </Button>
                  </div>
                }
                headerBordered
              >
                <List
                  pagination={{
                    hideOnSinglePage: true,
                    // 分页
                    current: this.state.componentData.number + 1,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showTotal: (total) => {
                      return `共 ${total} 条`
                    },
                    // pageSize: this.state.orderData.numberOfElements,
                    defaultPageSize: 8,
                    total: this.state.componentData.totalElements,
                    onChange: (page) => {
                      let values = {}
                      values.page = page
                      values.pageSize = this.state.componentData.size
                      values.orderId = this.state.selectOrderId
                      this.getComponents(values)
                    },
                  }}
                  dataSource={this.state.componentData.content}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        item.status === '已投产' ? (
                          <>
                            <Dropdown
                              overlay={
                                <Menu>
                                  {/* <Button
                              onClick={() => {
                                this.getErrTyep()
                                let stop = this.stopOrderCom
                                confirm({
                                  title: '你确认要暂停该零件的生产?',
                                  icon: <ExclamationCircleOutlined />,
                                  okText: '确认',
                                  okType: 'danger',
                                  content: this.Container(),
                                  cancelText: '取消',
                                  width: 600,
                                  onOk() {
                                    stop(item.id)
                                  },
                                  onCancel() {},
                                })
                              }}
                            >
                              暂停
                            </Button>
                            <Button
                              onClick={() => {
                                let list = this.getComponents
                                confirm({
                                  title: '你确认要加急该零件的生产?',
                                  icon: <ExclamationCircleOutlined />,
                                  okText: '确认',
                                  okType: 'danger',
                                  cancelText: '取消',
                                  onOk() {
                                    http
                                      .post('/admin/workOrder/toHot?orderComponentId=' + item.id, '')
                                      .then((res) => {
                                        message.success({
                                          content: '加急成功',
                                        })
                                        list()
                                      })
                                      .catch(() => {})
                                  },
                                  onCancel() {},
                                })
                              }}
                            >
                              加急
                            </Button> */}
                                  <Menu.Item key="0">
                                    <a
                                      onClick={() => {
                                        this.orderCompile.current.toCompile(this.state.order, item, true)
                                      }}
                                    >
                                      查看工艺
                                    </a>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <a
                                      onClick={() => {
                                        this.getErrTyep()
                                        let stop = this.stopOrderCom
                                        confirm({
                                          title: '你确认要暂停该零件的生产?',
                                          icon: <ExclamationCircleOutlined />,
                                          okText: '确认',
                                          okType: 'danger',
                                          content: this.Container(),
                                          cancelText: '取消',
                                          width: 600,
                                          onOk() {
                                            stop(item.id)
                                          },
                                          onCancel() {},
                                        })
                                      }}
                                    >
                                      暂停
                                    </a>
                                  </Menu.Item>
                                  <Menu.Item key="2">
                                    <a
                                      onClick={() => {
                                        let list = this.getComponents
                                        confirm({
                                          title: '你确认要加急该零件的生产?',
                                          icon: <ExclamationCircleOutlined />,
                                          okText: '确认',
                                          okType: 'danger',
                                          cancelText: '取消',
                                          onOk() {
                                            http
                                              .post('/admin/workOrder/toHot?orderComponentId=' + item.id, '')
                                              .then((res) => {
                                                message.success({
                                                  content: '加急成功',
                                                })
                                                list()
                                              })
                                              .catch(() => {})
                                          },
                                          onCancel() {},
                                        })
                                      }}
                                    >
                                      加急
                                    </a>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <a
                                      onClick={() => {
                                        confirm({
                                          title: '确认要停止制作?',
                                          icon: <ExclamationCircleOutlined />,
                                          okText: '确认',
                                          okType: 'danger',
                                          cancelText: '取消',
                                          onOk() {
                                            http
                                              .post('/admin/components/offOrderComponent?id=' + item.id, '')
                                              .then((res) => {
                                                message.success({
                                                  content: '停止成功',
                                                })
                                                // list()
                                              })
                                              .catch(() => {})
                                          },
                                          onCancel() {},
                                        })
                                      }}
                                    >
                                      制作停止
                                    </a>
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={['click']}
                            >
                              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                操作 <DownOutlined />
                              </a>
                            </Dropdown>
                          </>
                        ) : item.status === '暂停' ? (
                          <Button
                            onClick={() => {
                              let list = this.getComponents
                              confirm({
                                title: '你确认要重启该零件的生产?',
                                icon: <ExclamationCircleOutlined />,
                                okText: '确认',
                                okType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                  http
                                    .post('/admin/workOrder/reStartOrderComponents?orderComponentId=' + item.id, '')
                                    .then((res) => {
                                      message.success({
                                        content: '重启成功',
                                      })
                                      list()
                                    })
                                    .catch(() => {})
                                },
                                onCancel() {},
                              })
                            }}
                          >
                            重启
                          </Button>
                        ) : (
                          ''
                        ),
                      ]}
                      style={{ backgroundColor: item.id === this.state.selectOrderComponentId ? '#e5f2ff' : '' }}
                      onClick={() => {
                        this.setState({
                          selectOrderComponentId: item.id,
                          selectOrderComponent: item,
                        })
                        let values = {}
                        values.orderComponentId = item.id
                        this.getComponentsCraft(values)
                      }}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            shape="square"
                            style={{
                              backgroundColor:
                                item.status === '暂停'
                                  ? ''
                                  : item.status === '已拆解'
                                  ? '#ff8000'
                                  : (item.status === '已投产' && item.actionCraftTypeName === '表面处理' && item.actionStatus === '待接单') || item.status === '待质检'
                                  ? '#9f009a'
                                  : item.status === '已投产'
                                  ? '#306ce5'
                                  : item.status === '已完成'
                                  ? '#38ad5f'
                                  : '待拆解',
                              verticalAlign: 'middle',
                            }}
                          >
                            {item.status == null ? '待拆解' : item.status}
                          </Avatar>
                        }
                        title={
                          <>
                            {item.components.componentName}[数量:x{item.componentsNum}]{item.orderLevel != null ? '[加急中]' : ''}
                             {item.projectName === undefined ||
                            item.projectName === null ||
                            item.projectName === ""
                              ? ""
                              : "-[" + item.projectName + "]"}
                          </>
                        }
                        description={
                          item.status == '暂停' ? (
                            <>
                              <> {item.components.componentCode} </>
                              <br></br>
                              <> 异常原因:{item.errMemo} </>
                            </>
                          ) : (
                            <>
                              <>{item.components.componentCode}</>
                            </>
                          )
                        }
                      />
                    </List.Item>
                  )}
                ></List>
              </ProCard>
              <ProCard title="工序" headerBordered>
                <List
                  itemLayout="vertical"
                  pagination={{
                    hideOnSinglePage: false,
                    // 分页
                    current: this.state.craftData.number + 1,
                    showSizeChanger: false,
                    pageSize: this.state.craftData.size == null ? 8 : this.state.craftData.size,
                    showQuickJumper: false,
                    showTotal: (total) => {
                      return `共 ${total} 条`
                    },
                    defaultPageSize: this.state.craftData.size,
                    total: this.state.craftData.totalElements,
                    onChange: (page) => {
                      let values = {}
                      values.page = page
                      values.pageSize = this.state.craftData.size
                      values.orderId = this.state.selectOrderId
                      values.orderComponentId = this.state.selectOrderComponentId
                      this.getComponentsCraft(values)
                    },
                  }}
                  dataSource={this.state.craftData.content}
                  renderItem={(item) => (
                    <List.Item
                      actions={['派单时间:' + item.assign_time, '完成时间:' + item.done_time]}
                      style={{ backgroundColor: item.id === this.state.selectCraftId ? '#e5f2ff' : '' }}
                      onClick={() => {
                        this.setState({
                          selectCraftId: item.id,
                        })
                      }}
                      extra={
                        <>
                          {item.transfer === '是' ? (
                            <Button
                              onClick={() => {
                                this.setState({ isModalVisible: true })
                                this.getWorkOrderTransferDetail(item.groupid)
                              }}
                            >
                              查看交班详情
                            </Button>
                          ) : (
                            ''
                          )}
                          {this.state.selectOrderComponent.status === '已完成' && item.wo_status == null ? (
                            <Button
                              onClick={() => {
                                let list = this.getComponentsCraft
                                let values = {}
                                values.orderComponentId = this.state.selectOrderComponentId
                                values.componentCraftId = item.id
                                confirm({
                                  title: '确认要生成工单?',
                                  icon: <ExclamationCircleOutlined />,
                                  okText: '确认',
                                  okType: 'danger',
                                  cancelText: '取消',
                                  onOk() {
                                    http
                                      .post('/admin/workOrder/startWorkOrder', values)
                                      .then((res) => {
                                        console.log(res)
                                        message.success({
                                          content: '生成成功',
                                        })
                                        list(values)
                                      })
                                      .catch((err) => {
                                        console.log(err)
                                        message.error({
                                          content: '数据加载失败请联系系统管理员',
                                        })
                                      })
                                  },
                                  onCancel() {},
                                })
                              }}
                            >
                              生成工单
                            </Button>
                          ) : (
                            ''
                          )}
                        </>
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            shape="square"
                            style={{
                              backgroundColor: item.wo_status === '待接单' ? '#ff8000' : item.wo_status === '已接单' ? '#306ce5' : item.wo_status === '完成' ? '#38ad5f' : '',
                              verticalAlign: 'middle',
                            }}
                          >
                            {item.wo_status == null ? '未生成' : item.wo_status}
                          </Avatar>
                        }
                        title={
                          <>
                            {item.craft_type_name}-{item.craft_name} [{item.accept_user_name}]:{item.ac_type}
                            <br />
                            {item.memo}
                          </>
                        }
                        description={
                          item.param_values_json == null ? (
                            ''
                          ) : (
                            <>
                              {JSON.parse(item.param_values_json).map((item, index) => (
                                <>{item.type !== '附件' ? item.value : item.value.name}*</>
                              ))}
                              <br></br>
                              {JSON.parse(item.param_values_json).map((item, index) => (
                                <>{item.name}*</>
                              ))}
                            </>
                          )
                        }
                      />
                    </List.Item>
                  )}
                ></List>
              </ProCard>
             <OrderCompile ref={this.orderCompile} table={this}></OrderCompile>
            </ProCard>
          </ProCard.TabPane>
        ))}
      </ProCard>
    )
  }
}

export default Collect
