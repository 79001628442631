import ProDescriptions from '@ant-design/pro-descriptions'
import { Button, DatePicker, Form, InputNumber, message, Modal } from 'antd'
import moment from 'moment'
import React, { Component } from 'react'
import ReactToPrint from 'react-to-print'
import '../../assets/css/OrderList.css'
import http from '../api/http'
class QutationList extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      orderComponts: [],
      order: [],
      freeItemCount: 0,
      freeItemList: [],
      orderPrice: 0,
      foreignStatus:'',
      componentsPrice:[]
    }
  }

  toOffer = (order, orderComponts,orderId,foreignStatus) => {
    let values = {}
    values.orderId = orderId
    //拿出收费项
    http
      .post('/admin/freeItem/getComponentList', values)
      .then((res) => {
        console.log(res)
        let componentsPrice = this.state.componentsPrice
        for (let index = 0; index < res.data.length; index++) {
          // if (orderComponts[index].status === '已拆解') {
            componentsPrice[index] = {
              componentId: res.data[index].id,
              price: res.data[index].cost * (res.data[index].modulus === undefined ? 1 : res.data[index].modulus),
              modulus: res.data[index].modulus === undefined ? 1 : res.data[index].modulus,
            }
          // }
        }
        this.setState({
          componentsPrice,
        })
        let freeItemForm = {}
        this.countPrice()
        this.form1.current.setFieldsValue(freeItemForm)
        this.setState({
          order,
          orderComponts:res.data,
          visible: true,
          foreignStatus
        })
      })
      .catch(() => {
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  saveToAudit = () => {
    let values = {}
    values.orderId = this.state.order.id
    values.orderFreeItemsJson = JSON.stringify(this.state.freeItemList)
    http
      .post('/admin/order/orderOffConfirm', values)
      .then((res) => {
        message.success({
          content: '更新数据完成',
        })
        this.setState({ visible: false })
      })
      .catch(() => {
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  confirmQuotation = () => {
    let values = {}
    values.componentsPrice = JSON.stringify(this.state.componentsPrice)
    values.orderId = this.state.order.id
    for (let index = 0; index < this.state.componentsPrice.length; index++) {
    if(this.state.componentsPrice[index].commitmentTime == null){
      message.error({
        content: '有物料未选择交期',
      })
      return;
    }
    }
    http.post('/admin/order/confirmQuotation', values).then((res) => {
      message.success({
        content: '更新数据完成',
      })
      this.setState({ visible: false })
    })
  }

  toStock = (orderComponent, index) => {
    let values = {}
    values.id = orderComponent.id
    http.post('/admin/components/stockOrderComponent', values).then((res) => {
      let orderComponents = this.state.orderComponts
      orderComponents[index].foreignStatus = '缺货'
      this.setState({
        orderComponents,
      })
    })
  }

  onClose = () => {
    this.setState({ visible: false })
  }
  //计划价格
  countPrice = () => {
    let orderPrice = 0
    let freeItemCount = 0.0
    let freeItemList = this.state.freeItemList
    freeItemList.forEach((freeItem) => {
      let free = parseFloat(freeItem.freeValue)
      if (freeItem.freeItemType === '百分比') {
        free = parseFloat((this.state.order.evalCost * freeItem.freeValue) / 100).toFixed(2)
      }
      freeItemCount = parseFloat(freeItemCount) + parseFloat(free)
      orderPrice = this.state.order.evalCost + freeItemCount
    })
    this.setState({
      orderPrice,
      freeItemCount,
    })
  }

  render() {
    function range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }

    function disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day')
    }

    function disabledDateTime() {
      return {
        disabledHours: () => range(0, 24).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
      }
    }

    return (
      // <Panl></Panl>
      <Modal
        forceRender={true}
        title={this.state.title}
        width={1400}
        onClose={this.onClose}
        onCancel={this.onClose}
        visible={this.state.visible}
        bodyStyle={{ overflowX: 'auto', minHeight: '480px' }}
        footer={
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            {/* {this.state.foreignStatus === '已报价' ? '' : <> */}
            <Button onClick={this.confirmQuotation} type="primary">
              确认报价
            </Button>
            {/* </>} */}
          </div>
        }
      >
        <Form ref={this.form1} onFinish={this.submit}>
          <Form.List name="freeItemList">{(fields, { add, remove }) => <></>}</Form.List>
        </Form>
        <ReactToPrint
          trigger={() => (
            <a>
              <Button type="primary">打印</Button>
            </a>
          )}
          content={() => this.componentRef}
        />
        系数:
        <InputNumber
          value={this.state.defMultiple == null ? 1 : this.state.defMultiple}
          onChange={(e) => {
            if (e > 0) {
              let componentsPrice = this.state.componentsPrice
              for (let index = 0; index < componentsPrice.length; index++) {
                componentsPrice[index].modulus = e
              }

              this.setState({ componentsPrice, defMultiple: e })
            } else {
              let componentsPrice = this.state.componentsPrice
              for (let index = 0; index < componentsPrice.length; index++) {
                componentsPrice[index].modulus = 1
              }
              this.setState({ componentsPrice, defMultiple: e })
            }
          }}
        />
        统一承诺交期
        <DatePicker
                      disabledDate={disabledDate}
                      disabledTime={disabledDateTime}
                      onChange={(date, dateString) => {
                        let componentsPrice = this.state.componentsPrice
                        for (let index = 0; index < componentsPrice.length; index++) {
                          componentsPrice[index].commitmentTime = dateString
                        }
                        this.setState({componentsPrice})
                      }}
                    />
        <table
          className="order_printing"
          ref={(el) => (this.componentRef = el)}
          width="1480px"
          border="1px"
          style={{
            boxSizing: 'border-box',
            textAlign: 'center',
            borderColor: '#999',
            margin: '0 auto',
            boxShadow: '0 2px 5px #ccc',
          }}
        >
          <colgroup>
            <col width="200" />
            <col width="120" />
            <col width="120" />
            <col width="100" />
            <col width="100" />
            <col width="110" />
            <col width="110" />
            <col width="120" />
            <col width="120" />
            <col width="100" />
            <col width="100" />
            <col width="100" />
            <col width="100" />
            <col width="150" />
            <col width="170" />
            <col width="100" />
          </colgroup>
          <tbody>
            <tr class="firstRow">
              <td
                colspan="16"
                style={{
                  fontSize: '16px',
                  lineHeight: '50px',
                  textAlign: 'left',
                }}
              >
                <ProDescriptions column={5}>
                  <ProDescriptions.Item label="订单编号" valueType="text">
                    {this.state.order.orderCode}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="erp编号" valueType="text">
                    {this.state.order.extraOrderCode}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="客户名称" valueType="text">
                    {this.state.order.client == null ? '' : this.state.order.client.name}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="订单名称" valueType="text">
                    {this.state.order.orderName}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="下单时间" valueType="text">
                    {this.state.order.createTime}
                  </ProDescriptions.Item>
                </ProDescriptions>
              </td>
            </tr>
            <tr class="firstRow">
              <th>零件名称</th>
              <th>图号</th>
              <th>数量</th>
              <th>加工方式</th>
              <th>胚料重量</th>
              <th>材料费/件</th>
              <th>生产费/件</th>
              <th>工时/件</th>
              <th>单件成本</th>
              <th>报价系数</th>
              <th>单件报价</th>
              <th>合计报价</th>
              <th>报价状态</th>
              <th>期望交期</th>
              <th>承诺交期</th>
              <th>操作</th>
            </tr>
            {this.state.orderComponts.map((item, i) => (
              <>
                <tr>
                  <td style={{ fontSize: '13px' }}>{item.components.componentName}</td>
                  <td style={{ fontSize: '13px' }}>{item.components.componentCode}</td>
                  <td style={{ fontSize: '13px' }}>{item.componentsNum}</td>
                  <td style={{ fontSize: '13px' }}>{item.processingType !== undefined && item.processingType !== '' ? item.processingType : '自制'}</td>
                  <td style={{ fontSize: '13px' }}>{item.materialWeight == null ? '-' : item.materialWeight.toFixed(2)}</td>
                  <td style={{ fontSize: '13px' }}>{item.materialUnitPrice == null ? '-' : item.materialUnitPrice.toFixed(2)}元</td>
                  <td style={{ fontSize: '13px' }}>{item.components.workerPrice == null ? '-' : item.components.workerPrice.toFixed(2)}元</td>
                  <td style={{ fontSize: '13px' }}>
                    {item.components.workTime == null ? '-' : parseFloat(item.components.workTime * item.componentsNum).toFixed(2)}
                    分钟
                  </td>
                  <td style={{ fontSize: '13px' }}>{parseFloat(item.cost).toFixed(2)}</td>
                  {/* 系数 */}
                  <td style={{ fontSize: '13px' }}>
                    <InputNumber
                      value={this.state.componentsPrice[i] === undefined ? 1 : this.state.componentsPrice[i].modulus}
                      min={1}
                      onChange={(e) => {
                        if (e > 0) {
                          let componentsPrice = this.state.componentsPrice
                          componentsPrice[i].modulus = e

                          this.setState({ componentsPrice })
                        } else {
                          let componentsPrice = this.state.componentsPrice
                          componentsPrice[i].modulus = 1
                          this.setState({ componentsPrice })
                        }
                      }}
                    />
                  </td>
                  {/* 单价 */}
                  <td style={{ fontSize: '13px' }}>
                    <p>{(parseFloat(item.cost) * this.state.componentsPrice[i].modulus).toFixed(2)}</p>
                  </td>
                  {/* 合计 */}
                  <td style={{ fontSize: '13px' }}>
                    <p>{(parseFloat(item.cost) * this.state.componentsPrice[i].modulus * item.componentsNum).toFixed(2)}</p>
                  </td>
                  <td style={{ fontSize: '13px' }}>{item.foreignStatus == null ? '待报价' : item.foreignStatus}</td>
                  <td style={{ fontSize: '13px' }}>{item.planTime == null ? '' : moment(item.planTime).format('YYYY-MM-DD')}</td>
                  <td style={{ fontSize: '13px' }}>
                    {' '}
                    {item.commitmentTime != null ? moment(item.commitmentTime).format('YYYY-MM-DD') : <DatePicker
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                    onChange={(date, dateString) => {
                      let componentsPrice = this.state.componentsPrice
                      componentsPrice[i].commitmentTime = dateString
                    }}
                  /> }
                    
                  </td>
                  <td style={{ fontSize: '13px' }}>
                    <Button
                      onClick={() => {
                        this.toStock(item, i)
                      }}
                    >
                      缺货
                    </Button>
                  </td>
                </tr>
              </>
            ))}
            {/* <tr>
              <td colspan="14" style={{ fontSize: '16px', lineHeight: '50px', textAlign: 'left' }}>
                <ProDescriptions title={'成本汇总:' + parseFloat(this.state.order.evalCost).toFixed(2) + '元'}>
                  <Descriptions.Item label="物料成本">{parseFloat(this.state.order.materialCost).toFixed(2)}元</Descriptions.Item>
                  <Descriptions.Item label="人工成本">{parseFloat(this.state.order.workerCost).toFixed(2)}元</Descriptions.Item>
                </ProDescriptions>
              </td>
            </tr> */}

            {/* <tr>
              <td colspan="14" style={{ fontSize: '16px', lineHeight: '50px', textAlign: 'left' }}>
                <ProDescriptions title={'价目项汇总:' + this.state.freeItemCount} column={8}>
                  {this.state.freeItemList.map((item) => (
                    <Descriptions.Item label={item.freeItemType === '百分比' ? item.freeItemName + item.freeValue + '%' : item.freeItemName}>
                      {item.freeItemType === '百分比' ? parseFloat((this.state.order.evalCost * item.freeValue) / 100).toFixed(2) : item.freeValue}元
                    </Descriptions.Item>
                  ))}
                </ProDescriptions>
              </td>
            </tr>
            <tr>
              <th colspan="14" style={{ fontSize: '16px', lineHeight: '50px', textAlign: 'left' }}>
                总价： {parseFloat(this.state.orderPrice).toFixed(2)}
              </th>
            </tr> */}
          </tbody>
        </table>
      </Modal>
    )
  }
}

export default QutationList
