//使用抽屉进行编辑
import React, { Component } from 'react'
import { Drawer, Row, Form, Button, Input, Upload, Modal, Radio, Col, DatePicker, message } from 'antd'

import { isEmptyObject } from '../api/http'
import { UploadOutlined } from '@ant-design/icons'
import http from '../api/http'
const normFile = (e) => {
  console.log('Upload event:', e)
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}
class OrgForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      title: '新增模块',
      selectedIcon: null,
      isAdd: true,
      pid: 'root',
    }
  }

  toAdd(inEntity) {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        pid: inEntity.id,
        isAdd: true,
        selectItem: null,
        iconChooseVisible: false,
        title: '在[' + inEntity.name + ']下新增机构',
      })
      if (this.form1.current != null) {
        this.form1.current.resetFields()
      }
    } else {
      Modal.error({
        title: '请先选中要在哪个菜单下添加功能',
        content: '或双击记录可以直接编辑',
      })
    }
  }
  toEdit = (inEntity) => {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        isAdd: false,
        selectItem: inEntity,
        title: '修改机构',
      })
      this.form1.current.resetFields()
      this.form1.current.setFieldsValue(inEntity)
    } else {
      Modal.error({
        title: '请先选中记录',
        content: '或双击记录可以直接编辑',
      })
    }
  }
  checkForm = () => {
    this.form1.current.submit()
  }
  submit = (values) => {
    let url = ''
    if (this.state.selectItem != null) {
      url = '/admin/org/update'

      values.id = this.state.selectItem.id
    } else {
      values.pid = this.state.pid
      url = '/admin/org/add'
    }
    //提交
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.setState({
          selectItem: null,
        })
        this.props.getList()
      })
      .catch(() => {
        console.log('失败')
        this.props.table.offLoading()
      })

    this.setState({
      visible: false,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  beforePhotoUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('只能上传JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 5
    if (!isLt2M) {
      message.error('上传的单图不能超过 5MB!')
    }
    return isJpgOrPng && isLt2M
  }
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={480}
          onClose={this.onClose}
          onCancel={this.onClose}
          visible={this.state.visible}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          {' '}
          <Form layout="vertical" ref={this.form1} onFinish={this.submit}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="name" label="机构名称" rules={[{ required: true, message: '机构名称不能为空' }]}>
                  <Input placeholder="请输入机构名称" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="alias" label="别名" tooltip="登录界面自动替换">
                  <Input placeholder="机构名称" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="companyType" label="机构类型:" initialValue="分公司" tooltip="用于区分，可根据不同部门筛选对应的应用场景，可空">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="分公司">分公司</Radio.Button>
                    <Radio.Button value="技术部门">技术部门</Radio.Button>
                    <Radio.Button value="外包部门">外包部门</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="handerUserName" label="负责人姓名:">
                  <Input placeholder="负责人姓名" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="handerUserMobile" label="负责人电话:">
                  <Input placeholder="负责人电话" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="addr" label="地址:">
                  <Input placeholder="地址" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="joinTime" label="加入时间:">
                  <DatePicker></DatePicker>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="endTime" label="到期时间:">
                  <DatePicker></DatePicker>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="logoImg" label="企业logo:" valuePropName="fileList" getValueFromEvent={normFile}>
                  <Upload beforeUpload={this.beforeUpload} action="/upload.do" listType="picture">
                    <Button icon={<UploadOutlined />}>上传企业logo</Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="licenseImgFile" label="企业营业执照:" valuePropName="fileList" getValueFromEvent={normFile}>
                  <Upload action="/upload.do" beforeUpload={this.beforeUpload} listType="picture">
                    <Button icon={<UploadOutlined />}>上传营业执照</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="status" label="状态:" tooltip="企业停用后,所有子帐号都会失效！" initialValue="启用">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="启用">启用</Radio.Button>
                    <Radio.Button value="停用">停用</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item name="valuationType" label="工艺类型选择:" initialValue="工艺路线">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="工艺路线">工艺路线</Radio.Button>
                    <Radio.Button value="计时">计时</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
              <Form.Item name="isShowPrice" label="是否显示工价:" initialValue="是">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item name="qualityProcess" label="是否需要巡检:" initialValue="否">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
              <Form.Item name="isForeignOrder" label="是否支持接外单:" initialValue="否">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item name="showWorkOrder" label="待接工单显示全部:" initialValue="否">
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="是">是</Radio.Button>
                    <Radio.Button value="否">否</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}

OrgForm.propTypes = {}

export default OrgForm
