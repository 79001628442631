import React, { Component } from 'react'
import ProCard from '@ant-design/pro-card'
import logo from '../../assets/images/fullscreen/logo.png'
class WorkOrderScreen extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {}
  }

  render() {
    return <ProCard title={<img width="100%" src={logo}></img>} extra="2019年9月28日" split="vertical" bordered headerBordered></ProCard>
  }
}

export default WorkOrderScreen
