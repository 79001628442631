import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Drawer, Form, Input, Select, InputNumber, message, Modal, Radio, Row, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { Component } from 'react'
import http from '../api/http'
let _clientHeight = document.documentElement.clientHeight - 66
export default class MaterialForm extends Component {
  constructor(props) {
    super(props)
    this.typeForm = React.createRef()
    this.form = React.createRef()
    this.state = {
      selectMaterial: null,
      materialType: null,
      visible: false,
    }
  }
  showMaterialForm = (materialType, material) => {
    //新增
    this.form.current.resetFields()
    if (material != null) {
      this.form.current.setFieldsValue(material)
    }
    this.setState({
      materialType,
      selectMaterial: material,
      visible: true,
    })
  }

  submitForm = (values) => {
    console.log(values)
    let materialParamsList = values.materialParamsList
    let materialParamJson = ''
    // values.typeId = this.state.materialType.id
    if (materialParamsList.length > 0) {
      materialParamJson = JSON.stringify(materialParamsList)
      values.materialParamJson = materialParamJson
    }
    values.materialParamsList = null
    let url = '/admin/material/addMaterial'
    if (this.state.selectMaterial != null) {
      url = '/admin/material/updateMaterial'
      values.id = this.state.selectMaterial.id
    }
    http
      .post(url, values)
      .then((res) => {
        this.props.parent.getMaterialList(this.state.materialType.id)
        this.setState({ visible: false })
        message.success({
          content: '保存成功',
        })
      })
      .catch(() => {})
  }
  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  handleChange = (event) => {
    console.log('选择的字段类型:', event);
  }
  render() {
    return (
      <Drawer
        push={{ distance: 0 }}
        closable={false}
        forceRender={true}
        title={this.state.title}
        visible={this.state.visible}
        onClose={this.onClose}
        placement="right"
        width={800}
        maskClosable={false}
        height={_clientHeight}
        onCancel={this.onClose}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              onClick={() => {
                this.onClose()
              }}
              style={{ marginRight: 8 }}
            >
              取消
            </Button>
            {this.state.current === 0 ? (
              <Button
                onClick={() => {
                  if (this.state.selectMaterial == null) {
                    message.error({
                      content: '请先选择原料',
                    })
                  } else {
                    this.changMaterialSelecter(this.state.selectMaterial)
                  }
                }}
                type="primary"
              >
                设置参数
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  this.form.current.submit()
                }}
              >
                确认工艺
              </Button>
            )}
          </div>
        }
      >
        <Form layout="vertical" autoComplete={false} onFinish={this.submitForm} ref={this.form}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="name" label="原料名称：" rules={[{ required: true, message: '原类名称不能为空' }]}>
                <Input placeholder="原料名称：" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="unitPrice" label="原料单价：(元)" rules={[{ required: true, message: '单价不能为空' }]}>
                <InputNumber style={{ width: '100%' }} placeholder="单价：" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="unit" label="原料单位：" rules={[{ required: true, message: '计算单位不能为空' }]}>
                <Input placeholder="原料单位"></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="参数配置">
                <Form.List name="materialParamsList">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Space
                          key={field.key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item {...field} name={[field.name, 'id']} fieldKey={[field.fieldKey, 'id']}>
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item {...field} name={[field.name, 'name']} fieldKey={[field.fieldKey, 'name']} rules={[{ required: true, message: '请输入参数名' }]}>
                            <Input placeholder="参数名" />
                          </Form.Item>
                          <Form.Item {...field} name={[field.name, 'unit']} fieldKey={[field.id, 'unit']} rules={[{ required: true, message: '请输入参数单位' }]}>
                            <Input placeholder="参数单位" />
                          </Form.Item>
                          <Form.Item {...field} name={[field.name, 'formFieldType']} fieldKey={[field.id, 'formFieldType']} tooltip="" initialValue="数字">
                            <Select onChange={()=>{this.handleChange()}}>
                              <Select.Option value="数字">数字</Select.Option>
                              <Select.Option value="文本">文本</Select.Option>
                            </Select>
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={(e) => {
                              remove(field.name)
                            }}
                          />
                        </Space>
                      ))}
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        新增参数
                      </Button>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="materialCostsFunc" label="原料重量计算公式">
                <TextArea rows={5} placeholder="原料重量计算公式" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    )
  }
}
