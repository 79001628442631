import React, { Component } from "react";
import {
    PageHeader,
    Badge
} from 'antd';

import logo from '../../assets/images/img/logo_grey.png';
import operation1 from '../../assets/images/img/login_menu_t1.png';
import operation2 from '../../assets/images/img/login_menu_t2.png';
import operation3 from '../../assets/images/img/login_menu_t3.png';
import menu1 from '../../assets/images/img/login_menu1.png';
import menu2 from '../../assets/images/img/login_menu2.png';
import menu3 from '../../assets/images/img/login_menu3.png';
import menu4 from '../../assets/images/img/login_menu4.png';
import menu5 from '../../assets/images/img/login_menu5.png';
import './login_menu.css';

export default class login_menu extends Component {
  constructor(props) {
    super(props);
    
        this.state = {
            badge: 5,
            operation: [
                {url: '', img: operation1, name: '消息'},
                {url: '', img: operation2, name: '修改密码'},
                {url: '', img: operation3, name: '退出'}
            ],
            list: [
                {url: '', img: menu1, name: '技术工人'},
                {url: '', img: menu2, name: '管理者'},
                {url: '', img: menu3, name: '技术组长'},
                {url: '', img: menu4, name: '系统管理员'},
                {url: '', img: menu5, name: '财 务'}
            ]
        };
    
  }

  render() {

    return(
        <div className="login_menu">
            
            <PageHeader
                className="site-page-header"
                avatar={{src:logo}}
                extra={[
                    
                    <dl className="operation">
                        <dd>
                            <div>
                                <Badge count={this.state.badge}>
                                    <img src={this.state.operation[0].img} />
                                </Badge>
                                <p className="name">{this.state.operation[0].name}</p>
                            </div>
                        </dd>
                        <dd>
                            <div>
                                <img src={this.state.operation[1].img} />
                                <p className="name">{this.state.operation[1].name}</p>
                            </div>
                        </dd>
                        <dd>
                            <div>
                                <img src={this.state.operation[2].img} />
                                <p className="name">{this.state.operation[2].name}</p>
                            </div>
                        </dd>
                    </dl>

                ]}
            />
            
            <div className="menu">
                
                <p className="title">艾乐博后台管理系统</p>
                <ul>
                    {this.state.list.map((item,index) => {
                        return (
                            <li key={index}>
                                <a>
                                    <img src={item.img} />
                                    <p>{item.name}</p>
                                </a>
                            </li>
                        )
                    })}
                </ul>
                
            </div>

        </div>
    )
  }
}