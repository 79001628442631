import React, { Component } from 'react'

export default class Panel extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.state = {}
  }
  render() {
    return (
      <div style={this.props.style}>
        <div style={{ display: 'flex' }}>
          <div style={{ float: 'left', flex: '1' }}> {this.props.title}</div>
          <div style={{ float: 'right' }}> {this.props.extra}</div>
        </div>
      </div>
    )
  }
}
