import { Button, Col, Form, Input, InputNumber, Modal, Radio, Row } from 'antd'
import React, { Component } from 'react'
import http from '../api/http'

class FreeItemForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      title: '新增客户',
      selectItem: null,
      areaOptions: [
        {
          value: '广东省',
          label: '广东省',
          children: [
            {
              value: '佛山市',
              label: '佛山市',
              children: [
                {
                  value: '南海区',
                  label: '南海区',
                },
              ],
            },
          ],
        },
      ],
    }
  }
  toAdd() {
    this.setState({
      visible: true,
      selectItem: null,
      title: '新增客户',
    })
    if (this.form1.current != null) {
      this.form1.current.resetFields()
    }
  }
  toEdit = (inEntity) => {
    this.setState({
      visible: true,
      selectItem: inEntity,
      title: '修改客户信息',
    })
    this.form1.current.resetFields()
    this.form1.current.setFieldsValue(inEntity)
  }

  checkForm = () => {
    this.form1.current.submit()
  }
  submit = (values) => {
    let url = '/admin/freeItem/add'
    if (this.state.selectItem != null) {
      url = '/admin/freeItem/update'
      values.id = this.state.selectItem.id
    }
    //提交
    this.props.table.onLoading()
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.table.offLoading()
        this.props.table.getList()
      })
      .catch(() => {
        this.props.table.offLoading()
      })

    this.setState({
      visible: false,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={800}
          onCancel={this.onClose}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="horizontal" autoComplete="off" style={{ marginTop: 10 }} ref={this.form1} labelCol={{ span: 5 }} onFinish={this.submit}>
            <Form.Item name="groupName" label="收费分组项">
              <Input />
            </Form.Item>

            <Form.Item
              name="freeItemName"
              label="收费项名称"
              rules={[
                {
                  required: true,
                  message: '收费项名称不能为空',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="freeItemType" label="数值类型:" initialValue="百分比">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="百分比">百分比</Radio.Button>
                <Radio.Button value="数值">数值</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="isRewrite" label="是否允许调整" initialValue="允许">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="允许">允许</Radio.Button>
                <Radio.Button value="不能">不能</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="freeValue" label="默认值">
              <InputNumber style={{ width: '90%' }} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default FreeItemForm
