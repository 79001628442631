import React, { Component } from "react";
import {
  Button,
  Modal,
  Pagination
} from "antd";
import http from "../api/http";
import FileSourceForm from "./FileSourceForm";
import "../../App.css";
import { ExclamationCircleOutlined,ReloadOutlined } from "@ant-design/icons";
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable'

const { confirm } = Modal;

class FileSource extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      sortedInfo: null,
      searchForm: null,
      selectItem: {},
      loading: false,
      filteredInfo: null,
      data: [],
      page: 1,
      pageSize: 15
    };
  }

  //加载完成
  componentDidMount() {
    this.getList();
  }

  onLoading() {
    this.setState({
      loading: true,
    });
  }
  offLoading() {
    this.setState({
      loading: false,
    });
  }
  getList = (values) => {
    this.onLoading();
    if(values == null){
      values = {}
    }
    values.page = values.page
    values.pageSize = this.state.pageSize
    http
      .post("/admin/fileSource/pageQuery", values)
      .then((res) => {
        this.setState({
          data: res.data,
        });

        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };

  //新增
  toAdd = () => {
    this.form.current.toAdd();
  };

  //编辑
  toEdit = (record) => {
    this.form.current.toEdit(record);
  };

  //删除
  del = (record) => {
    let list = this.getList;
    confirm({
      title: "这是配置信息，确认删除这条记录?",
      icon: <ExclamationCircleOutlined />,
      content: "这如果有其他外关联信息使用就无法删除",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        http
          .post("/admin/fileSource/del?id=" + record.id, "")
          .then((res) => {
            Modal.success({
              content: "删除成功",
            });
            list();
          })
          .catch(() => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            });
          });
      },
      onCancel() {},
    });
  };


  apply = (record) => {
    let list = this.getList;
    confirm({
      title: "确认应用这个存储服务吗?",
      icon: <ExclamationCircleOutlined />,
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        http
          .post("/admin/fileSource/applResource?id=" + record.id, "")
          .then((res) => {
            Modal.success({
              content: "应用成功",
            });
            list();
          })
          .catch(() => {});
      },
      onCancel() {},
    });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    // 表头
    const columns = [
      {
        title: "方案名称",
        dataIndex: "alias",
        key: "alias",
        sorter: (a, b) => a.alias.length - b.alias.length,
        ellipsis: true,
        width: 150,
        // render: (text) => <a>{text}</a>, //重定义输出
      },

      {
        title: "存储方式",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type - b.type,
        ellipsis: true,
        width: 150,
      },
      {
        title: "访问路路径",
        dataIndex: "basePath",
        key: "basePath",
        sorter: (a, b) => a.basePath - b.basePath,
        ellipsis: true,
        width: 200,
      },
      {
        title: "根目录",
        dataIndex: "bucket",
        key: "bucket",
        sorter: (a, b) => a.bucket - b.bucket,
        ellipsis: true,
        width: 200,
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status - b.status,
        ellipsis: true,
        width: 100,
      },
      {
        title: "操作",
        key: "action",
        width: 200,
        render: (value, record) => {
          if (record.status === "应用中") {
            return (
              <div>
                <Button type="link" onClick={this.toEdit.bind(this, record)}>
                  编辑
                </Button>
                <Button type="link" onClick={this.del.bind(this, record)}>
                  删除
                </Button>
              </div>
            );
          } else {
            return (
              <div>
                <Button type="link" onClick={this.apply.bind(this, record)}>
                  应用
                </Button>
                <Button type="link" onClick={this.toEdit.bind(this, record)}>
                  编辑
                </Button>
                <Button type="link" onClick={this.del.bind(this, record)}>
                  删除
                </Button>
              </div>
            );
          }
        },
      },
    ];
    return (
      <>
        <Panel
          title="文件存储配置"
          fontWeight={true}
          bodyPadding="15"
          topRight={
            <Button key="new" onClick={this.toAdd}>
              新增
            </Button>
          }
          footerBar={
            <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              this.getList()
            }}
          >
            刷新
          </Button>
          }
          footerRight={
            <Pagination
            // 分页
            showTotal= {(total) => {
              return <>共 {total} 条</>
            }}
            showSizeChanger={ false}
            showQuickJumper={ true}
            defaultPageSize={ this.state.data.size}
            total={ this.state.data.totalElements}
            onChange={(page)=>{
              let values = {}
                values.page = page
                values.pageSize = this.state.pageSize
                this.getList(values)
            }} 
          onRow={(record) => ({
            onDoubleClick: () => {
              this.toEdit(record);
            },
          })}
          />
         }
        >
          <ResizeTable
            bordered
            size="middle"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={this.state.data.content}
            pagination={false}
          />
          <FileSourceForm ref={this.form} getList={this.getList} table={this} />
        </Panel>

        {/* <Spin spinning={this.state.loading} delay={500}>
          <PageHeader
            className="site-page-header"
            onBack={() => null}
            title="文件存储配置"
            subTitle="系统文件存储方式通用配置,提供给系统开发人员配置[双击编辑]"
            extra={[
              <Button key="new" onClick={this.toAdd}>
                新增
              </Button>,
            ]}
          />
          <Divider />

          <Row>
            <Col span={24} offset={1}></Col>
          </Row>

          <Divider dashed />
          <Table
            size="middle"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={this.state.data.content}
            pagination={{
              // 分页
              showTotal: (total) => {
                return `共 ${total} 条`;
              },
              showSizeChanger: false,
              showQuickJumper: true,
              defaultPageSize: this.state.data.size,
              total: this.state.data.totalElements,
              onChange: this.changePage,
            }}
            onRow={(record) => ({
              onDoubleClick: () => {
                this.toEdit(record);
              },
            })}
          />
          <FileSourceForm ref={this.form} getList={this.getList} table={this} />
        </Spin> */}
      </>
    );
  }
}
FileSource.propTypes = {};

export default FileSource;
