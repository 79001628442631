//使用抽屉进行编辑
import { Button, Form, Input, Modal, Radio } from 'antd'
import React, { Component } from 'react'
import http, { isEmptyObject } from '../api/http'
class DataDirForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      title: '新增模块',
      selectedIcon: null,
      isAdd: true,
      pid: 'root',
    }
  }
  toAdd(inEntity) {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        pid: inEntity.id,
        isAdd: true,
        selectItem: null,
        iconChooseVisible: false,
        title: '在[' + inEntity.name + ']下新增模块',
      })
      if (this.form1.current != null) {
        this.form1.current.resetFields()
      }
    } else {
      Modal.error({
        title: '请先选中要在哪个菜单下添加功能',
        content: '或双击记录可以直接编辑',
      })
    }
  }
  toEdit = (inEntity) => {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        isAdd: false,
        selectItem: inEntity,
        title: '修改模块',
      })
    }
    this.form1.current.resetFields()
    this.form1.current.setFieldsValue(inEntity)
  }
  checkForm = () => {
    this.form1.current.submit()
  }
  submit = (values) => {
    let url = ''
    if (this.state.selectItem != null) {
      url = '/admin/funcModule/update'

      values.id = this.state.selectItem.id
    } else {
      values.parentId = this.state.pid
      url = '/admin/funcModule/add'
    }
    //提交
    // this.props.table.onLoading()
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })

        // this.props.table.offLoading()
        this.props.getList()
      })
      .catch(() => {
        console.log('失败')
        // this.props.table.offLoading()
      })

    this.setState({
      visible: false,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  getFileFuncTypes() {
    let sarchParams = {}
    sarchParams.groupName = '功能模块类型'
    http
      .post('/admin/DataDir/getConfByGroupName', sarchParams)
      .then((res) => {
        let fucnModuleArray = []
        if (res.data.content != null) {
          res.data.content.forEach(function (sourceType) {
            let option = {}
            option.label = sourceType.displayValue
            option.value = sourceType.dirValue
            fucnModuleArray.push(option)
          })
        }
        this.setState({
          funcModuleType: fucnModuleArray,
        })
        console.log('类型', this.state.funcModuleType)
      })
      .catch(() => {
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  componentDidMount() {
    this.getFileFuncTypes()
  }
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  openIconChoose = () => {
    this.setState({ iconChooseVisible: true })
  }
  closeIconChoose = () => {
    // const { form } = this.props;
    // form.setFieldsValue({ icon: null });
    this.setState({ iconChooseVisible: false, selecteditem: null })
  }
  confirmIconChoose = () => {
    this.setState({ iconChooseVisible: false })
  }

  selectIcon = (icon) => {
    // const { form } = this.props;
    this.setState({ selectedIcon: icon })
    // form.setFieldsValue({
    //   icon: icon,
    // });
  }

  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={480}
          onClose={this.onClose}
          onCancel={this.onClose}
          visible={this.state.visible}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="vertical" ref={this.form1} onFinish={this.submit}>
            <Form.Item name="name" label="模块名称：" rules={[{ required: true, message: '模块名称不能为空' }]}>
              <Input placeholder="模块名称" />
            </Form.Item>
            <Form.Item name="moduleIcon" label="菜单图标：" initialValue="MenuOutlined" rules={[{ required: true, message: '菜单图标不能为空' }]}>
              <Input
                placeholder="菜单图标"
                // addonAfter={<SettingOutlined onClick={this.openIconChoose} />}
              />
            </Form.Item>
            <Form.Item name="funcType" label="类型：" initialValue="菜单" rules={[{ required: true, message: '请选择类型' }]}>
              <Radio.Group options={this.state.funcModuleType} optionType="button" buttonStyle="solid" />
            </Form.Item>
            <Form.Item name="urlOpenType" initialValue="router" label="连接打开方式：" rules={[{ required: true, message: '请选择打开方式' }]}>
              <Radio.Group optionType="button" buttonStyle="solid">
                <Radio.Button value="router">路由</Radio.Button>
                <Radio.Button value="url">url</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="url" label="模块路径：">
              <Input placeholder="模块路径" />
            </Form.Item>
            <Form.Item name="seq" label="顺序：">
              <Input placeholder="顺序[如1-1]"></Input>
            </Form.Item>
            <Form.Item name="isSuperFunc" initialValue="否" label="是否超级用户功能：">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="是">是</Radio.Button>
                <Radio.Button value="否">否</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="status" label="状态：" initialValue="启用">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="启用">启用</Radio.Button>
                <Radio.Button value="禁用">禁用</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="winType" label="展示方式：" initialValue="功能窗口">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="功能窗口">功能窗口</Radio.Button>
                <Radio.Button value="新全屏窗口">新全屏窗口</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

DataDirForm.propTypes = {}

export default DataDirForm
