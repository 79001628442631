//使用抽屉进行编辑
import React, { Component } from 'react'
import { Modal, Input,  Button, Form,  Row, Col,Select } from 'antd'
import { isEmptyObject } from '../../api/http'
import '@ant-design/pro-form/dist/form.css'
import http from '../../api/http'
const { Option } = Select;

class ExternalForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      title: '新增系统',
      selectItem: {},
      org : [],
      list:{}
    }
  }

  orgList(){
    let url = '/admin/externalSystem/orgList'
    http
      .post(url)
      .then((res) => {
        this.setState({org : res.data})
      })
      .catch(() => {
        console.log('失败')
      })
  }

  toAdd() {
    this.form1.current.resetFields()
    this.orgList();
    this.setState({
      visible: true,
      selectItem: null,
      title: '新增系统',
    })
  }
  
  toEdit = (inEntity) => {
    inEntity.orgId = inEntity.org.id
    console.log(inEntity);
    this.orgList();
    this.form1.current.resetFields()
    this.form1.current.setFieldsValue(inEntity)
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        title: '修改系统信息',
      })
    } else {
      Modal.error({
        title: '请先选中记录',
      })
    }
    this.setState({list:inEntity})
  }

  checkForm = () => {
    console.log(this.form1.current)
    this.form1.current.submit()
  }
  submit = (values) => {
    console.log(values);
     let url = '/admin/externalSystem/add'
    if (this.state.selectItem != null) {
      url = '/admin/externalSystem/update'
      values.id = this.state.selectItem.id
      
    }

    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        this.props.getList()
      })
      .catch(() => {
        console.log('失败')
      })

    this.setState({
      visible: false,
    }) 
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {}


  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={800}
          onCancel={this.onClose}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="horizontal" autoComplete="off" style={{ marginTop: 10 }} ref={this.form1} 
          labelCol={{ span: 6 }}
           onFinish={this.submit}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="系统名称"
                  rules={[
                    {
                      required: true,
                      message: '系统名称不能为空',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="externalUrl" label="接入地址"
                 rules={[
                    {
                      required: true,
                      message: '接入地址不能为空',
                    },
                  ]}
                  >
                    
                  <Input />
                </Form.Item>
              </Col>

            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="username" label="接入账号">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="password" label="接入密码">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
            <Col span={12}>
                <Form.Item name="orgId" label="所属公司"
                 rules={[
                    {
                      required: true,
                      message: '所属公司不能为空',
                    },
                  ]}>
                <Select
                    style={{
                    width: 273,
                    }}
                >
                    {
                      this.state.org.map((item,index)=>{
                      return <Option key={index} value={item.id}>{item.name}</Option>

                    })
                    }
                </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="dBid" label="账套ID"
                 rules={[
                    {
                      required: true,
                      message: '账套ID不能为空',
                    },
                  ]}>
                    <Input />
                </Form.Item>
              </Col>
             
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="iP" label="IP">
                  <Input />
                </Form.Item>
              </Col>

            </Row>

          </Form>
        </Modal>
      </div>
    )
  }
}

ExternalForm.propTypes = {}

export default ExternalForm
