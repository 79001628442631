import { DownCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, UpCircleOutlined } from '@ant-design/icons'
import { Avatar, Badge, Form, Button, Drawer, Dropdown, Empty, message, Modal, Space, Tabs, Timeline, Radio, Cascader } from 'antd'
import React, { Component } from 'react'
import { pdfjs } from 'react-pdf'
import SwiperCore, { Navigation, Zoom } from 'swiper'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/zoom/zoom.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import '../../assets/css/orderCompile.css'
import step_pic from '../../assets/images/img/order_list_step1.png'
import http from '../api/http'
import ComponentCraftPrint from './ComponentCraftPrint'
import OrderCompileTab from './OrderCompileTab'
import OrderCraft from './OrderCraft'
import OrderCraftEmployee from './OrderCraftEmployee'
import OrderMaterial from './OrderMaterial'
import Storage from '../../utils/Storage'
SwiperCore.use([Zoom, Navigation])
const { confirm } = Modal
const { TabPane } = Tabs
pdfjs.GlobalWorkerOptions.workerSrc = `//etooling.irobot-auto.com/pdf.worker.min.js`
let _clientHeight = document.documentElement.clientHeight
let _clientWidth = document.documentElement.clientWidth
class order_dismantling extends Component {
  constructor(props) {
    // tab切换
    super(props)
    this.newTabIndex = 0
    this.orderCompileTab = React.createRef()
    this.materialSelecter = React.createRef()
    this.orderCraftEmployee = React.createRef()
    this.showPdfWin = React.createRef()
    this.craftSelecter = React.createRef()
    this.componentCraftPrint = React.createRef()
    this.state = {
      panes: [],
      visible: false,
      order: {},
      isInsterToCraft: false,
      orderComponents: {},
      components: {},
      componentsCrafts: [],
      materialCostsDetail: [],
      selectMaterial: null,
      selectMaterialType: null,
      componentCraftDetail: null,
      lastComponentCraftDetail: null,
      selectCraftType: { name: '' },
      selectCraft: { name: '' },
      materialPrice: 0.0,
      workerPrice: 0.0,
      steps: [],
      priceConditionNum: 0,
      priceConditionName: '',
      hisComponents: [],
      workerTime: 0.0,
      selectStep: {},
      componentsEmployeeMap: {},
      selectComponentMaterial: null,
      processingType: ['自制'],
      inTabVersions: {},
      step: 0,
      pagination: {},
    }
  }
  copyHisVersionToNow = (hisOrderComponentId) => {
    let refresh = this.toCompile
    let values = {}
    let order = this.state.order
    let orderComponents = this.state.orderComponents
    values.hisOrderComponentId = hisOrderComponentId
    values.toOrderComponentId = this.state.orderComponents.id
    confirm({
      title: '你是否确认使用这历史工艺?',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/components/copyHisVersionToNow', values)
          .then((res) => {
            Modal.success({
              content: '修改成功',
            })
            refresh(order, orderComponents, true)
            this.setState({ activeKey: '当前版本' })
          })
          .catch(() => {})
      },
      onCancel() {},
    })
  }

  toCompile = (order, orderComponents, newOpen, pagination) => {
    console.log(pagination)
    let selectStep = this.state.selectStep
    if (order == null) {
      order = this.state.order
    }
    if (orderComponents == null) {
      orderComponents = this.state.orderComponents
    }
    this.setState({
      order: {},
      orderComponents: {},
      componentsCrafts: [],
      materialCostsDetail: [],
      selectMaterial: null,
      selectMaterialType: null,
      // componentCraftDetail: null,
      selectCraftType: { name: '' },
      selectCraft: { name: '' },
      materialPrice: 0.0,
      workerPrice: 0.0,
      conditionWorkerPrice: 0.0,
      steps: [],
      selectStep,
    })

    //当前是否新零件
    let materialStep = { id: '选料', type: '选料', name: '选料' }
    let steps = this.state.steps
    steps.splice(0, steps.length)

    steps.push(materialStep)
    http
      .post('/admin/components/toComponentCompile?orderComponentId=' + orderComponents.id, null)
      .then((res) => {
        let componentsCrafts = res.data.componentsCrafts
        let componentsEmployeeMap = res.data.componentsEmployeeMap
        let hisComponents = res.data.hisComponents
        let workerPrice = this.state.workerPrice
        let conditionWorkerPrice = this.state.conditionWorkerPrice
        let workerTime = 0.0
        let orderComponents = res.data.orderComponents
        let panes = this.state.panes
        let priceConditionNum = res.data.priceConditionNum
        let priceConditionName = res.data.priceConditionName

        let components = orderComponents.components
        let processingType = []
        processingType.push(orderComponents.processingType)
        this.setState({
          order: order,
          orderComponents,
          priceConditionNum,
          priceConditionName,
          components: components,
          visible: true,
          processingType,
          pagination,
        })
        //初始化物料
        let materialList = components.materials
        this.setState(materialList)

        if (componentsCrafts != null) {
          //初始化工步
          componentsCrafts.forEach((item) => {
            let componentCraftDetail = {}
            componentCraftDetail.selectCraft = item.craft
            componentCraftDetail.id = item.id
            componentCraftDetail.workerTime = item.workerTime
            componentCraftDetail.selectCraftType = item.craft.craftType
            componentCraftDetail.craftParam = item.craft.craftParamList
            componentCraftDetail.carftParamsValues = JSON.parse(item.paramValuesJson)
            componentCraftDetail.price = item.price
            componentCraftDetail.memo = item.memo
            componentCraftDetail.seq = item.seq
            workerPrice = workerPrice + item.price
            if (item.craft.craftType.isOutType === '是') {
              conditionWorkerPrice = conditionWorkerPrice + item.price
            } else {
              conditionWorkerPrice = conditionWorkerPrice + item.price * this.state.orderComponents.priceConditionNum
            }
            // alert(item.workerTime)
            workerTime = parseFloat(workerTime) + parseFloat(item.workerTime)

            //组织数据
            let lastCraftTypeId = steps[steps.length - 1].craftTypeId
            // console.log(lastCraftTypeId + ';' + componentCraftDetail.selectCraftType.id)
            if (lastCraftTypeId !== componentCraftDetail.selectCraftType.id) {
              let componentCrafts = []
              componentCrafts.push(componentCraftDetail)
              let step = {
                id: componentCraftDetail.id,
                seq: steps.length,
                craftTypeId: componentCraftDetail.selectCraftType.id,
                name: componentCraftDetail.selectCraftType.name,
                type: 'craft',
                componentCrafts,
              }
              steps.push(step)
            } else {
              let lastLastStep = steps[steps.length - 1]
              let componentCrafts = lastLastStep.componentCrafts
              componentCrafts.push(componentCraftDetail)
              lastLastStep.componentCrafts = componentCrafts
            }
          })
        }
        let lastLastStep = this.state.lastLastStep
        if (newOpen != null) {
          selectStep = {}
          panes = []
          lastLastStep = {}
        }
        console.log(hisComponents)
        this.setState({
          steps,
          workerTime,
          hisComponents,
          conditionWorkerPrice,
          panes,
          inTabVersions: {},
          activeKey: '当前版本',
          isInsterToCraft: false,
          orderComponents,
          materialList,
          componentsEmployeeMap,
          workerPrice,
          componentsCrafts: componentsCrafts,
          selectStep,
          lastLastStep,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  backOrderList = () => {
    this.props.table.toOrderDetail(this.state.order.id, null, null, this.state.pagination)
    this.setState({ visible: false })
  }

  componentDidMount() {}

  openSelectMaterial = () => {
    this.materialSelecter.current.toShow(this.state.orderComponents.id)
  }

  openSelecterCraftEmployee = (componentCraftDetail) => {
    this.orderCraftEmployee.current.toEdit(componentCraftDetail, this.state.order, this.state.orderComponents)
  }

  selectMaterial = (item, selectMaterialType) => {
    let components = this.state.components
    components.material = item
    //console.log(item)
    this.setState({
      selectMaterial: item,
      selectMaterialType: selectMaterialType,
    })
  }

  saveMaterialParamsValues = () => {
    let materialCostsDetail = this.state.materialCostsDetail
    let materialId = this.state.selectMaterial.id
    let componentId = this.state.components.id
    let materialPrice = materialCostsDetail.materialCosts
    let materialWeight = materialCostsDetail.materialWeight
    let paramValues = ''
    if (materialCostsDetail.materialParamsValues != null) {
      for (let index = 0; index < materialCostsDetail.materialParamsValues.length; index++) {
        paramValues = paramValues + materialCostsDetail.materialParamsValues[index] + '|'
      }
    }
    let values = {}
    values.materialId = materialId
    values.componentId = componentId
    values.materialPrice = materialPrice
    values.paramValues = paramValues
    values.materialWeight = materialWeight

    http
      .post('/admin/components/saveComponentMaterial', values)
      .then((res) => {
        message.success({
          content: '更新数据完成',
        })
        this.props.table.offLoading()
      })
      .catch(() => {
        //console.log('失败')
        this.props.table.offLoading()
      })
  }

  setMaterialCosts = (materialCostsDetail) => {
    console.log(materialCostsDetail)
    //保存到数据库
    let materialId = this.state.selectMaterial.id
    let componentId = this.state.components.id
    let materialPrice = materialCostsDetail.materialCosts
    let materialWeight = materialCostsDetail.materialWeight
    let paramValues = ''
    if (materialCostsDetail.materialParamsValues != null) {
      let a = 0
      for (let index = 0; index < materialCostsDetail.materialParamsValues.length; index++) {
        if (materialCostsDetail.dataParamsValues[index + a] !== undefined) {
          paramValues =
            paramValues +
            materialCostsDetail.materialParamsValues[index] +
            '(' +
            materialCostsDetail.dataParamsValues[index + a] +
            ')' +
            '(' +
            materialCostsDetail.dataParamsValues[a + index + 1] +
            ')' +
            '|'
        } else {
          paramValues = paramValues + materialCostsDetail.materialParamsValues[index] + '|'
        }
        a += 1
      }
    }
    if (this.state.selectMaterial !== null) {
      let material = this.state.selectMaterial
      let materialParams = this.state.selectMaterial.materialParams.split('|')
      material.materialParams = materialParams
      material.paramValues = paramValues
      material.materialTypeId = this.state.selectMaterial.materialType.id
      material.materialTypeName = this.state.selectMaterial.materialType.name
      materialCostsDetail = this.state.materialCostsDetail
      materialCostsDetail.push(material)
      materialCostsDetail.materialCosts = materialCostsDetail.materialCosts + materialPrice
      // materialPrice =  materialCostsDetail.materialCosts
    }
    console.log(materialCostsDetail)
    this.setState({ materialCostsDetail })
    let values = {}
    values.materialId = materialId
    values.componentId = componentId
    values.materialPrice = materialPrice
    values.paramValues = paramValues
    values.materialWeight = materialWeight
    http
      .post('/admin/components/saveComponentMaterial', values)
      .then((res) => {
        message.success({
          content: '更新数据完成',
        })
        this.props.table.offLoading()
        this.offLoading()
      })
      .catch(() => {
        this.props.table.offLoading()
      })
  }

  selectComponentCraftDetail = (componentCraftDetail) => {
    console.log(componentCraftDetail)
    let values = {}
    let paramValuesJson = JSON.stringify(componentCraftDetail.carftParamsValues)

    values.componentCraftId = componentCraftDetail.id
    values.paramValuesJson = paramValuesJson
    values.componentId = this.state.components.id
    values.craftId = componentCraftDetail.selectCraft.value
    let lastStepsNum = this.state.steps.length
    let lastCraftNum = 0
    if (lastStepsNum > 0) {
      // lastCraftNum = this.state.steps[this.state.steps.length - 1].componentCrafts != null ? this.state.steps[this.state.steps.length - 1].componentCrafts.length : 0
      lastCraftNum =
        this.state.steps[this.state.steps.length - 1].componentCrafts != null
          ? this.state.steps[this.state.steps.length - 1].componentCrafts[this.state.steps[this.state.steps.length - 1].componentCrafts.length - 1].seq
          : 0
    }
    console.log(this.state.steps)
    console.log(lastStepsNum, lastCraftNum)
    // if(lastCraftNum === 10 || lastCraftNum > 10){
    //   lastStepsNum = lastStepsNum + 1
    //   lastCraftNum = lastCraftNum - 10
    //   let step = this.state.step
    //   step += 1
    //   this.setState({step,numPlus:true})
    // }
    if (componentCraftDetail.seq === undefined || componentCraftDetail === null) {
      values.seq = lastStepsNum + lastCraftNum + 1
    } else {
      values.seq = componentCraftDetail.seq
    }
    values.memo = componentCraftDetail.memo
    http
      .post('/admin/components/getCraftUnitCostsAndSave', values)
      .then((res) => {
        //组装数据
        let componentCraft = res.data
        let workerPrice = this.state.workerPrice
        componentCraftDetail.id = componentCraft.id
        componentCraftDetail.price = componentCraft.price
        componentCraftDetail.workerTime = componentCraft.workerTime
        workerPrice = workerPrice + componentCraft.price

        message.success({
          content: '更新数据完成',
        })
        this.craftSelecter.current.onClose()
        this.setState({ componentCraftDetail })
        this.toCompile(this.state.order, this.state.orderComponents)
        this.offLoading()
      })

      .catch((err) => {
        console.log(err)
        // this.offLoading()
      })

    this.setState({})
  }

  UnitCostsAndSaveByTime = (componentCraftDetail) => {
    console.log(componentCraftDetail)
    let values = {}
    for (let index = 0; index < componentCraftDetail.carftParamsValues.length; index++) {
      for (let a = 0; a < componentCraftDetail.craftParam.length; a++) {
        if (componentCraftDetail.craftParam[a].defaults !== undefined && componentCraftDetail.craftParam[a].id === componentCraftDetail.carftParamsValues[index].id) {
          componentCraftDetail.carftParamsValues[index].value = componentCraftDetail.craftParam[a].defaults
        }
      }
    }
    let paramValuesJson = JSON.stringify(componentCraftDetail.carftParamsValues)
    values.componentCraftId = componentCraftDetail.id
    values.paramValuesJson = paramValuesJson
    values.componentId = this.state.components.id
    values.craftId = componentCraftDetail.selectCraft.value
    let lastStepsNum = this.state.steps.length
    let lastCraftNum = 0
    if (lastStepsNum > 0) {
      lastCraftNum = this.state.steps[this.state.steps.length - 1].componentCrafts != null ? this.state.steps[this.state.steps.length - 1].componentCrafts.length : 0
    }
    if (componentCraftDetail.seq === undefined || componentCraftDetail === null) {
      values.seq = lastStepsNum + 1 + '' + lastCraftNum
    } else {
      values.seq = componentCraftDetail.seq
    }
    values.memo = componentCraftDetail.memo
    // this.onLoading()
    http
      .post('/admin/components/UnitCostsAndSaveByTime', values)
      .then((res) => {
        //组装数据
        let componentCraft = res.data
        let workerPrice = this.state.workerPrice
        componentCraftDetail.id = componentCraft.id
        componentCraftDetail.price = componentCraft.price
        componentCraftDetail.workerTime = componentCraft.workerTime
        workerPrice = workerPrice + componentCraft.price

        message.success({
          content: '更新数据完成',
        })
        this.craftSelecter.current.onClose()
        this.setState({ componentCraftDetail })
        this.toCompile(this.state.order, this.state.orderComponents)
      })

      .catch((err) => {
        //console.log(err)
        // this.offLoading()
      })

    this.setState({})
  }
  delStep = () => {
    let refresh = this.toCompile
    console.log(this.state.selectStep)
    if (this.state.selectStep.type === '选料') {
      let order = this.state.order
      let orderComponents = this.state.orderComponents
      let orderComponentId = this.state.selectComponentMaterial.id
      confirm({
        title: '确认删除这物料吗?',
        icon: <ExclamationCircleOutlined />,
        content: '这如果有其他外关联信息使用就无法删除',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          http
            .post('/admin/components/delComponentMaterial?componentMaterialId=' + orderComponentId, '')
            .then((res) => {
              message.success({ content: '删除完成' })
              refresh(order, orderComponents)
            })
            .catch(() => {})
        },
        onCancel() {},
      })
    } else {
      let selectComponentCraftDetail = this.state.componentCraftDetail
      let order = this.state.order
      let orderComponents = this.state.orderComponents
      // console.log(selectComponentCraftDetail)
      if (this.state.selectStep.type !== '选料') {
        confirm({
          title: '确认删除这工步?',
          icon: <ExclamationCircleOutlined />,
          content: '这如果有其他外关联信息使用就无法删除',
          okText: '确认',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            http
              .post('/admin/components/delComponentCraft?componentCraftId=' + selectComponentCraftDetail.id, '')
              .then((res) => {
                message.success({ content: '删除完成' })
                refresh(order, orderComponents)
              })
              .catch(() => {})
          },
          onCancel() {},
        })
      }
    }
  }

  toPrint = () => {
    this.componentCraftPrint.current.toPrint(this.state.components, this.state.order, this.state.orderComponents.componentsNum, this.state.componentsCrafts)
  }
  updateHisMaterial = () => {
    http.post('/admin/components/updateHisMaterial').then((res) => {})
  }
  onClose = () => {
    this.setState({
      visible: false,
      componentCraftDetail: null,
    })
  }

  // tab切换
  onChange = (activeKey) => {
    this.setState({ activeKey })
  }
  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }

  remove = (targetKey) => {
    let inTabVersions = this.state.inTabVersions
    delete inTabVersions[targetKey]
    this.setState({ activeKey: '当前版本', inTabVersions })
  }

  render() {
    //const [page, setPage] = useState(1);
    const options = [
      {
        value: '自制',
        label: '自制',
      },
      {
        value: '委外',
        label: '委外',
        children: [
          {
            value: '供料加工',
            label: '供料加工',
          },
          {
            value: '包工包料',
            label: '包工包料',
          },
        ],
      },
    ]
    //信息通知
    return (
      <Drawer
        push={{ distance: 0 }}
        closable={false}
        forceRender={true}
        title={this.state.title}
        visible={this.state.visible}
        onClose={this.onClose}
        maskClosable={false}
        placement="bottom"
        height={_clientHeight}
        onCancel={this.onClose}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <>
            <div class="price">
              <dl>
                <dd>原料重量：{this.state.components.materialWeight == null ? '' : parseFloat(this.state.components.materialWeight).toFixed(2)}kg</dd>
                <dd>原料成本：{this.state.components.materialPrice == null ? '' : parseFloat(this.state.components.materialPrice).toFixed(2)}元</dd>
                <dd>人工成本：{parseFloat(this.state.workerPrice).toFixed(2)}</dd>
                <dd>阶梯人力成本：{parseFloat(this.state.conditionWorkerPrice).toFixed(2)}</dd>
                <dd>单件工时：{parseFloat(this.state.workerTime * 60).toFixed(2)}分钟 </dd>
              </dl>
            </div>
            <div class="price">
              <dl>
                <dd>
                  合计单件成本：
                  {this.state.materialCostsDetail == null || this.state.materialCostsDetail === ''
                    ? this.state.workerPrice
                    : this.state.materialCostsDetail.materialCosts === '' || this.state.materialCostsDetail.materialCosts == null
                    ? (this.state.workerPrice + this.state.components.materialPrice).toFixed(2)
                    : 123}
                  元
                </dd>
                <dd>
                  *{this.state.orderComponents.componentsNum}件=
                  {this.state.materialCostsDetail == null || this.state.materialCostsDetail === ''
                    ? ((parseFloat(this.state.components.materialPrice) + parseFloat(this.state.workerPrice)) * this.state.orderComponents.componentsNum).toFixed(2)
                    : this.state.materialCostsDetail.materialCosts === '' || this.state.materialCostsDetail.materialCosts == null
                    ? ((parseFloat(this.state.components.materialPrice) + parseFloat(this.state.workerPrice)) * this.state.orderComponents.componentsNum).toFixed(2)
                    : ((parseFloat(this.state.components.materialPrice) + parseFloat(this.state.workerPrice)) * this.state.orderComponents.componentsNum).toFixed(2)}
                  元
                </dd>
              </dl>
            </div>
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button
                onClick={() => {
                  this.onClose()
                  if (this.props.table.toOrderDetail != null) {
                    this.props.table.toOrderDetail(this.state.order.id, null, null, this.state.pagination)
                  }
                }}
                style={{ marginRight: 8 }}
              >
                取消
              </Button>
              {this.state.current === 0 ? (
                <Button
                  onClick={() => {
                    if (this.state.selectMaterial == null) {
                      message.error({
                        content: '请先选择原料',
                      })
                    } else {
                      this.changMaterialSelecter(this.state.selectMaterial)
                    }
                  }}
                  type="primary"
                >
                  设置参数
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    let values = {}
                    values.componentId = this.state.components.id
                    values.orderComponentId = this.state.orderComponents.id
                    values.processingType = this.state.processingType
                    console.log(values)
                    http
                      .post('/admin/components/saveComponentCraft', values)
                      .then((res) => {
                        message.success({
                          content: '更新数据完成',
                        })
                        this.setState({
                          visible: false,
                        })
                        this.props.table.toOrderDetail(this.state.order.id, null, null, this.state.pagination)
                      })
                      .catch((err) => {
                        message.error({
                          content: '更新数据失败',
                        })
                      })
                  }}
                  type="primary"
                >
                  确认工艺
                </Button>
              )}
            </div>
          </>
        }
      >
        {this.state.visible ? (
          <div className="order_dismantling">
            <div className="order_dismantling_con">
              <div className="title">
                <img src={step_pic} />
                <dl>
                  <dd>单号：{this.state.order.orderCode}</dd>
                  <dd>
                    零件名称：
                    {this.state.components.componentName + '[' + this.state.components.componentCode + ']'}
                  </dd>
                  <dd>客户名称：{this.state.order.client == null ? '' : this.state.order.client.name}</dd>
                  <dd>
                    <p>数量： </p>
                    <Avatar
                      style={{
                        display: 'block',
                        float: 'left',
                        backgroundColor: '#f56a00',
                        verticalAlign: 'middle',
                      }}
                      size="large"
                    >
                      x{this.state.orderComponents.componentsNum}
                    </Avatar>
                  </dd>
                  <dd>
                    {this.state.orderComponents.priceConditionName}:{parseFloat(this.state.orderComponents.priceConditionNum * 100).toFixed(0)}%
                  </dd>
                  <Cascader
                    options={options}
                    defaultValue={this.state.processingType}
                    onChange={(e) => {
                      let processingType = e[e.length - 1]
                      this.setState({ processingType })
                    }}
                    placeholder="请选择类型"
                  />
                </dl>
                <dl>
                  <Space>
                    <>
                      <Button>
                        <a onClick={this.toPrint}>浏览工艺卡</a>
                      </Button>
                    </>
                    {this.state.hisComponents != null && this.state.hisComponents.length > 0 ? (
                      <dd>
                        <Badge count={this.state.hisComponents.length}>
                          <Dropdown
                            overlay={
                              <div className="hisComponents">
                                <dl>
                                  {this.state.hisComponents.map((item, index) =>
                                    this.state.components.newInOrder === item.order_id ? (
                                      <Badge.Ribbon text="当前版本">
                                        <dd>
                                          <div class="div1">
                                            <p>
                                              {item.order_name}[{item.order_code}]
                                            </p>
                                            <span>{item.create_time}</span>
                                          </div>
                                        </dd>
                                      </Badge.Ribbon>
                                    ) : (
                                      <dd>
                                        <div
                                          class="div1"
                                          onClick={() => {
                                            // const { panes } = this.state
                                            // const activeKey = item.order_code
                                            // panes.push({ title: item.create_time, key: item.order_code, orderId: item.order_id, orderComponentsId: item.order_components_id })
                                            // this.setState({ panes, activeKey })
                                            let inTabVersions = this.state.inTabVersions
                                            if (inTabVersions[item.order_code] != null) {
                                              this.setState({ activeKey: item.order_code })
                                            } else {
                                              inTabVersions[item.order_code] = {
                                                title: item.create_time,
                                                key: item.order_code,
                                                orderId: item.order_id,
                                                orderComponentsId: item.order_components_id,
                                              }
                                              this.setState({ activeKey: item.order_code, inTabVersions })
                                            }
                                          }}
                                        >
                                          <p>
                                            {item.order_name}[{item.order_code}]
                                          </p>
                                          <span>{item.create_time}</span>
                                        </div>
                                      </dd>
                                    )
                                  )}
                                </dl>
                              </div>
                            }
                            trigger={['click']}
                            placement="bottomRight"
                          >
                            <Button onClick={(e) => e.preventDefault()}>历史工艺</Button>
                          </Dropdown>
                        </Badge>
                      </dd>
                    ) : (
                      ''
                    )}
                  </Space>
                </dl>
              </div>

              <Tabs size={'small'} hideAdd onChange={this.onChange} activeKey={this.state.activeKey} type="editable-card" onEdit={this.onEdit}>
                <TabPane tab={'当前版本'} key="当前版本" closable={false}>
                  <div className="con">
                    <div className="o">
                      <div className="banner">
                        <embed
                          align="middle"
                          allowscriptaccess="never"
                          allowfullscreen="true"
                          mode="transparent"
                          src={this.state.components.componetImg == null ? '' : this.state.components.componetImg.webPath}
                          style={{ minHeight: 650 }}
                          width={'100%'}
                        />
                      </div>
                    </div>
                    <div className="l">
                      <div className="title">
                        <p>工艺设置</p>
                      </div>
                      <div className="operation">
                        <Button icon={<PlusCircleOutlined />} className="add" onClick={this.openSelectMaterial}>
                          物料
                        </Button>
                        <Button
                          icon={<PlusCircleOutlined />}
                          className="add"
                          onClick={() => {
                            this.craftSelecter.current.toAdd(this.state.materialCostsDetail != null ? this.state.materialCostsDetail.materialWeight : 'null')
                            this.materialSelecter.current.onClose()
                            // this.showPdfWin.current.showWin(this.state.components.componetImg)
                          }}
                        >
                          工艺
                        </Button>
                        <Button
                          onClick={() => {
                            this.delStep()
                          }}
                          icon={<MinusCircleOutlined />}
                        >
                          删除
                        </Button>
                        <Button
                          disabled={this.state.selectStep == null || this.state.selectStep.type == null || this.state.selectStep.type === '选料'}
                          icon={<UpCircleOutlined />}
                          onClick={() => {
                            http
                              .post('/admin/components/craftUp?componentCraftId=' + this.state.componentCraftDetail.id, '')
                              .then((res) => {
                                this.toCompile(this.state.order, this.state.orderComponents)
                              })
                              .catch(() => {})
                          }}
                        >
                          上移
                        </Button>
                        <Button
                          icon={<DownCircleOutlined />}
                          onClick={() => {
                            http
                              .post('/admin/components/craftDown?componentCraftId=' + this.state.componentCraftDetail.id, '')
                              .then((res) => {
                                this.toCompile(this.state.order, this.state.orderComponents)
                              })
                              .catch(() => {})
                          }}
                          disabled={this.state.selectStep == null || this.state.selectStep.type == null || this.state.selectStep.type === '选料'}
                        >
                          下移
                        </Button>
                      </div>

                      <div className="step">
                        <Timeline mode="left">
                          {this.state.steps.map((item, index) => (
                            <ul>
                              <Timeline.Item
                                color={this.state.selectStep.id === item.id ? 'orange' : '#008fd7'}
                                label={
                                  <div>
                                    <p className="t">{index}0</p>
                                    <span
                                      className="d"
                                      onClick={() => {
                                        if (item.type === '选料') {
                                          this.setState({
                                            selectStep: item,
                                          })
                                        }
                                      }}
                                      style={{ backgroundColor: this.state.selectStep.id === item.id ? 'orange' : '#008fd7' }}
                                    >
                                      {item.name}
                                    </span>
                                  </div>
                                }
                              ></Timeline.Item>
                              <ul>
                                {item.type === '选料' && this.state.materialList != null
                                  ? this.state.materialList.map((cm, i) => (
                                      <Timeline.Item
                                        color={this.state.selectComponentMaterial != null && cm.id === this.state.selectComponentMaterial.id ? 'orange' : '#1890ff'}
                                        key={cm.id}
                                        onClick={() => {
                                          if (item.type === '选料') {
                                            this.setState({
                                              selectComponentMaterial: cm,
                                              selectStep: item,
                                            })
                                            this.materialSelecter.current.toShow(this.state.orderComponents.id, cm)
                                            this.craftSelecter.current.onClose()
                                          }
                                        }}
                                        style={{
                                          fontSize: this.state.selectStep.type === '选料' ? '14px' : '',
                                          fontWeight:
                                            this.state.selectComponentMaterial == null
                                              ? ''
                                              : cm.id === this.state.selectComponentMaterial.id && this.state.selectStep.type === '选料'
                                              ? 'bold'
                                              : '',
                                        }}
                                      >
                                        {cm.material.materialType.name}-{cm.material.name}-{cm.isFax}
                                      </Timeline.Item>
                                    ))
                                  : item.componentCrafts != null
                                  ? item.componentCrafts.map((craft, index) => (
                                      <Timeline.Item
                                        style={{
                                          fontSize: this.state.componentCraftDetail != null && craft.id === this.state.componentCraftDetail.id ? '14px' : '',
                                          fontWeight:
                                            this.state.componentCraftDetail != null && this.state.selectStep.type !== '选料' && craft.id === this.state.componentCraftDetail.id
                                              ? 'bold'
                                              : '',
                                        }}
                                        color={this.state.componentCraftDetail != null && craft.id === this.state.componentCraftDetail.id ? 'orange' : '#1890ff'}
                                        key={craft.id}
                                        onClick={() => {
                                          this.setState({
                                            componentCraftDetail: craft,
                                            selectStep: item,
                                            selectCraft: craft.selectCraft,
                                            selectCraftType: craft.selectCraftType,
                                          })
                                          this.craftSelecter.current.onClose()
                                          this.materialSelecter.current.onClose()
                                        }}
                                      >
                                        {craft.selectCraft.name}[{craft.selectCraft.craftType.isStartCraft === '是' ? '并' : '串'}]{craft.memo !== '' ? '-' + craft.memo : ''}
                                      </Timeline.Item>
                                    ))
                                  : ''}
                              </ul>
                            </ul>
                          ))}
                        </Timeline>
                      </div>
                    </div>
                    <div className="r">
                      <OrderCraft table={this} ref={this.craftSelecter}></OrderCraft>
                      <OrderMaterial table={this} ref={this.materialSelecter}></OrderMaterial>
                      {this.state.selectStep.type === '选料' ? (
                        <div className="title">
                          <p>
                            {this.state.selectMaterial === null || this.state.selectMaterialType == null
                              ? ''
                              : this.state.selectMaterialType.name + '-' + this.state.selectMaterial.name + '-参数设置'}
                          </p>
                        </div>
                      ) : (
                        <div className="title">
                          <p>
                            {this.state.selectCraftType === null || this.state.selectCraft == null
                              ? ''
                              : this.state.selectCraftType.name + '-' + this.state.selectCraft.name + '-参数设置'}
                          </p>
                        </div>
                      )}
                      <div className="step_parameter">
                        {/* 第一步 添加步骤按钮 */}
                        {this.state.selectStep.type === '选料' ? (
                          this.state.selectComponentMaterial == null ? (
                            <Empty
                              onClick={this.openSelectMaterial}
                              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                              imageStyle={{
                                height: 120,
                              }}
                              description={
                                <span>
                                  <a>点击添加原料</a>
                                </span>
                              }
                            ></Empty>
                          ) : (
                            ''
                          )
                        ) : (
                          <div className="step_parameter_con">
                            <ul>
                              {this.state.componentCraftDetail == null
                                ? ''
                                : this.state.componentCraftDetail == null
                                ? ''
                                : this.state.componentCraftDetail.craftParam.map((param, index) => (
                                    <li>
                                      <p> {param.name}</p>
                                      <div>
                                        {param.formFieldType ? (
                                          <input
                                            type="text"
                                            onChange={(e) => {
                                              if (param.name !== '零件重量') {
                                                let componentCraftDetail = this.state.componentCraftDetail
                                                let carftParamsValues = componentCraftDetail.carftParamsValues
                                                let newValue = carftParamsValues[index]
                                                newValue.value = e.target.value
                                                carftParamsValues[index] = newValue
                                                componentCraftDetail.carftParamsValues = carftParamsValues
                                                this.setState({
                                                  componentCraftDetail,
                                                })
                                              }
                                            }}
                                            value={this.state.componentCraftDetail.carftParamsValues[index].value}
                                          />
                                        ) : (
                                          <input
                                            type="number"
                                            onChange={(e) => {
                                              if (param.name !== '零件重量') {
                                                let componentCraftDetail = this.state.componentCraftDetail
                                                let carftParamsValues = componentCraftDetail.carftParamsValues
                                                let newValue = carftParamsValues[index]
                                                newValue.value = e.target.value
                                                carftParamsValues[index] = newValue
                                                componentCraftDetail.carftParamsValues = carftParamsValues
                                                this.setState({
                                                  componentCraftDetail,
                                                })
                                              }
                                            }}
                                            value={this.state.componentCraftDetail.carftParamsValues[index].value}
                                          />
                                        )}
                                      </div>
                                      <p style={{ marginLeft: 6 }}> {param.unit}</p>
                                    </li>
                                  ))}

                              <li>
                                <p>描述</p>
                                <div>
                                  <textarea
                                    onChange={(v) => {
                                      if (this.state.componentCraftDetail != null) {
                                        let componentCraftDetail = this.state.componentCraftDetail
                                        componentCraftDetail.memo = v.target.value
                                        this.setState({ componentCraftDetail })
                                      }
                                    }}
                                    value={this.state.componentCraftDetail == null ? '' : this.state.componentCraftDetail.memo}
                                  ></textarea>
                                </div>
                              </li>
                              <li>
                                <p> 工本</p>
                                <div>
                                  <input
                                    type="number"
                                    onChange={() => {}}
                                    value={this.state.componentCraftDetail == null ? '' : parseFloat(this.state.componentCraftDetail.price).toFixed(2)}
                                  />
                                </div>
                                <p style={{ marginLeft: 6 }}> 元</p>
                              </li>
                              {this.state.selectMaterialType === null || this.state.selectMaterialType.isOutType !== '是'}
                              <li>
                                <p> 阶梯价格</p>
                                <div>
                                  <input
                                    type="number"
                                    onChange={() => {}}
                                    value={
                                      this.state.componentCraftDetail == null
                                        ? ''
                                        : parseFloat(this.state.componentCraftDetail.price * this.state.orderComponents.priceConditionNum).toFixed(2)
                                    }
                                  />
                                </div>
                                <p style={{ marginLeft: 6 }}> 元</p>
                              </li>
                              <li>
                                <p> 工时</p>
                                <div>
                                  <input
                                    type="number"
                                    onChange={() => {}}
                                    value={this.state.componentCraftDetail == null ? '' : parseFloat(this.state.componentCraftDetail.workerTime * 60).toFixed(2)}
                                  />
                                </div>
                                <p style={{ marginLeft: 6 }}> 分钟</p>
                              </li>
                              <li>
                                <p> 序号</p>
                                <div>
                                  <input
                                    type="number"
                                    onChange={(e) => {
                                      console.log(e.target.value)
                                      let componentCraftDetail = this.state.componentCraftDetail
                                      componentCraftDetail.seq = e.target.value
                                      this.setState({ componentCraftDetail })
                                    }}
                                    value={this.state.componentCraftDetail == null ? 0 : this.state.componentCraftDetail.seq}
                                  />
                                </div>
                              </li>
                              {this.state.componentCraftDetail != null &&
                              this.state.componentsEmployeeMap[this.state.orderComponents.id + '#' + this.state.componentCraftDetail.id] != null ? (
                                <li>
                                  <p> 指定技工</p>
                                  <div>
                                    <input
                                      onChange={() => {}}
                                      value={this.state.componentsEmployeeMap[this.state.orderComponents.id + '#' + this.state.componentCraftDetail.id].name}
                                    />
                                  </div>
                                </li>
                              ) : (
                                ''
                              )}
                            </ul>
                            <div className="btn">
                              <a
                                onClick={() => {
                                  let componentCraftDetail = this.state.componentCraftDetail
                                  if (JSON.parse(Storage.get('loginUser')).company.valuationType === '计时') {
                                    this.UnitCostsAndSaveByTime(componentCraftDetail)
                                  } else {
                                    this.selectComponentCraftDetail(componentCraftDetail)
                                  }
                                }}
                              >
                                保存
                              </a>
                              <a
                                onClick={() => {
                                  this.craftSelecter.current.toEdit(
                                    this.state.selectStep,
                                    this.state.materialCostsDetail != null ? this.state.materialCostsDetail.materialWeight : 'null'
                                  )
                                }}
                              >
                                重选
                              </a>
                              <a
                                onClick={() => {
                                  let componentCraftDetail = this.state.componentCraftDetail
                                  this.openSelecterCraftEmployee(componentCraftDetail)
                                }}
                              >
                                指派技工
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPane>

                {Object.values(this.state.inTabVersions).map((pane) => (
                  <TabPane tab={pane.title} key={pane.key}>
                    <OrderCompileTab orderId={pane.orderId} orderComponentsId={pane.orderComponentsId} table={this}></OrderCompileTab>
                  </TabPane>
                ))}
              </Tabs>
            </div>
          </div>
        ) : (
          ''
        )}

        <OrderCraftEmployee table={this} ref={this.orderCraftEmployee}></OrderCraftEmployee>

        <ComponentCraftPrint ref={this.componentCraftPrint}></ComponentCraftPrint>
      </Drawer>
    )
  }
}

export default order_dismantling
