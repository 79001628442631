import React, { Component } from "react";
import "../../assets/css/ShowPdf_ipad.css";
import { pdfjs } from "react-pdf";
import { Button, Drawer, Select } from "antd";
import { Modal, Row, Col, Form, Input, message, notification } from "antd";
import http from "../api/http";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import ComponentCraftPrint from "../etooling/ComponentCraftPrint";
import MobileOrder from "../etooling/workorder/MobileOrder";
import MobileQuality from "./MobileQuality";
import MobileDelivery from "./MobileDelivery";
import QualityProcessCard from "./QualityProcessCard";
import ParameterSettings from "./SettingTest";
import Storage from "../../utils/Storage";
import MobileQualityProcess from "./MobileQualityProcess";
const { Option } = Select;
pdfjs.GlobalWorkerOptions.workerSrc = `//etooling.etooling-auto.com/pdf.worker.min.js`;

//接收安卓的消息
window.androidToWebView = function (str) {
  // message.success(str)
  console.log(str);
  window.callback.getAndroidMsg(str);
};

window.setCallback = function (callback) {
  // message.success('init')
  window.callback = callback;
};

const { confirm } = Modal;
class ShowPdf_ipad extends Component {
  constructor(props) {
    super(props);
    this.mobileOrder = React.createRef();
    this.mobileQuality = React.createRef();
    this.mobileDelivery = React.createRef();
    this.qualityProcessCard = React.createRef();
    this.componentCraftPrint = React.createRef();
    this.ParameterSettings = React.createRef();
    this.mobileQualityProcess = React.createRef();
    this.state = {
      drawerWidth: 450,
      usbReady: false,
      workGroupId: "",
      componentId: "",
      title: "",
      orderName: "",
      componentNum: "",
      orderComponent: "",
      orderIndex: "",
      componentIndex: "",
      orderComponentId: "",
      workOrder: "",
      orderStep: "",
      componentName: "",
      componentCode: "",
      orderPrice: 0,
      inputNum: "",
      status: "",
      wdStatus: "",
      orderComponentQcId: "",
      completedNumber: "",
      role: "质检人员",
      componetImg: {
        webPath: "",
        fileType: "",
      },
      workOrderIds: [
        {
          id: "",
          craftName: "",
        },
      ],
      visible: false,
      qualityVisible: false,
      ProcessCardVisible: false,
      parameterVisible: false,
      deliveryVisible: false,
      deliverNum: 0,
      acceptUserId: "",
      transfer: "否",
      errType: "",
      errMemo: "",
      craftName: "",
      programFiles: null,
      programName:'1'
    };
  }

  componentDidMount() {
    if (Storage.get("isQcUser") === "yes") {
      this.getQuality();
    } else if (Storage.get("isWarehouseAdmin") === "yes") {
      this.getDelivery();
    } else if (Storage.get("isQualityProcess") === "yes") {
      this.getInitQualityProcess();
    } else {
      this.getWorkOrder();
      if (window.androidApi != null) {
        window.androidApi.checkUsb();
      }
    }
    window.setCallback(this);
  }

  getWorkOrder = () => {
    let employeeId = JSON.parse(Storage.get("loginUser")).id;
    let values = { loginUserId: employeeId };
    let processWorkOrderGroupList = [];
    let newWorkOrderOrderGroupList = [];
    if (employeeId != null && employeeId !== "") {
      http
        .post("/admin/workOrder/getByWorkOrderListEmployee", values)
        .then((res) => {
          if (res.data.processWorkOrderGroup != null) {
            processWorkOrderGroupList = res.data.processWorkOrderGroup;
          }
          if (res.data.outProcessWorkOrderGroup.content != null) {
            let outPgroup = res.data.outProcessWorkOrderGroup.content;
            for (let o = 0; o < outPgroup.length; o++) {
              processWorkOrderGroupList.push(outPgroup[o]);
            }
          }

          newWorkOrderOrderGroupList = res.data.newWorkOrderOrderGroup;
          let outTypeWorkOrderOrderGroup =
            res.data.outTypeWorkOrderOrderGroup.content;

          for (let o = 0; o < outTypeWorkOrderOrderGroup.length; o++) {
            let outOg = outTypeWorkOrderOrderGroup[o];
            outOg.isOut = "是";
            newWorkOrderOrderGroupList.push(outOg);
          }
          if (processWorkOrderGroupList.length !== 0) {
            this.getChildrenMsg(this, processWorkOrderGroupList[0]);
          } else if (newWorkOrderOrderGroupList.length !== 0) {
            this.getChildrenMsg(this, newWorkOrderOrderGroupList[0]);
          }
          this.getLastWorkFile();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({
        workOrderCntByUser: {
          waitAcceptOrderCntInfo: {},
          todayDoneCraft: {},
        },
        newWorkOrderOrderGroup: [],
        processWorkOrderGroupList: [],
        newWorkOrderOrderGroupList: [],
        outProcessWorkOrderGroup: [],
        outTypeWorkOrderOrderGroup: [],
        workOrderInfo: {
          process: {},
          newWorkOrder: {
            new_order_detai: {
              material_detail: [],
              craft_detail: [],
            },
          },
        },
      });
    }
  };
  // 质检页面初始化
  getQuality = () => {
    http
      .post("/admin/quality/getInitQualityDetail")
      .then((res) => {
        console.log(res);
        if (res.data.file_type === ".pdf" || res.data.file_type === ".PDF") {
          this.setState({
            componetImg: {
              webPath:
                "http://etooling.etooling-auto.com/pdf-website/index.html?pdf=" +
                res.data.web_path,
              fileType: res.data.file_type,
            },
          });
        } else {
          this.setState({
            componetImg: {
              webPath:
                "http://etooling.etooling-auto.com/showImg/index.html?img=" +
                res.data.web_path,
              fileType: res.data.file_type,
            },
          });
        }
        this.setState({
          orderComponent: res.data,
          componentName: res.data.component_name,
          componentCode: res.data.component_code,
          status: res.data.status,
          componentNum: res.data.components_num,
        });
        this.qualityProcessCard.current.setState({
          orderComponentQcId: res.data.id,
          orderComponentId: res.data.oc_id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 仓库页面初始化
  getDelivery = () => {
    http.post("/admin/warehouse/getDeliverOrderDetail").then((res) => {
      if (res.data !== undefined && res.data !== null) {
        if (
          res.data.deliverComponentsVo.fileType === ".pdf" ||
          res.data.deliverComponentsVo.fileType === ".PDF"
        ) {
          this.setState({
            componetImg: {
              webPath:
                "http://etooling.etooling-auto.com/pdf-website/index.html?pdf=" +
                res.data.deliverComponentsVo.componentImgPath,
              fileType: res.data.deliverComponentsVo.fileType,
            },
          });
        } else {
          this.setState({
            componetImg: {
              webPath:
                "http://etooling.etooling-auto.com/showImg/index.html?img=" +
                res.data.deliverComponentsVo.componentImgPath,
              fileType: res.data.deliverComponentsVo.fileType,
            },
          });
        }
        this.setState({
          orderComponent: res.data,
          componentName: res.data.deliverComponentsVo.componentName,
          componentCode: res.data.deliverComponentsVo.componentCode,
          status: res.data.deliverComponentsVo.warehouseStatus,
          componentNum: res.data.deliverComponentsVo.componentNum,
          deliverNum: res.data.deliverComponentsVo.deliverNum,
          orderName: res.data.deliverOrderDetail.order_name,
          orderCode: res.data.deliverOrderDetail.extra_order_code,
          orderComponentId: res.data.deliverComponentsVo.orderComponentId,
        });
      }
    });
  };

  // 巡检初始化
  getInitQualityProcess = () => {
    http
      .post("/admin/ipad/getInitQualityProcess")
      .then((res) => {
        console.log(res);
        if (
          res.data.img.fileType === ".pdf" ||
          res.data.img.fileType === ".PDF"
        ) {
          this.setState({
            componetImg: {
              webPath:
                "http://etooling.etooling-auto.com/pdf-website/index.html?pdf=" +
                res.data.img.webPath,
              fileType: res.data.img.fileType,
            },
          });
        } else {
          this.setState({
            componetImg: {
              webPath:
                "http://etooling.etooling-auto.com/showImg/index.html?img=" +
                res.data.img.webPath,
              fileType: res.data.img.fileType,
            },
          });
        }
        this.setState({
          componentName: res.data.componentName,
          componentCode: res.data.componentCode,
          status: res.data.status,
          componentNum: res.data.componentNum,
          orderName: res.data.order.orderName,
          orderCode: res.data.order.extraOrderCode,
          orderComponentId: res.data.orderComponentId,
          workOrderId: res.data.workOrders[0].id,
          craftName: res.data.workOrders[0].craftName,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 入库
  delivery = () => {
    let values = {};
    values.num = this.state.componentNum;
    values.id = this.state.orderComponentId;
    http
      .post("/admin/warehouse/confirmDeliver", values)
      .then((res) => {
        Modal.success({
          title: "提交数据完成",
          icon: "none",
        });
        this.getDelivery();
      })
      .catch((err) => {
        //console.log(res);(err);
      });
  };

  // 接单
  toAccept = () => {
    let values = {};
    let _this = this;
    values.workOrderIds = _this.state.workGroupId;
    http
      .post("/admin/craft/allShip", values)
      .then((res) => {
        if(_this.state.programFiles != null){
          this.sendMsgtogglePiad()
        }
        Modal.success({
          title: "接单完成!",
        });
        this.getWorkOrder();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 获取上一个工序是否有程序文件
  getLastWorkFile = () => {
    let values = {};
    let _this = this;
    values.workOrderIds = _this.state.workGroupId;
    values.workId = this.state.workOrder.id;
    http
      .post("/admin/workOrder/getLastWorkFile", values)
      .then((res) => {
        this.setState({
          programFiles: res.data.webPath,
          programName: res.data.name,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  downLoadCloudFileToUsb = () => {
    if (this.state.usbReady) {
      if (window.androidApi != null) {
        window.androidApi.downLoadCloudFileToUsb(
          this.state.programFiles,
          this.state.programName
        );
        this.sendMsgtoCNC()
      }
    } else {
      message.error("未检测出USB");
    }

  }

  sendMsgtogglePiad = () => {
    http
        .post('/admin/workOrder/sendMsgtogglePiad',null)
        .then((res) => {
          message.success('发送成功')
        })
  }

    sendMsgtoCNC = () => {
      http
          .post('/admin/workOrder/sendMsgtoCNC',null)
          .then((res) => {
            message.success('发送成功')
          })
    }

  // 质检通过
  toAudit = () => {
    let values = {};
    // let orderIndex = this.state.orderIndex
    let item = this.state.orderComponent;
    // let componentIndex = this.state.componentIndex
    values.orderComponentQcId = item.id;
    values.orderComponentId = item.qc_id;
    let data = this.state.toDoList;
    http
      .post("/admin/quality/quality", values)
      .then((res) => {
        Modal.success({
          title: "成功",
          content: "提交数据完成",
        });
        // data[orderIndex].deliverComponentVoList.splice(componentIndex, 1);
        this.setState({
          toDoList: data,
        });
        this.getQuality();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 进入巡检
  qualityProcess = () => {
    let values = {};
    values.id = this.state.workOrderIds[0].id;
    http
      .post("/admin/workOrder/qualityProcess", values)
      .then((res) => {
        Modal.success({
          title: "质检完成!",
        });
        this.mobileOrder.current.getWorkOrderListByEmployee(
          JSON.parse(Storage.get("loginUser")).id
        );
        this.getWorkOrder();
      })
      .catch((err) => {});
  };

  showWin = () => {
    this.getWorkOrder();
    this.setState({
      visible: true,
    });
    this.mobileOrder.current.getWorkOrderListByEmployee(
      JSON.parse(Storage.get("loginUser")).id
    );
  };
  QualityShowWin = () => {
    // this.getQuality()
    this.setState({
      qualityVisible: true,
    });
    this.mobileQuality.current.getQualityList();
  };
  ProcessCardShowWin = () => {
    this.setState({
      ProcessCardVisible: true,
    });
    this.qualityProcessCard.current.getDetail();
  };
  qualityProcessShowWin = () => {
    this.setState({ qualityProcessVisible: true });
    this.mobileQualityProcess.current.getQualityProcessList();
  };
  inputChange2 = (e) => {
    this.setState({
      inputNum: e.target.value,
    });
  };
  inputChange1(e) {
    this.setState({
      errMemo: e.target.value,
    });
  }
  //打开工艺卡
  toPrint = () => {
    http
      .post(
        "/admin/quality/qualityReport?orderComponentId=" +
          this.state.componentId,
        null
      )
      .then((res) => {
        this.setState({
          order: res.data.order,
          orderComponents: res.data.orderComponents,
          components: res.data.orderComponents.components,
          componentsCrafts: res.data.componentsCrafts,
          orderComponentsQcDetail: res.data.orderComponentsQcDetail,
        });
        this.componentCraftPrint.current.toPrint(
          this.state.components,
          this.state.order,
          this.state.orderComponents.componentsNum,
          this.state.componentsCrafts
        );
      });
  };

  //移交工单
  workOrderTransfer = () => {
    let values = {};
    values.workOrderGroupId = this.state.workGroupId;
    if (this.state.acceptUserId === "") {
      Modal.error({
        title: "失败",
        content: "请先选择用户",
      });
    }
    values.acceptUserId = this.state.acceptUserId;
    console.log(values);
    http.post("/admin/workOrder/workOrderTransfer", values).then((res) => {
      this.getWorkOrder();
    });
  };

  // 零件暂停
  stopOrderCom = (id) => {
    let values = {};
    values.id = this.state.orderComponentId;
    values.errType = this.state.errType;
    values.errMemo = this.state.errMemo;
    console.log(values);
    http
      .post("/admin/workOrder/stopOrderComponents", values)
      .then((res) => {
        message.success({
          content: "暂停成功",
        });
        this.getWorkOrder();
      })
      .catch(() => {});
  };

  //提交完成数
  confirmWorkOrder = () => {
    console.log(this.state.orderComponent);
    let value = this.state.inputNum;
    if (value === "") {
      value = this.state.componentNum;
    }
    if (/^\d+$/.test(value)) {
      let values = {};
      values.id = this.state.workOrderIds[0].id;
      values.num = value;
      http
        .post("/admin/workOrder/confirmWorkOrder", values)
        .then((res) => {
          Modal.success({
            title: "成功",
            content: "提交数据完成",
          });
          this.mobileOrder.current.getWorkOrderListByEmployee(
            JSON.parse(Storage.get("loginUser")).id
          );
          this.getWorkOrder()
        })
        .catch((err) => {});
      // }
    } else {
      Modal.error({
        title: "数据异常",
        content: "请输入正确的数字",
      });
    }
  };

  //自检
  audit() {
    let values = {};
    values.id = this.state.workOrderIds[0].id;
    http
      .post("/admin/workOrder/auditWorkOrder", values)
      .then((res) => {
        Modal.success({
          title: "质检完成!",
        });
        this.mobileOrder.current.getWorkOrderListByEmployee(
          JSON.parse(Storage.get("loginUser")).id
        );
        this.getWorkOrder();
        this.setState({programFiles:null})
      })
      .catch((err) => {});
  }

  getChildrenMsg = (result, msg) => {
    if (msg.img.fileType === ".pdf" || msg.img.fileType === ".PDF") {
      this.setState({
        componetImg: {
          webPath:
            "http://etooling.etooling-auto.com/pdf-website/index.html?pdf=" +
            msg.img.webPath,
          fileType: msg.img.fileType,
        },
      });
    } else {
      this.setState({
        componetImg: {
          webPath:
            msg.img.webPath,
          fileType: msg.img.fileType,
        },
      });
    }
    if (msg.transfer != null) {
      this.setState({ transfer: msg.transfer });
    }
    this.setState({
      orderCode: msg.extraOrderCode,
      workGroupId: msg.id,
      doneNum: msg.doneNum,
      componentId: msg.orderComponentId,
      orderName: msg.order.orderName,
      orderStep: msg.craftTypeName,
      componentName: msg.componentName,
      status: msg.status,
      orderPrice: msg.price,
      componentNum: msg.componentNum,
      componentCode: msg.componentCode,
      isBatchWorkOrder: msg.isBatchWorkOrder,
      orderComponentId: msg.orderComponentId,
    });
    let workOrderIds = [];
    for (let index = 0; index < msg.workOrders.length; index++) {
      if (
        msg.workOrders[index].status !== "完成" &&
        msg.workOrders[index].status !== "工单质检"
      ) {
        workOrderIds.push(msg.workOrders[index]);
      }
    }
    this.setState({
      workOrderIds: workOrderIds,
      wdStatus: workOrderIds[0].status,
    });
    this.ParameterSettings.current.setState({
      workOrderGroupId: msg.id,
      workOrderId: workOrderIds[0].id,
    });
    this.getLastWorkFile();
  };
  getQualityImg = (result, item, orderIndex, index) => {
    if (item.file_type === ".pdf" || item.file_type === ".PDF") {
      this.setState({
        componetImg: {
          webPath:
            "http://etooling.etooling-auto.com/pdf-website/index.html?pdf=" +
            item.web_path,
          fileType: item.fileType,
        },
      });
    } else {
      this.setState({
        componetImg: {
          webPath:
            "http://etooling.etooling-auto.com/showImg/index.html?img=" +
            item.web_path,
          fileType: item.fileType,
        },
      });
    }
    this.setState({
      orderComponent: item,
      status: item.status,
      orderIndex: orderIndex,
      componentIndex: index,
      componentNum: item.components_num,
      componentName: item.component_name,
      componentCode: item.component_code,
      orderPart: item.component_name,
      orderComponentId: item.oc_id,
      orderComponentQcId: item.id,
    });
    this.qualityProcessCard.current.setState({
      orderComponentQcId: item.id,
      orderComponentId: item.oc_id,
    });
  };

  getDeliveryImg = (result, item, orderIndex, index, OrderItem) => {
    if (item.fileType === ".pdf" || item.fileType === ".PDF") {
      this.setState({
        componetImg: {
          webPath:
            "http://etooling.etooling-auto.com/pdf-website/index.html?pdf=" +
            item.componentImgPath,
          fileType: item.fileType,
        },
      });
    } else {
      this.setState({
        componetImg: {
          webPath:
            "http://etooling.etooling-auto.com/showImg/index.html?img=" +
            item.componentImgPath,
          fileType: item.fileType,
        },
      });
    }
    this.setState({
      orderComponent: item,
      status: item.warehouseStatus,
      orderIndex: orderIndex,
      componentIndex: index,
      componentNum: item.componentNum,
      componentName: item.componentName,
      componentCode: item.componentCode,
      orderName: OrderItem.orderName,
      orderCode: OrderItem.orderCode,
      orderComponentId: item.orderComponentId,
      deliverNum: item.deliverNum,
    });
    this.qualityProcessCard.current.setState({
      orderComponentQcId: item.id,
      orderComponentId: item.oc_id,
    });
  };
  getQualityProcessImg = (result, item, orderIndex, index, OrderItem) => {
    console.log(item);
    console.log(OrderItem);
    if (
      OrderItem.img.fileType === ".pdf" ||
      OrderItem.img.fileType === ".PDF"
    ) {
      this.setState({
        componetImg: {
          webPath:
            "http://etooling.etooling-auto.com/pdf-website/index.html?pdf=" +
            OrderItem.img.webPath,
          fileType: OrderItem.img.fileType,
        },
      });
    } else {
      this.setState({
        componetImg: {
          webPath:
            "http://etooling.etooling-auto.com/showImg/index.html?img=" +
            OrderItem.img.webPath,
          fileType: OrderItem.img.fileType,
        },
      });
    }
    this.setState({
      workOrderId: item.id,
      workOrderGroupId: OrderItem.id,
      craftName: item.craftName,
      componentName: item.componentName,
      componentCode: item.componentCode,
      status: item.status,
      componentNum: OrderItem.componentNum,
      orderName: item.orderName,
      orderCode: item.orderCode,
      orderComponentId: item.orderComponentId,
    });
  };
  // 巡检通过
  auditQualityProcess = () => {
    let values = {};
    values.id = this.state.workOrderId;
    console.log(values);
    http.post("/admin/ipad/auditWorkOrder", values).then((res) => {
      message.success("提交成功");
      this.getInitQualityProcess();
    });
  };
  QualityProcessToBack = () => {
    let values = {};
    values.id = this.state.workOrderId;
    http.post("/admin/ipad/qualityProcessToBack", values).then((res) => {
      message.success("提交成功");
      this.getInitQualityProcess();
    });
  };
  closeWin = () => {
    this.setState({ visible: false });
  };
  QualityCloseWin = () => {
    this.setState({ qualityVisible: false });
  };
  qualityProcessCloseWin = () => {
    this.setState({ qualityProcessVisible: false });
  };
  ProcessCardCloseWin = () => {
    this.setState({ ProcessCardVisible: false });
  };
  //参数设置收回
  ParameterSettingsCloseWin = () => {
    this.setState({ parameterVisible: false });
  };
  DeliveryShowWin = () => {
    this.setState({ deliveryVisible: true });
    this.mobileDelivery.current.getDeliveryList();
  };
  deliveryCloseWin = () => {
    this.setState({ deliveryVisible: false });
  };

  updatePwd = () => {
    let values = {};
    values.initPwd = this.state.initPwd;
    values.newPwd = this.state.newPwd;
    http
      .post("/admin/userMgr/resetPwd", values)
      .then((res) => {
        message.success("修改密码成功");
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  concessionsAccepted() {
    return (
      <div class="qaStlye">
        <div style={{ padding: "8%" }}>
          <input
            type="success"
            mode="input"
            title="质检描述"
            placeholder="请输入质检描述"
            onChange={(e) => {
              console.log(e.target.value);
            }}
          ></input>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <Drawer
          onClose={this.closeWin}
          placement="left"
          closable={false}
          visible={this.state.visible}
          forceRender={true}
          title={this.state.title}
          width={this.state.drawerWidth}
        >
          <MobileOrder
            ref={this.mobileOrder}
            getChildrenMsg={this.getChildrenMsg}
          ></MobileOrder>
          <ComponentCraftPrint
            ref={this.componentCraftPrint}
          ></ComponentCraftPrint>
        </Drawer>

        <Drawer
          onClose={this.QualityCloseWin}
          closable={false}
          placement="left"
          visible={this.state.qualityVisible}
          forceRender={true}
          title={this.state.title}
          width={this.state.drawerWidth}
        >
          <MobileQuality
            ref={this.mobileQuality}
            getQualityImg={this.getQualityImg}
          ></MobileQuality>
        </Drawer>

        <Drawer
          onClose={this.deliveryCloseWin}
          closable={false}
          placement="left"
          visible={this.state.deliveryVisible}
          forceRender={true}
          title={""}
          width={this.state.drawerWidth}
        >
          <MobileDelivery
            ref={this.mobileDelivery}
            getDeliveryImg={this.getDeliveryImg}
          ></MobileDelivery>
        </Drawer>

        <Drawer
          onClose={this.qualityProcessCloseWin}
          placement="left"
          visible={this.state.qualityProcessVisible}
          forceRender={true}
          title={this.state.title}
          width={this.state.drawerWidth}
        >
          <MobileQualityProcess
            ref={this.mobileQualityProcess}
            getQualityProcessImg={this.getQualityProcessImg}
          ></MobileQualityProcess>
        </Drawer>

        <Drawer
          onClose={this.ProcessCardCloseWin}
          placement="left"
          visible={this.state.ProcessCardVisible}
          forceRender={true}
          title={this.state.title}
          width={this.state.drawerWidth}
        >
          <QualityProcessCard
            ref={this.qualityProcessCard}
            getQuality={this.getQuality}
            ProcessCardCloseWin={this.ProcessCardCloseWin}
          ></QualityProcessCard>
        </Drawer>

        <ParameterSettings
          ref={this.ParameterSettings}
          getWorkOrder={this.getWorkOrder}
          ParameterSettingsCloseWin={this.ParameterSettingsCloseWin}
        ></ParameterSettings>

        <div className="show_pdf">
          <div className="head">
            <div
              className="menu_drop"
              onClick={
                Storage.get("isQcUser") === "yes"
                  ? this.QualityShowWin
                  : Storage.get("isWarehouseAdmin") === "yes"
                  ? this.DeliveryShowWin
                  : Storage.get("isQualityProcess") === "yes"
                  ? this.qualityProcessShowWin
                  : this.showWin
              }
            >
              <p>我的工单</p>
            </div>
            {Storage.get("isQcUser") === "yes" ? (
              <div className="information">
                <ul>
                  <li>
                    零件名称 : {this.state.componentName}[
                    {this.state.componentCode}]
                  </li>
                  <li>数量 {this.state.componentNum}</li>
                </ul>
              </div>
            ) : Storage.get("isWarehouseAdmin") === "yes" ? (
              <div className="information">
                <ul>
                  <li>
                    {this.state.orderName}[{this.state.orderCode}]
                  </li>
                  <li>
                    零件名称 : {this.state.componentName}[
                    {this.state.componentCode}]
                  </li>
                  <li>
                    总数/已入库数 {this.state.componentNum}/
                    {this.state.deliverNum}{" "}
                  </li>
                </ul>
              </div>
            ) : Storage.get("isQualityProcess") === "yes" ? (
              <div className="information">
                <ul>
                  <li>
                    {this.state.orderName}[{this.state.orderCode}]
                  </li>
                  工艺 :
                  {this.state.craftName === "" ? "" : this.state.craftName}
                  <li>
                    零件名称 : {this.state.componentName}[
                    {this.state.componentCode}]
                  </li>
                  <li>
                    总数/已提交数 {this.state.componentNum}/{this.state.doneNum}{" "}
                  </li>
                </ul>
              </div>
            ) : (
              <div className="information">
                <ul>
                  <li>
                    {this.state.orderName}[{this.state.orderCode}]
                  </li>
                  <li>
                    工艺 :{" "}
                    {this.state.workOrderIds[0].craftName === undefined
                      ? ""
                      : this.state.workOrderIds[0].craftName}
                    [{this.state.workOrderIds[0].seq}]
                  </li>
                  <li>
                    零件名称 : {this.state.componentName}[
                    {this.state.componentCode}]
                  </li>
                  <li>
                    总数/已提交数 {this.state.componentNum}/{this.state.doneNum}{" "}
                  </li>
                  <li style={{ textAlign: "right", flex: "initial" }}>
                    金额 :{" "}
                    {this.state.status === "已接单"
                      ? parseFloat(this.state.orderPrice).toFixed(2)
                      : ""}{" "}
                    元
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="con">
            <div className="pic">
               <iframe
                src={this.state.componetImg.webPath}
                style={{ width: "100%", height: "100%" }}
              ></iframe> 
            </div>

            <div className="operation">
              {/* <button
                style={{
                  bottom: "17.5%",
                  left: "2%",
                  marginBottom: "100rpx",
                  position: "fixed",
                }}
                onClick={() => {
                  let _this = this;
                  confirm({
                    title: "切换角色",
                    icon: <ExclamationCircleOutlined />,
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    content: (
                      <>
                        <Form
                          layout="horizontal"
                          autoComplete="off"
                          style={{ marginTop: 10 }}
                          ref={this.form1}
                          labelCol={{ span: 6 }}
                          onFinish={this.submit}
                        >
                          <Row gutter={24}>
                            <Col span={12}>
                              <Radio.Group value={_this.state.role} onChange={(e) => {_this.setState({role:e.target.value})}}>
                                <Radio value={'质检人员'}>质检</Radio>
                                <Radio value={'仓库人员'}>仓库</Radio>
                                <Radio value={'技工'}>加工</Radio>
                              </Radio.Group>
                            </Col>
                          </Row>
                        </Form>
                      </>
                    ),
                    width: 600,
                    onOk() {
                      _this.updatePwd();
                    },
                    onCancel() {},
                  });
                }}
              >
                切换角色
              </button> */}
              <button
                style={{
                  bottom: "3%",
                  left: "2%",
                  marginBottom: "0",
                  position: "fixed",
                }}
                onClick={() => {
                  confirm({
                    title: "真的要退出账号吗?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    onOk() {
                      Storage.set("loginUser", null);
                      Storage.set("token", null);
                      Storage.set("fileRootPath", null);
                      Storage.set("userFuncs", null);
                      window.location.href = "/IpadLogin";
                    },
                    onCancel() {},
                  });
                }}
              >
                退出
              </button>
              <button
                style={{
                  bottom: "8%",
                  left: "2%",
                  marginBottom: "50rpx",
                  position: "fixed",
                }}
                onClick={() => {
                  let _this = this;
                  confirm({
                    title: "修改密码",
                    icon: <ExclamationCircleOutlined />,
                    okText: "确认",
                    okType: "danger",
                    cancelText: "取消",
                    content: (
                      <>
                        <Form
                          layout="horizontal"
                          autoComplete="off"
                          style={{ marginTop: 10 }}
                          ref={this.form1}
                          labelCol={{ span: 6 }}
                          onFinish={this.submit}
                        >
                          <Row gutter={24}>
                            <Col span={12}>
                              <Form.Item name="initPwd" label="原密码">
                                <Input
                                  onChange={(e) =>
                                    this.setState({
                                      initPwd: e.target.value,
                                    })
                                  }
                                />
                              </Form.Item>
                              <Form.Item name="newPwd" label="新密码">
                                <Input
                                  onChange={(e) =>
                                    this.setState({
                                      newPwd: e.target.value,
                                    })
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </>
                    ),
                    width: 600,
                    onOk() {
                      _this.updatePwd();
                    },
                    onCancel() {},
                  });
                }}
              >
                修改密码
              </button>
              {this.state.wdStatus === "已接单" ? (
                <>
                  <button
                    style={{
                      background: "#1a54d2",
                      boxShadow: "0 0 10px #1a54d2",
                      marginBottom: "40px",
                    }}
                    onClick={() => {
                      let craftTypeList = JSON.parse(
                        this.state.workOrderIds[0].craftParamValues
                      );
                      let data = [];
                      for (var i = 0; i < craftTypeList.length; i++) {
                        if (craftTypeList[i].paramType === "执行人员录入参数") {
                          data.push(craftTypeList[i]);
                        }
                      }
                      if (data.length === 0) {
                        let _this = this;
                        confirm({
                          title: "确认提交数量",
                          icon: <ExclamationCircleOutlined />,
                          okText: "确认",
                          okType: "danger",
                          cancelText: "取消",
                          content: (
                            <>
                              <Form
                                layout="horizontal"
                                autoComplete="off"
                                style={{ marginTop: 10 }}
                                ref={this.form1}
                                labelCol={{ span: 6 }}
                                onFinish={this.submit}
                              >
                                <Row gutter={24}>
                                  <Col span={12}>
                                    <Form.Item
                                      name="orderSort"
                                      label="提交数量"
                                    >
                                      <Input
                                        defaultValue={this.state.componentNum}
                                        onChange={(e) => this.inputChange2(e)}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                            </>
                          ),
                          width: 600,
                          onOk() {
                            _this.confirmWorkOrder();
                          },
                          onCancel() {},
                        });
                      }
                      if (data.length !== 0) {
                        this.ParameterSettings.current.init_();
                      }
                    }}
                  >
                    提交
                  </button>
                  <button
                    style={{
                      boxShadow: "0 0 10px #1a54d2",
                      marginBottom: "40px",
                    }}
                    onClick={() => {
                      http
                        .post("/admin/workOrder/getOrderComErrType")
                        .then((res) => {
                          let errTypes = [];
                          for (
                            let index = 0;
                            index < res.data.length;
                            index++
                          ) {
                            let typeData = {
                              value: "",
                              label: "",
                              children: [],
                            };
                            typeData.value = res.data[index].id;
                            typeData.label = res.data[index].dirValue;
                            errTypes.push(typeData);
                          }
                          let _this = this;
                          confirm({
                            title: "确认提交数量",
                            icon: <ExclamationCircleOutlined />,
                            okText: "确认",
                            okType: "danger",
                            cancelText: "取消",
                            content: (
                              <>
                                <Form
                                  layout="horizontal"
                                  autoComplete="off"
                                  style={{ marginTop: 10 }}
                                  ref={this.form1}
                                  labelCol={{ span: 6 }}
                                  onFinish={this.submit}
                                >
                                  <Row gutter={24}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="errType"
                                        label="异常类型"
                                      >
                                        <Select
                                          defaultValue={
                                            (errTypes[0].label,
                                            errTypes[0].value)
                                          }
                                          placeholder="请选择"
                                          filterOption={false}
                                          onChange={(key) => {
                                            if (
                                              key != null &&
                                              key !== undefined &&
                                              key !== ""
                                            ) {
                                              this.setState({
                                                errType: key,
                                              });
                                            }
                                          }}
                                        >
                                          {errTypes.map((item, index) => (
                                            <Option
                                              key={item.value}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row gutter={24}>
                                    <Col span={12}>
                                      <Form.Item
                                        name="errMemo"
                                        label="异常备注"
                                      >
                                        <Input
                                          onChange={(e) => this.inputChange1(e)}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form>
                              </>
                            ),
                            width: 600,
                            onOk() {
                              _this.stopOrderCom();
                            },
                            onCancel() {},
                          });
                        });
                    }}
                  >
                    暂停
                  </button>
                  {this.state.transfer === "是" ? (
                    <button
                      style={{
                        boxShadow: "0 0 10px #1a54d2",
                        marginBottom: "40px",
                      }}
                      onClick={() => {
                        http.post("/admin/workOrder/craftUser").then((res) => {
                          console.log(res.data);
                          if (res.data.length > 0) {
                            confirm({
                              title: "确认接收工单者",
                              icon: <ExclamationCircleOutlined />,
                              okText: "确认",
                              okType: "danger",
                              cancelText: "取消",
                              content: (
                                <>
                                  <Select
                                    style={{ width: 400, height: 50 }}
                                    placeholder="请选择接收者"
                                    optionFilterProp="children"
                                    onChange={(e) => {
                                      this.setState({ acceptUserId: e });
                                    }}
                                  >
                                    {res.data.map((user, index) => (
                                      <Option value={user.id}>
                                        {user.name}
                                      </Option>
                                    ))}
                                  </Select>
                                  ,
                                </>
                              ),
                              width: 600,
                              onOk() {
                                _this.workOrderTransfer();
                              },
                              onCancel() {},
                            });
                          }
                        });
                        let _this = this;
                      }}
                    >
                      移交
                    </button>
                  ) : (
                    ""
                  )}
                </>
              ) : this.state.status === "待质检" ? (
                <>
                  <button
                    onClick={() => {
                      let _this = this;
                      confirm({
                        title: "确认要通过零件",
                        icon: <ExclamationCircleOutlined />,
                        okText: "确认",
                        okType: "danger",
                        cancelText: "取消",
                        onOk() {
                          _this.toAudit();
                        },
                      });
                    }}
                  >
                    通过
                  </button>
                  <button
                    onClick={() => {
                      this.ProcessCardShowWin();
                    }}
                  >
                    不通过
                  </button>
                  {/* <button onClick={() => {
                        }}>让步接受</button> */}
                </>
              ) : (this.state.status === "待接单" &&
                  this.mobileOrder.current.state.processWorkOrderGroupList
                    .length === 0) ||
                this.state.status === "返工" ||
                this.state.isBatchWorkOrder === "是" ? (
                <button
                  onClick={() => {
                    let _this = this;
                    confirm({
                      title: "确认要接这工单吗?",
                      icon: <ExclamationCircleOutlined />,
                      okText: "确认",
                      okType: "danger",
                      cancelText: "取消",
                      onOk() {
                        _this.toAccept();
                      },
                    });
                  }}
                >
                  接单
                </button>
              ) : this.state.wdStatus === "质检" ? (
                <button
                  onClick={() => {
                    let _this = this;
                    confirm({
                      title: "确定质检通过吗?",
                      okText: "确定",
                      okType: "danger",
                      cancelText: "取消",
                      onOk() {
                        if (
                          JSON.parse(Storage.get("loginUser")).company
                            .qualityProcess === "是"
                        ) {
                          if(_this.state.programFiles != null){
                            _this.sendMsgtogglePiad()
                          }
                          _this.qualityProcess();
                        } else {
                          if(_this.state.programFiles != null){
                            _this.sendMsgtogglePiad()
                          }
                          _this.audit();
                        }
                      },
                    });
                  }}
                >
                  质检
                </button>
              ) : this.state.status === "待入库" ? (
                <button
                  onClick={() => {
                    let _this = this;
                    confirm({
                      title: "确认提交数量",
                      icon: <ExclamationCircleOutlined />,
                      okText: "确认",
                      okType: "danger",
                      cancelText: "取消",
                      content: (
                        <>
                          <Form
                            layout="horizontal"
                            autoComplete="off"
                            style={{ marginTop: 10 }}
                            ref={this.form1}
                            labelCol={{ span: 6 }}
                            onFinish={this.submit}
                          >
                            <Row gutter={24}>
                              <Col span={12}>
                                <Form.Item name="orderSort" label="提交数量">
                                  <Input
                                    defaultValue={this.state.componentNum}
                                    onChange={(e) => this.inputChange2(e)}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      ),
                      width: 600,
                      onOk() {
                        _this.delivery();
                      },
                      onCancel() {},
                    });
                  }}
                >
                  入库
                </button>
              ) : this.state.status === "工单质检" ? (
                <>
                  <button
                    onClick={() => {
                      let _this = this;
                      confirm({
                        title: "确认提交数量",
                        icon: <ExclamationCircleOutlined />,
                        okText: "确认",
                        okType: "danger",
                        cancelText: "取消",
                        content: (
                          <>
                            <Form
                              layout="horizontal"
                              autoComplete="off"
                              style={{ marginTop: 10 }}
                              ref={this.form1}
                              labelCol={{ span: 6 }}
                              onFinish={this.submit}
                            >
                              <Row gutter={24}>
                                <Col span={12}>
                                  <Form.Item name="orderSort" label="提交数量">
                                    <Input
                                      defaultValue={this.state.componentNum}
                                      onChange={(e) => this.inputChange2(e)}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        ),
                        width: 600,
                        onOk() {
                          _this.auditQualityProcess();
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    通过
                  </button>
                  <button
                    onClick={() => {
                      let _this = this;
                      confirm({
                        title: "确认提交数量",
                        icon: <ExclamationCircleOutlined />,
                        okText: "确认",
                        okType: "danger",
                        cancelText: "取消",
                        content: (
                          <>
                            <Form
                              layout="horizontal"
                              autoComplete="off"
                              style={{ marginTop: 10 }}
                              ref={this.form1}
                              labelCol={{ span: 6 }}
                              onFinish={this.submit}
                            >
                              <Row gutter={24}>
                                <Col span={12}>
                                  <Form.Item name="orderSort" label="提交数量">
                                    <Input
                                      defaultValue={this.state.componentNum}
                                      onChange={(e) => this.inputChange2(e)}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </>
                        ),
                        width: 600,
                        onOk() {
                          _this.QualityProcessToBack();
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    不通过
                  </button>
                </>
              ) : (
                ""
              )}
              {this.state.status === "待接单" ||
              this.state.status === "返工" ? (
                <button onClick={this.toPrint.bind()}>工艺卡</button>
              ) : this.state.status === "已接单" ? (
                  <button onClick={this.toPrint.bind()}>工艺卡</button>
              ) : (
                ""
              )}
              {this.state.usbReady ? (
                this.state.programFiles != null ? (
                  <button
                  style={{background:'#48e118'}}
                    onClick={() => {
                      this.downLoadCloudFileToUsb()
                    }}
                  >
                    下载程序
                  </button>
                ) : (
                  ""
                )
              ) : (
                ''
                // <button
                // style={{background:'#d9d9d9',borderColor:'#d9d9d9'}}
                // disabled
                //     onClick={() => {
                //       this.downLoadCloudFileToUsb()
                //     }}
                //   >
                //     下载程序
                //   </button>
              )}
              <button
                onClick={() => {
                  window.location.reload();
                }}
              >
                刷新
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ShowPdf_ipad.propTypes = {};

export default ShowPdf_ipad;
