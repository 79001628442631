import { ExclamationCircleOutlined, PlusOutlined,ReloadOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Drawer,
  Modal,
  Tree,
  Pagination
} from "antd";
import React, { Component } from "react";
import http from "../api/http";
import RoleForm from "./RoleForm";
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable'


const { confirm } = Modal;
class roleMgr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      treeData: [],
      visible: false,
      autoExpandParent: true,
      setAutoExpandParent: true,
      expandedKeys: [],
      checkedKeys: [],
      fileId: "",
      selectRoleId: "",
      selectedKeys: [],
      page: 1,
      pageSize: 10
    };

    this.roleForm = React.createRef();
  }
  //加载完成
  componentDidMount() {
    this.getRoleList();
    this.getModulTree();
  }

  onLoading() {
    this.setState({
      loading: true,
    });
  }
  offLoading() {
    this.setState({
      loading: false,
    });
  }

  getRoleList = (values) => {
    this.onLoading();
    if(values == null){
      values = {}
    }
    values.page = values.page
    values.pageSize = this.state.pageSize
    http
      .post("/admin/role/pageQuery", values)
      .then((res) => {
        this.setState({
          data: res.data,
        });

        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };
  getModulTree = () => {
    this.onLoading();
    http
      .post("/admin/funcModule/getAntdTree", "")
      .then((res) => {
        this.setState({
          treeData: res.data.treeData,
          expandedKeys: res.data.havaChildItmes,
        });

        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        Modal.error({
          content: "加载功能树出错",
        });
      });
  };
  
  toAdd = (record) => {
    this.roleForm.current.toAdd(record);
  };

  toEdit = (record) => {
    this.roleForm.current.toEdit(record);
  };

  del = (record) => {
    let list = this.getRoleList
    confirm({
      title: "这是配置信息，确认删除这条记录?",
      icon: <ExclamationCircleOutlined />,
      content: "这如果有其他外关联信息使用就无法删除",
      okText: "确认",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        http
          .post("/admin/role/del?id=" + record.id, "")
          .then((res) => {
            Modal.success({
              content: "删除成功",
            });
            list();
          })
          .catch(() => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            });
          });
      },
      onCancel() {},
    });
  };

  showModulTree = (record) => {
    this.setState({
      visible: true,
      selectedKeys: record.funcModuleIds,
      checkedKeys: record.funcModuleIds,
      selectRoleId: record.id,
    });
  };
  closeModulTree = (record) => {
    this.setState({
      visible: false,
    });
  };
  onCheck = (checkedKeys) => {
    this.setState({
      checkedKeys: checkedKeys,
    });
  };

  onSubmitRoleModules = () => {
    //提交
    this.onLoading();
    let values = {};
    let checkModuleIds = "";
    this.state.checkedKeys.forEach((item) => {
      checkModuleIds = checkModuleIds + item + ",";
    });
    values.roleId = this.state.selectRoleId;
    values.checkModules = checkModuleIds;
    http
      .post("/admin/role/acceptRoleModules", values)
      .then((res) => {
        Modal.success({
          content: "更新数据完成",
          checkedKeys: [],
        });
        this.offLoading();
        this.getRoleList();
      })
      .catch(() => {
        console.log("失败");
        this.offLoading();
      });

    this.setState({
      visible: false,
    });
  };
  render() {
    // 表头
    const columns = [
      {
        title: "角色名称",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        width: 200,
      },
      {
        title: "头像",
        dataIndex: "avatar",
        key: "avatar",
        ellipsis: true,
        width: 200,
        render: (value, record) => {
          if (record.avatar != null) {
            return (
              <div>
                <Avatar src={record.avatar.webPath} />
              </div>
            );
          }
          return <div></div>;
        },
      },

      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        width: 150,
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        width: 200,
        render: (value, record) => {
          return (
            <div>
              <Button type="link" onClick={this.toEdit.bind(this, record)}>
                编辑
              </Button>
              <Button
                type="link"
                onClick={this.showModulTree.bind(this, record)}
              >
                角色授权
              </Button>
              <Button type="link" onClick={this.del.bind(this, record)}>
                删除
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <>
        <Panel
          title="角色管理"
          fontWeight={true}
          bodyPadding="15"
          topRight={
            <Button key="new" icon={<PlusOutlined />} onClick={this.toAdd}>
              新增
            </Button>
          }
          footerBar={
            <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              this.getRoleList()
            }}
          >
            刷新
          </Button>
          }
          footerRight={
              <Pagination
               // 分页
               showTotal={ (total) => {
                return <>共 {total} 条</>
              }}
              showSizeChanger={false}
              showQuickJumper= {true}
              defaultPageSize={this.state.data.size}
              total={ this.state.data.totalElements}
              onChange={(page)=>{
                let values = {}
                values.page = page
                values.pageSize = this.state.pageSize
                this.getRoleList(values)
              }} 
            onRow={(record) => ({
              onDoubleClick: () => {
                this.showModulTree(record);
              },
            })}
              />
          }
        >
          <ResizeTable
            bordered
            size="middle"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={this.state.data.content}
            pagination={false}
          />

          <RoleForm
            ref={this.roleForm}
            getRoleList={this.getRoleList}
            table={this}
          />

          <Drawer
            forceRender={true}
            title={this.state.title}
            width={480}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  onClick={this.closeModulTree}
                  style={{ marginRight: 8 }}
                >
                  取消
                </Button>
                <Button onClick={this.onSubmitRoleModules} type="primary">
                  提交
                </Button>
              </div>
            }
          >
            <Tree
              checkable
              checkedKeys={this.state.checkedKeys}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={this.state.autoExpandParent}
              selectedKeys={this.state.selectedKeys}
              onCheck={(checkedKeys) => {
                this.setState({
                  checkedKeys: checkedKeys,
                });
              }}
              checkStrictly={false}
              treeData={this.state.treeData}
            />
          </Drawer>
        </Panel>
      </>
    );
  }
}

roleMgr.propTypes = {};

export default roleMgr;
