//使用抽屉进行编辑
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Steps } from 'antd'
import React, { Component } from 'react'
import http, { getApiMainPath, isEmptyObject } from '../api/http'

const { Step } = Steps
class CraftTypeForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      imageUrl: null,
      visible: false,
      fileId: '',
      selectCraftType: null,
    }
  }
  toAdd(selectCraftType) {
    console.log('selectCraftType', selectCraftType)
    this.setState({
      visible: true,
      selectCraftType,
      title: '新增员工',
      imageUrl: '',
    })
    if (this.form1.current != null) {
      this.form1.current.resetFields()
    }
  }

  checkForm = () => {
    this.form1.current.submit()
  }
  submit = (values) => {
    values.craftTypeId = this.state.selectCraftType.id
    let url = '/admin/craftTypeEmployee/add'
    values.iconId = this.state.fileId

    //提交
    http
      .post(url, values)
      .then((res) => {
        message.success({
          content: '更新数据完成',
        })
        this.props.table.getEmployee(this.state.selectCraftType)
      })
      .catch((err) => {
        console.log(err)
        console.log('失败')
      })

    this.setState({
      visible: false,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={480}
          onClose={this.onClose}
          onCancel={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="vertical" ref={this.form1} onFinish={this.submit}>
            <Form.Item name="name" label="姓名：" rules={[{ required: true, message: '姓名不能为空' }]}>
              <Input placeholder="请输入员工姓名" />
            </Form.Item>
            <Form.Item name="mobile" label="手机号码：" rules={[{ required: true, message: '手机号码不能为空' }]}>
              <Input placeholder="请输入手机号码"></Input>
            </Form.Item>
            <Form.Item name="newPassWord" label="初始化密码">
              <Input placeholder="输入初始化密码"></Input>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

CraftTypeForm.propTypes = {}

export default CraftTypeForm
