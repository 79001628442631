//使用抽屉进行编辑
import React, { Component } from 'react'
import { Drawer, Form, Button, InputNumber, Col, Row, Steps, Upload, Radio, DatePicker, message, Input, Modal } from 'antd'
import moment from 'moment'
import { isEmptyObject } from '../api/http'
import { getApiMainPath } from '../api/http'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

import http from '../api/http'
const { Step } = Steps
class CraftTypeForm extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      imageUrl: null,
      visible: false,
      fileId: '',
      selectItem: null,
      craftType: null,
    }
  }
  toAdd(selCraftType) {
    this.setState({
      visible: true,
      selectItem: null,
      title: '在[' + selCraftType.name + ']下——新增设备',
      imageUrl: '',
      craftType: selCraftType,
    })
    if (this.form1.current != null) {
      this.form1.current.resetFields()
    }
  }
  toEdit = (inEntity, selCraftType) => {
    if (inEntity.buyTime != null) {
      inEntity.buyTime = moment(inEntity.buyTime)
    }
    console.log(inEntity)
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        craftType: selCraftType,
        selectItem: inEntity,
        title: '修改设备信息',
      })
      this.form1.current.resetFields()
      this.form1.current.setFieldsValue(inEntity)
    } else {
      Modal.error({
        title: '请先选中记录',
        content: '或双击记录可以直接编辑',
      })
    }
  }

  checkForm = () => {
    this.form1.current.submit()
  }
  submit = (values) => {
    let url = '/admin/machine/add'
    if (this.state.selectItem != null) {
      url = '/admin/machine/update'
      values.id = this.state.selectItem.id
    }
    values.buyTime = moment(values.buyTime).format('YYYY-MM-DD HH:mm:ss')
    values.typeId = this.state.craftType.id
    //提交
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: '更新数据完成',
        })
        // this.props.table.getCraftTypeDevices(this.state.craftType)
      })
      .catch((err) => {
        console.log(err)
      })

    this.setState({
      visible: false,
    })
  }
  //上传头像

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={480}
          onCancel={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="vertical" ref={this.form1} onFinish={this.submit}>
            <Form.Item name="name" label="设备名：" rules={[{ required: true, message: '设备名不能为空' }]}>
              <Input placeholder="请输入设备名" />
            </Form.Item>

            <Form.Item name="buyTime" label="购买时间：">
              <DatePicker style={{ width: '100%' }} onOk={this.onOk} placeholder="请输入购买时间" />
            </Form.Item>

            <Form.Item name="openTimeCost" label="设备启动成本：">
              <InputNumber style={{ width: '100%' }} placeholder="请输入设备启动成本 元/小时" />
            </Form.Item>
            <Form.Item name="machineCode" label="设备编码">
              <Input style={{ width: '100%' }} placeholder="设备编码" />
            </Form.Item>
            <Form.Item name="status" label="状态:" initialValue="启用">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="启用">启用</Radio.Button>
                <Radio.Button value="停用">停用</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}

CraftTypeForm.propTypes = {}

export default CraftTypeForm
