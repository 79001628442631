//使用抽屉进行编辑
import {
  Button, Col, Form, Input, InputNumber, Modal, Radio, Row, Space, TreeSelect,Typography
} from "antd";
import React, { Component } from "react";
import http, { isEmptyObject } from "../api/http";
const { Link } = Typography


class SystemForm extends Component {
  constructor(props) {
    super(props);
    this.form1 = React.createRef();
    this.state = {
      visible: false,
      title: "新增配置",
      selectItem: null,
      treeData:[],
      serves:[],
    };
  }
  toAdd() {
    this.setState({
      visible: true,
      selectItem: null,
      title: "新增配置",
    });
    if (this.form1.current != null) {
      this.form1.current.resetFields();
    }
  }
  toEdit = (inEntity) => {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        title: "修改配置",
      });
    } else {
      Modal.error({
        title: "请先选中记录",
        content: "或双击记录可以直接编辑",
      });
    }
    this.form1.current.resetFields();
    this.form1.current.setFieldsValue(inEntity);
  };

  checkForm = () => {
    this.form1.current.submit();
  };

  submit = (values) => {
    let url = "/admin/system/add";
    if (this.state.selectItem != null) {
      url = "/admin/system/update";
      values.id = this.state.selectItem.id;
    }
    let serveTags = ''
    this.state.serves.forEach((tag) => {
      serveTags = serveTags + tag + ','
    })
    values.serves = serveTags
    //提交
    this.props.table.onLoading();
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: "更新数据完成",
        });
        this.props.table.offLoading();
        this.props.getList();
      })
      .catch(() => {
        console.log("失败");
        this.props.table.offLoading();
      });

    this.setState({
      visible: false,
    });
  };

  getSystemServe = () => {
    http
        .post('/admin/system/getSystemServeList',null)
        .then((res) => {
          let treeData = []
          for (let index = 0; index < res.data.length; index++) {
            let data = {}
            data.key = res.data[index].id
            data.label = res.data[index].name
            treeData.push(data)
          }
          this.setState({
            treeData
          })
        })
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  componentDidMount() {
    this.getSystemServe()
  }
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={720}
          onClose={this.onClose}
          onCancel={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="vertical" ref={this.form1} onFinish={this.submit}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="sysPwd"
                  label="外系统密码"
                  rules={[{ required: true, message: "密码不能为空" }]}
                >
                  <Input type="password" placeholder="密码"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="状态"
                  initialValue={'启用'}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="启用">启用</Radio.Button>
                    <Radio.Button value="停用">停用</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="nickName"
                  label="系统昵称"
                >
                  <Input placeholder="昵称"></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="supTags"
                  label={
                    <Space>
                      授权服务:
                    </Space>
                  }
                >
                  <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="选择服务"
                    allowClear
                    multiple
                    treeData={this.state.treeData}
                    treeDefaultExpandAll
                    fieldNames={{key:'id',label:'name'}}
                    onChange={(value) => {
                      this.setState({
                        serves: value,
                      })
                    }}
                  ></TreeSelect>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

SystemForm.propTypes = {};

export default SystemForm;
