import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Upload,
} from "antd";
import Password from "antd/lib/input/Password";
import React, { Component } from "react";
import http from "../api/http";
import { getApiMainPath } from "../api/http";
export default class EmployeeForm extends Component {
  constructor(props) {
    super(props);
    this.form1 = React.createRef();
    this.state = {
      visible: false,
      title: "新增客户",
      selectItem: null,
      orgId: null,
      imgFileId: null,
      imgFileWebPath: null,
      allCraftType: null,
      allMachines:null
    };
  }
  onLoading = () => {
    this.setState({
      loading: true,
    });
  };
  offLoading = () => {
    this.setState({
      loading: false,
    });
  };

  toAdd(orgId) {
    this.setState({
      visible: true,
      selectItem: null,
      title: "新增员工",
      orgId,
    });
    this.getCraftTypes();
    this.getAllMachines();

    if (this.form1.current != null) {
      this.form1.current.resetFields();
    }
  }
  toEdit = (inEntity) => {
    this.getCraftTypes();
    this.getAllMachines();
    this.form1.current.resetFields();
    this.form1.current.setFieldsValue(inEntity);
    let imgFileWebPath = null;
    if (inEntity.avatar != null) {
      imgFileWebPath = inEntity.avatar.webPath;
    }

    this.setState({
      visible: true,
      selectItem: inEntity,
      imgFileWebPath: imgFileWebPath,
      title: "修改员工信息",
    });
  };
  getCraftTypes = () => {
    http
      .post("/admin/craftType/getAllCraftTypes", "")
      .then((res) => {
        console.log(res);
        this.setState({
          allCraftType: res.data.craftsTypes,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllMachines = () => {
    http
      .post("/admin/machine/getAll", "")
      .then((res) => {
        this.setState({
          allMachines: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };


  checkForm = () => {
    console.log(this.form1.current);
    this.form1.current.submit();
  };
  submit = (values) => {
    let url = "/admin/userMgr/add";
    values.orgId = this.state.orgId;
    values.imgFileId=this.state.imgFileId;
    if (this.state.selectItem != null) {
      url = "/admin/userMgr/update";
      values.id = this.state.selectItem.id;
    }
    //提交
    http
      .post(url, values)
      .then((res) => {
        message.success("保存成功");
        this.setState({
          visible: false,
        });
        this.props.table.getEmployeeList(this.state.orgId);
      })
      .catch((err) => {
        message.error("提交失败");
        console.log("失败");
        console.log(err);
      });
  };

  beforeUpload(file) {
    let fileType = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    let checkFile = false;
    if (fileType === "pdf") {
      checkFile = true;
    }
    if (fileType === "jpg") {
      checkFile = true;
    }
    if (fileType === "jpeg") {
      checkFile = true;
    }
    if (fileType === "png") {
      checkFile = true;
    }
    if (!checkFile) {
      message.error("图片只接受pdf/jpg/jpeg/png/pdf文件上传");
    }
    return checkFile;
  }

  //上传零件图
  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ uploading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      this.setState({
        imgFileId:
          info.file.response.data == null ? "" : info.file.response.data.id,
          imgFileWebPath:
          info.file.response.data == null
            ? null
            : info.file.response.data.webPath,
      });
      this.setState({ uploading: false });
    }
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  componentDidMount() {}
  componentWillUnmount() {}
  //组件更新完成
  componentDidUpdate() {}
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={800}
          onCancel={this.onClose}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form
            layout="horizontal"
            autoComplete="off"
            style={{ marginTop: 10 }}
            ref={this.form1}
            labelCol={{ span: 6 }}
            onFinish={this.submit}
          >
            <Form.Item
              name="loginId"
              label="登录帐号"
              rules={[
                {
                  required: true,
                  message: "登录帐号不能为空",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="员工姓名"
              rules={[
                {
                  required: true,
                  message: "员工姓名不能为空",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="mobile"
              label="手机号码"
              rules={[
                {
                  pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                  message: "请输入正确的手机号",
                },
                {
                  required: true,
                  message: "手机号码不能为空",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="uploadAvatar" label="上传头像">
              <Upload
                name="avatarFile"
                maxCount={1}
                fileList={this.state.impFiles}
                action={getApiMainPath() + "/admin/userMgr/uploadAvatar"}
                onChange={this.handleChange}
                beforeUpload={this.beforeUpload}
              >
                {this.state.imgFileWebPath != null ? (
                  <img
                    src={this.state.imgFileWebPath}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  <Button icon={<UploadOutlined />}>上传头像</Button>
                )}
              </Upload>
            </Form.Item>
            <Form.Item name="mainCraftTypeId" label="主工艺:">
              <Select>
                {this.state.allCraftType != null
                  ? this.state.allCraftType.map((item, index) => (
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    ))
                  : ""}
              </Select>
            </Form.Item>
            <Form.Item name="mainMachineId" label="主操作设备:">
              <Select>
                {this.state.allMachines != null
                  ? this.state.allMachines.map((item, index) => (
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    ))
                  : ""}
              </Select>
            </Form.Item>
            <Form.Item name="newPassWord" label="初始化密码">
              <Password />
            </Form.Item>
            <Form.Item name="sex" label="性别" initialValue="男">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="男">男</Radio.Button>
                <Radio.Button value="女">女</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="isSuperUser"
              label="是否超级用户:"
              initialValue="否"
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="是">是</Radio.Button>
                <Radio.Button value="否">否</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="status" label="状态:" initialValue="启用">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="启用">启用</Radio.Button>
                <Radio.Button value="禁用">禁用</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
