import { Button, Col, Form, Modal, DatePicker, Row, Spin, Pagination,message,Space,Input } from 'antd'
import React, { Component } from 'react'
import { Column } from '@ant-design/charts'
import '../../App.css'
import { ReloadOutlined } from "@ant-design/icons";
import http, { getApiMainPath } from '../api/http'
import moment from 'moment'
import Panel from "../../component/etooling-ui/Panel";
import ResizeTable from '../../component/etooling-ui/ResizeTable';
const { RangePicker } = DatePicker
class MachineList extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.state = {
      data:[],
      page: 1,
      pageSize: 15,
      startDate:"",
      endDate:"",
      monthChart:[],
      month:"2021-08",
    }
  }

  //加载完成
  componentDidMount() {
    let values = {}
    values.page = this.state.page
    values.pageSize = this.state.pageSize
    this.getList(values)
    this.loadData()
  }

  onLoading() {
    this.setState({
      loading: true,
    })
  }

  offLoading() {
    this.setState({
      loading: false,
    })
  }

  loadData = () => {
    let values = { dateMonth: this.state.month }
    http
      .post('/admin/machine/employeeCraftCnt', values)
      .then((res) => {
        let monthChart = res.data.monthChart
        this.setState({
          monthChart,
        })
      })
      .catch((err) => {
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }


  getList = (values) => {
    if (values == null) {
      values = {}
    }
    values.page = values.page
    values.pageSize = this.state.pageSize
    values.startDate = this.state.startDate
    values.endDate = this.state.endDate
    http
      .post('/admin/machine/machineList', values)
      .then((res) => {
        this.setState({
          data: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  
  changePage = (page) => {
    let values = {}
    values.page = page
    values.pageSize = this.state.pageSize
      this.getList(values)
  }

  toEdit = (record) => {
    this.form.current.toEdit(record)
  }
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    })
  }
  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    })
      this.getList()
  }

  render() {
    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}
    // 表头
    const columns = [
      {
        title: '机床编号',
        dataIndex: 'code',
        key: 'code',
        ellipsis: true,
        width: 150,
      },
      {
        title: '设备编码',
        dataIndex: 'machine_code',
        key: 'machine_code',
        ellipsis: true,
        width: 150,
      },
      {
        title: '设备名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 200,
      },
      {
        title: '日期',
        dataIndex: 'recode_date',
        key: 'recode_date',
        ellipsis: true,
        width: 150,
      },
      {
        title: '工作时长/秒',
        dataIndex: 'boot_time',
        key: 'boot_time',
        ellipsis: true,
        width: 100,
      },
    ]

    return (
      <Panel
      title="设备管理"
      fontWeight
      topBar={
          <Row>
            <Col span={24} offset={1}>
              <Form layout="inline" ref={this.searchForm} onFinish={(value) => {
                this.setState({
                  componentName : value.componentName
                })
                    this.getList(value)
              }}>
                <Form.Item label="筛选条件" />
                <Form.Item label="物料名称" name="componentName">
                  <Input placeholder="图号或物料名称" />
                </Form.Item>
                <Form.Item label="时段">
                <RangePicker
                  onChange={(date, dateString) => {
                    let startDate = dateString[0]
                    let endDate = dateString[1]
                    this.setState({ startDate, endDate })
                  }}
                ></RangePicker>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
              </Form.Item>
              </Form>
            </Col>
          </Row>
          }
          footerBar={
            <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              this.getList()
            }}
          >
            刷新
          </Button>
          }
        footerRight={
                  <Pagination
              // 分页
              showTotal={ (total) => {
                return <>共 {total} 条</>
              }}
              showSizeChanger={ false}
              showQuickJumper={true}
              defaultPageSize={15}
              total={this.state.data.totalElements}
              onChange={ (page) => {
                let values = {}
                values.page = page
                values.pageSize = this.state.pageSize
                  this.getList(values)
              }}
              onRow={(record) => ({
                onDoubleClick: () => {
                  this.toEdit(record)
                },
              })}
              />
      }
         >
          <ResizeTable
          bordered
            search={false}
            size="small"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={this.state.data.content}
            pagination={false}
          />
      <Space>
      <DatePicker
      defaultValue={moment(this.state.month, 'YYYY-MM')}
      onChange={(date, dateString) => {
        this.setState({
          month: dateString,
        })
      }}
      picker="month"
    />
    <Button
      onClick={() => {
        this.loadData()
      }}
    >
      刷新
    </Button>
    </Space>
         <Column style={{ height: '300px' }} data={this.state.monthChart} isGroup={true} xField="月份" yField="支出" seriesField="name" label={{
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ]}} />
      </Panel>
    )
  }
}

export default MachineList
