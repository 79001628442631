import { message } from 'antd'
import React, { Component } from 'react'
import Storage from '../../../utils/Storage'

import '../../../assets/css/MobileOrder.css'
import http from '../../api/http'
class MobileOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      workOrderCntByUser: {
        waitAcceptOrderCntInfo: {},
        todayDoneCraft: {},
      },
      newWorkOrderOrderGroup: [],
      processWorkOrderGroupList: [],
      newWorkOrderOrderGroupList: [],
      outProcessWorkOrderGroup: [],
      outTypeWorkOrderOrderGroup: [],
      isShowPrice: '是',
      workOrderInfo: {
        process: {},
        newWorkOrder: {
          new_order_detai: {
            material_detail: [],
            craft_detail: [],
          },
        },
      },
    }
  }

  DivOnClick = (e) => {
    this.props.getChildrenMsg(this, e)
  }

  getWorkOrderListByEmployee = (employeeId) => {
    if (employeeId) {
      let values = { loginUserId: employeeId }
      http
        .post('/admin/workOrder/getByWorkOrderEmployeeCnt', values)
        .then((res) => {
          this.setState({
            workOrderCntByUser: res.data,
          })
        })
        .catch((err) => {
          console.log(err)
          // message.error({
          //   content: '数据加载失败请联系系统管理员',
          // })
        })
      this.getByWorkOrderListEmployee(employeeId)
    } else {
      this.setState({
        workOrderCntByUser: {
          waitAcceptOrderCntInfo: {},
          todayDoneCraft: {},
        },
        newWorkOrderOrderGroup: [],
        processWorkOrderGroupList: [],
        newWorkOrderOrderGroupList: [],
        outProcessWorkOrderGroup: [],
        outTypeWorkOrderOrderGroup: [],
        workOrderInfo: {
          process: {},
          newWorkOrder: {
            new_order_detai: {
              material_detail: [],
              craft_detail: [],
            },
          },
        },
      })
    }
  }

  componentDidMount() {
    if (JSON.parse(Storage.get('loginUser')).company.isShowPrice !== undefined) {
      this.setState({ isShowPrice: JSON.parse(Storage.get('loginUser')).company.isShowPrice })
    }
  }

  getByWorkOrderListEmployee(employeeId) {
    let values = { loginUserId: employeeId }
    let processWorkOrderGroupList = []
    let newWorkOrderOrderGroup = []
    let newWorkOrderOrderGroupList = []
    let outTypeWorkOrderOrderGroup = []
    if (employeeId != null && employeeId !== '') {
      http
        .post('/admin/workOrder/getByWorkOrderListEmployee', values)
        .then((res) => {
          if (res.data.processWorkOrderGroup != null) {
            processWorkOrderGroupList = res.data.processWorkOrderGroup
          }
          if (res.data.outProcessWorkOrderGroup.content != null) {
            let outPgroup = res.data.outProcessWorkOrderGroup.content
            for (let o = 0; o < outPgroup.length; o++) {
              processWorkOrderGroupList.push(outPgroup[o])
            }
          }

          newWorkOrderOrderGroup = res.data.newWorkOrderOrderGroup
          newWorkOrderOrderGroupList = res.data.newWorkOrderOrderGroup
          outTypeWorkOrderOrderGroup = res.data.outTypeWorkOrderOrderGroup.content

          for (let o = 0; o < outTypeWorkOrderOrderGroup.length; o++) {
            let outOg = outTypeWorkOrderOrderGroup[o]
            outOg.isOut = '是'
            newWorkOrderOrderGroupList.push(outOg)
          }
          console.log(processWorkOrderGroupList)
          this.setState({
            processWorkOrderGroupList,
            newWorkOrderOrderGroup,
            newWorkOrderOrderGroupList,
            outTypeWorkOrderOrderGroup,
          })
        })
        .catch((err) => {
          console.log(err)
          // message.error({
          //   content: '数据加载失败请联系系统管理员',
          // })
        })
    } else {
      this.setState({
        workOrderCntByUser: {
          waitAcceptOrderCntInfo: {},
          todayDoneCraft: {},
        },
        newWorkOrderOrderGroup: [],
        processWorkOrderGroupList: [],
        newWorkOrderOrderGroupList: [],
        outProcessWorkOrderGroup: [],
        outTypeWorkOrderOrderGroup: [],
        workOrderInfo: {
          process: {},
          newWorkOrder: {
            new_order_detai: {
              material_detail: [],
              craft_detail: [],
            },
          },
        },
      })
    }
  }

  render() {
    return (
      <div>
        <div className="statistics">
          <div className="statistics_switch">
            <div className="switch">
              <div className="orders">
                <div className="list">
                  <div className="item">
                    <p>
                      {this.state.workOrderCntByUser != null ? this.state.workOrderCntByUser.waitAcceptOrderCntInfo.cnt : '0'}
                      <span>单</span>
                    </p>
                    <p>可接工单</p>
                    <p>
                      {this.state.workOrderCntByUser == null || this.state.workOrderCntByUser.waitAcceptOrderCntInfo.component_num == null
                        ? '0'
                        : this.state.workOrderCntByUser.waitAcceptOrderCntInfo.component_num}
                      件零件
                      {this.state.isShowPrice === '是' ? (
                        <>
                          /
                          {this.state.workOrderCntByUser == null || this.state.workOrderCntByUser.waitAcceptOrderCntInfo.price == null
                            ? '0.00'
                            : parseFloat(this.state.workOrderCntByUser.waitAcceptOrderCntInfo.price).toFixed(2)}{' '}
                          元
                        </>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                  <div className="item">
                    <p>
                      {this.state.workOrderCntByUser == null
                        ? '0'
                        : this.state.workOrderCntByUser.todayDoneCraft.cnt == null
                        ? '0'
                        : this.state.workOrderCntByUser.todayDoneCraft.cnt}
                      <span>单</span>
                    </p>
                    <p>今天完成</p>
                    <p>
                      {this.state.workOrderCntByUser.todayDoneCraft.submit_step_cnt == null ? '' : this.state.workOrderCntByUser.todayDoneCraft.submit_step_cnt}工步
                      {this.state.isShowPrice === '是' ? (
                        <>/{this.state.workOrderCntByUser.todayDoneCraft.price == null ? '0.00' : parseFloat(this.state.workOrderCntByUser.todayDoneCraft.price).toFixed(2)}元</>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="order">
          <div style={{ padding: '4%'}}>
            {this.state.processWorkOrderGroupList != null
              ? this.state.processWorkOrderGroupList.map((processWorkOrderGroup, index) => (
                  <div className="order_change" onClick={() => this.DivOnClick(processWorkOrderGroup)}>
                    <div className="order_title">
                      <div className="title">
                        <p>在制 : {processWorkOrderGroup.craftTypeName}</p>
                        <p className="btn">图纸</p>
                      </div>
                      <p>总工价 : {parseFloat(processWorkOrderGroup.price).toFixed(2)}元</p>
                      <p>项目号 : {processWorkOrderGroup.extraOrderCode}</p>
                      <p className="title_m">
                        {processWorkOrderGroup.componentName}[{processWorkOrderGroup.componentCode}]
                      </p>
                      <div className="list">
                        <div className="item">
                          <p>计划时间 :{processWorkOrderGroup.planTime != null && processWorkOrderGroup.planTime !== undefined ? processWorkOrderGroup.planTime : ''}</p>
                          <p>
                            完成数 : {processWorkOrderGroup.doneNum}/{processWorkOrderGroup.stepNum}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="order_con" id="selector1">
                      <div className="list">
                        {processWorkOrderGroup.workOrders != null
                          ? processWorkOrderGroup.workOrders.map((item, index) => (
                              <div className="item">
                                <div className="order_conduct1">
                                  <div className="title">
                                    <div className="icon_x" style={{ backgroundColor: '#3c65bd', boxShadow: '0 0 5px #3c65bd' }}></div>
                                    <div className="text">
                                      <p>
                                        {item.craftName}[{parseFloat(item.price * item.componentNum).toFixed(2)}元]
                                      </p>
                                      <p>计划时间:{item.planTime}</p>
                                      <div class="con">
                                        <div class="remarks">
                                          <p>{item.doneNum}</p>
                                          <p>已提交</p>
                                        </div>
                                        <div class="remarks">
                                          <p>{item.componentNum}</p>
                                          <p>总量</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : ''}
                      </div>
                    </div>
                  </div>
                ))
              : ''}

            {this.state.newWorkOrderOrderGroupList != null
              ? this.state.newWorkOrderOrderGroupList.map((newOrderGroup, index) => (
                  <div className="order_change" onClick={() => this.DivOnClick(newOrderGroup)}>
                    <div className="order_title" style={{ background: '#f17d00' }}>
                      <div className="title">
                        <p>待接 : {newOrderGroup.craftTypeName}</p>
                        <p className="btn">工艺卡</p>
                        <p className="btn">图纸</p>
                      </div>
                      <p>项目号 : {newOrderGroup.extraOrderCode}</p>
                      <p className="title_m">
                        {newOrderGroup.componentName}[{newOrderGroup.componentCode}]
                      </p>
                      <div className="list">
                        <div className="item">
                          <p>计划时间 : {newOrderGroup.planTime != null && newOrderGroup.planTime !== undefined ? newOrderGroup.planTime : ''}</p>
                          <div style={{ overflow: 'hidden', float: 'right' }}>
                            <p>零件数 :{newOrderGroup.componentNum}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="order_con">
                      <div className="list">
                        <div className="item">
                          {newOrderGroup.workOrders != null
                            ? newOrderGroup.workOrders.map((spwo, index) => (
                                <div className="order_conduct1">
                                  {/* <div className="lable"><p>11</p></div> */}
                                  <div className="title">
                                    <div style={{ float: 'left' }}>
                                      <div className="icon_x">{/* <uni-icons type="star-filled" size="26" color="#fff" /> */}</div>
                                      <div className="text">
                                        <p>{spwo.craftName}[{spwo.memo !== '' ? spwo.memo : ''}]</p>
                                        <p>派单时间 : {spwo.assignTime}</p>
                                      </div>
                                    </div>
                                    <div style={{ overflow: 'hidden', float: 'right', display: 'flex', alignItems: 'center' }}>
                                      {/* <p style={{ display: 'block', float: 'left', fontSize: '12px' }}>工价 :{parseFloat(spwo.price * spwo.componentNum).toFixed(2)}</p> */}
                                      {/* <p style={{ display: 'block', float: 'left', fontSize: '12px' }}>元</p> */}
                                    </div>
                                  </div>
                                </div>
                              ))
                            : ''}
                        </div>
                      </div>
                      <div className="list">
                        <div className="item">
                          <div className="order_conduct1">
                            <div className="operation1">
                              <div style={{ overflow: 'hidden', float: 'left', display: 'flex', alignItems: 'center' }}>
                                {/* <p style={{ display: 'block', float: 'left' }}>总工价 :</p>
                                {parseFloat(newOrderGroup.price).toFixed(2)}
                                <p style={{ display: 'block', float: 'left' }}>元</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
    )
  }
}

export default MobileOrder
