import { DatePicker, Spin } from 'antd'
import React, { Component } from 'react'
import './ProjectSchedule.css'
import http, { HashMap } from '../../api/http'
const { RangePicker } = DatePicker

class ProjectSchedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //colorList: [],
      selectedColor: '',
      dateWidth: props.dateWidth == null ? 200 : props.dateWidth,
      dateMaxValue: 8,
      orders: [{}],
      activatedOrderIds: [],
      xField:
        props.xField == null
          ? [
              { key: '2021-06-21', title: '21', subtitle: '星期一' },
              { key: '2021-06-22', title: '22', subtitle: '星期二' },
              { key: '2021-06-23', title: '23', subtitle: '星期三' },
              { key: '2021-06-24', title: '24', subtitle: '星期四' },
              { key: '2021-06-25', title: '25', subtitle: '星期五' },
              { key: '2021-06-26', title: '26', subtitle: '星期六' },
              { key: '2021-06-27', title: '27', subtitle: '星期日' },
            ]
          : props.xField,
      yField:
        props.yField == null
          ? [
              { key: '1', title: '钻', color: '#666', allValue: 15 },
              { key: '2', title: '车', color: '#666', allValue: 13 },
              { key: '3', title: '铣', color: '#666' },
              { key: '4', title: '焊', color: '#666' },
            ]
          : props.yField,
      data: null,
      orderColor: {},
      orderData: [],
      dataCol: {},
      isLoading: true,
    }
  }

  initShedule = (xField, yField, dataCol, isLoading) => {
    // alert(1)
    let data = {}
    this.setState({ xField, yField, data, dataCol, isLoading })
  }
  setOrderColor = (orderColor) => {
    this.setState({ orderColor })
  }
  removeOrderToPlan = (item, orderColor) => {
    this.state.orderData.forEach((orderData, orderIndex) => {
      console.log(orderData)
      console.log(orderIndex)
      if (orderData.order_id === item.order_id) {
        this.state.orderData.splice(orderIndex, 1)
      }
    })
    this.setState({ orderColor, orderColor })
    let data = this.state.data
    // //循环各工艺
    let craftTypes = this.state.yField
    craftTypes.forEach((ct) => {
      // console.log(orderInfo[ct.title])
      let craftTypeLine = data[ct.key] == null ? {} : data[ct.key]
      let craftTypeOrders = craftTypeLine.craftData == null ? [] : craftTypeLine.craftData
      craftTypeOrders.forEach((craftData, orderIndex) => {
        if (craftData.orderId === item.order_id) {
          craftTypeOrders.splice(orderIndex, 1)
          craftTypeLine.craftData = craftTypeOrders
          data[ct.key] = craftTypeLine
        }
      })
    })
    this.setState({ data })
  }

  refreshShedule = (data) => {}
  componentDidMount() {
    // this.getColor()
  }

  saveOrderSort = (startData, endData, workDayTime) => {
    let values = {}
    values.orderList = JSON.stringify(this.state.orderData)
    values.startData = startData
    values.endData = endData
    values.workDayTime = workDayTime
    console.log(this.state.orderData)
    http.post('/admin/plan/saveProjectPlan', values).then((res) => {
      window.location.href = './projectSchedule?projectPlanId=' + res.data.id + '&workDayTime=' + res.data.workDayTime
    })
  }

  addOrderToPlan = (item, orderColor) => {
    let order = {}
    order = item
    order.orderColor = orderColor[item.order_id]
    this.state.orderData.push(order)
    this.setState({ orderColor, orderColor })
    let data = this.state.data
    // //循环各工艺
    let craftTypes = this.state.yField
    craftTypes.forEach((ct) => {
      let craftTypeLine = data[ct.key] == null ? {} : data[ct.key]
      let craftTypeOrders = craftTypeLine.craftData == null ? [] : craftTypeLine.craftData
      let orderInfo = { orderId: item.order_id, orderCode: item.extra_order_code, orderName: item.order_name, dataValue: item[this.state.dataCol[ct.title]] }
      craftTypeOrders.push(orderInfo)
      craftTypeLine.craftData = craftTypeOrders
      data[ct.key] = craftTypeLine
    })
    this.setState({ data })
  }

  render() {
    let { columns, isLoading } = this.state

    if (isLoading) {
      return (
        <div
          style={{
            display: 'block',
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(255,255,255,0.94)',
            zIndex: '99',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <Spin size="large" />
          </div>
        </div>
      )
    }

    return (
      <div style={{ overflow: 'hidden', padding: '1%' }}>
        <div className="ProjectSchedule">
          <div class="b">
            <div className="l">
              {/* <p className="title">在产</p> */}
              <ul className="type">
                {this.state.yField.map((item, index) => (
                  <li>
                    <span style={{ background: item.color == null ? '#666' : item.color }}>
                      <p>
                        {item.title}
                        <br></br>[{parseInt(item.allValue)}]小时
                      </p>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="r">
              <div className="list" style={{ width: this.state.xField.length * this.state.dateWidth + 'px' }}>
                <div className="con">
                  <div className="data">
                    <div class="dataList">
                      {/* <p className="year">2019年3月</p> */}
                      <dl>
                        {this.state.xField.map((item, index) => (
                          <dd style={{ width: this.state.dateWidth + 'px' }}>
                            {item.title}[{item.subtitle}]
                          </dd>
                        ))}
                      </dl>
                    </div>
                  </div>
                  <ul>
                    {this.state.yField.map((craftType, craftIndex) => (
                      <li>
                        <>
                          <dl
                            style={{
                              // left: this.state.dateWidth * 1 + 5 + 'px',
                              width: (parseInt(this.state.dateWidth) * parseInt(craftType.allValue)) / parseInt(this.state.dateMaxValue) - 10 + 'px',
                              background: '#e6e6e6',
                            }}
                          >
                            {this.state.data[craftType.key] == null
                              ? ''
                              : this.state.data[craftType.key].craftData.map((craftData, dIndex) => (
                                  <dd
                                    style={{
                                      background: this.state.orderColor[craftData.orderId] == null ? '#D0021B' : this.state.orderColor[craftData.orderId],
                                      width:
                                        craftType.allValue != null && craftType.allValue > 0 && craftData.dataValue > 0
                                          ? parseInt((parseInt(craftData.dataValue) / parseInt(craftType.allValue)) * 100) + '%'
                                          : '0%',
                                    }}
                                  >
                                    <p>
                                      {parseInt(craftData.dataValue)}小时
                                      <br />
                                      {craftData.orderName}
                                    </p>
                                  </dd>
                                ))}
                          </dl>
                        </>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ProjectSchedule.propTypes = {}

export default ProjectSchedule
