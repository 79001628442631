import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { Route, Switch } from 'react-router-dom'
import './App.css'
import AdminBody from './pages/admin_body/admin_body'
import ShowPdf from './pages/ipad_view/ShowPdf_ipad'
import Login from './pages/login/login'
import WorkOrderScreen from './pages/analysis/WorkOrderScreen'
import ProjectSchedule from './pages/etooling/projectSchedule/ProjectPlan'
// import ProjectSchedule from './pages/etooling/projectSchedule/ProjectSchedule'
import FullScreenIndex from './pages/analysis/FullScreenIndex'
import Detail from './pages/etooling/workorder/Detail'
import MyRouter from './utils/MyRouter'
import IpadLogin from './pages/ipad_view/IpadLogin'
import TestLogin from './pages/ipad_view/IpadLoginTest'
import TestCnc from './pages/ipad_view/test_cnc'
import Test from './pages/test/Test'
moment.locale('zh-cn')

function App() {
  document.title = '佛山易图灵'
  return (
    <ConfigProvider locale={zhCN}>
      <div className="App">
        <Switch>
          <MyRouter path="/" exact component={AdminBody} />
          <Route path="/fullScreenIndex" exact component={FullScreenIndex} />
          <Route path="/projectSchedule" exact component={ProjectSchedule} />
          <Route path="/workOrderScreen" exact component={WorkOrderScreen} />
          <Route path="/detail" component={Detail} />
          <Route path="/login" component={Login} />
          <Route path="/ShowPdf_ipad" component={ShowPdf} />
          <Route path="/IpadLogin" component={IpadLogin} />
          <Route path="/Test" component={Test} />
          <Route path="/TestLogin" component={TestLogin}></Route>
          <Route path="/TestCnc" component={TestCnc}></Route>
        </Switch>
      </div>
    </ConfigProvider>
  )
}

export default App
