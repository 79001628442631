//使用抽屉进行编辑
import React, { Component } from "react";
import { Drawer, Form, Button, Col, Row, Input, Radio, Modal } from "antd";
import { isEmptyObject } from "../api/http";
import http from "../api/http";
class FileSourceForm extends Component {
  constructor(props) {
    super(props);
    this.form1 = React.createRef();
    this.state = {
      visible: false,
      title: "新增存储方案",
      selectItem: null,
      fileSourceTypes: [],
    };
  }
  toAdd() {
    this.setState({
      visible: true,
      selectItem: null,
      title: "新增存储方案",
    });
    if (this.form1.current != null) {
      this.form1.current.resetFields();
    }
  }
  toEdit = (inEntity) => {
    if (!isEmptyObject(inEntity)) {
      this.setState({
        visible: true,
        selectItem: inEntity,
        title: "修改存储方案",
      });
    } else {
      Modal.error({
        title: "请先选中记录",
        content: "或双击记录可以直接编辑",
      });
    }
    this.form1.current.resetFields();
    this.form1.current.setFieldsValue(inEntity);
  };
  checkForm = () => {
    this.form1.current.submit();
  };
  submit = (values) => {
    let url = "/admin/fileSource/add";
    if (this.state.selectItem != null) {
      url = "/admin/fileSource/update";
      values.id = this.state.selectItem.id;
    }
    //提交
    this.props.table.onLoading();
    http
      .post(url, values)
      .then((res) => {
        Modal.success({
          content: "更新数据完成",
        });
        this.setState({
          selectItem: null,
        });
        this.props.table.offLoading();
        this.props.getList();
      })
      .catch(() => {
        console.log("失败");
        this.props.table.offLoading();
      });

    this.setState({
      visible: false,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  getFileSourceTypes() {
    let sarchParams = {};
    sarchParams.groupName = "云存储";
    http
      .post("/admin/DataDir/getConfByGroupName", sarchParams)
      .then((res) => {
        let sourceTypeArray = [];
        if (res.data.content != null) {
          res.data.content.forEach(function (sourceType) {
            let option = {};
            option.label = sourceType.displayValue;
            option.value = sourceType.dirValue;
            sourceTypeArray.push(option);
          });
        }
        this.setState({
          fileSourceTypes: sourceTypeArray,
        });
      })
      .catch(() => {
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  }
  componentDidMount() {
    this.getFileSourceTypes();
  }
  componentWillUnmount() {}
  //组件更新完成
  render() {
    return (
      <div>
        <Modal
          forceRender={true}
          title={this.state.title}
          width={720}
          onCancel={this.onClose}
        onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
          footer={
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button onClick={this.checkForm} type="primary">
                提交
              </Button>
            </div>
          }
        >
          <Form layout="vertical" ref={this.form1} onFinish={this.submit}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="alias"
                  label="存储方案名称"
                  rules={[{ required: true, message: "存储方案名称不能为空" }]}
                >
                  <Input placeholder="存储方案名称" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="type"
                  label="存储方式"
                  rules={[{ required: true, message: "请选择存储方式" }]}
                >
                  <Radio.Group
                    options={this.state.fileSourceTypes}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="bucket"
                  label="文件存放根目录"
                  rules={[{ required: true, message: "根目录不能为空" }]}
                >
                  <Input placeholder="根目录/bucket"></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="accessKey" label="accessKey">
                  <Input placeholder="accessKey"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="secretKey" label="secretKey">
                  <Input placeholder="secretKey"></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="jsImportModuleName" label="js通知存储模块">
                  <Input placeholder="js通知存储模块"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="implClassName" label="后台通知存储模块">
                  <Input placeholder="后台通知存储模块"></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="basePath" label="访问文件根路径">
                  <Input placeholder="访问文件根路径"></Input>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

FileSourceForm.propTypes = {};

export default FileSourceForm;
