import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleOutlined, PushpinOutlined } from '@ant-design/icons'
import { Col, Form, Input, message, Modal, Row, Select, Space, Tree, Typography } from 'antd'
import { Option } from 'antd/lib/mentions'
import confirm from 'antd/lib/modal/confirm'
import React, { Component } from 'react'
import http from '../api/http'
import Panel from '../api/Panel'
const { DirectoryTree } = Tree
const { Link } = Typography
export default class TagTree extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      sampleTreeData: [
        {
          title: '0-0',
          key: '0-0',
          children: [],
        },
        {
          title: '0-1',
          key: '0-1',
          children: [
            { title: '0-1-0-0', key: '0-1-0-0' },
            { title: '0-1-0-1', key: '0-1-0-1' },
            { title: '0-1-0-2', key: '0-1-0-2' },
          ],
        },
        {
          title: '0-2',
          key: '0-2',
        },
      ],
      expandedRowKeys: [],
      checkedKeysValue: [],
      selectedKeys: [],
      treeData: null,
      shearType: '参数设置',
      showForm: false,
      selectItem: null,
      parentId: '',
      isAdd: true,
    }
  }

  //加载完成
  componentDidMount() {
    this.getTree()
  }

  getTree = (values) => {
    if (values == null) {
      values = {}
      values.target = this.props.target
      values.rootName = this.props.rootName
      values.shearType = this.state.shearType
    }

    http
      .post('/admin/tag/getTagTree', values)
      .then((res) => {
        console.log(res.data.tree)

        this.setState({
          treeData: this.props.showRootNode ? res.data.tree : res.data.tree[0].children,
          expandedRowKeys: res.data.expandedIds,
        })
      })
      .catch(() => {
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  toAdd = () => {
    this.setState({
      showForm: true,
      isAdd: true,
    })
    if (this.form1.current != null) {
      this.form1.current.resetFields()
    }
  }
  toEdit = () => {
    if (this.state.selectItem == null) {
      message.error('请先选择要删除分类，根目录并不能修改')
    } else {
      this.setState({
        showForm: true,
        isAdd: false,
      })
      this.form1.current.resetFields()
      this.form1.current.setFieldsValue(this.state.selectItem)
    }
  }

  delete = () => {
    let getTree = this.getTree

    if (this.state.selectItem == null) {
      message.error('请先选择要删除分类，根目录并不能删除')
    } else {
      let tagId = this.state.selectItem.id
      confirm({
        title: '这是配置信息，确认删除这条记录?',
        icon: <ExclamationCircleOutlined />,
        content: '这如果有其他外关联信息使用就无法删除',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          http
            .post('/admin/tag/del?tagId=' + tagId, '')
            .then((res) => {
              message.success('删除成功')
              getTree()
            })
            .catch((err) => {
              getTree()
            })
        },
        onCancel() {},
      })
    }
  }
  submit = (values) => {
    let url = '/admin/tag/add'
    console.log(this.props.target)
    values.target = this.props.target
    values.shearType = this.state.shearType
    values.tagId = this.state.selectItem == null ? '' : this.state.selectItem.id
    if (!this.state.isAdd) {
      url = '/admin/tag/edit'
    }
    //提交

    let this_ = this
    http
      .post(url, values)
      .then((res) => {
        message.success('保存成功')
        let expandedRowKeys = this_.state.expandedRowKeys
        console.log(expandedRowKeys)
        this_.setState({
          showForm: false,
          expandedRowKeys,
        })
        this_.getTree()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <>
        <div style={this.props.style}>
          <Panel
            extra={
              true === this.props.showMgrBar ? (
                <>
                  <Space>
                    <Link
                      type="link"
                      onClick={() => {
                        this.toAdd()
                      }}
                    >
                      <PlusCircleOutlined style={{ marginRight: '5px' }} />
                      添加
                    </Link>
                    <Link
                      type="link"
                      onClick={() => {
                        this.toEdit()
                      }}
                    >
                      <EditOutlined style={{ marginRight: '5px' }} />
                      修改
                    </Link>
                    <Link
                      type="link"
                      onClick={() => {
                        this.delete()
                      }}
                    >
                      <DeleteOutlined style={{ marginRight: '5px' }} />
                      删除
                    </Link>
                  </Space>
                </>
              ) : (
                ''
              )
            }
            title={
              true === this.props.showShareType ? (
                <Select
                  defaultValue="公司"
                  onChange={(value) => {
                    this.setState({ shearType: value })
                    let values = {}
                    values.target = this.props.target
                    values.rootName = this.props.rootName
                    values.shearType = value
                    this.getTree(values)
                  }}
                  bordered={false}
                >
                  <Option value="公司">
                    <PushpinOutlined style={{ marginRight: '5px' }} />
                    公司
                  </Option>
                  <Option value="个人">
                    <PushpinOutlined style={{ marginRight: '5px' }} />
                    个人
                  </Option>
                  <Option value="共享">
                    <PushpinOutlined style={{ marginRight: '5px' }} />
                    共享
                  </Option>
                </Select>
              ) : (
                ''
              )
            }
          ></Panel>
          <div style={{ clear: 'both' }}>
            {this.state.treeData == null ? (
              ''
            ) : (
              <DirectoryTree
                expandAction={false}
                rowKey={(record) => record.key}
                style={{ marginTop: '10px' }}
                checkable={this.props.checkable}
                defaultExpandedKeys={this.state.expandedRowKeys}
                onCheck={(checkedKeysValue, info) => {
                  this.setState({ checkedKeysValue })
                }}
                onSelect={(selectedKeys, info) => {
                  console.log(info)
                  console.log(selectedKeys)
                  if (info.node != null) {
                    this.setState({ selectItem: info.node.itemObject, parentId: selectedKeys.key })
                  } else {
                    this.setState({ selectItem: null, parentId: null })
                  }
                  //   this.setState({ selectedKeys })
                  // this.getEmployeeList(info.node.key)
                  // this.setState({ selectOrg: info.node })
                }}
                // selectedKeys={this.state.selectedKeys}
                treeData={this.state.treeData}
              />
            )}
          </div>
        </div>
        <Modal
          forceRender={true}
          okText="提交"
          cancelText="取消"
          width={400}
          title={
            this.state.selectItem == null ? '在-[' + this.state.shearType + ']-添加'  : '在-[' + this.state.selectItem.name + ']--下添加'
          }
          visible={this.state.showForm}
          onOk={() => {
            this.form1.current.submit()
          }}
          onCancel={() => {
            this.setState({ showForm: false })
          }}
        >
          <Form ref={this.form1} onFinish={this.submit}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="name" label="分类名称:" rules={[{ required: true, message: '模块名称不能为空' }]}>
                  <Input placeholder="分类名称" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    )
  }
}
