import React, { Component } from "react";

class Container extends Component {
  constructor(props) {
    super(props);
  }

  //flex   占用大小比例控制(默认1)
  //flexDirection   如果false则横向(默认纵向)

  //titleCon   标题框右边内容区(string)
  //topCon   顶部内容区(string)
  //topBorder   顶部内容区线(默认false)
  //footerCon   底部内容区(string)
  //footerBorder   底部内容区线(默认false)

  //conPadding   中间内容区间距(默认0px)

  render() {
    return (
      <>
        {/* 容器区 */}
        <div
          style={{
            display: "flex",
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: this.props.overflow ? "initial" : "hidden",
            flex: this.props.flex ? this.props.flex : 1,
            marginTop: this.props.marginTop + "px",
            marginBottom: this.props.marginBottom + "px",
            marginLeft: this.props.marginLeft + "px",
            marginRight: this.props.marginRight + "px",
            flexDirection:
              this.props.flexDirection == false ? "initial" : "column",
            padding: this.props.padding + "px",
            borderTopWidth:
              this.props.border || this.props.borderTop ? "1px" : "inherit",
            borderBottomWidth:
              this.props.border || this.props.borderBottom ? "1px" : "inherit",
            borderLeftWidth:
              this.props.border || this.props.borderLeft ? "1px" : "inherit",
            borderRightWidth:
              this.props.border || this.props.borderRight ? "1px" : "inherit",
            borderStyle: "solid",
            borderColor: this.props.borderColor
              ? this.props.borderColor
              : "#306ce6",
            boxSizing: "border-box",
            background: this.props.background ? this.props.background : "#fff",
          }}
        >
          {/* 标题内容框 */}
          {this.props.title || this.props.titleCon ? (
            <div
              style={{
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                padding: "10px 15px",
                lineHeight: "35px",
                background: this.props.background
                  ? this.props.background
                  : "none",
                borderBottomWidth: "1px",
                borderStyle: "solid",
                borderColor: this.props.borderColor
                  ? this.props.borderColor
                  : "#ddd",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                boxSizing: "border-box",
              }}
            >
              <p
                //标题
                style={{
                  flex: "1",
                  fontSize: "18px",
                  color: "#000",
                  fontWeight: this.props.fontWeight ? "bold" : "",
                }}
              >
                {this.props.title}
              </p>
              {this.props.titleCon ? (
                <div
                  //标题框右边内容
                  style={{
                    overflow: "hidden",
                  }}
                >
                  {this.props.titleCon}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {/* 顶部内容区 */}
          {this.props.topCon ? (
            <div
              style={{
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                padding: "15px",
                lineHeight: "35px",
                background:
                  this.props.background != null
                    ? this.props.background
                    : "none",
                borderBottom: this.props.topBorder ? "1px solid #ddd" : "none",
              }}
            >
              {this.props.topCon}
            </div>
          ) : (
            ""
          )}

          {/* 中间内容区 */}
          <div
            style={{
              overflow: "auto",
              display: "flex",
              flex: "1",
              flexDirection:
                this.props.flexDirection == false ? "initial" : "column",
              padding:
                (this.props.bodyPadding ? this.props.bodyPadding : "0") + "px",
              // paddingTop: "0px",
              // paddingBottom: "0px",
            }}
          >
            {this.props.children}
          </div>

          {/* 底部内容区 */}
          {this.props.footerCon ? (
            <div
              style={{
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                padding: "15px",
                lineHeight: "35px",
                background:
                  this.props.background != null
                    ? this.props.background
                    : "none",
                borderTop: this.props.footerBorder ? "1px solid #ddd" : "none",
                boxSizing: "border-box",
              }}
            >
              {this.props.footerCon}
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default Container;
