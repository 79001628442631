import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import ProDescriptions from '@ant-design/pro-descriptions'
import { Button, Descriptions, Form, Input, InputNumber, message, Modal, Radio, Space } from 'antd'
import React, { Component } from 'react'
import ReactToPrint from 'react-to-print'
import http from '../api/http'
class OrderOffer extends Component {
  constructor(props) {
    super(props)
    this.form1 = React.createRef()
    this.state = {
      visible: false,
      orderComponts: [],
      order: [],
      freeItemCount: 0,
      freeItemList: [],
      orderPrice: 0,
      componentsPrice:[]
    }
  }

  toOffer = (order, orderComponts) => {
    console.log(orderComponts)
    //拿出收费项
    http
      .post('/admin/freeItem/pageQuery', null)
      .then((res) => {
        console.log(res)
        let componentsPrice = this.state.componentsPrice
        for (let index = 0; index < res.data.content.length; index++) {
          if(res.data.content[index].status === '已拆解'){
            componentsPrice[index].id  = res.data.content[index].id
          }
          
        }
        this.setState({
          freeItemList: res.data.content,
        })
        let freeItemForm = {}
        freeItemForm.freeItemList = res.data.content
        this.countPrice()
        this.form1.current.setFieldsValue(freeItemForm)
      })
      .catch(() => {
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
    this.setState({
      order,
      orderComponts,
      visible: true,
    })
  }
  saveToAudit = () => {
    let values = {}
    values.orderId = this.state.order.id
    values.componentsPrice = JSON.stringify(this.state.componentsPrice)
    http
      .post('/admin/order/confirmQuotation', values)
      .then((res) => {
        message.success({
          content: '更新数据完成',
        })
        this.setState({ visible: false })
      })
      .catch(() => {
        message.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  confirmQuotation = () => {
    let values = {}
    values.componentsPrice = JSON.stringify(this.state.componentsPrice)
    values.orderId = this.state.order.id
    http
        .post('/admin/order/confirmQuotation',values)
        .then((res) => {
          message.success({
            content: '更新数据完成',
          })
          this.setState({ visible: false })

        })
  }
  onClose = () => {
    this.setState({ visible: false })
  }
  //计划价格
  countPrice = () => {
    let orderPrice = 0
    let freeItemCount = 0.0
    let freeItemList = this.state.freeItemList
    freeItemList.forEach((freeItem) => {
      let free = parseFloat(freeItem.freeValue)
      if (freeItem.freeItemType === '百分比') {
        free = parseFloat((this.state.order.evalCost * freeItem.freeValue) / 100).toFixed(2)
      }
      freeItemCount = parseFloat(freeItemCount) + parseFloat(free)
      orderPrice = this.state.order.evalCost + freeItemCount
    })
    this.setState({
      orderPrice,
      freeItemCount,
    })
  }

  render() {
    return (
      <Modal
        forceRender={true}
        title={this.state.title}
        width={1024}
        onClose={this.onClose}
        onCancel={this.onClose}
        visible={this.state.visible}
        bodyStyle={{ overflowX: 'auto', minHeight: '480px' }}
        footer={
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button onClick={this.confirmQuotation} type="primary">
              确认报价1
            </Button>
          </div>
        }
      >
        <Form ref={this.form1} onFinish={this.submit}>
          <Form.List name="freeItemList">
            {(fields, { add, remove }) => (
              <>
                <Form.Item label="价目项"></Form.Item>
                {fields.map((field, index) => (
                  <Space
                    key={field.key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item {...field} name={[field.name, 'freeItemName']} fieldKey={[field.fieldKey, 'freeItemName']} rules={[{ required: true, message: '价目项名称' }]}>
                      <Input placeholder="价目项名称" />
                    </Form.Item>

                    <Form.Item {...field} name={[field.name, 'freeValue']} fieldKey={[field.id, 'freeValue']} rules={[{ required: true, message: '价格' }]}>
                      <InputNumber
                        placeholder="价格"
                        onChange={(value) => {
                          let freeItemList = this.state.freeItemList
                          freeItemList[index].freeValue = value
                          this.setState({ freeItemList })
                        }}
                      />
                    </Form.Item>
                    <Form.Item {...field} name={[field.name, 'freeItemType']} fieldKey={[field.id, 'freeItemType']} tooltip="" initialValue="百分比">
                      <Radio.Group
                        buttonStyle="solid"
                        onChange={(value) => {
                          let freeItemList = this.state.freeItemList
                          freeItemList[index].freeItemType = value
                          this.setState({ freeItemList })
                        }}
                      >
                        <Radio.Button value="百分比">%</Radio.Button>
                        <Radio.Button value="价格">元</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={(e) => {
                        remove(field.name)
                      }}
                    />
                  </Space>
                ))}
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  新增收款项
                </Button>
              </>
            )}
          </Form.List>
        </Form>
        <ReactToPrint
          trigger={() => (
            <a>
              <Button type="primary">打印</Button>
            </a>
          )}
          content={() => this.ref}
        />
        <table
          className="order_printing"
          ref={(el) => (this.componentRef = el)}
          width="1280px"
          border="1px"
          style={{ boxSizing: 'border-box', textAlign: 'center', borderColor: '#999', margin: '0 auto', boxShadow: '0 2px 5px #ccc' }}
        >
          <colgroup>
            <col width="200" />
            <col width="120" />
            <col width="120" />
            <col width="100" />
            <col width="100" />
            <col width="110" />
            <col width="110" />
            <col width="120" />
            <col width="120" />
            <col width="100" />
            <col width="100" />
            <col width="100" />
          </colgroup>
          <tbody>
            <tr class="firstRow">
              <td colspan="12" style={{ fontSize: '16px', lineHeight: '50px', textAlign: 'left' }}>
                <ProDescriptions column={5}>
                  <ProDescriptions.Item label="订单编号" valueType="text">
                    {this.state.order.orderCode}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="erp编号" valueType="text">
                    {this.state.order.extraOrderCode}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="客户名称" valueType="text">
                    {this.state.order.client == null ? '' : this.state.order.client.name}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="订单名称" valueType="text">
                    {this.state.order.orderName}
                  </ProDescriptions.Item>
                  <ProDescriptions.Item label="下单时间" valueType="text">
                    {this.state.order.createTime}
                  </ProDescriptions.Item>
                </ProDescriptions>
              </td>
            </tr>
            <tr class="firstRow">
              <th>图号</th>
              <th>材料</th>
              <th>胚料重量</th>
              <th>材料费/件</th>
              <th>生产费/件</th>
              <th>工时/件</th>
              <th>生产总量</th>
              <th>总工时</th>
              <th>成本(元)</th>
              <th>报价状态</th>
              <th>期望交期</th>
              <th>零件报价(元)</th>
            </tr>
            {this.state.orderComponts.map((item,i) => (
                <>
                {item.components.materials !== undefined ? (
                  item.components.materials.map((material,index) => (
                    <>
               <tr>
                 {index === 0 ? (<td rowspan={item.components.materials.length} style={{ fontSize: '13px' }}>{item.components.componentCode}</td>) :''}
                    <td style={{ fontSize: '13px' }}>{material.material.name == null ? '-' : material.material.name}</td>
                    <td style={{ fontSize: '13px' }}>{material.weight == null ? '-' : material.weight.toFixed(2) + '/' + material.material.unit}</td>
                 <td style={{ fontSize: '13px' }}>{material.price == null ? '-' : material.price.toFixed(2)}元</td>
                 <td style={{ fontSize: '13px' }}>{item.components.workerPrice == null ? '-' : item.components.workerPrice.toFixed(2)}元</td>
                <td style={{ fontSize: '13px' }}>{item.components.workTime == null ? '-' : parseFloat(item.components.workTime).toFixed(2)}分钟</td>
                <td style={{ fontSize: '13px' }}>{item.componentsNum == null ? '-' : item.componentsNum}</td>
                <td style={{ fontSize: '13px' }}>{item.components.workTime == null ? '-' : parseFloat(item.components.workTime * item.componentsNum).toFixed(2)}分钟</td>
                <td style={{ fontSize: '13px' }}>{parseFloat(item.cost * item.componentsNum).toFixed(2)}</td>
                {index === 0 ? ( <td  style={{ fontSize: '13px' }} rowspan={item.components.materials.length}>{item.foreignStatus == null ? '待报价' : item.foreignStatus}</td>) :''}
                {index === 0 ? ( <td  style={{ fontSize: '13px' }} rowspan={item.components.materials.length}>{item.planTime == null ? '' : item.planTime}</td>) :''}
                {/* {index === 0 ? ( <td  style={{ fontSize: '13px' }} rowspan={item.components.materials.length}><InputNumber value={item.quotationPrice !== undefined ? item.quotationPrice : 1} min={1} onChange={(e) => {
                if(e > 0){
                  let componentsPrice = this.state.componentsPrice
                  componentsPrice[i] = {componentId:item.id,price:e}
                  this.setState({componentsPrice})
                }else {
                  let componentsPrice = this.state.componentsPrice
                  componentsPrice[i] = {componentId:item.id,price:1}
                  this.setState({componentsPrice})
                }
                }} type="number"/></td>) :''} */}
                </tr>
                 </>
                  ))
                ) : (
                  <>
                     <tr>
                <td style={{ fontSize: '13px' }}>{item.components.componentCode}</td>
                <td style={{ fontSize: '13px' }}>{item.components.material == null ? '-' : item.components.material.name}</td>
                <td style={{ fontSize: '13px' }}>{item.components.materialWeight == null ? '-' : item.components.materialWeight + '/' + item.components.material.unit}</td>
                <td style={{ fontSize: '13px' }}>{item.components.materialPrice == null ? '-' : item.components.materialPrice}元</td>
                <td style={{ fontSize: '13px' }}>{item.components.workerPrice == null ? '-' : item.components.workerPrice}元</td>
                <td style={{ fontSize: '13px' }}>{item.components.workTime == null ? '-' : parseFloat(item.components.workTime).toFixed(2)}分钟</td>
                <td style={{ fontSize: '13px' }}>{item.componentsNum == null ? '-' : item.componentsNum}</td>
                <td style={{ fontSize: '13px' }}>{item.components.workTime == null ? '-' : parseFloat(item.components.workTime * item.componentsNum).toFixed(2)}分钟</td>
                <td style={{ fontSize: '13px' }}>{parseFloat(item.cost * item.componentsNum).toFixed(2)}</td>
              </tr>
                </>
                )}
                </>
           
            ))}
            <tr>
              <td colspan="12" style={{ fontSize: '16px', lineHeight: '50px', textAlign: 'left' }}>
                <ProDescriptions title={'成本汇总:' + parseFloat(this.state.order.evalCost).toFixed(2) + '元'}>
                  <Descriptions.Item label="物料成本">{parseFloat(this.state.order.materialCost).toFixed(2)}元</Descriptions.Item>
                  <Descriptions.Item label="人工成本">{parseFloat(this.state.order.workerCost).toFixed(2)}元</Descriptions.Item>
                </ProDescriptions>
              </td>
            </tr>

            <tr>
              <td colspan="12" style={{ fontSize: '16px', lineHeight: '50px', textAlign: 'left' }}>
                <ProDescriptions title={'价目项汇总:' + this.state.freeItemCount} column={8}>
                  {this.state.freeItemList.map((item) => (
                    <Descriptions.Item label={item.freeItemType === '百分比' ? item.freeItemName + item.freeValue + '%' : item.freeItemName}>
                      {item.freeItemType === '百分比' ? parseFloat((this.state.order.evalCost * item.freeValue) / 100).toFixed(2) : item.freeValue}元
                    </Descriptions.Item>
                  ))}
                </ProDescriptions>
              </td>
            </tr>
            <tr>
              <th colspan="12" style={{ fontSize: '16px', lineHeight: '50px', textAlign: 'left' }}>
                总价： {parseFloat(this.state.orderPrice).toFixed(2)}
              </th>
            </tr>
          </tbody>
        </table>
      </Modal>
    )
  }
}

export default OrderOffer
