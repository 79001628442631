import {
  ExclamationCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { blockStatement } from "@babel/types";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  DatePicker,
  message,
  Row,
  Spin,
  Table,
  Radio,
  Space,
  Cascader,
  Pagination,
} from "antd";
import React, { Component } from "react";
import ResizeTable from "../../component/etooling-ui/ResizeTable";
import Panel from "../../component/etooling-ui/Panel";
import "../../App.css";
import http from "../api/http";
import QualityFrom from "./QualityFrom";
import Qualityreport from "./Qualityreport";
const { RangePicker } = DatePicker;
const { confirm } = Modal;
class QualityForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.searchForm = React.createRef();
    this.qualityreport = React.createRef();
    this.state = {
      orderComponentsQcDetail: {},
      order: {},
      orderComponents: {},
      components: {},
      componentsCrafts: [],
      qcType: "已质检",
      sortedInfo: null,
      selectItem: {},
      loading: false,
      page: 1,
      pageSize: 15,
      filteredInfo: null,
      orderName: "",
      //测试数据
      data: [],
    };
  }

  //加载完成
  componentDidMount() {
    let values = {};
    values.page = this.state.page;
    values.pageSize = this.state.pageSize;
    this.getList(values);
  }

  onLoading() {
    this.setState({
      loading: true,
    });
  }
  offLoading() {
    this.setState({
      loading: false,
    });
  }
  getQuality = (values) => {
    this.onLoading();
    if (values == null) {
      values = {};
    }
    values.orderName = this.state.orderName;
    values.startDate = this.state.startDate;
    values.endDate = this.state.endDate;
    http
      .post("/admin/quality/getQualityList", values)
      .then((res) => {
        this.setState({
          data: res.data,
        });
        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };
  getList = (values) => {
    this.onLoading();
    if (values == null) {
      values = {};
    }
    values.orderName = this.state.orderName;
    values.startDate = this.state.startDate;
    values.endDate = this.state.endDate;
    http
      .post("/admin/quality/pageQuery", values)
      .then((res) => {
        this.setState({
          data: res.data,
        });
        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };
  getErrQualityList = (values) => {
    this.onLoading();
    if (values == null) {
      values = {};
    }
    values.orderName = this.state.orderName;
    values.startDate = this.state.startDate;
    values.endDate = this.state.endDate;
    http
      .post("/admin/quality/getErrQualityList", values)
      .then((res) => {
        this.setState({
          data: res.data,
        });
        this.offLoading();
      })
      .catch(() => {
        this.offLoading();
        Modal.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };
  changePage = (page) => {
    let values = {};
    values.page = page;
    values.pageSize = this.state.pageSize;
    if (this.state.qcType === "已质检") {
      this.getList(values);
    }
    if (this.state.qcType === "待质检") {
      this.getQuality(values);
    }
    if (this.state.qcType === "不通过") {
      this.getErrQualityList(values);
    }
  };

  toAdd = () => {
    this.form.current.toAdd();
  };
  toExcel = (e) => {
    var url = "";
    if (e === "已质检") {
      url = "/admin/quality/qualityToExcel";
    }
    if (e === "待质检") {
      url = "/admin/quality/toExcel";
    }
    if (e === "不通过") {
      url = "/admin/quality/getErrQualityToExcel";
    }
    let values = {}
    values.orderName = this.state.orderName
      values.startDate = this.state.startDate;
      values.endDate = this.state.endDate;
      http
        .download(url, values, "质检清单.xlsx")
        .then((res) => {})
        .catch((err) => {
          message.error({
            content: "数据加载失败请联系系统管理员",
          });
        });
  };
  allToExcel = (e) => {
    this.searchForm.current.validateFields().then((values) => {
      values.startDate = this.state.startDate;
      values.endDate = this.state.endDate;
      http
        .download("/admin/quality/allQualityToExcel", values, "质检清单.xlsx")
        .then((res) => {})
        .catch((err) => {
          message.error({
            content: "数据加载失败请联系系统管理员",
          });
        });
    });
  };
  toEdit = (record) => {
    this.form.current.toEdit(record);
  };
  toPrint = (record) => {
    http
      .post("/admin/quality/qualityReport?orderComponentId=" + record.id, null)
      .then((res) => {
        this.setState({
          order: res.data.order,
          orderComponents: res.data.orderComponents,
          components: res.data.orderComponents.components,
          componentsCrafts: res.data.componentsCrafts,
          orderComponentsQcDetail: res.data.orderComponentsQcDetail,
        });
        this.qualityreport.current.toPrint(
          this.state.components,
          this.state.order,
          this.state.orderComponents.componentsNum,
          this.state.componentsCrafts,
          this.state.orderComponentsQcDetail
        );
      });
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  };

  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    });
    if (this.state.qcType === "已质检") {
      this.getList();
    }
    if (this.state.qcType === "待质检") {
      this.getQuality();
    }
    if (this.state.qcType === "不通过") {
      this.getErrQualityList();
    }
  }

  render() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    // 表头
    const columns = [
      {
        title: "图号",
        dataIndex: "component_code",
        key: "component_code",
        sorter: (a, b) => a.groupName.length - b.groupName.length,
        ellipsis: true,
        width: 140,
      },
      {
        title: "物料名称",
        dataIndex: "component_name",
        key: "component_name",
        sorter: (a, b) => a.displayValue - b.displayValue,
        ellipsis: true,
        width: 120,
      },
      {
        title: "数量",
        dataIndex: "components_num",
        key: "components_num",
        ellipsis: true,
        width: 120,
      },
      {
        title: "质检日期",
        dataIndex: "qc_time",
        key: "qc_time",
        ellipsis: true,
        width: 120,
      },
      {
        title: "订单名称",
        dataIndex: "order_name",
        key: "order_name",
        width: 120,
        ellipsis: true,
      },
      {
        title: "订单编号",
        dataIndex: "extra_order_code",
        key: "extra_order_code",
        width: 120,
        ellipsis: true,
      },
      {
        title: "质检错误",
        dataIndex: "qc_fail_type",
        key: "qc_fail_type",
        width: 120,
        ellipsis: true,
      },
      {
        title: "错误描述",
        dataIndex: "demo",
        key: "demo",
        width: 120,
        ellipsis: true,
      },
      {
        title: "加工者",
        dataIndex: "work_employee_name",
        key: "work_employee_name",
        width: 120,
        ellipsis: true,
      },
      {
        title: "处理人",
        dataIndex: "name",
        key: "name",
        width: 120,
        ellipsis: true,
      },
      {
        title: "操作",
        width: 140,
        key: "action",
        render: (value, record) => {
          return (
            <div>
              <Button type="link" onClick={this.toEdit.bind(this, record)}>
                编辑
              </Button>
              <Button if={this.state.qcType === "不通过"}>
                <a onClick={this.toPrint.bind(this, record)}>查看质检报告</a>
              </Button>
            </div>
          );
        },
      },
    ];

    return (
      <Panel
        title={"质检清单"}
        fontWeight
        topBar={
          <>
            <Space size={16}>
              关键字搜索：
            <Input placeholder="图号或物料或订单编号" onChange={(value) => {
              this.setState({orderName:value.target.value})
            }} />
               <Radio.Group value={this.state.qcType} onChange={(value) => {
                  this.setState({
                      qcType:value.target.value
                  })
                  if (value.target.value === '待质检') {
                    this.getQuality();
                  }
                  if (value.target.value === '已质检') {
                    this.getList();
                  }
                  if(value.target.value === '不通过'){
                    this.getErrQualityList()
                  }
                }} >
                    <Radio value={'待质检'}>待质检</Radio>
                    <Radio value={'已质检'}>已质检</Radio>
                    <Radio value={'不通过'}>不通过</Radio>
                  </Radio.Group>
                  <RangePicker
                  onChange={(date, dateString) => {
                    let startDate = dateString[0]
                    let endDate = dateString[1]
                    this.setState({ startDate, endDate })
                  }}
                ></RangePicker>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => {
                  if (this.state.qcType === "已质检") {
                    this.getList();
                  }
                  if (this.state.qcType === "待质检") {
                    this.getQuality();
                  }
                  if (this.state.qcType === "不通过") {
                    this.getErrQualityList();
                  }
                }}
              >
                查询
              </Button>
              <Button type="primary" onClick={()=>{
                      this.toExcel(this.state.qcType)

                  }} key="primary">
                   导出报表
                  </Button>
                  <Button type="primary" onClick={()=>{
                      this.allToExcel()

                  }} key="primary">
                   全部导出
                  </Button>
            </Space>
          </>
        }
        footerBar={
          <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              this.getList();
            }}
          >
            刷新
          </Button>
        }
        footerRight={
          <Pagination
            showQuickJumper={false}
            pageSize={this.state.data == null ? this.state.data.size : 15}
            current={this.state.data == null ? 1 : this.state.data.number + 1}
            defaultPageSize={
              this.state.numberOfElements == null
                ? 15
                : this.state.data.numberOfElements
            }
            total={this.state.data.totalElements}
            showTotal={(total) => {
              return <>共 {total} 条</>;
            }}
            onChange={(page, pageSzie) => {
              let values = {
                pageSize: pageSzie,
                page: page,
                searchName: this.state.searchValue,
              };
              if (this.state.qcType === "已质检") {
                this.getList(values);
              }
              if (this.state.qcType === "待质检") {
                this.getQuality(values);
              }
              if (this.state.qcType === "不通过") {
                this.getErrQualityList(values);
              }
            }}
          />
        }
      >
        <ResizeTable
          bordered
          dataSource={this.state.data == null ? [] : this.state.data.content}
          pagination={false}
          size="small"
          columns={columns}
        ></ResizeTable>
             <QualityFrom ref={this.form} getList={this.getList} table={this} />
             <Qualityreport ref={this.qualityreport}></Qualityreport>
      </Panel>
    );
  }
}
QualityForm.propTypes = {};

export default QualityForm;
