//使用抽屉进行编辑
import React, { Component } from 'react'
import { ExclamationCircleOutlined, PlusOutlined,ReloadOutlined } from '@ant-design/icons'
import { Button,Input, Modal,  Space,Pagination } from 'antd'
import '@ant-design/pro-form/dist/form.css'
import http from '../../api/http'
import Panel from "../../../component/etooling-ui/Panel";
import ResizeTable from '../../../component/etooling-ui/ResizeTable'
import ExternalServiceForm from './ExternalServiceForm'
const { confirm } = Modal

class ExternalServiceList extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.searchForm = React.createRef()
    this.state = {
      sortedInfo: null,
      selectItem: {},
      page: 1,
      pageSize: 15,
      //测试数据
      data: [],
      searchValue:'',
    }
  }

  //加载完成
  componentDidMount() {
    let values = {}
    values.page = this.state.page
    values.pageSize = this.state.pageSize
    this.getList(values)
  }

  getList = (values) => {
    if (values == null) {
      values = {}
    }
    values.page = values.page
    values.pageSize = this.state.pageSize
    values.searchValue = this.state.searchValue
    http
      .post('/admin/externalService/pageQuery', values)
      .then((res) => {
        let data  = res.data;
        console.log(res.data);
        this.setState({
          data: data,
        })
      })
      .catch(() => {
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
 
  //新增
  toAdd = () => {
    this.form.current.toAdd()
  }

  //编辑
  toEdit = (record) => {
    this.form.current.toEdit(record)
  }

  //删除
  del = (record) => {
    let list = this.getList
    confirm({
      title: '这是配置信息，确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,
      content: '这如果有其他外关联信息使用就无法删除',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/externalService/del?id=' + record.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list()
          })
          .catch(() => {
            Modal.error({
              content: '删除失败,请联系管理员！！！！！！',
            })
          })
      },
      onCancel() {},
    })
  }



  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    })
    this.getList()
  }

  render() {
    // 表头
    const columns = [
      {
        title: '服务名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 100,
      },
      {
        title: '服务地址',
        dataIndex: 'servicePath',
        key: 'servicePath',
        width: 150,
        ellipsis: true,
      },
      {
        title: '接入系统',
        dataIndex: 'externalSystem',
        key: 'externalSystem',
        width: 200,
        ellipsis: true,
         render: (value, record) => {
            return (
                value !=null ?  value.name : ""
            )
          }, 
      },
      {
        title: '实现类',
        dataIndex: 'serviceImplPath',
        key: 'serviceImplPath',
        ellipsis: true,
        width: 150,
      },
      {
        title: '定时任务',
        dataIndex: 'cornString',
        key: 'cornString',
        width: 200,
      },
      {
        title: '操作',
        width: 200,
        key: 'action',
        render: (value, record) => {
          return (
            <div>
              <Button type="link" onClick={this.toEdit.bind(this, record)}>
                编辑
              </Button>
              <Button type="link" onClick={this.del.bind(this, record)}>
                删除
              </Button>
            </div>
          )
        },
      },
    ]

    return (
      <Panel
      title="服务管理"
      fontWeight
      topBar={
        <Space>
        关键词搜索：
        <Input
        placeholder="服务名称"
          value={this.state.searchValue}
          onChange={(evn) => {
            let searchValue = evn.target.value
            this.setState({ searchValue: searchValue })
          }}
        />
        <Button
          type="primary"
          onClick={() => {
            this.getList()
          }}
        >
          查询
        </Button>
      </Space>
      }
      topRight={
            <Button type="primary" icon={<PlusOutlined />} onClick={this.toAdd} key="primary">
      新增服务
    </Button>
      }
    
      footerBar={
        <Button
        icon={<ReloadOutlined />}
        type="text"
        onClick={() => {
          this.getList()
        }}
      >
        刷新
      </Button>
      }
      footerRight={
          <Pagination
                // 分页
                showTotal={ (total) => {
                  return <>共 {total} 条</>
                }}
                showSizeChanger={false}
              showQuickJumper= {true}
              defaultPageSize={15}
              total= {this.state.data.totalElements}
              onChange={(page)=>{
                let values = {}
                values.page = page
                values.pageSize = this.state.pageSize
                this.getList(values)
              }} 
            onRow={(record) => ({
              onDoubleClick: () => {
                this.toEdit(record)
              }
            })}
          />
      }
      >
          <ResizeTable
          bordered
            search={false}
            size="small"
            rowKey={(record) => record.id}
            columns={
              columns
            }
            dataSource={this.state.data.content}
            pagination={false}
          />
           <ExternalServiceForm ref={this.form} getList={this.getList} table={this} />
        </Panel>
    )
  }
}

ExternalServiceList.propTypes = {}

export default ExternalServiceList
