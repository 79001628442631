import { AppstoreOutlined, ExclamationCircleOutlined, PlusOutlined, TableOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, List, Modal, Row, Select, Spin, Switch, Table } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { Option } from 'antd/lib/mentions'
import React, { Component } from 'react'
import '../../App.css'
import http from '../api/http'
const { confirm } = Modal
class PddGoods extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.searchForm = React.createRef()
    this.state = {
      sortedInfo: null,
      selectItem: {},
      loading: false,
      page: 1,
      pageSize: 30,
      showDataType: 'Table',
      filteredInfo: null,
      //测试数据
      data: [],
      types: [],
    }
  }

  //加载完成
  componentDidMount() {
    let values = {}
    values.page = this.state.page
    values.pageSize = this.state.pageSize
    this.getList(values)
    this.getType()
  }

  onLoading() {
    this.setState({
      loading: true,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }
  getType() {
    http
      .post('/admin/pdd/goods/getGoodsType')
      .then((res) => {
        this.setState({
          types: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  getList = (values) => {
    this.onLoading()
    if (values == null) {
      values = {}
    }

    http
      .post('/admin/pdd/goods/pageQuery', values)
      .then((res) => {
        this.setState({
          data: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  changePage = (page) => {
    console.log(page)
    let values = {}
    values.page = page
    values.pageSize = this.state.pageSize
    this.getList(values)
  }

  toAdd = () => {
    // console.log(this.form.current);
    this.form.current.toAdd()
  }
  toEdit = (record) => {
    this.form.current.toEdit(record)
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    })
  }

  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    })
    this.getList()
  }

  render() {
    let { sortedInfo } = this.state

    // 表头
    const columns = [
      {
        title: '类型',
        dataIndex: 'goods_type',
        key: 'goods_type',
        ellipsis: true,
        width: 20,
      },
      {
        title: '商品编号',
        dataIndex: 'goods_id',
        key: 'goods_id',
        sorter: (a, b) => a.groupName.length - b.groupName.length,
        ellipsis: true,
        width: 40,
      },

      {
        title: '商品名称',
        dataIndex: 'goods_name',
        key: 'goods_name',
        sorter: (a, b) => a.displayValue - b.displayValue,
        ellipsis: true,
        width: 40,
      },
      {
        title: '商品简称',
        dataIndex: 'short_name',
        key: 'short_name',
        ellipsis: true,
        width: 40,
      },
      {
        title: '市场价',
        dataIndex: 'market_price',
        key: 'market_price',
        ellipsis: true,
        width: 20,
        render: (value, record) => {
          return value == null ? '-' : parseFloat(value / 100).toFixed(2)
        },
      },
      {
        title: '正常价',
        dataIndex: 'normal_price',
        key: 'normal_price',
        ellipsis: true,
        width: 20,
        render: (value, record) => {
          return value == null ? '-' : parseFloat(value / 100).toFixed(2)
        },
      },
      {
        title: '拼团价',
        dataIndex: 'group_price',
        key: 'group_price',
        width: 20,
        ellipsis: true,
        render: (value, record) => {
          return value == null ? '-' : parseFloat(value / 100).toFixed(2)
        },
      },
      {
        title: '小图url',
        dataIndex: 'hd_thumb_url',
        key: 'hd_thumb_url',
        width: 50,
        ellipsis: true,
      },
      {
        title: '大图url',
        dataIndex: 'hd_url',
        key: 'hd_url',
        width: 50,
        ellipsis: true,
      },

      {
        title: '物品所属分类的排行榜url',
        width: 40,
        index: 'ranking_list_tag_link_url',
        key: 'ranking_list_tag_link_url',
      },
      {
        title: '物品所属分类的排名',
        width: 40,
        index: 'ranking_list_tag_text',
        key: 'ranking_list_tag_text',
      },
      {
        title: '销量',
        width: 40,
        index: 'sales_tip',
        key: 'sales_tip',
      },
    ]

    return (
      <div>
        <Spin spinning={this.state.loading} delay={500}>
          <Row>
            <Col span={24} offset={1}>
              <Form layout="inline" ref={this.searchForm} onFinish={this.getList}>
                <Form.Item label="筛选条件" />
                <Form.Item label="商品名称" name="searchValue">
                  <Input placeholder="商品名称" />
                </Form.Item>
                <Form.Item label="商品类型" name="goodsType">
                  <Select showSearch style={{ width: 120 }} defaultValue="" placeholder="选择员工" optionFilterProp="children">
                    <Option value="">全部</Option>
                    {this.state.types != null ? this.state.types.map((item, index) => <Option value={item}>{item}</Option>) : ''}
                  </Select>
                </Form.Item>
                <Form.Item label="显示方式" name="">
                  <Switch
                    checkedChildren={<AppstoreOutlined />}
                    onChange={(checked) => {
                      if (checked) {
                        this.setState({
                          showDataType: 'Table',
                        })
                      } else {
                        this.setState({
                          showDataType: 'List',
                        })
                      }
                    }}
                    unCheckedChildren={<TableOutlined />}
                    defaultChecked
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    查询
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          {this.state.showDataType === 'Table' ? (
            <Table
              scroll={{ x: 2400, y: 600 }}
              search={false}
              size="small"
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={this.state.data.content}
              pagination={{
                // 分页
                showTotal: (total) => {
                  return `共 ${total} 条`
                },
                showSizeChanger: false,
                showQuickJumper: true,
                defaultPageSize: 30,
                total: this.state.data.totalElements,
                onChange: this.changePage,
              }}
            />
          ) : (
            <List
              grid={{ gutter: 24, column: 5 }}
              dataSource={this.state.data.content}
              renderItem={(item) => (
                <Card
                  style={{ margin: 10 }}
                  title={'分类:' + item.goods_type}
                  extra={<a href={item.ranking_list_tag_link_url}>排名:{item.ranking_list_tag_link_url == null ? '' : item.ranking_list_tag_text}</a>}
                  hoverable
                  cover={<img alt="example" src={item.hd_thumb_url} />}
                  actions={[
                    <div>
                      <span>市场价</span>
                      <br />
                      {item.market_price == null ? '-' : parseFloat(item.market_price / 100).toFixed(2)}
                    </div>,
                    <div>
                      <span>正常价</span>
                      <br />
                      {item.normal_price == null ? '-' : parseFloat(item.normal_price / 100).toFixed(2)}
                    </div>,
                    <div>
                      <span>拼团价</span>
                      <br />
                      {item.group_price == null ? '-' : parseFloat(item.group_price / 100).toFixed(2)}
                    </div>,
                  ]}
                >
                  <Meta title={item.short_name} description={<div style={{ textAlign: 'left' }}>{item.sales_tip == null ? '-' : item.sales_tip}</div>} />
                </Card>
              )}
              pagination={{
                hideOnSinglePage: false,
                // 分页
                current: this.state.data.number + 1,
                showSizeChanger: false,
                pageSize: this.state.data.size == null ? 8 : this.state.data.size,
                showQuickJumper: false,
                showTotal: (total) => {
                  return `共 ${total} 条`
                },
                defaultPageSize: this.state.data.size,
                total: this.state.data.totalElements,
                onChange: (page) => {
                  let values = {}
                  values.page = page
                  values.pageSize = this.state.data.size
                  values.orderId = this.state.selectOrderId
                  values.orderComponentId = this.state.selectOrderComponentId
                  this.getList(values)
                },
              }}
            ></List>
          )}
        </Spin>
      </div>
    )
  }
}
PddGoods.propTypes = {}

export default PddGoods
