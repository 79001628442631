import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined, SettingOutlined, SendOutlined, RedoOutlined,ReloadOutlined } from '@ant-design/icons'
import ProCard from '@ant-design/pro-card'
import '@ant-design/pro-card/dist/card.css'
import { Avatar, Button, List, Modal, Space, Spin, Steps, Form, Row, Col, Input,Drawer,Cascader, Badge } from 'antd'
import React, { Component } from 'react'
import Storage from '../../utils/Storage'
import http from '../api/http'
import CraftForm from './CraftForm'
import CraftPriceSetting from './CraftPriceSetting'
import CraftDataSetting from './CraftDataSetting'
import CraftTypeForm from './CraftTypeForm'
import DeviceForm from './DeviceForm'
import EmployeeForm from './EmployeeForm'
import dateFm from '../api/dateFm'
import TagTree from '../etooling/TagTree'
import Panel from "../../component/etooling-ui/Panel";

const { Step } = Steps

const { confirm } = Modal
class CraftTable extends Component {
  constructor(props) {
    super(props)
    this.typeForm = React.createRef()
    this.craftForm = React.createRef()
    this.craftPriceSetting = React.createRef()
    this.employeeForm = React.createRef()
    this.deviceForm = React.createRef()
    this.craftDataSetting = React.createRef()
    this.TagTree = React.createRef()
    this.state = {
      craftTypes: [],
      msg: '',
      loading: false,
      craftEmployees: [],
      devices: [],
      machineSocketData: {},
      fileRootPath: '',
      craftParamId:'',
      current: 0,
      terrData:[],
      crafts: [],
      tagMgrVisible:false,
      nowDate: dateFm.format(new Date(), 'yyyyMMdd'),
      selectCraftType: null,
      valuationType:'',
    }
  }

  onLoading = () => {
    this.setState({
      loading: true,
    })
  }
  offLoading = () => {
    this.setState({
      loading: false,
    })
  }
  toAddType = () => {
    this.typeForm.current.toAdd()
  }

  toEditType = (item) => {
    this.typeForm.current.toEdit(item)
  }
  inputChange2(e) {
    this.setState({
      msg: e.target.value,
    })
  }

  getTypeList = (values) => {
    this.onLoading()
    http
      .post('/admin/craftType/getList', values)
      .then((res) => {
        console.log(res.data)
        this.setState({
          craftTypes: res.data.content,
        })
        this.offLoading()
      })
      .catch((err) => {
        console.log(err)
        this.offLoading()
      })
  }
  delType = (record) => {
    let list = this.getTypeList
    confirm({
      title: '这是配置信息，确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,

      content: '这如果有其他外关联信息使用就无法删除',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/craftType/del?id=' + record.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list()
          })
          .catch(() => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            })
          })
      },
      onCancel() {},
    })
  }
  componentDidMount() {
    this.getTypeList()
    this.getSocketData()
    let fileRootPath = Storage.get('fileRootPath')
    this.setState({
      fileRootPath,
      valuationType:JSON.parse(Storage.get('loginUser')).company.valuationType
    })
  }
  onChange = (current) => {
    this.setState({ current })
  }
  getTreeData = (data) => {
    let parents =  []
    for(let i = 0; i <data.length;i++ ){
      let parent = {}
      parent.label = data[i].name
      parent.value = data[i].id
      if(data[i].craftParamList !== undefined){
        parent.children  = this.getTreeData(data[i].craftParamList)
      }
      parents.push(parent)
    }
    return parents;
  } 

  getCrafts = (item) => {
    this.setState({ current: 0 })

    this.setState({ selectCraftType: item })

    this.onLoading()
    let param = { typeId: item.id }
    http
      .post('/admin/craft/pageQuery', param)
      .then((res) => {
        let treeData = this.getTreeData(res.data.content)
        this.setState({
          craftParamName:[],
          crafts: res.data.content,
          treeData
        })
        this.getCraftTypeDevices(item)
        this.getEmployee(item)
        this.offLoading()
      })
      .catch((err) => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  getSocketData = () => {
    http
      .post('/admin/machine/getMachineSocketData')
      .then((res) => {
        console.log(res)
        this.setState({
          machineSocketData: res.data,
        })
      })
      .catch((err) => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  toAddCraft = () => {
    this.craftForm.current.toAdd(this.state.selectCraftType)
  }
  toEditCraft = (item) => {
    this.craftForm.current.toEdit(item, this.state.selectCraftType)
  }
  delCraft = (record) => {
    let list = this.getCrafts
    let selectCraftType = this.state.selectCraftType
    confirm({
      title: '这是配置信息，确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,
      content: '这如果有其他外关联信息使用就无法删除',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/craft/del?id=' + record.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list(selectCraftType)
          })
          .catch((err) => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            })
          })
      },
      onCancel() {},
    })
  }

  getCraftTypeDevices = (item) => {
    this.setState({ selectCraftType: item })

    this.onLoading()
    let param = { typeId: item.id }
    http
      .post('/admin/machine/pageQuery', param)
      .then((res) => {
        this.setState({
          devices: res.data.content,
        })
        this.offLoading()
      })
      .catch((err) => {
        console.log(err)
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  toCraftPriceSetting = (craft) => {
    this.craftPriceSetting.current.toEdit(craft, this.state.selectCraftType)
  }

  toCraftDataSetting = (item) => {
    this.craftDataSetting.current.toEdit(item,this.state.selectCraftType)
  }

  toDeviceAdd = () => {
    this.deviceForm.current.toAdd(this.state.selectCraftType)
  }

  toDeviceEdit = (device) => {
    this.deviceForm.current.toEdit(device, this.state.selectCraftType)
  }
  getEmployee = (crafts) => {
    this.onLoading()
    let param = { craftTypeId: crafts.id }
    http
      .post('/admin/craftTypeEmployee/pageQuery', param)
      .then((res) => {
        this.setState({
          craftEmployees: res.data.content,
        })
        this.offLoading()
      })
      .catch((err) => {
        console.log(err)
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  toAddEmployee = () => {
    this.employeeForm.current.toAdd(this.state.selectCraftType)
  }

  CraftParamChange = (value,data) => {
    let cascaderData = []
    cascaderData.push(data[0].label,data[1].label)
    this.setState({
      craftParamId: value[1],
      craftParamName:cascaderData
    })
    let values = {}
    values.target = value[1]
    values.rootName = '参数设置'
    values.shearType = '参数设置'
    this.TagTree.current.getTree(values)
  }

  delEmpolyee = (record) => {
    console.log('de', record)
    let list = this.getEmployee
    let craftType = this.state.selectCraftType
    confirm({
      title: '这是配置信息，确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/craftTypeEmployee/del?craftTypeId=' + craftType.id + '&id=' + record.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list(craftType)
          })
          .catch((err) => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            })
          })
      },
      onCancel() {},
    })
  }

  toDelDevice = (item) => {
    let list = this.getCraftTypeDevices
    confirm({
      title: '确认删除这条记录?',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        http
          .post('/admin/machine/del?id=' + item.id, '')
          .then((res) => {
            Modal.success({
              content: '删除成功',
            })
            list(this.state.selectCraftType)
          })
          .catch((err) => {
            Modal.error({
              content: "删除失败,请联系管理员！！！！！！",
            })
          })
      },
      onCancel() {},
    })
  }

  sortContainer() {
    return (
      <>
        <Form layout="horizontal" autoComplete="off" style={{ marginTop: 10 }} ref={this.form1} labelCol={{ span: 6 }} onFinish={this.submit}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="orderSort" label="信息">
                <Input onChange={(e) => this.inputChange2(e)} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }

  render() {
    return (
      <Panel
      title="工艺管理"
      fontWeight
      footerBar={
        <Button
        icon={<ReloadOutlined />}
        type="text"
        onClick={() => {
          this.getTypeList()
        }}
      >
        刷新
      </Button>
      }
      >
        <Panel 
      layout="h-box">
          <Panel flex="0.5">
            <ProCard
            
            bordered
            bodyStyle={{padding:'15px'}}
              title="工艺分类"
              extra={[
                <Button icon={<PlusOutlined />} onClick={this.toAddType}>
                  新增分类
                </Button>,
              ]}
              headerBordered
            >
              <List
                itemLayout="horizontal"
                dataSource={this.state.craftTypes}
                renderItem={(item) => (
                  <a
                    onClick={() => {
                      this.getCrafts(item)
                    }}
                  >
                    <List.Item
                      key={item.id}
                      actions={[
                        <Space>
                          <EditOutlined
                            style={{ fontSize: '16px' }}
                            onClick={() => {
                              this.toEditType(item)
                            }}
                          />
                        </Space>,
                        <Space>
                          <DeleteOutlined
                            onClick={() => {
                              this.delType(item)
                            }}
                            style={{ fontSize: '16px' }}
                          />
                        </Space>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Badge color={item === this.state.selectCraftType ? 'orange' : '#1890ff'} text={<Avatar size={40}  src={item.img != null ? item.img.webPath : 'http://file.irobot-auto.com/irobot-auto/20220620/891c913b-ed61-41c0-8a42-6d01dfa99a5c.png'} >
                          </Avatar>} />
                        }
                        description={'月薪:' + item.salaryMonth}
                        title={item.name + (item.isOutType === '是' ? '(委外)' : '')}
                      />
                    </List.Item>
                  </a>
                )}
              />
            </ProCard>
          </Panel>

          <Panel>
            <ProCard
              title={[
                <Avatar src={this.state.selectCraftType != null && this.state.selectCraftType.icon ? this.state.selectCraftType.icon.webPath : <ArrowLeftOutlined />} />,
                this.state.selectCraftType != null ? this.state.selectCraftType.name + '[详细信息]' : '请先选择分类',
              ]}
              extra={[
                <Button
                  key="toNewCraftTag"
                  disabled={this.state.selectCraftType == null ? true : false}
                  style={{ display: this.state.current === 0 ? '' : 'none' }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    this.setState({
                      tagMgrVisible:true
                    })
                  }}
                >
                  预设分类参数
                </Button>,
                <Button
                  key="toNewCraft"
                  disabled={this.state.selectCraftType == null ? true : false}
                  style={{ display: this.state.current === 0 ? '' : 'none' }}
                  icon={<PlusOutlined />}
                  onClick={this.toAddCraft}
                >
                  新增工艺
                </Button>,
                <Button
                  key="toNewDevice"
                  disabled={this.state.selectCraftType == null ? true : false}
                  style={{ display: this.state.current === 1 ? '' : 'none' }}
                  icon={<PlusOutlined />}
                  onClick={this.toDeviceAdd}
                >
                  新增设备
                </Button>,
                <Button
                  key="toNewEmployee"
                  disabled={this.state.selectCraftType == null ? true : false}
                  style={{ display: this.state.current === 2 ? '' : 'none' }}
                  icon={<PlusOutlined />}
                  onClick={this.toAddEmployee}
                >
                  新增技工
                </Button>,
              ]}
            >
              <Steps type="navigation" size="small" current={this.state.current} onChange={this.onChange}>
                <Step title="工艺设置" />
                <Step title="设备设置" />
                <Step title="工人设置" />
              </Steps>
              <div style={{}}>
                <List
                  dataSource={this.state.crafts}
                  style={{ display: this.state.current === 0 ? '' : 'none' }}
                  itemLayout="horizontal"
                  renderItem={(item) => (
                    <a>
                      <List.Item
                        key={item.id}
                        actions={[
                          <Space>
                            {this.state.valuationType === '工艺路线' ? 
                             <Button
                             onClick={() => {
                               this.toCraftPriceSetting(item)
                             }}
                             icon={<SettingOutlined />}
                           >
                             价格设置
                           </Button> : this.state.valuationType === '计时' ?
                            <Button
                            onClick={() => {
                              this.toCraftDataSetting(item)
                            }}
                            icon={<SettingOutlined />}
                          >
                            参数设置
                          </Button> : ''
                           }
                           
                          </Space>,
                          <Space>
                            <Button
                              icon={<EditOutlined />}
                              onClick={() => {
                                this.toEditCraft(item)
                              }}
                            >
                              修改
                            </Button>
                          </Space>,
                          <Space>
                            <Button
                              onClick={() => {
                                this.delCraft(item)
                              }}
                              danger
                              icon={<DeleteOutlined />}
                            >
                              删除
                            </Button>
                          </Space>,
                        ]}
                      >
                        <List.Item.Meta
                          avatar={<Button type="primary"> {item.name}</Button>}
                          title={item.craftParamList.map(function (param, key) {
                            return (
                              <span
                                style={{
                                  marginLeft: 30,
                                  color: 'black',
                                }}
                                key={param.id}
                              >
                                {param.name}*{param.unit}
                              </span>
                            )
                          })}
                          description={<div>{item.laborCostsfunc != null ? '成本计算公式：' + item.laborCostsfunc : ''}</div>}
                        />
                      </List.Item>
                    </a>
                  )}
                ></List>
                <ProCard>
                  <List
                    dataSource={this.state.devices}
                    style={{ display: this.state.current === 1 ? '' : 'none' }}
                    itemLayout="horizontal"
                    renderItem={(item) => (
                      <a>
                        <List.Item
                          key={item.id}
                          actions={[
                            <Space>
                              <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                  this.toDeviceEdit(item)
                                }}
                              >
                                修改
                              </Button>
                            </Space>,
                            <Button
                              onClick={() => {
                                this.toDelDevice(item)
                              }}
                              danger
                              icon={<DeleteOutlined />}
                            >
                              删除
                            </Button>,
                            <Button
                              icon={<SendOutlined />}
                              onClick={() => {
                                let str = this.state.msg
                                var hexCharCode = []
                                hexCharCode.push('0x')
                                for (var i = 0; i < str.length; i++) {
                                  hexCharCode.push(str.charCodeAt(i).toString(2))
                                }
                                let msg = hexCharCode.join('')
                                confirm({
                                  title: '确认您的发送信息',
                                  icon: <ExclamationCircleOutlined />,
                                  okText: '确认',
                                  okType: 'danger',
                                  content: this.sortContainer(),
                                  cancelText: '取消',
                                  width: 600,
                                  onOk() {
                                    fetch('http://192.168.137.1:6888', {
                                      method: 'post',
                                      body: msg,
                                    })
                                      .then(function (response) {
                                        //获取数据,数据处理
                                      })
                                      .catch(function (err) {
                                        //错误处理
                                      })
                                  },
                                  onCancel() {},
                                })
                              }}
                            >
                              发送信息
                            </Button>,
                          ]}
                        >
                          <List.Item.Meta
                            description={
                              <>
                                购买时间:{item.buyTime}
                                <br />
                                设备编码:{item.machineCode}
                              </>
                            }
                            avatar={<Button type="primary"> {item.name}</Button>}
                          />
                          <div>
                            今天开机时间:
                            {this.state.machineSocketData[item.machineCode + '_' + this.state.nowDate] == null
                              ? 0
                              : this.state.machineSocketData[item.machineCode + '_' + this.state.nowDate] / 1000}{' '}
                            秒
                          </div>
                        </List.Item>
                      </a>
                    )}
                  ></List>
                </ProCard>
                <List
                  dataSource={this.state.craftEmployees}
                  style={{ display: this.state.current === 2 ? '' : 'none' }}
                  itemLayout="horizontal"
                  renderItem={(item) => (
                    <a>
                      <List.Item
                        key={item.id}
                        actions={[
                          <Space>
                            <DeleteOutlined
                              onClick={() => {
                                this.delEmpolyee(item)
                              }}
                              style={{ fontSize: '16px' }}
                            />
                          </Space>,
                        ]}
                      >
                        <List.Item.Meta avatar={<Button type="primary"> {item.name}</Button>} />
                        <div>{'电话:' + item.mobile}</div>
                      </List.Item>
                    </a>
                  )}
                ></List>
              </div>
             </ProCard>
          </Panel>
          </Panel>
            
          <CraftTypeForm ref={this.typeForm} table={this} />
          <CraftForm ref={this.craftForm} table={this} />
          <EmployeeForm ref={this.employeeForm} table={this} />
          <DeviceForm ref={this.deviceForm} table={this} />
          <CraftPriceSetting ref={this.craftPriceSetting} table={this}></CraftPriceSetting>
          <CraftDataSetting ref={this.craftDataSetting} table={this}></CraftDataSetting>
          
        <Drawer
          title="预设参数分类"
          placement="right"
          width={500}
          closable={true}
          onClose={() => {
            this.setState({ tagMgrVisible: false })
          }}
          visible={this.state.tagMgrVisible}
          extra={
            <Space>
              <Button type="primary">提交</Button>
            </Space>
          }
        >
           <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="工艺"
                  rules={[{ required: true, message: '请选择工艺' }]}
                >
                  <Cascader options={this.state.treeData} value={this.state.craftParamName} onChange={(this.CraftParamChange)} placeholder="请选择工艺" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <TagTree ref={this.TagTree} table={this} target={this.state.craftParamId} checkable={false} showShareType={false} showMgrBar={true} showRootNode={true} rootName="参数设置"></TagTree>
        </Drawer>
        </Panel>
    )
  }
}

export default CraftTable
