import { Button, Form, Input, Modal, Pagination } from 'antd'
import React, { Component } from 'react'
import moment from 'moment'
import '../../App.css'
import http from '../api/http'
import DataDirForm from './DataDirForm'
import { ReloadOutlined } from '@ant-design/icons'
import Panel from '../../component/etooling-ui/Panel'
import ResizeTable from '../../component/etooling-ui/ResizeTable'

const { confirm } = Modal
class LoginLogMgr extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.state = {
      sortedInfo: null,
      searchForm: {},
      selectItem: {},
      loading: false,
      page: 1,
      pageSize: 15,
      filteredInfo: null,
      //测试数据
      data: [],
    }
  }

  //加载完成
  componentDidMount() {
    this.getList()
  }

  onLoading() {
    this.setState({
      loading: true,
    })
  }

  offLoading() {
    this.setState({
      loading: false,
    })
  }

  getList = (values) => {
    this.onLoading()

    if (values == null) {
      values = {}
    }
    this.setState({
      searchForm: values,
    })
    values.page = values.page
    values.pageSize = this.state.pageSize
    http
      .post('/admin/loginLog/pageQuery', values)
      .then((res) => {
        this.setState({
          data: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    })
  }

  onChange(ipage, ipageSize) {
    this.setState({
      page: ipage,
      pageSize: ipageSize,
    })
    this.getList()
  }

  render() {
    // 表头
    const columns = [
      {
        title: '员工id',
        dataIndex: 'login_id',
        key: 'loginId',
        sorter: (a, b) => {},
        width: 1,
      },
      {
        title: '员工姓名',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {},
        width: 1,
      },
      {
        title: '登入结果',
        dataIndex: 'login_results',
        key: 'loginResults',
        sorter: (a, b) => {},
        width: 1,
      },
      {
        title: '登入时间',
        dataIndex: 'login_time',
        key: 'loginTime',
        sorter: (a, b) => {},
        width: 1,
        render: (value, record) => {
          return <>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</>
        },
      },
      {
        title: '登入ip地址',
        dataIndex: 'remote_ip',
        key: 'remoteIp',
        sorter: (a, b) => {},
        width: 1,
      },
      {
        title: '登入状态',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => {},
        width: 1,
      },
    ]

    return (
      <>
        <Panel
          title="登入日志"
          fontWeight={true}
          bodyPadding="15"
          topBar={
            <Form layout="inline" ref="searchForm" onFinish={this.getList}>
              <Form.Item label="筛选条件" />
              <Form.Item label="员工id" name="searchId">
                <Input placeholder="员工id" />
              </Form.Item>
              <Form.Item label="员工姓名" name="searchName">
                <Input placeholder="员工姓名" />
              </Form.Item>
              <Form.Item label="登入ip地址" name="searchRemoteIp">
                <Input placeholder="登入ip地址" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
              </Form.Item>
            </Form>
          }
          footerBar={
            <Button
              icon={<ReloadOutlined />}
              type="text"
              onClick={() => {
                this.getList()
              }}
            >
              刷新
            </Button>
          }
          footerRight={
            <Pagination
              // 分页
              showTotal={(total) => {
                return <>共 {total} 条</>
              }}
              showSizeChanger={false}
              showQuickJumper={true}
              defaultPageSize={this.state.data.size}
              total={this.state.data.totalElements}
              onChange={(page) => {
                let values = {}
                values.page = page
                values.pageSize = this.state.pageSize
                this.getList(values)
              }}
              onRow={(record) => ({
                onDoubleClick: () => {
                  // this.toEdit(record);
                },
              })}
            />
          }
        >
          <ResizeTable bordered size="middle" rowKey={(record) => record.id} columns={columns} dataSource={this.state.data.content} pagination={false} />
          <DataDirForm ref={this.form} getList={this.getList} table={this} />
        </Panel>
      </>
    )
  }
}
LoginLogMgr.propTypes = {}

export default LoginLogMgr
