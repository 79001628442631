import React, { Component } from 'react'
import { createFromIconfontCN } from '@ant-design/icons'
const Icon = createFromIconfontCN({
  scriptUrl: ['//at.alicdn.com/t/font_2304160_16xyho5a62v.js'],
})
export default class IconFont extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return <Icon {...this.props}></Icon>
  }
}
