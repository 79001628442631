import { Button, Col, DatePicker, Form, Input, Modal, Row, Spin, Table } from 'antd'
import React, { Component } from 'react'
import '../../../App.css'
import http from '../../api/http'
import {Link} from 'react-router-dom'
import ResizeTable from "../../../component/etooling-ui/ResizeTable";
const { RangePicker } = DatePicker
class ProjectPlanList extends Component {
  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.searchForm = React.createRef()
    this.state = {
      data: [],
    }
  }

  componentWillMount() {
  }
  //加载完成
  componentDidMount() {
    let values = {}
    values.page = this.state.page
    values.pageSize = this.state.pageSize
    values.craftId = this.state.qcType
    this.getList(values)
  }

  onLoading() {
    this.setState({
      loading: true,
    })
  }
  offLoading() {
    this.setState({
      loading: false,
    })
  }

  getList = (values) => {
    this.onLoading()
    if (values == null) {
      values = {}
    }
    values.orderName = this.state.selectValue
    http
      .post('/admin/plan/getPage', values)
      .then((res) => {
          console.log(res.data)
        this.setState({
          data: res.data,
        })
        this.offLoading()
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }
  changePage = (page) => {
    let values = {}
    values.page = page
    values.pageSize = this.state.pageSize
    //  values.craftId = this.state.qcType
    this.getList(values)
  }

  toAdd = () => {
    const w=window.open('about:blank');
    w.location.href="./projectSchedule"
    // window.location.href = './projectSchedule'
  }
  toEdit = (record) => {
    const w=window.open('about:blank');
    w.location.href = './projectSchedule?projectPlanId='+record.id+'&workDayTime='+record.work_day_time+'&start_date='+record.start_date
  }
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    })
  }

  render() {
    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}
    // 表头
    const columns = [
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 40,
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        key: 'create_time',
        ellipsis: true,
        width: 20,
      },
      {
        title: '日工作时长/小时',
        dataIndex: 'work_day_time',
        key: 'work_day_time',
        ellipsis: true,
        width: 20,
      },
      {
        title: '开始时间',
        dataIndex: 'start_date',
        key: 'start_date',
        ellipsis: true,
        width: 20,
      },
      {
        title: '结束时间',
        dataIndex: 'end_date',
        key: 'end_date',
        ellipsis: true,
        width: 20,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        ellipsis: true,
        width: 20,
      },
      {
        title: '操作',
        width: 40,
        key: 'action',
        render: (value, record) => {
          return (
            <div>
                <Button type="link" onClick={(this.toEdit.bind(this, record))}>
                查看
              </Button>
            </div>
          )
        },
      },
    ]

    return (
      <div>
        <Spin spinning={this.state.loading} delay={500}>
          <Row>
            <Col span={24} offset={1}>
              <Form
                layout="inline"
                ref={this.searchForm}
                onFinish={(value) => {
                  this.getList(value)
                }}
              >
                <Form.Item label="筛选条件" />
                <Form.Item label="订单名称" name="orderName">
                  <Input placeholder="图号或物料名称或订单名称或订单编号" />
                </Form.Item>
                <Form.Item label="时段">
                  <RangePicker
                    onChange={(date, dateString) => {
                      let startDate = dateString[0]
                      let endDate = dateString[1]
                      this.setState({ startDate, endDate })
                    }}
                  ></RangePicker>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    查询
                  </Button>
                </Form.Item>
                <Form.Item>
                <Button type="primary"  htmlType="submit" onClick={(
                    this.toAdd.bind()
                  )} >
                    新增
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <ResizeTable
            search={false}
            size="small"
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={this.state.data}
            pagination={{
              // 分页
              showTotal: (total) => {
                return `共 ${total} 条`
              },
              showSizeChanger: false,
              showQuickJumper: true,
              defaultPageSize: 15,
              total: this.state.data.totalElements,
              onChange: (page) => {
                let values = {}
                values.page = page
                values.pageSize = this.state.pageSize
                values.craftId = this.state.qcType
                this.getList(values)
                console.log(this.state.workOrderIds)
                this.setState({
                  selectKeyS: [],
                  selectedRowKeys: [],
                  workOrderIds: [],
                  loading: false,
                })
              },
            }}
            onRow={(record) => ({
            })}
          />
        </Spin>
      </div>
    )
  }
}

export default ProjectPlanList

