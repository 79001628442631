import React, { Component } from "react";
import Panel from "../../../../component/etooling-ui/Panel";
import {
  Button,
  DatePicker,
  Input,
  message,
  Pagination,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import { Column } from "@ant-design/charts";
import http from "../../../api/http";
import { RedoOutlined, ReloadOutlined } from "@ant-design/icons";
import ResizeTable from "../../../../component/etooling-ui/ResizeTable";
import moment from "moment";
import ProCard from "@ant-design/pro-card";
class ProductReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      year: "2021",
      monthChart: [],
      data: [],
      isLoading: true,
    };
  }

  componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    let values = { year: this.state.year };
    http
      .post("/admin/report/yearComponentCount", values)
      .then((res) => {
        let columns = [];
        let monthChart = res.data.monthChart;

        let months = res.data.monthList;
        months.forEach((month) => {
          let col = {
            title: month,
            dataIndex: month,
            align: "center",
            key: month,
            ellipsis: true,
            width: 120,
          };
          columns.push(col);
        });
        this.setState({
          columns,
          data: res.data.data,
          monthChart,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: "数据加载失败请联系系统管理员",
        });
      });
  };
  render() {
    let {columns,isLoading} = this.state

    if(isLoading){
      　return (<div
        style={{
          display: "block",
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          width: "100%",
          height: "100%",
          background: "rgba(255,255,255,0.94)",
          zIndex: "99",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Spin size="large" />
        </div>
      </div>)
    }
    return (
      <Panel
        title={this.state.year + "年:机加各月产量汇总表 单位：件"}
        titleRight={
          <Space>
            <DatePicker
              defaultValue={moment(this.state.year, "YYYY")}
              onChange={(date, dateString) => {
                console.log(dateString);
                this.setState({
                  year: dateString,
                });
              }}
              picker="year"
            />
            <Button
            icon={<RedoOutlined />}
              onClick={() => {
                this.loadData();
              }}
              
            >
              刷新
            </Button>
          </Space>
        }
        footerBar={
          <Button
            icon={<ReloadOutlined />}
            type="text"
            onClick={() => {
              var values = {};
              values.status = this.state.status;
              this.loadData(values);
            }}
          >
            刷新
          </Button>
        }
      >
        <ResizeTable
          bordered
          dataSource={this.state.data == null ? [] : this.state.data}
          pagination={false}
          size="small"
          columns={columns}
        ></ResizeTable>
        <Column
          data={this.state.monthChart}
          xField="月份"
          yField="产量"
          point={{ size: 5, shape: "diamond" }}
          meta={{
            type: { alias: "类别" },
            sales: { alias: "销售额" },
          }}
        />
      </Panel>
    );
  }
}

ProductReport.propTypes = {};

export default ProductReport;
