import React, { Component } from 'react'
import SwiperCore, { Navigation, Zoom } from 'swiper'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/zoom/zoom.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import IconFont from '../api/IconFont'
import Storage from '../../utils/Storage'
import { Button, Modal, Tag, Spin, message, Drawer, Cascader, Form, Input } from 'antd'

import '../../assets/css/OrderMaterial.css'
import http from '../api/http'
import TextArea from 'antd/lib/input/TextArea'

SwiperCore.use([Zoom, Navigation])

class OrderCraft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      craftsTypes: [],
      craftsMap: [],
      craftParamMap: [],
      crafts: [],
      selectCractType: null,
      selectCraft: null,
      craftlParams: [],
      materialWeight: null,
      carftParamsValues: [],
      materialCosts: 0,
      showJsFunctionStr: '',
      componentCraftDetail: null,
      craftUnitCosts: 0,
      current: 0,
      treeData: [],
      visible: false,
      memo: '',
    }
  }
  componentDidMount() {}
  getCraftTypeList = (values) => {
    http
      .post('/admin/craftType/getAllCrafts')
      .then((res) => {
        let treeData = this.getTreeData(res.data.craftsTypes, res.data.craftsMap)
        this.setState({
          craftsTypes: res.data.craftsTypes,
          craftsMap: res.data.craftsMap,
          craftParamMap: res.data.craftParamMap,
          treeData,
        })
      })
      .catch(() => {
        this.offLoading()
        Modal.error({
          content: '数据加载失败请联系系统管理员',
        })
      })
  }

  getTreeData = (paren, childrens) => {
    let parents = []
    for (let i = 0; i < paren.length; i++) {
      let p = {}
      p.label = paren[i].name
      p.value = paren[i].id
      if (childrens !== undefined) {
        if (childrens[paren[i].id] !== undefined) {
          if (childrens[paren[i].id].length > 0 && childrens[paren[i].id] !== null && childrens[paren[i].id] !== undefined) {
            p.children = this.getTreeData(childrens[paren[i].id])
          }
        } else {
          p.disabled = true
        }
      }
      if (paren[i].craftParamList !== undefined) {
        p.craftParamList = paren[i].craftParamList
      }
      parents.push(p)
    }
    return parents
  }

  toAdd = (materialWeight) => {
    this.getCraftTypeList()
    this.setState({
      visible: true,
      materialWeight,
      crafts: [],
      selectCractType: null,
      selectCraft: null,
      craftlParams: [],
      carftParamsValues: [],
      craftUnitCosts: 0,
      showJsFunctionStr: '',
      current: 0,
      memo: '',
    })
  }

  toEdit = (componentCraftDetail, materialWeight) => {
    this.setState({
      visible: true,
      crafts: [],
      selectCractType: null,
      selectCraft: null,
      craftlParams: [],
      carftParamsValues: [],
      craftUnitCosts: 0,
      showJsFunctionStr: '',
      materialWeight,
      current: 0,
      memo: '',
    })
    this.getCraftTypeList()
  }

  CraftParamChange = (craft, data) => {
    console.log(data[1].craftParamList)
    let carftParamsValues = []
    for (let p = 0; p < data[1].craftParamList.length; p++) {
      let param = data[1].craftParamList[p]
      let value = param.paramType === '系数' ? 1 : ''
      if (param.name === '零件重量' && this.state.materialWeight != null) {
        value = this.state.materialWeight
      }
      carftParamsValues.push({ id: param.id, name: param.name, paramType: param.paramType, value: value })
    }
    this.setState({ craftlParams: data[1].craftParamList, selectCraft: data[1], carftParamsValues,selectCractType:data[0] })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  render() {
    return (
      <>
        {this.state.visible ? (
          <Drawer
            getContainer={false}
            style={{ position: 'absolute' }}
            mask={false}
            closable={false}
            forceRender={true}
            title="添加工艺"
            width={'100%'}
            visible={this.state.visible}
            onClose={this.onClose}
            maskClosable={false}
            onCancel={this.onClose}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                  取消
                </Button>
                <Button
                  onClick={() => {
                    if (this.state.selectCraft == null) {
                      message.error({
                        content: '工艺',
                      })
                    } else {
                      //组装数据
                      let componentCraftDetail = {}
                      componentCraftDetail.selectCraft = this.state.selectCraft
                      componentCraftDetail.selectCraftType = this.state.selectCractType
                      componentCraftDetail.craftParam = this.state.craftlParams
                      componentCraftDetail.carftParamsValues = this.state.carftParamsValues
                      // componentCraftDetail.craftUnitCosts = res.data
                      componentCraftDetail.memo = this.state.memo
                      if (JSON.parse(Storage.get('loginUser')).company.valuationType === '计时') {
                        this.props.table.UnitCostsAndSaveByTime(componentCraftDetail)
                      } else {
                        this.props.table.selectComponentCraftDetail(componentCraftDetail)
                      }
                      this.setState({
                        visible: false,
                      })
                    }
                  }}
                  type="primary"
                >
                  完成
                </Button>
              </div>
            }
          >
            <Form layout="vertical">
              <Form.Item label="请选择工艺">
                <Cascader showSearch={true} allowClear={false} options={this.state.treeData} onChange={this.CraftParamChange} placeholder="请选择工艺" />
              </Form.Item>
              {this.state.selectCractType === null ? (
                ''
              ) : (
                <div className="con">
                  <dl className="choice" style={{ flex: '1' }}>
                    {this.state.crafts.map((craft, index) => (
                      <Form.Item>
                        <dd
                          key={craft.id}
                          onClick={() => {
                            let craftlParams = craft.craftParamList
                            let carftParamsValues = []

                            for (let p = 0; p < craftlParams.length; p++) {
                              let param = craftlParams[p]
                              let value = param.paramType === '系数' ? 1 : ''
                              if (param.name === '零件重量' && this.state.materialWeight != null) {
                                value = this.state.materialWeight
                              }
                              carftParamsValues.push({ id: param.value, name: param.name, paramType: param.paramType, value: value })
                            }

                            if (craft.craftParamList !== undefined) {
                              this.setState({
                                craftlParams: [],
                              })
                            }
                            this.setState({
                              selectCraft: craft,
                              carftParamsValues,
                            })
                          }}
                        >
                          <Button
                            size={36}
                            style={{
                              fontSize: '14px',
                              height: '36px',
                              backgroundColor: this.state.selectCraft == null ? '' : this.state.selectCraft.id === craft.id ? 'orange' : '',
                            }}
                          >
                            {craft.name}
                          </Button>
                        </dd>
                      </Form.Item>
                    ))}
                  </dl>
                </div>
              )}

              {this.state.selectCraft == null ? (
                ''
              ) : (
                <div>
                  <ul>
                    {this.state.craftlParams.map((param, index) => (
                      <li style={{ flex: '0 0 31%' }}>
                        {param.defaults === undefined && param.paramType !== '执行人员录入参数' ? (
                          <Form.Item name={param.id} label={param.name + '*' + param.unit}>
                            <>
                            {param.formFieldType === '文本' ? 
                            <Input
                            type="text"
                            value={this.state.carftParamsValues[index].value}
                            onChange={(e) => {
                              let carftParamsValues = this.state.carftParamsValues
                              let oldValue = carftParamsValues[index]
                              let newValue = { id: oldValue.id, paramType: oldValue.paramType, name: oldValue.name, value: e.target.value }
                              carftParamsValues[index] = newValue
                              this.setState({
                                carftParamsValues: carftParamsValues,
                              })
                            }}
                          />
                          : 
                          <Input
                                type="number"
                                value={this.state.carftParamsValues[index].value}
                                onChange={(e) => {
                                  let carftParamsValues = this.state.carftParamsValues
                                  let oldValue = carftParamsValues[index]
                                  let newValue = { id: oldValue.id, paramType: oldValue.paramType, name: oldValue.name, value: parseFloat(e.target.value) }
                                  carftParamsValues[index] = newValue
                                  this.setState({
                                    carftParamsValues: carftParamsValues,
                                  })
                                }}
                              />
                          }
                            </>
                          </Form.Item>
                        ) : (
                          ''
                        )}
                      </li>
                    ))}
                  </ul>
                  <Form.Item label="描述" name="memo">
                    <TextArea
                      value={this.state.memo}
                      onChange={(e) => {
                        this.setState({
                          memo: e.target.value,
                        })
                      }}
                    ></TextArea>
                  </Form.Item>
                </div>
              )}
            </Form>
          </Drawer>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default OrderCraft
