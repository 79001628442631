import { Button, message, Space, Typography } from 'antd'
import React, { Component } from 'react'
import http from '../api/http'
const { Title, Link, Paragraph, Text } = Typography

// window.androidToWebView = function (str) {
//   message.success(str)
//   console.log(str)
//   window.callback.show(str)
// }

// window.setCallback = function (callback) {
//   message.success('init')
//   window.callback = callback
// }
export default class Test extends Component {
  constructor(props) {
    super(props)
    this.tagTree = React.createRef()
    this.state = {
      sampleTreeData: {},
      expandedRowKeys: [],
      selectedKeys: [],
      selectShearType: [],
      value: [],
      showStr: '',
    }
  }
  componentDidMount() {
    window.setCallback(this)
  }

  show = (str) => {
    this.setState({ showStr: str })
  }
  render() {
    return (
      <>
        {/* <Button
          onClick={() => {
            let file1 = { name: '001图', file_format: 'pdf', download_url: 'http://file.irobot-auto.com/irobot-auto/20211028/17b47bdc-9385-4abf-b37f-f7dc3d145935.pdf' }
            let file2 = { name: '002图', file_format: 'pdf', download_url: 'http://file.irobot-auto.com/irobot-auto/20211028/17b47bdc-9385-4abf-b37f-f7dc3d145935.pdf' }
            let fileList = []
            fileList.push(file1)
            fileList.push(file2)
            let outPutZipFileName = '测试'
            http.batchDownload(outPutZipFileName, fileList)
          }}
        >
          测试批量下载
        </Button> */}
        <Space>
          内容:{this.state.showStr}{' '}
          <Button
            onClick={() => {
              window.androidApi.downLoadCloudFileToUsb('http://')
            }}
          >
            测试
          </Button>
        </Space>
      </>
    )
    // return <TagTree ref={this.tagTree} target="物料分类" checkable={true} showShareType={true} showMgrBar={true} rootName="所有物料"></TagTree>
    // <ChineseAreaCascader
    //   onChange={(value, selectedOptions) => {
    //     console.log(selectedOptions)
    //   }}
    // ></ChineseAreaCascader>
  }
}
